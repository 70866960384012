import { calculateAdjustmentPrice } from './PricingUtils'
import { viewAgents } from './RestUtils'

const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT

const getFieldNameAndValue = (inputTarget) => {
  return {
    inputFullName: inputTarget.name,
    inputNewValue:
      inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value,
  }
}

const getInputIriAndOldValue = (props, inputName) => {
  let inputOldValue = props
  let iri = ''
  let mainClass = ''
  let alreadyHasMainClass = true
  let cancelElementLoop = false
  let inputNameSplit = inputName.split(/\[|\]|\./)
  inputNameSplit = inputNameSplit.filter((x) => x != '')

  inputNameSplit.forEach((element, index) => {
    //Stop processing if the relation between tables does not exist
    if (cancelElementLoop) {
      return
    }

    if (element === '@id') {
      return
    }

    if (inputOldValue !== null) {
      mainClass = element

      //if @id not found means that element is an integer
      if (inputOldValue['@id'] !== undefined) {
        iri = inputOldValue['@id']
      }

      if (inputOldValue[element] === undefined) {
        alreadyHasMainClass = false
        cancelElementLoop = true
        return
      }

      inputOldValue = inputOldValue[element]
    } else {
      alreadyHasMainClass = false
    }
  })

  return {
    iri: iri,
    inputOldValue: inputOldValue,
    classToInitialize: !alreadyHasMainClass ? mainClass : null,
  }
}

const getInputMethodAndValue = (input, props) => {
  let inputName = input.name.split('.').pop()
  if (inputName === '@id') {
    //if inputName === @id means that is a entity relation so we want the IRI to the main relation
    inputName = input.name.split('.').at(-2)
  }

  inputName = inputName.charAt(0).toUpperCase() + inputName.slice(1)

  let inputValue = input.type === 'checkbox' ? input.checked : input.value
  if (input.type === 'number' && inputValue === '') {
    //to avoid BE exceptions (null is allowed for int fields)
    inputValue = null
  }

  let quoteRequestId = null
  if (props.id && props.id.includes('/api/quote-requests/')) {
    quoteRequestId = props.id
  }

  return {
    method: `set${inputName}`,
    value: inputValue,
    popupText: inputName,
    quoteRequestId: quoteRequestId,
  }
}

const validateAutoSaveMultiInput = async (
  props,
  inputs,
  notify,
  refresh,
  popupText = 'Fields',
  avoidClassToInitializeCollision = false
) => {
  const { name: inputFullName } = inputs[0]
  const { iri, classToInitialize } = getInputIriAndOldValue(
    props,
    inputFullName
  )
  const data = []

  const fields = inputs.map((input, idx) => {
    const values = getInputMethodAndValue(input, props)

    let newClass = classToInitialize
    if (idx > 0 && avoidClassToInitializeCollision) {
      newClass = null
    }

    return {
      ...data,
      ...values,
      classToInitialize: newClass,
    }
  })

  const payload = {
    iri: iri,
    fields: fields,
  }

  await autoSaveField(payload, notify, refresh, popupText)
}

const validateAutoSaveSingleInput = async (
  props,
  inputEvent,
  notify,
  refresh,
  inputPopupText = ''
) => {
  const { inputFullName } = getFieldNameAndValue(inputEvent)
  const { iri, classToInitialize } = getInputIriAndOldValue(
    props,
    inputFullName
  )

  //check if oldValue is different from newValue to save, otherwise ignore
  const { method, value, popupText, quoteRequestId } = getInputMethodAndValue(
    inputEvent,
    props
  )

  if (inputPopupText === '') {
    inputPopupText = popupText
  }

  const payload = {
    iri: iri,
    fields: [
      {
        method,
        value,
        quoteRequestId,
        classToInitialize,
      },
    ],
  }

  if (
    payload.fields[0].method === 'setOutstandingFinanceNotes' &&
    payload.fields[0].value.length > 18
  ) {
    alert('The finance reference must be less than 19 characters long')
    return false
  }

  if (
    payload.fields[0].method === 'setCommercialPurchase' &&
    payload.fields[0].value === '1'
  ) {
    alert('Please enter a VAT number')
  }

  if (payload.fields[0].method === 'setHighAdjustmentCost') {
    if (value <= 0) {
      calculateAdjustmentPrice(quoteRequestId, 'cost', value)
    } else {
      alert('Please only enter a negative value')
      return false
    }
  }

  if (payload.fields[0].method === 'setHighAdjustmentPercentage') {
    if (value <= 0) {
      calculateAdjustmentPrice(quoteRequestId, 'perc', value)
    } else {
      alert('Please only enter a negative value')
      return false
    }
  }

  if (
    payload.fields[0].method === 'setMake' ||
    payload.fields[0].method === 'setModel'
  ) {
    viewAgents(quoteRequestId, true)
  }

  if (
    payload.fields[0].method === 'setHgvAccess' ||
    payload.fields[0].method === 'setPartsRemoved' ||
    payload.fields[0].method === 'setRoll' ||
    payload.fields[0].method === 'setV5'
  ) {
    payload.fields[0].value = value === 'true'
  }

  await autoSaveField(payload, notify, refresh, inputPopupText)
}

const autoSaveField = async (payload, notify, refresh, popupText) => {
  const request = new Request(`${entrypoint}/services/quote/save-fields`, {
    body: JSON.stringify(payload),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      if (responseJSON.error_msg !== undefined) {
        notify(responseJSON.error_msg, { type: 'error' })
      } else if (refresh !== null) {
        if (payload.fields[0].method === 'setFullName') {
          refresh()
        }

        if (payload.fields[0].method === 'setTooGoodToScrap') {
          refresh()
        }

        if (payload.fields[0].method === 'setDonationAmount') {
          refresh()
        }

        if (payload.fields[0].method === 'setHighAdjustmentCost') {
          refresh()
        }

        if (payload.fields[0].method === 'setHighAdjustmentPercentage') {
          refresh()
        }

        if (payload.fields[0].method === 'setStatus') {
          refresh()
        }

        if (payload.fields[0].method === 'setTooGoodToScrap') {
          refresh()
        }

        if (
          payload.fields[0].method === 'setMake' ||
          payload.fields[0].method === 'setModel'
        ) {
          refresh()
        }

        //If a new class is initialised, we need to refresh to sync details, otherwise we can get exceptions
        if (payload.fields[0].classToInitialize !== null) {
          refresh()
        }
      }
    })
}

export {
  validateAutoSaveSingleInput,
  validateAutoSaveMultiInput,
  autoSaveField,
}

import React from 'react'
import UploadList from './UploadList'
import NotesList from './NotesList'
import './style/index.scss'

const CustomerNotesPanel = () => {
  return (
    <React.Fragment>
      <NotesList />
      <UploadList />
    </React.Fragment>
  )
}

export default CustomerNotesPanel

import { useState } from 'react'
import { Button, CircularProgress, Grid } from '@mui/material'
import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import {
  quarantineSalvageBookingsLead,
  redirectToSB,
} from '../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const Quarantine = ({ sb }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [loading, setLoading] = useState(false)
  const notQuarantined = sb == null || sb.quarantined == null

  if (sb != null && (sb.cancelled || sb.payments[0])) {
    return null
  }

  const handleButtonClick = async function () {
    if (!loading) {
      setLoading(true)
    }

    // Update Quarantine lead status in SB
    const responseStatus = await quarantineSalvageBookingsLead(
      record.originId,
      notQuarantined
    )

    if (responseStatus) {
      if (notQuarantined) {
        redirectToSB(sb.quote_request_id, 'quarantine')
      }
      refresh()
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  return (
    <Grid item xs={12}>
      <Button
        variant="contained"
        color="secondary"
        label="Quarantine Lead"
        fullWidth
        disabled={loading}
        onClick={handleButtonClick}
        sx={{
          border: '2px solid #FFF !important',
          backgroundColor: `${notQuarantined ? '#880000' : '#F0F42F'}`,
          color: `${notQuarantined ? '#FFF' : '#2A2A2A'}`,
          height: '40px',
          fontWeight: '700',
          '&:hover': {
            backgroundColor: `${notQuarantined ? '#880000' : '#F0F42F'}`,
          },
        }}
      >
        {notQuarantined ? 'QUARANTINE LEAD' : 'UNQUARANTINE LEAD'}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Grid>
  )
}

Quarantine.propTypes = {
  sb: PropTypes.object,
}

export default Quarantine

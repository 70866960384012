import React, { useEffect, useRef, useState } from 'react'
import './style/index.scss'
import {
  ClickAwayListener,
  Fab,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PropTypes from 'prop-types'

const ModalOptions = ({ setContactLogScreen, listLength }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleOptionsModalToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleOptionsModalClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleOptionModalSelection = (event) => {
    setOpen(false)
    setContactLogScreen(event.target.id)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <React.Fragment>
      <Fab
        className="add-button"
        ref={anchorRef}
        id="add-options-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        size="medium"
        color="secondary"
        variant="extended"
        onClick={handleOptionsModalToggle}
        sx={{
          position: 'absolute',
          bottom: 16,
          right: 16,
          width: '40px',
          height: '40px',
          borderRadius: '100%',
        }}
      >
        <AddIcon className="add-button" />
      </Fab>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={listLength === 1 ? 'bottom-start' : 'top-start'}
        transition
        disablePortal
        sx={{
          zIndex: 100,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleOptionsModalClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="add-options-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    id="add-contact-log"
                    onClick={handleOptionModalSelection}
                  >
                    Add a Contact Log
                  </MenuItem>
                  <MenuItem
                    id="send-email"
                    onClick={handleOptionModalSelection}
                  >
                    Send Email
                  </MenuItem>
                  <MenuItem id="send-text" onClick={handleOptionModalSelection}>
                    Send Text
                  </MenuItem>
                  <MenuItem
                    id="send-email-text"
                    onClick={handleOptionModalSelection}
                  >
                    Send Email & Text
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}

ModalOptions.propTypes = {
  setContactLogScreen: PropTypes.func,
  listLength: PropTypes.number,
}

export default ModalOptions

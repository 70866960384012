import {
  EditBase,
  Form,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { Grid } from '@mui/material'
import { validateAutoSaveSingleInput } from '../../../../Utils/AutoSave'
import PropTypes from 'prop-types'
import { blockBlankCharFromInput } from '../../../../Utils/ConversionUtils'

const LeadDetailsPanel = (props) => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const roles = localStorage.getItem('permissions').split(',')

  return (
    <EditBase>
      <Form>
        <Grid
          sx={
            // If the lead is SC and the user is salvage then the customer details must be obscured
            roles.includes('ROLE_SALVAGE') &&
            record.leadContact.vehicle.vehicleSalvage.assignedTo == 'cars' && {
              backgroundColor: '#800',
              padding: '1rem',
              margin: '-1rem',
            }
          }
        >
          <Grid container spacing={1}>
            <Grid item flex={1}>
              <TextInput
                label="Customer Name"
                source="leadContact.fullName"
                readOnly={true}
                disabled={true}
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
              />
            </Grid>
            <Grid item flex={1}>
              <TextInput
                label="Alternative name"
                source="leadContact.contactName"
                readOnly={true}
                disabled={true}
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item flex={1}>
              <TextInput
                label="Contact Number"
                source="leadContact.telNumber"
                onKeyDown={blockBlankCharFromInput}
                readOnly={true}
                disabled={true}
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
              />
            </Grid>
            <Grid item flex={1}>
              <TextInput
                label="Alternative Number"
                source="leadContact.altTelNumber"
                onKeyDown={blockBlankCharFromInput}
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
                readOnly={true}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item flex={1}>
              <TextInput
                label="Postcode"
                source="leadContact.postcode"
                autoComplete="off"
                data-lpignore="true"
                data-form-type="other"
                onKeyDown={blockBlankCharFromInput}
                readOnly={true}
                disabled={true}
              />
            </Grid>
            <Grid item flex={2}>
              <TextInput
                label="Address Line 1"
                source="leadContact.addressLine1"
                readOnly={true}
                disabled={true}
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item flex={2}>
              <TextInput
                label="Address Line 2"
                source="leadContact.addressLine2"
                readOnly={true}
                disabled={true}
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
              />
            </Grid>
            <Grid item flex={1}>
              <TextInput
                label="Town"
                source="leadContact.town"
                readOnly={true}
                disabled={true}
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item flex={1}>
              <TextInput
                label="County"
                source="leadContact.county"
                readOnly={true}
                disabled={true}
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
              />
            </Grid>
            <Grid item flex={2}>
              <TextInput
                label="Email Address"
                source="leadContact.email"
                readOnly={true}
                disabled={true}
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </EditBase>
  )
}

export default LeadDetailsPanel

LeadDetailsPanel.propTypes = {
  setContactLogScreen: PropTypes.func,
  setHistoryPanelSelectedTab: PropTypes.func,
  setPostCodeChanged: PropTypes.func,
}

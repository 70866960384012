import { Button } from '@mui/material'
import { useNotify, useRefresh } from 'react-admin'
import { changePaymentTypeSalvageBookingsLead } from '../../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const ConvertPartialToBalanceButton = ({ sb, loading, setLoading }) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const handleButtonClick = async function () {
    if (!loading) {
      setLoading(true)
    }

    // Post new payment to salvage bookings
    const responseStatus = await changePaymentTypeSalvageBookingsLead(
      sb.quote_request_id,
      sb.payments.partial.id,
      'partial',
      'balance'
    )

    if (responseStatus) {
      refresh()
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  return (
    <Button
      variant="contained"
      color="primary"
      label="Convert Partial Payment to Full Balance Payment"
      fullWidth
      disabled={loading}
      onClick={handleButtonClick}
    >
      Convert Partial Payment to Full Balance Payment
    </Button>
  )
}

ConvertPartialToBalanceButton.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  sb: PropTypes.object,
}

export default ConvertPartialToBalanceButton

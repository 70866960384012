import React from 'react'
import { InputAdornment, TextField } from '@mui/material'
import {
  blockInvalidCharFromNumberInput,
  numberInputOnWheelPreventChange,
} from '../../../../Utils/ConversionUtils'
import PropTypes from 'prop-types'

const FeeAmountInput = ({ amountValidationError, handleChange, amount }) => {
  return (
    <React.Fragment>
      <TextField
        id="fees-amount"
        label="Amount"
        type="number"
        fullWidth
        onWheel={numberInputOnWheelPreventChange}
        onKeyDown={blockInvalidCharFromNumberInput}
        onChange={handleChange}
        value={amount}
        error={amountValidationError}
        InputProps={{
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }}
        sx={{
          '& .MuiInputBase-root': {
            height: '40px',
            minHeight: '40px',
          },
        }}
      />
    </React.Fragment>
  )
}

FeeAmountInput.propTypes = {
  amountValidationError: PropTypes.bool,
  handleChange: PropTypes.func,
  amount: PropTypes.string,
}

export default FeeAmountInput

import React from 'react'
import { dateConverter } from '../../../Utils/ConversionUtils'
import { getInboxNotificationCounter } from '../../../Utils/RestUtils'
import DialButton from '../Modals/DialButton'

const PhoneContact = (props) => {
  const { telNumber, altTelNumber, originId } = { ...props }

  return (
    <React.Fragment>
      {telNumber ? (
        <DialButton
          number={telNumber}
          goToEditPage={true}
          originId={originId}
        />
      ) : null}
      {altTelNumber ? (
        <DialButton
          number={altTelNumber}
          goToEditPage={true}
          originId={originId}
          addMarginTop={true}
        />
      ) : null}
    </React.Fragment>
  )
}

const DateFormatting = (props) => {
  const { dateToFormat, isBold } = { ...props }
  if (dateToFormat === undefined) {
    return
  }

  const { date, hour } = dateConverter(dateToFormat, false, false)

  return (
    <React.Fragment>
      <div className={`green ${isBold ? 'bold' : ''}`}>{date}</div>
      <div>
        <span className={`green ${isBold ? 'bold' : ''}`}>@ </span>
        <span className={`${isBold ? 'bold' : ''}`}>{hour}</span>
      </div>
    </React.Fragment>
  )
}

const VehicleDateFormatting = (props) => {
  const { dateToFormat } = { ...props }
  if (dateToFormat === undefined) {
    return
  }

  const { date } = dateConverter(dateToFormat, false, false)

  const dateSplit = date.split('/')

  return <div className="bold yellow">{dateSplit[2]}</div>
}

const VehicleStatus = (props) => {
  if (props.vehicleCollectionDeclined) {
    return 'Collection Cancelled'
  }

  if (props.collectionAgentId) {
    if (
      props.vehicleStatus == 'Booked in' ||
      props.vehicleStatus == 'Collected'
    ) {
      return props.vehicleStatus
    }

    if (props.vehicleStatus == 'Lead sent') {
      return 'Collection'
    }

    if (props.vehicleStatus == 'Lead received') {
      const response = getInboxNotificationCounter(props.qrId)
      if (response.count > 0) {
        return 'Arranging collection'
      }

      return 'Collection'
    }
  }

  return 'N/A'
}

export { PhoneContact, DateFormatting, VehicleDateFormatting, VehicleStatus }

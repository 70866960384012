import PropTypes from 'prop-types'

const sendLog = (quoteRequest, description, type = 'LOG_MANUAL_UPDATE') => {
  const user = localStorage.getItem('email')
  const log = { quoteRequest, description, type, user }
  const getRequest = () => {
    return new Request(
      `${process.env.REACT_APP_API_PLATFORM_ENDPOINT}/audit-log/`,
      {
        body: JSON.stringify(log),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'POST',
      }
    )
  }

  return fetch(getRequest())
    .then((response) => response.json())
    .then((JsonResponse) => {
      if (JsonResponse.Error !== undefined) {
        throw Error(JsonResponse.Error)
      }
      return JsonResponse
    })
}

sendLog.propTypes = {
  'quote-request': PropTypes.number,
  description: PropTypes.string,
  type: PropTypes.oneOf([
    'LOG_MANUAL_UPDATE',
    'LOG_AUTO_UPDATE',
    'LOG_CRON',
    'LOG_CRON_ERROR',
    'LOG_ERROR',
    'LOG_FEEDBACK',
    'LOG_PAGE_LEFT_OFFER',
    'LOG_SALVAGE_BOOKINGS_UPDATE',
    'LOG_MANUAL_SEND_EMAIL',
    'LOG_MANUAL_SEND_TEXT',
  ]),
}

export { sendLog }

import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from '@mui/material'
import { Button, useNotify, useRefresh } from 'react-admin'
import PasswordInputsBuilder from './PasswordInputsBuilder'
import RolesListDropdown from './RolesListDropdown'
import PropTypes from 'prop-types'
import { createNewUser } from '../../Utils/RestUtils'
import { validateAddUser } from './hooks/userValidation'

const AddUserModal = () => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [openModal, setOpenModal] = useState(false)
  const [userDetails, setUserDetails] = useState({
    id: 0,
    displayName: '',
    email: '',
    password: '',
    repeatPassword: '',
    roles: 'selectRole',
    disabled: false,
    highValueUser: false,
  })

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleInputChange = (e) => {
    const inputName = e.target.name

    const inputValue =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value

    setUserDetails({
      ...userDetails,
      [inputName]: inputValue,
    })
  }

  const handleAddUser = async () => {
    const error = validateAddUser(userDetails)
    if (error !== undefined) {
      notify(error, { type: 'error' })
      return
    }

    const response = await createNewUser({ ...userDetails })
    if (response.result === 'error' && response.error_msg) {
      notify(response.error_msg, { type: 'error' })
      return
    }

    userDetails.id = response.data.userId

    handleCloseModal()
    refresh()
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleOpenModal}
        label="Create"
        className="users-list__table-create-btn"
      />

      <Dialog
        onClose={handleCloseModal}
        open={openModal}
        className="view-users__modal"
      >
        <DialogTitle>Request Quote</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: '30px' }}>
            Please add name and details of the new user below:
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                name="displayName"
                label="Display Name"
                value={userDetails.displayName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="email"
                label="Email"
                value={userDetails.email}
                onChange={handleInputChange}
              />
            </Grid>
            <PasswordInputsBuilder
              userDetails={userDetails}
              handleInputChange={handleInputChange}
            />
            <Grid item xs={6}>
              <RolesListDropdown
                currentUser={userDetails}
                handleInputChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup sx={{ float: 'right' }}>
                <FormControlLabel
                  name="disabled"
                  onChange={handleInputChange}
                  control={
                    <Switch checked={userDetails.disabled} color="warning" />
                  }
                  label="User Account Disabled?"
                  sx={{ marginRight: 0 }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup sx={{ float: 'right' }}>
                <FormControlLabel
                  name="highValueUser"
                  onChange={handleInputChange}
                  control={
                    <Switch
                      checked={userDetails.highValueUser}
                      color="warning"
                    />
                  }
                  label="High value user?"
                  sx={{ marginRight: 0 }}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingTop: 0 }}>
          <Button label="Cancel" onClick={handleCloseModal} autoFocus />
          <Button label="Save" onClick={handleAddUser} autoFocus />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

AddUserModal.propTypes = {
  addUserToList: PropTypes.func,
}

export default AddUserModal

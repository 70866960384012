import React, { useState } from 'react'
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material'
import { useNotify, useRefresh } from 'react-admin'
import {
  blockInvalidCharFromNumberInput,
  numberInputOnWheelPreventChange,
} from '../../../../../Utils/ConversionUtils'
import PropTypes from 'prop-types'
import { payBalanceSalvageBookingsLead } from '../../../../../Utils/SalvageBookingsUtils'

function FinanceOutstanding({ sb }) {
  const notify = useNotify()
  const refresh = useRefresh()
  const [amount, setAmount] = useState('')
  const [validationError, setValidationError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleChange = (values) => {
    setValidationError(false)
    setAmount(values.currentTarget.value)
  }

  const handleButtonClick = async function () {
    if (amount === '') {
      setValidationError(true)
      notify('Amount must be entered', { type: 'error' })
      return
    }
    if (amount < 1) {
      setValidationError(true)
      notify('Amount must be at least £1', { type: 'error' })
      return
    }
    if (amount > sb.payments.outstandingBalance) {
      setValidationError(true)
      notify(
        'Amount must be less than ' +
          new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
          }).format(sb.payments.outstandingBalance)
      )
      return
    }

    if (!loading) {
      setLoading(true)
    }

    // Post new finance payment to salvage bookings
    const responseStatus = await payBalanceSalvageBookingsLead(
      sb.quote_request_id,
      amount,
      'finance',
      ''
    )

    if (responseStatus) {
      refresh()
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  if (sb.payments.balance != null) {
    return (
      <Grid item xs={12}>
        Balance already paid
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid item xs={6}>
          <TextField
            id="finance-payment-amount"
            label="Finance Amount"
            type="number"
            onWheel={numberInputOnWheelPreventChange}
            onKeyDown={blockInvalidCharFromNumberInput}
            fullWidth
            onChange={handleChange}
            value={amount}
            error={validationError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              ),
            }}
            sx={{
              '& .MuiInputBase-root': {
                marginBottom: 0,
                height: '40px',
                minHeight: '40px',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            label="Pay Partial"
            fullWidth
            disabled={loading}
            onClick={handleButtonClick}
            sx={{
              border: '2px solid #FFF !important',
              backgroundColor: '#ED9C23',
              color: '#FFF',
              height: '40px',
              fontWeight: '700',
              '&:hover': {
                backgroundColor: '#ED9C23',
              },
            }}
          >
            Pay Finance
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

FinanceOutstanding.propTypes = {
  sb: PropTypes.object,
}

export default FinanceOutstanding

import React from 'react'
import ListContactLog from './List'
import AddContactLog from './AddContactLog'
import SendEmail from './SendEmail'
import SendText from './SendText'
import SendEmailText from './SendEmailText'
import './style/index.scss'
import '../../style/contact-logs.scss'
import PropTypes from 'prop-types'

const ContactLogPanel = ({
  setSelectedTab,
  setViewAgentsModal,
  contactLogScreen,
  setContactLogScreen,
}) => {
  return (
    <React.Fragment>
      <div id="contact-log-section"></div>
      {contactLogScreen === 'list' && (
        <ListContactLog setContactLogScreen={setContactLogScreen} />
      )}
      {contactLogScreen === 'add-contact-log' && (
        <AddContactLog
          setSelectedTab={setSelectedTab}
          setViewAgentsModal={setViewAgentsModal}
          setContactLogScreen={setContactLogScreen}
        />
      )}
      {contactLogScreen === 'send-email' && (
        <SendEmail setContactLogScreen={setContactLogScreen} />
      )}
      {contactLogScreen === 'send-text' && (
        <SendText setContactLogScreen={setContactLogScreen} />
      )}
      {contactLogScreen === 'send-email-text' && (
        <SendEmailText setContactLogScreen={setContactLogScreen} />
      )}
    </React.Fragment>
  )
}

ContactLogPanel.propTypes = {
  contactLogScreen: PropTypes.string,
  setContactLogScreen: PropTypes.func,
  setSelectedTab: PropTypes.func,
  setViewAgentsModal: PropTypes.func,
}

export default ContactLogPanel

import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Notification, useLogin, useNotify } from 'react-admin'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material'
import './Login.scss'

const Login = ({}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const login = useLogin()
  const notify = useNotify()
  const location = useLocation()
  const [referrer, setReferrer] = useState('')

  useEffect(() => {
    const { nextPathname } = location.state || { nextPathname: referrer }
    setReferrer(nextPathname)
  }, [])

  const submit = (e) => {
    setLoading(true)
    e.preventDefault()

    login({ email, password }, `${referrer}`)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        notify('Invalid email or password', { type: 'error' })
      })
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh',
        marginTop: '0 !important',
        marginBottom: '0 !important',
      }}
    >
      <Card sx={{ maxWidth: '320px', paddingBottom: '18px' }}>
        <form onSubmit={submit}>
          <CardContent>
            <TextField
              type="email"
              name="email"
              label="Email"
              value={email}
              fullWidth
              margin="dense"
              size="small"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              type="password"
              name="password"
              label="Password"
              value={password}
              fullWidth
              margin="dense"
              size="small"
              mb={2}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Notification />
            <Button
              size="large"
              type="submit"
              disabled={loading}
              startIcon={
                loading && (
                  <CircularProgress size={14} thickness={3} color="primary" />
                )
              }
              sx={{ marginTop: '1rem' }}
              fullWidth
            >
              Login
            </Button>
          </CardContent>
        </form>
      </Card>
    </Grid>
  )
}

export default Login

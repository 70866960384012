import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { Link, useNotify, useRecordContext, useRefresh } from 'react-admin'
import { scrollToPanelById } from '../../../../Utils/QuoteRequestUtils'
import { assignAgentToLead } from '../../../../Utils/RestUtils'
import AssignAgentButton from './AssignAgentButton'
import CancelAssignmentModal from './CancelAssignmentModal'
import RequestQuoteModal from './RequestQuoteModal'
import { getCollectionAgents } from './hooks/getCollectionAgents'

const ListAgents = ({
  agents,
  assignedAgentId,
  assignedAgentZoneId,
  assignedAgentCompanyName,
  updateAssignedAgentOnAgentsData,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const record = useRecordContext()
  const tableRef = useRef()
  const { agentsList } = getCollectionAgents(agents, tableRef)
  const isScam = record.leadContact.scam
  const isSalvage = record.leadContact.vehicle.tooGoodToScrap
  const isAdmin = localStorage.getItem('permissions').includes('ROLE_ADMIN')

  const handleAssignAgent = async (agent) => {
    const response = await assignAgentToLead({
      quoteRequestId: record['@id'],
      agentId: agent.agent_id,
      offerId: agent.offer_id,
      agentInfo: agent.company_name,
      pageLeft: agent.car_offer_page_left,
      commission: agent.commission,
      donation: agent.donation !== null ? agent.donation : 0,
      collectorPrice: agent.price,
      customerPrice: agent.sort_price,
    })

    if (response.result === 'success') {
      refresh()

      updateAssignedAgentOnAgentsData(
        agent.agent_id,
        agent.company_name_origin
          ? agent.company_name_origin
          : agent.company_name
      )

      scrollToPanelById('bookingsPanel')
    } else {
      notify(response.error_msg, { type: 'error' })
    }
  }

  const handleDialNumberButtonClick = async (number) => {
    window.location = 'tel:' + number
  }

  const handleRuleUrl = (rule) => {
    let url = rule.iri.replaceAll('/', '%2F')
    return <Link to={'/rules/' + url}>({rule.name})</Link>
  }

  return (
    <TableContainer
      className="view-agents__collection-table"
      sx={{ marginTop: '1rem' }}
      ref={tableRef}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Customer service tel.</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Dropoff price</TableCell>
            <TableCell>Payment method</TableCell>
            <TableCell>Estimated method</TableCell>
            <TableCell>Collection times</TableCell>
            <TableCell>Ooh coll.</TableCell>
            <TableCell>Gtd coll.</TableCell>
            <TableCell>Collector price</TableCell>
            <TableCell>Pricing notes</TableCell>
            <TableCell>Commission</TableCell>
            <TableCell>Customer price</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agentsList &&
            agentsList.map((agent, index) => (
              <TableRow
                key={index}
                className={`${
                  assignedAgentId === agent.agent_id && isScam ? 'scam' : ''
                }`}
              >
                <TableCell>{agent.agent_id}</TableCell>
                <TableCell
                  className="yellow"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {agent.company_name}
                  {agent.weight_string && '\n'}
                  {agent.weight_string && agent.weight_string}
                  {isAdmin && agent.matching_rule_info && '\n'}
                  {isAdmin &&
                    agent.matching_rule_info &&
                    handleRuleUrl(agent.matching_rule_info)}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() =>
                      handleDialNumberButtonClick(agent.contact_tel_num)
                    }
                    color="primary"
                    style={{
                      whiteSpace: 'nowrap',
                      backgroundColor: '#F7FC03',
                      color: '#454D5E',
                      width: '100%',
                      marginTop: '0',
                    }}
                  >
                    {agent.contact_tel_num}
                  </Button>
                </TableCell>
                <TableCell>{agent.business_type}</TableCell>
                <TableCell>{agent.dropoff_extra}</TableCell>
                <TableCell>{agent.payment_method}</TableCell>
                <TableCell>{agent.collection_estimated}</TableCell>
                <TableCell>{agent.collection_times}</TableCell>
                <TableCell>{agent.collection_ooh}</TableCell>
                <TableCell>{agent.collection_estimated_max}</TableCell>
                <TableCell>£{agent.price}</TableCell>
                <TableCell
                  className="italic"
                  dangerouslySetInnerHTML={{
                    __html: `${agent.pricing_notes
                      .replace(/Â/g, '')
                      .replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
                  }}
                ></TableCell>
                <TableCell>£{agent.commission}</TableCell>
                <TableCell>
                  £{(agent.customer_price - agent.donation).toFixed(2)}
                  <br />
                  {agent.donation !== 0 && agent.donation !== null && (
                    <div>
                      (£{agent.donation} - {agent.charity})
                    </div>
                  )}
                </TableCell>
                <TableCell sx={{ width: '175px' }}>
                  {(!isSalvage ||
                    (isSalvage && agent.agent_id !== 121) ||
                    (isSalvage && agent.agent_id !== 86)) && (
                    <Stack gap={0.5}>
                      {assignedAgentId > 0 ? (
                        <CancelAssignmentModal
                          assignedAgentId={assignedAgentId}
                          assignedAgentZoneId={assignedAgentZoneId}
                          assignedAgentCompanyName={assignedAgentCompanyName}
                          selectedAgentId={agent.agent_id}
                          selectedAgentZoneId={agent.offer_id}
                          selectedAgentCompanyName={agent.company_name_origin}
                          handleAssignAgent={() => handleAssignAgent(agent)}
                          updateAssignedAgentOnAgentsData={
                            updateAssignedAgentOnAgentsData
                          }
                        />
                      ) : (
                        <AssignAgentButton
                          handleAssignAgent={() => handleAssignAgent(agent)}
                        />
                      )}
                      <RequestQuoteModal agentId={agent.agent_id} />
                    </Stack>
                  )}
                </TableCell>
              </TableRow>
            ))}

          {agentsList && agentsList.length === 0 && (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" colSpan={14} className="yellow bold">
                No collection agents available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ListAgents

ListAgents.propTypes = {
  agents: PropTypes.array,
  assignedAgentId: PropTypes.number,
  assignedAgentZoneId: PropTypes.number,
  assignedAgentCompanyName: PropTypes.string,
  updateAssignedAgentOnAgentsData: PropTypes.func,
}

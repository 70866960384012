import React from 'react'
import { useRecordContext } from 'react-admin'
import { Grid, Typography } from '@mui/material'
import BalancePaid from './BalancePaid'
import BalancePending from './BalancePending'
import BalanceOutstanding from './BalanceOutstanding'
import BalanceOutstandingByCheque from './BalanceOutstandingByCheque'
import PropTypes from 'prop-types'
import ConvertBalanceToPartialButton from './ConvertBalanceToPartialButton'

const BalancePayment = ({ sb, setPopupPanelName, setPopupPanelProps }) => {
  const record = useRecordContext()

  if (sb == null || sb.cancelled != null || sb.assigned_to_copart_at == null) {
    return null
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: '#ED9C23',
              fontWeight: '700',
              letterSpacing: '0.46px',
              marginRight: '5px',
              textTransform: 'uppercase',
            }}
          >
            Balance Payment:
          </Typography>
        </Grid>
        {sb.payments.balance ? (
          sb.payments.balance.payment_paid ? (
            sb.payments.outstandingBalance > 0 ? (
              <ConvertBalanceToPartialButton sb={sb} />
            ) : (
              <BalancePaid sb={sb} />
            )
          ) : (
            <BalancePending sb={sb} />
          )
        ) : record.leadContact.vehicle.vehicleSalvage.chequePayment ? (
          <BalanceOutstandingByCheque
            sb={sb}
            setPopupPanelName={setPopupPanelName}
            setPopupPanelProps={setPopupPanelProps}
          />
        ) : (
          <BalanceOutstanding sb={sb} setPopupPanelName={setPopupPanelName} />
        )}
      </Grid>
    </React.Fragment>
  )
}

BalancePayment.propTypes = {
  sb: PropTypes.object,
  setPopupPanelName: PropTypes.func,
  setPopupPanelProps: PropTypes.func,
}

export default BalancePayment

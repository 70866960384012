import { Card, CardContent, Typography } from '@mui/material'
import {
  BooleanField,
  Datagrid,
  EditButton,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  List,
  TextField,
  useRecordContext,
} from 'react-admin'
import get from 'lodash/get'
import PropTypes from 'prop-types'

const StatusFilter = () => (
  <FilterList label="Active status">
    <FilterListItem
      label="Active"
      value={{
        collectionAgentContactActive: 1,
      }}
    />
    <FilterListItem
      label="Inactive"
      value={{
        collectionAgentContactActive: 0,
      }}
    />
  </FilterList>
)

const FilterSidebar = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch
        label="Search Company Name"
        source="collectionAgentCompanyName"
      />
      <StatusFilter />
    </CardContent>
  </Card>
)

const UnixDateField = ({ source }) => {
  const record = useRecordContext()
  let timestamp = get(record, source)
  let time = timestamp * 1000
  return <span>{new Date(time).toLocaleDateString()}</span>
}

UnixDateField.propTypes = {
  source: PropTypes.string,
}

const ListAgents = (props) => (
  <List
    {...props}
    aside={<FilterSidebar />}
    title="Collection Agents"
    perPage={500}
    sort={{ field: 'collectionAgentCreateDate', order: 'DESC' }}
  >
    <Datagrid>
      <TextField label="Company Name" source="collectionAgentCompanyName" />
      <UnixDateField label="Date created" source="collectionAgentCreateDate" />
      <TextField label="Postcode" source="collectionAgentPostcode" />
      <BooleanField label="Active" source="collectionAgentContactActive" />
      <EditButton />
    </Datagrid>
  </List>
)

const ListCollectionAgent = (props) => {
  return (
    <CardContent>
      <Typography typography="h4" component="h1" mb={2}>
        Collection Agents
      </Typography>
      <ListAgents {...props} />
    </CardContent>
  )
}

export default ListCollectionAgent

import { useState, useEffect } from 'react'
import {
  getOwedFeesByDate,
  getOwedFeesByDateCsv,
  updateOwedFees,
} from '../../Utils/RestUtils'
import {
  Alert,
  Box,
  Button,
  Card,
  Dialog,
  DialogTitle,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  CardContent,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Close } from '@mui/icons-material'

function ListOwedFees() {
  const [owedFees, setOwedFees] = useState()
  const [feesDetails, setFeesDetails] = useState(null)
  const [filteringDate, setFilteringDate] = useState(new Date())
  const [showDialog, setShowDialog] = useState(false)

  function filterSelectedDate(value) {
    let filterDate = filteringDate
    if (value) {
      filterDate = value
    }

    return filterDate.getMonth() + 1 + '-' + filterDate.getFullYear()
  }

  async function loadOwedFeesFromApi() {
    const dateToFilter = filterSelectedDate()
    setOwedFees(await getOwedFeesByDate(dateToFilter))
  }

  useEffect(() => {
    loadOwedFeesFromApi()
  }, [])

  async function handleDateChange(selectedDate) {
    if (!selectedDate.isValid()) return
    setFilteringDate(selectedDate.toDate())

    const dateToFilter = filterSelectedDate(selectedDate.toDate())
    setOwedFees(await getOwedFeesByDate(dateToFilter))
  }

  function handleExportClick(event) {
    event.preventDefault()

    const dateToFilter = filterSelectedDate(filteringDate)
    getOwedFeesByDateCsv(dateToFilter)
  }

  async function refreshOwedFeesList(key) {
    const dateToFilter = filterSelectedDate(filteringDate)
    await updateOwedFees(owedFees.data[key].collection_agent_id, dateToFilter)
    setOwedFees(await getOwedFeesByDate(dateToFilter))
  }

  const handleClose = () => {
    setShowDialog(false)
  }

  const OwedFeesList = () => {
    if (owedFees.count == 0 || owedFees.data == undefined) {
      return (
        <Alert icon={false} severity="error">
          No invoices were found for this month.
        </Alert>
      )
    }

    return (
      <div>
        <Box style={{ display: 'flex', marginTop: '10px' }}>
          <Card style={{ flex: '1 1 auto' }}>
            <Table sx={{ padding: 2 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Collection Agent ID
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Phone Number
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Amount Owed
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(owedFees.data).map((key) => (
                  <TableRow key={key}>
                    <TableCell>
                      {owedFees.data[key].collection_agent_id}
                    </TableCell>
                    <TableCell>
                      {owedFees.data[key].collection_agent_company_name}
                    </TableCell>
                    <TableCell>
                      {owedFees.data[key].collection_agent_contact_tel_num}
                    </TableCell>
                    <TableCell>
                      £
                      {parseFloat(owedFees.data[key].total_to_invoice).toFixed(
                        2
                      )}
                    </TableCell>
                    <TableCell>{owedFees.data[key].payment_status}</TableCell>
                    <TableCell>
                      <Button
                        sx={{ marginBottom: '5px' }}
                        onClick={() => {
                          setShowDialog(true)
                          setFeesDetails(owedFees.data[key])
                        }}
                      >
                        View details
                      </Button>
                      <br />
                      {owedFees.data[key].payment_status.toLowerCase() !==
                        'paid' && (
                        <Button
                          onClick={() => {
                            refreshOwedFeesList(key)
                          }}
                        >
                          Mark as paid
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </Box>
        <OwedFeesDetails />
      </div>
    )
  }

  const OwedFeesDetails = () => {
    if (feesDetails !== null) {
      return (
        <Dialog onClose={handleClose} open={showDialog} maxWidth={'lg'}>
          <DialogTitle
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h5">Owed Fees details</Typography>
            <Button
              startIcon={<Close />}
              style={{ marginTop: 0 }}
              onClick={() => {
                setShowDialog(false)
                setFeesDetails(null)
              }}
            >
              Close
            </Button>
          </DialogTitle>

          <Card sx={{ padding: 1 }}>
            <LeadInfoBuilder {...feesDetails.collection_agent_leads} />
            <SmsInfoBuilder {...feesDetails.collection_agent_smss} />
          </Card>
        </Dialog>
      )
    }

    return <></>
  }

  const LeadInfoBuilder = (leadData) => {
    if (Object.keys(leadData).length == 0) {
      return <></>
    }

    return (
      <Card style={{ flex: '1 1 auto' }}>
        <Table sx={{ padding: 2 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Lead ID</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Added</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                Customer name
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                Customer postcode
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                Car make / model
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                Car registration
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Invoice ID</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                Commission value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(leadData).map((key) => (
              <TableRow key={key}>
                <TableCell>{leadData[key].request_quote_id}</TableCell>
                <TableCell>{leadData[key].lead_created}</TableCell>
                <TableCell>{leadData[key].customer_name}</TableCell>
                <TableCell>{leadData[key].user_postcode}</TableCell>
                <TableCell>
                  {leadData[key].car_make} / {leadData[key].car_model}
                </TableCell>
                <TableCell>{leadData[key].car_registration}</TableCell>
                <TableCell>{leadData[key].freshbooks_invoice_id}</TableCell>
                <TableCell>
                  £{parseFloat(leadData[key].total_to_pay).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    )
  }

  const SmsInfoBuilder = (smsData) => {
    if (Object.keys(smsData).length == 0) {
      return <></>
    }

    return (
      <Card style={{ flex: '1 1 auto' }}>
        <Table sx={{ padding: 2 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Sms ID</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Lead ID</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Date sent</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Invoice ID</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(smsData).map((key) => (
              <TableRow key={key}>
                <TableCell>{smsData[key].smss_id}</TableCell>
                <TableCell>{smsData[key].request_quote_id}</TableCell>
                <TableCell>{smsData[key].timestamp}</TableCell>
                <TableCell>{smsData[key].freshbooks_invoice_id}</TableCell>
                <TableCell>
                  £{parseFloat(smsData[key].sms_price).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    )
  }

  const DateTimePicker = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={['month', 'year']}
          maxDate={
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
          }
          inputFormat="MM/YYYY"
          label="From"
          value={dayjs(filteringDate)}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    )
  }

  return (
    <CardContent>
      <Typography typography="h4" component="h1" mb={2}>
        Owed Fees
      </Typography>
      <Typography typography="h5" component="h1" mb={2}>
        Please select a month
      </Typography>
      <Box
        sx={{
          display: 'flex',
          marginTop: '1rem',
          marginBottom: '1rem',
          gap: '1rem',
        }}
      >
        <Box>
          <DateTimePicker />
        </Box>
        {owedFees != null && owedFees.result !== 'error' && (
          <Box>
            <Button onClick={handleExportClick}>Export</Button>
          </Box>
        )}
      </Box>
      {owedFees != null ? <OwedFeesList /> : <p>Loading...</p>}
    </CardContent>
  )
}

export default ListOwedFees

import { useState } from 'react'
import { Box, Button, CircularProgress } from '@mui/material'
import { useNotify, useRefresh } from 'react-admin'
import { changePaymentTypeSalvageBookingsLead } from '../../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const ConvertBalanceToPartialButton = ({ sb }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [loading, setLoading] = useState(false)

  const handleButtonClick = async function () {
    if (!loading) {
      setLoading(true)
    }

    const responseStatus = await changePaymentTypeSalvageBookingsLead(
      sb.quote_request_id,
      sb.payments.balance.id,
      'balance',
      'partial'
    )

    if (responseStatus) {
      refresh()
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        label="Convert Balance Payment to Partial Payment"
        fullWidth
        disabled={loading}
        onClick={handleButtonClick}
      >
        Convert Balance Payment to Partial Payment
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  )
}

ConvertBalanceToPartialButton.propTypes = {
  sb: PropTypes.object,
}

export default ConvertBalanceToPartialButton

const validateAddUser = (userDetails) => {
  const error = validateCommonUserInputs(userDetails)
  if (error !== undefined) {
    return error
  }

  if (userDetails.password === '' || userDetails.repeatPassword === '') {
    return 'Please insert a password'
  }

  if (userDetails.roles === 'selectRole') {
    return 'Please select a role'
  }
}

const validateEditUser = (userDetails) => {
  const error = validateCommonUserInputs(userDetails)
  if (error !== undefined) {
    return error
  }
}

const validateCommonUserInputs = (userDetails) => {
  if (userDetails.displayName === '') {
    return 'Please insert a valid name'
  }

  if (userDetails.email === '') {
    return 'Please insert a valid email'
  }

  if (userDetails.roles.length === 0) {
    return 'Please select a role'
  }

  if (userDetails.password !== userDetails.repeatPassword) {
    return 'Passwords do not match'
  }
}

export { validateAddUser, validateEditUser }

import { Button, Typography } from '@mui/material'

import { TextField, Show, TabbedShowLayout, Tab } from 'react-admin'

const ShowCollectionAgent = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Agent Info">
          <Typography variant="h6" gutterBottom>
            Basic information
          </Typography>
          <TextField source="collectionAgentCompanyName" fullWidth />
          <TextField source="collectionAgentSmsName" fullWidth />
          <TextField source="collectionAgentBusinessType" fullWidth />
          <TextField source="collectionAgentAtfNo" fullWidth />
          <TextField source="collectionAgentWasteLicenseNo" fullWidth />
          <TextField source="collectionAgentCompanyRegNo" fullWidth />

          <Typography variant="h6" gutterBottom>
            Main office details
          </Typography>

          <TextField source="collectionAgentAddress1" fullWidth />
          <TextField source="collectionAgentAddress2" fullWidth />
          <TextField source="collectionAgentTown" fullWidth />
          <TextField source="collectionAgentCounty" fullWidth />
          <TextField source="collectionAgentPostcode" fullWidth />
          <TextField source="collectionAgentCompanyRegNo" fullWidth />
        </Tab>
        <Tab label="Contact Info">
          <TextField source="collectionAgentContactName" fullWidth />
          <TextField source="collectionAgentContactTelNum" fullWidth />
          <TextField source="collectionAgentContactAltTelNum" fullWidth />
          <TextField source="collectionAgentContactCustomerTelNum" fullWidth />
          <TextField source="collectionAgentCustomerServiceEmail" fullWidth />
          <TextField source="collectionAgentContactEmail" fullWidth />
          <TextField source="scfcContactEmail" fullWidth />
          <TextField source="collectionAgentDirectorEmail" fullWidth />
          <TextField source="collectionAgentContactEmail" fullWidth />
        </Tab>
        <Tab label="Freshbooks Details">
          <TextField source="freshbooksId" fullWidth />
          <TextField source="invoiceEmail" fullWidth />
        </Tab>
        <Tab label="Extra Information"></Tab>
        <Tab label="Collection Yards"></Tab>
        <Tab label="Collection Zones">
          <Button variant="contained" color="primary">
            Bulk Update Zones
          </Button>
        </Tab>
        <Tab label="Change Password"></Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default ShowCollectionAgent

import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Grid, TextField } from '@mui/material'
import { Button } from 'react-admin'
import {
  blockInvalidCharFromNumberInput,
  numberInputOnWheelPreventChange,
} from '../../../../Utils/ConversionUtils'
import {
  BMS_SALVAGE_AGENT_ID,
  SHORE_CARS_AGENT_ID,
} from '../../../../Utils/ConstantsUtils'
import ReassignLeadButton from '../ReassignLeadButton'

const AssignToCopart = ({ sb, setPopupPanelName, setPopupPanelProps }) => {
  const [reserveValidationError, setReserveValidationError] = useState(null)
  const [reserve, setReserve] = useState('')
  const [purchasePriceValidationError, setPurchasePriceValidationError] =
    useState(null)
  const [purchasePrice, setPurchasePrice] = useState('')
  const [extraWorkCostValidationError, setExtraWorkCostValidationError] =
    useState(null)
  const [extraWorkCost, setExtraWorkCost] = useState('')
  const [
    desiredAuctionPriceValidationError,
    setDesiredAuctionPriceValidationError,
  ] = useState(null)
  const [desiredAuctionPrice, setDesiredAuctionPrice] = useState('')

  const handleReserveChange = (event) => {
    setReserveValidationError('')
    if (event.target.value <= 0) {
      setReserveValidationError('Reserve must be more than 0')
    }

    setReserve(event.target.value)
  }

  const handlePurchasePriceChange = (event) => {
    setPurchasePriceValidationError('')
    if (event.target.value < 0) {
      setPurchasePriceValidationError('Purchase Price must be at least 0')
    }

    setPurchasePrice(event.target.value)
  }

  const handleExtraWorkCostChange = (event) => {
    setExtraWorkCostValidationError('')
    if (event.target.value < 0) {
      setExtraWorkCostValidationError('Extra work cost must be at least 0')
    }

    setExtraWorkCost(event.target.value)
  }

  const handleDesiredAuctionPriceChange = (event) => {
    setDesiredAuctionPriceValidationError('')
    if (event.target.value < 0) {
      setDesiredAuctionPriceValidationError(
        'Desired auction price must be at least 0'
      )
    }

    setDesiredAuctionPrice(event.target.value)
  }

  const handleButtonClick = () => {
    let validationError = false

    if (reserve === '') {
      setReserveValidationError('Reserve must be set')
      validationError = true
    }
    if (purchasePrice === '') {
      setPurchasePriceValidationError('Purchase price must be set')
      validationError = true
    }
    if (extraWorkCost === '') {
      setExtraWorkCostValidationError('Extra work cost must be set')
      validationError = true
    }
    if (desiredAuctionPrice === '') {
      setDesiredAuctionPriceValidationError('Desired auction price must be set')
      validationError = true
    }
    if (validationError) return

    setPopupPanelProps({
      scPurchasePrice: purchasePrice,
      scExtraWorkCost: extraWorkCost,
      scDesiredAuctionPrice: desiredAuctionPrice,
    })
    setPopupPanelName('premiumLead')
  }

  return (
    <React.Fragment>
      <Grid item xs={5}></Grid>
      <Grid item xs={2}>
        <TextField
          id="reserve-assign-copart"
          label="Reserve"
          variant="outlined"
          type="number"
          value={reserve}
          onWheel={numberInputOnWheelPreventChange}
          onKeyDown={blockInvalidCharFromNumberInput}
          onChange={handleReserveChange}
          error={!!reserveValidationError}
          helperText={reserveValidationError ?? ' '}
          sx={{
            '& .MuiInputBase-root': {
              height: '40px',
              minHeight: '40px',
            },
          }}
        />
        <TextField
          id="sc-purchase-price"
          label="Purchase Price"
          variant="outlined"
          type="number"
          value={purchasePrice}
          onWheel={numberInputOnWheelPreventChange}
          onKeyDown={blockInvalidCharFromNumberInput}
          onChange={handlePurchasePriceChange}
          error={!!purchasePriceValidationError}
          helperText={purchasePriceValidationError ?? ' '}
          sx={{
            '& .MuiInputBase-root': {
              height: '40px',
              minHeight: '40px',
            },
          }}
        />
        <TextField
          id="sc-extra-work-cost"
          label="Extra Work Cost"
          variant="outlined"
          type="number"
          value={extraWorkCost}
          onWheel={numberInputOnWheelPreventChange}
          onKeyDown={blockInvalidCharFromNumberInput}
          onChange={handleExtraWorkCostChange}
          error={!!extraWorkCostValidationError}
          helperText={extraWorkCostValidationError ?? ' '}
          sx={{
            '& .MuiInputBase-root': {
              height: '40px',
              minHeight: '40px',
            },
          }}
        />
        <TextField
          id="sc-desired-auction-price"
          label="Desired Auction Price"
          variant="outlined"
          type="number"
          value={desiredAuctionPrice}
          onWheel={numberInputOnWheelPreventChange}
          onKeyDown={blockInvalidCharFromNumberInput}
          onChange={handleDesiredAuctionPriceChange}
          error={!!desiredAuctionPriceValidationError}
          helperText={desiredAuctionPriceValidationError ?? ' '}
          sx={{
            '& .MuiInputBase-root': {
              height: '40px',
              minHeight: '40px',
            },
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          label="Assign to copart"
          fullWidth
          onClick={handleButtonClick}
          sx={{
            border: '2px solid #FFF !important',
            backgroundColor: '#13A310',
            color: '#FFF',
            height: '40px',
            fontWeight: '700',
            textTransform: 'uppercase',
            '&:hover': {
              backgroundColor: '#13A310',
            },
          }}
        ></Button>
        <ReassignLeadButton
          currentAgentId={SHORE_CARS_AGENT_ID}
          newAgentId={BMS_SALVAGE_AGENT_ID}
          buttonLabel="Transfer to BMS Salvage"
        />
      </Grid>
    </React.Fragment>
  )
}

AssignToCopart.propTypes = {
  sb: PropTypes.object,
  setPopupPanelName: PropTypes.func,
  setPopupPanelProps: PropTypes.func,
}

export default AssignToCopart

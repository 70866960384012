import React, { useState } from 'react'
import { Grid, TextField } from '@mui/material'
import SetNewStatusButton from '../StatusPanel/SetNewStatusButton'
import PropTypes from 'prop-types'

const EmptyCustomStatus = ({ sb }) => {
  const [validationError, setValidationError] = useState(false)
  const [status, setStatus] = useState('')

  const handleChange = (values) => {
    setValidationError(false)
    setStatus(values.currentTarget.value)
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={8} sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <TextField
            label="Custom Status"
            variant="outlined"
            type="text"
            fullWidth
            onChange={handleChange}
            error={validationError}
            sx={{
              '& .MuiInputBase-root': {
                marginBottom: 0,
                height: '40px',
                minHeight: '40px',
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <SetNewStatusButton
            sb={sb}
            label="Set Status"
            slug="custom"
            name={status}
            setValidationError={setValidationError}
            resetButton="false"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

EmptyCustomStatus.propTypes = {
  sb: PropTypes.object,
}

export default EmptyCustomStatus

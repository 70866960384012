import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const LeadDetails = ({ sb }) => {
  return (
    <React.Fragment>
      <Grid item xs={5}></Grid>
      <Grid item xs={2}>
        <strong>Vehicle Assigned to Copart</strong> <br />
        Lot:{' '}
        <a
          href={'http://seller.copart.co.uk/lotdisplay/' + sb.lot_number}
          target="_blank"
          rel="noreferrer"
        >
          {sb.lot_number}{' '}
        </a>
        {sb.shore_cars_details && (
          <>
            <br />
            Reserve: {sb.reserve} <br />
            Purchase Price: {sb.shore_cars_details.purchase_price ?? 'N/A'}{' '}
            <br />
            Extra Work Cost: {sb.shore_cars_details.extra_work_cost ??
              'N/A'}{' '}
            <br />
            Desired Auction Price:{' '}
            {sb.shore_cars_details.desired_auction_price ?? 'N/A'}
          </>
        )}
      </Grid>
    </React.Fragment>
  )
}
LeadDetails.propTypes = {
  sb: PropTypes.object,
}
export default LeadDetails

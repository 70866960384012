import { CardContent, Typography } from '@mui/material'
import ListAllSettings from '../../Components/SiteSettings/ListAllSettings'

const ListSettings = (props) => {
  return (
    <CardContent>
      <Typography typography="h4" component="h1" mb={2}>
        Settings
      </Typography>
      <ListAllSettings {...props} />
    </CardContent>
  )
}

export default ListSettings

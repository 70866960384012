import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const SalvageList = ({ salvageData }) => {
  return (
    <React.Fragment>
      <Typography variant="h1" className="view-agents__list-title">
        Salvage
      </Typography>
      <TableContainer className="view-agents__blue-table">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" className="uppercase yellow">
                Ruleset Id
              </TableCell>
              <TableCell align="left" className="">
                Ruleset name
              </TableCell>
              <TableCell align="left">Note</TableCell>
              <TableCell align="left">Up to price</TableCell>
              <TableCell align="left">Commission</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salvageData['list_of_rulesets'].map((rule) => (
              <TableRow
                key={rule[0].id}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell align="left">{rule[0].id}</TableCell>
                <TableCell align="left">{rule[0].name}</TableCell>
                <TableCell align="left">
                  {rule[0].note && rule[0].note.length > 0
                    ? rule[0].note
                    : 'N/A'}
                </TableCell>
                <TableCell align="left">£{rule[0].min_price}</TableCell>
                <TableCell align="left">£{rule[0].commissionValue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default SalvageList

SalvageList.propTypes = {
  salvageData: PropTypes.array,
}

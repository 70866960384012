import { useState } from 'react'
import { Box, Typography, Tooltip, IconButton, Stack } from '@mui/material'
import Grid from '@mui/material/Grid'
import { CheckRounded, Close, Help, Search } from '@mui/icons-material'
import {
  Create,
  TextInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  FormDataConsumer,
  SelectInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
import PropTypes from 'prop-types'
import $ from 'jquery'
import { getAddressLookup } from '../../Utils/RestUtils'

const IconWithTooltip = (props) => (
  <Tooltip title={props.text}>
    <Help />
  </Tooltip>
)

IconWithTooltip.propTypes = {
  text: PropTypes.any,
}

const CreateCollectionAgent = (props) => {
  const [addressList, setAddressList] = useState()

  const getAddressList = async () => {
    try {
      let postcode = $('[name="collectionAgentPostcode"]').val()
      const data = await getAddressLookup(postcode.replace(/\s+/g, ''))
      if (data.status === 'error') {
        notify(data.error, { type: 'error' })
      } else {
        setAddressList(data.address)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const clearAddress = () => {
    setAddressList([])
  }

  const populateAddress = (e) => {
    const addressParts = e.target.value.split(',')
    let inputsToSave = [
      {
        name: 'leadContact.postcode',
        value: $('[name="leadContact.postcode"]').val(),
      },
    ]

    if (typeof addressParts[0] !== 'undefined') {
      populateInput('collectionAgentAddress1', addressParts[0])
    }

    if (typeof addressParts[1] !== 'undefined') {
      populateInput('collectionAgentAddress2', addressParts[1])
    }

    if (typeof addressParts[3] !== 'undefined') {
      populateInput('collectionAgentTown', addressParts[3])
    }

    if (typeof addressParts[4] !== 'undefined') {
      populateInput('collectionAgentCounty', addressParts[4])
    }

    clearAddress()
  }

  const populateInput = (input, value) => {
    let field = document.querySelector('[name="' + input + '"]')

    const lastValue = field.value
    field.value = value
    const event = new Event('input', { bubbles: true })
    const tracker = field._valueTracker

    if (tracker) {
      tracker.setValue(lastValue)
    }

    field.dispatchEvent(event)
  }

  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="Agent Info">
          <Grid
            container
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 2fr)',
              gridRowStart: 1,
              gridRowEnd: 1,
              marginRight: '1rem',
              marginBottom: '1rem',
            }}
          >
            <Grid item>
              <Typography variant="h6" gutterBottom>
                Basic information
              </Typography>
              <Box flex={1} p="1em" fullWidth>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Company Name"
                          source="collectionAgentCompanyName"
                          fullWidth
                          required={true}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label={
                            <div>
                              SMS Sender
                              <IconWithTooltip text="It is used as a sender's name in the system SMS. It should be up to 11 characters." />
                            </div>
                          }
                          source="collectionAgentSmsName"
                          fullWidth
                          required={true}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <SelectInput
                          label={
                            <div>
                              Business type
                              <IconWithTooltip text="An ATF is a registered Authorized Treatment Facility, you must have your ATF no. to select this option. If you are not an ATF, you can select 'Specialist Buyer' and then enter your company registration no. below." />
                            </div>
                          }
                          source="collectionAgentBusinessType"
                          optionValue="id"
                          optionText="name"
                          choices={[
                            { id: 0, name: 'None' },
                            { id: 1, name: 'Registered ATF' },
                            { id: 2, name: 'Specialist Buyer' },
                            { id: 3, name: 'Specialist buyer and ATF' },
                          ]}
                          fullWidth
                        />
                      </Box>
                      <FormDataConsumer>
                        {({ formData, ...rest }) =>
                          formData.collectionAgentBusinessType == 1 && (
                            <Box flex={1} ml="0.5em">
                              <TextInput
                                label="Registered ATF no."
                                source="collectionAgentAtfNo"
                                fullWidth
                              />
                            </Box>
                          )
                        }
                      </FormDataConsumer>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Waste carries license no."
                          source="collectionAgentWasteLicenseNo"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label="Company reg no."
                          source="collectionAgentCompanyRegNo"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Typography variant="h6" gutterBottom>
                Freshbooks Details
              </Typography>
              <Box flex={1} p="1em" fullWidth>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Freshbooks Client ID"
                          source="freshbooksId"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          source="invoiceEmail"
                          fullWidth
                          required={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Typography variant="h6" gutterBottom>
                Main office address
              </Typography>
              <Box flex={1} p="1em" fullWidth>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Box display="flex">
                      <Box flex={1} mr="0">
                        <TextInput
                          label="Postcode"
                          source="collectionAgentPostcode"
                          fullWidth
                          required={true}
                          InputProps={{
                            endAdornment: (
                              <IconButton edge="end" onClick={getAddressList}>
                                <Search color="primary" />
                              </IconButton>
                            ),
                          }}
                        />
                        {addressList && addressList.length > 0 && (
                          <Stack direction="row">
                            <SelectInput
                              source="name"
                              label="Select Address list"
                              choices={addressList}
                              translateChoice={false}
                              onChange={(e) => populateAddress(e)}
                              optionValue="name"
                            />
                            <IconButton
                              style={{
                                alignSelf: 'baseline',
                                marginTop: '8px',
                              }}
                              onClick={clearAddress}
                            >
                              <Close />
                            </IconButton>
                          </Stack>
                        )}
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Address line 1"
                          source="collectionAgentAddress1"
                          fullWidth
                          required={true}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label="Address line 2"
                          source="collectionAgentAddress2"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Town"
                          source="collectionAgentTown"
                          fullWidth
                          required={true}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label="County"
                          source="collectionAgentCounty"
                          fullWidth
                          required={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <BooleanInput
                source="collectionAgentYardDiff"
                label="Different collection yard address"
                fullWidth
              />

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.collectionAgentYardDiff &&
                  formData.collectionAgentYardDiff == 1 && (
                    <ArrayInput source="collectionAgentYards">
                      <SimpleFormIterator>
                        <TextInput
                          source="collectionAgentYardPostcode"
                          label="Postcode"
                          required={true}
                        />
                        <TextInput
                          source="collectionAgentYardAddress1"
                          label="Address line 1"
                          required={true}
                        />
                        <TextInput
                          source="collectionAgentYardAddress2"
                          label="Address line 2"
                        />
                        <TextInput
                          source="collectionAgentYardTown"
                          label="Town"
                          required={true}
                        />
                        <TextInput
                          source="collectionAgentYardCounty"
                          label="County"
                          required={true}
                        />
                        <BooleanInput
                          source="collectionAgentYardPrimary"
                          label="Primary Address"
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>
            </Grid>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                Contact Information
              </Typography>
              <Box flex={1} p="1em" fullWidth>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Main contact name"
                          source="collectionAgentContactName"
                          fullWidth
                          required={true}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label={
                            <div>
                              Telephone number
                              <IconWithTooltip text="Admin direct contact." />
                            </div>
                          }
                          source="collectionAgentContactTelNum"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label={
                            <div>
                              Mobile number
                              <IconWithTooltip text="Leads forward to this number." />
                            </div>
                          }
                          source="collectionAgentContactAltTelNum"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label={
                            <div>
                              Customer Service number
                              <IconWithTooltip text="Number provided to customer." />
                            </div>
                          }
                          source="collectionAgentContactCustomerTelNum"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Lead Email"
                          source="collectionAgentContactEmail"
                          fullWidth
                          required={true}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label="Customer Services email"
                          source="collectionAgentCustomerServiceEmail"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label={
                            <div>
                              Contact email
                              <IconWithTooltip text="It's used as a contact email when sending Assign Lead template. Use semicolon(;) to add several addresses." />
                            </div>
                          }
                          source="scfcContactEmail"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label="Managing Director email"
                          source="collectionAgentDirectorEmail"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Typography variant="h6" gutterBottom>
                Extra information
              </Typography>
              <Box flex={1} p="1em" fullWidth>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <BooleanInput
                          source="collectionAgentContactActive"
                          label="Active?"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <BooleanInput
                          source="collectionAgentPriceRulesEnabled"
                          label="Price rules enabled"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <BooleanInput
                          source="collectionAgentBms"
                          label="Collection Planner Active"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex" mb="1rem">
                      <Box flex={1} ml="0">
                        <BooleanInput
                          source="weightAdjust"
                          label={
                            <div>
                              Add Extra Weight
                              <IconWithTooltip text="No - system default will be used" />
                            </div>
                          }
                          fullWidth
                        />
                      </Box>
                      <Box flex={1}>
                        <FormDataConsumer>
                          {({ formData, ...rest }) =>
                            formData.weightAdjust &&
                            formData.weightAdjust == 1 && (
                              <Box flex={1}>
                                <NumberInput
                                  source="weightAdjustVal"
                                  label={
                                    <div>
                                      Weight % Adjustment
                                      <IconWithTooltip text="If 0, no adjustment will be made" />
                                    </div>
                                  }
                                  fullWidth
                                />
                              </Box>
                            )
                          }
                        </FormDataConsumer>
                      </Box>
                    </Box>
                    <Box display="flex" fullWidth>
                      <Box flex={1}>
                        <TextInput
                          label="Any additional notes?"
                          multiline={true}
                          source="collectionAgentContactNotes"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex" fullWidth>
                      <Box flex={1}>
                        <TextInput
                          label="Pricing notes"
                          multiline={true}
                          source="collectionAgentPricingNotes"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1}>
                        <SelectInput
                          label="Pay extra for alloy wheels"
                          source="collectionAgentExtraForAlloyWheels"
                          fullwidth
                          choices={[
                            { id: 1, name: 'No' },
                            { id: 14, name: '£5.00 per set' },
                            { id: 2, name: '£8.00 per set' },
                            { id: 3, name: '£10.00 per set' },
                            { id: 4, name: '£12.00 per set' },
                            { id: 5, name: '£14.00 per set' },
                            { id: 6, name: '£16.00 per set' },
                            { id: 7, name: '£18.00 per set' },
                            { id: 8, name: '£20.00 per set' },
                            { id: 9, name: '£22.00 per set' },
                            { id: 10, name: '£24.00 per set' },
                            { id: 11, name: '£26.00 per set' },
                            { id: 12, name: '£28.00 per set' },
                            { id: 13, name: '£30.00 per set' },
                            { id: 15, name: '£35.00 per set' },
                            { id: 16, name: '£40.00 per set' },
                            { id: 17, name: '£45.00 per set' },
                            { id: 18, name: '£50.00 per set' },
                          ]}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1}>
                        <BooleanInput
                          source="collectionAgentPayOverweight"
                          label="We are happy to add 15% for vehicles over 1600kg"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      Payment methods
                    </Typography>
                    <Box display="flex">
                      <Box flex={1}>
                        <BooleanInput
                          source="collectionAgentPaymentPaypal"
                          label="Paypal"
                          color="success"
                          options={{ checkedIcon: <CheckRounded /> }}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1}>
                        <BooleanInput
                          source="collectionAgentPaymentCheque"
                          label="Cheque"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1}>
                        <BooleanInput
                          source="collectionAgentPaymentTransfer"
                          label="Transfer"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1}>
                        <BooleanInput
                          source="collectionAgentPaymentCashRequest"
                          label="Cash on special request"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1}>
                        <SelectInput
                          label="Pay Extra for Drop Off at Depot"
                          source="collectionAgentExtraForDropOff"
                          fullwidth
                          choices={[
                            { id: 0, name: 'No' },
                            { id: 10, name: '£10' },
                            { id: 15, name: '£15' },
                            { id: 20, name: '£20' },
                            { id: 25, name: '£25' },
                            { id: 30, name: '£30' },
                            { id: 35, name: '£35' },
                            { id: 40, name: '£40' },
                          ]}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

export default CreateCollectionAgent

import { FormControl, MenuItem, Select } from '@mui/material'
import { SALVAGE_BOOKINGS_FEE_TYPES } from '../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const FeeTypeSelect = ({ typeValidationError, sb, handleChange, type }) => {
  return (
    <FormControl variant="outlined" fullWidth error={typeValidationError}>
      <Select
        value={type}
        displayEmpty
        onChange={handleChange}
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{ fontWeight: 700, height: '40px !important' }}
      >
        <MenuItem value="">Select Fee Type</MenuItem>
        {SALVAGE_BOOKINGS_FEE_TYPES.map(
          (type) =>
            !sb.fees.some((e) => e.type === type.value) && (
              <MenuItem
                key={type.label}
                value={type.value}
                defaultamount={type.defaultAmount}
              >
                {type.label}
              </MenuItem>
            )
        )}
      </Select>
    </FormControl>
  )
}

FeeTypeSelect.propTypes = {
  typeValidationError: PropTypes.bool,
  sb: PropTypes.object,
  handleChange: PropTypes.func,
  type: PropTypes.string,
}

export default FeeTypeSelect

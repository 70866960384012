const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT

const UNASSIGN_EMAIL_TEMPLATE_TYPE = 'lead_unassigned_collector_tmp'

const getRequestEndpointUrl = (requestEndpoint) => {
  return requestEndpoint.replace('/api/', '')
}

const getCAPQuote = async (vehicle) => {
  const request = new Request(
    `${entrypoint}/services/pricing/upper-lower-bounds`,
    {
      body: JSON.stringify({ vehicleId: vehicle }),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const getManualCap = async (vehicle, recalculate) => {
  const request = new Request(
    `${entrypoint}/services/quote/calculate-manual-cap`,
    {
      body: JSON.stringify({ vehicle, recalculate }),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const getVehicleAdjustments = async (vehicleIri) => {
  const apiEndpoint = getRequestEndpointUrl(vehicleIri)
  const request = new Request(`${entrypoint}/${apiEndpoint}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON.leadContact.vehicle
    })
}

const getDamageListFromAPI = async (vehicleIri) => {
  const apiEndpoint = getRequestEndpointUrl(vehicleIri)
  const request = new Request(`${entrypoint}/${apiEndpoint}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON.leadContact.vehicle
    })
}

const getAdjustmentsSettings = async () => {
  const apiEndpoint = getRequestEndpointUrl('settings/adjustments')
  const request = new Request(`${entrypoint}/${apiEndpoint}?pagination=false`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON['hydra:member']
    })
}

const updateVehicleAdjustmentValue = async (adjustmentEndpoint, value) => {
  const apiEndpoint = getRequestEndpointUrl(adjustmentEndpoint)
  const request = new Request(`${entrypoint}/${apiEndpoint}`, {
    body: JSON.stringify({
      value,
      confirmed: true,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'PUT',
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const getOptions = async (target, resource) => {
  const request = new Request(
    `${entrypoint}/services/helper/options?target=${target}&resource=${resource}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getAdminUsers = async () => {
  const request = new Request(`${entrypoint}/services/helper/admin-users`, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'GET',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getCharities = async () => {
  const request = new Request(`${entrypoint}/services/helper/get-charities`, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'GET',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const postUploadZones = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/collection-agent/zone/add`,
    {
      body: formData,
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const getNotificationCount = async () => {
  const request = new Request(`${entrypoint}/services/notifications/count`, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'GET',
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getContactLog = async (quoteID, type) => {
  const request = new Request(
    `${entrypoint}/services/quote/contact-logs?quoteID=${quoteID}&type=${type}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getAutotraderLink = async (formData) => {
  const request = new Request(`${entrypoint}/services/quote/autotrader`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getRegLookup = async (formData) => {
  const request = new Request(`${entrypoint}/services/quote/reglookup`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const updateLeadSource = async (quoteID, source) => {
  const request = new Request(
    `${entrypoint}/services/quote/update-lead-source`,
    {
      body: JSON.stringify({
        quote: quoteID,
        source: source,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const createNewLead = async (formData) => {
  const request = new Request(`${entrypoint}/quote-flow/add-vehicle`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const updateVehicleDamage = async (id, indentifier, action) => {
  let formData = {
    lead: id,
    damageId: indentifier,
    action: action,
  }

  const request = new Request(`${entrypoint}/services/quote/update-damage`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getAddressLookup = async (postcode) => {
  let formData = {
    postcode: postcode,
  }

  const request = new Request(`${entrypoint}/services/quote/postcode-lookup`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const muteInbox = async (inboxId) => {
  const request = new Request(`${entrypoint}/services/inbox/mute`, {
    body: JSON.stringify({ inboxId: inboxId }),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }
      return responseJSON
    })
}

const searchInboundCall = async (formData) => {
  const request = new Request(`${entrypoint}/services/inbound-calls/search`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const hpiCheck = async (quoteID, additional) => {
  const request = new Request(
    `${entrypoint}/services/quote/hpi-check?quoteID=${quoteID}&additionalReg=${additional}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.result !== 'success') {
        return {
          result: 'error',
          message: 'Request failed: ' + responseJSON.message,
        }
      }

      return responseJSON
    })
}

const viewAgents = async (quoteID, refresh) => {
  let refreshLeads = 'false'
  if (refresh) {
    refreshLeads = 'true'
  }
  const request = new Request(
    `${entrypoint}/services/agents-proximity?iri=${quoteID}&range=10000&refresh=${refreshLeads}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
    .catch(function (error) {
      // catch
      return {
        result: 'error',
        message: 'Request failed: ' + error,
      }
    })
}

const getPricingNotes = async (quoteID) => {
  const request = new Request(
    `${entrypoint}/services/quote/pricing-notes?quoteID=${quoteID}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const calculatePrices = async (formData) => {
  const request = new Request(`${entrypoint}/services/quote/calculate-prices`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }
      return responseJSON
    })
}

const calculateCommission = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/quote/calculate-commission`,
    {
      body: JSON.stringify(formData),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }
      return responseJSON
    })
}

const getTemplates = async (method) => {
  const request = new Request(
    `${entrypoint}/services/helper/templates?method=${method}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getOwedFeesByDate = async (dateFilter) => {
  const apiEndpoint = getRequestEndpointUrl('services/collector-fees-by-date')
  const request = new Request(
    `${entrypoint}/${apiEndpoint}?date=${dateFilter}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getOwedFeesByDateCsv = async (dateFilter) => {
  const request = new Request(
    `${entrypoint}/services/collector-fees-by-date-csv?date=${dateFilter}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return await fetch(request)
    .then((res) => {
      return res.blob()
    })
    .then((blob) => {
      const href = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `owed-fees-export-${dateFilter}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch((err) => console.error(err))
}

const updateOwedFees = async (agentId, month) => {
  let formData = {
    agentId: agentId,
    date: month,
  }

  const request = new Request(`${entrypoint}/services/update-collector-fees`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const updateUserStatus = async (data) => {
  const request = new Request(`${entrypoint}/services/user/update-status`, {
    body: JSON.stringify(data),
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const getDashboard = async () => {
  const request = new Request(`${entrypoint}/services/dashboard`, {
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'GET',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getLeadNotifications = async (
  recordId,
  noteType,
  orderProperty = 'timestamp',
  orderType = 'desc'
) => {
  const request = new Request(
    `${entrypoint}/lead-notifications?itemsPerPage=10000&order[${orderProperty}]=${orderType}&quoteRequest.id=${recordId}&noteType=${noteType}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON['hydra:member']
    })
}

const getCustomerNotes = async (recordId) => {
  const request = new Request(
    `${entrypoint}/services/quote/customer-notes?quoteId=${recordId}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const getVehicleNotes = async (leadId) => {
  const request = new Request(
    `${entrypoint}/services/quote/vehicle-notes?leadId=${leadId}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const uploadCustomerPhotos = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/quote/upload-customer-photos`,
    {
      body: formData,
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => {
      if (response.status === 500) {
        return {
          status: 500,
        }
      }

      return response.json()
    })
    .then((responseJSON, responseStatus) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const uploadVATInvoices = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/quote/upload-vat-invoices`,
    {
      body: formData,
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const saveVehicleNote = async (formData) => {
  const request = new Request(`${entrypoint}/services/quote/vehicle-note`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const saveLeadNotifications = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/quote/lead-notification-note`,
    {
      body: JSON.stringify(formData),
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const updateLeadNotifications = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/quote/update-notification-note`,
    {
      body: JSON.stringify(formData),
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const deleteLeadNotifications = async (id) => {
  const request = new Request(`${entrypoint}/lead-notifications/${id}`, {
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'DELETE',
  })

  return fetch(request)
    .then((response) => {
      return { result: 'success' }
    })
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { result: 'success' }
    })
}

const assignAgentToLead = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/collection-agent/assign-agent`,
    {
      body: JSON.stringify(formData),
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const completeScrapBooking = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/quote/complete-scrap-booking`,
    {
      body: JSON.stringify(formData),
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const completeSalvageBooking = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/quote/complete-salvage-booking`,
    {
      body: JSON.stringify(formData),
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const getUnassignAgentCancellationEmailTemplates = async () => {
  const request = new Request(
    `${entrypoint}/services/collection-agent/unassign-agent-email-templates?templateType=${UNASSIGN_EMAIL_TEMPLATE_TYPE}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const getInboxNotificationCounter = async (quoteId) => {
  const request = new Request(
    `${entrypoint}/services/quote/inbox-notification-count?quoteId=${quoteId}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const requestAgentQuote = async (agentId, leadId, sendEmail) => {
  const request = new Request(
    `${entrypoint}/services/collection-agent/request-agent-quote?agentId=${agentId}&leadId=${leadId}&sendExportEmail=${sendEmail}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const unassignAgentFromLead = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/collection-agent/unassign-agent`,
    {
      body: JSON.stringify(formData),
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const getTagsList = async () => {
  const apiEndpoint = getRequestEndpointUrl('tags') + '?itemsPerPage=200'
  const request = new Request(`${entrypoint}/${apiEndpoint}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON['hydra:member']
    })
}

const updateTooGoodToScrapTags = async (data) => {
  const request = new Request(
    `${entrypoint}/services/quote/update-too-good-to-scrap-tags`,
    {
      body: JSON.stringify(data),
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const getQuoteRequestList = async (extraLink) => {
  const request = new Request(
    `${entrypoint}/services/quote/lead-list?${extraLink}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getPurchaseAgreement = async (data) => {
  const request = new Request(`${entrypoint}/purchase-agreement/view`, {
    body: JSON.stringify(data),
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const getUsersData = async (extraLink) => {
  const request = new Request(
    `${entrypoint}/services/user/users-list?${extraLink}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const saveUpdatedUser = async (user) => {
  const request = new Request(`${entrypoint}/services/user/edit-user`, {
    body: JSON.stringify(user),
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const createNewUser = async (user) => {
  const request = new Request(`${entrypoint}/services/user/add-user`, {
    body: JSON.stringify(user),
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const updateLeadTypeAndTags = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/collection-agent/update-lead-type-and-tags`,
    {
      body: JSON.stringify(formData),
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const cancelLeadBooking = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/quote/cancel-lead-booking`,
    {
      body: JSON.stringify(formData),
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const getTemporaryUploadURLs = async (quoteRequestId) => {
  const request = new Request(
    `${entrypoint}/services/quote/get-vehicle-image-urls`,
    {
      body: JSON.stringify({
        quoteRequestId: quoteRequestId,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getTemporaryQuoteRequestInvoiceUrls = async (quoteRequestId) => {
  const request = new Request(
    `${entrypoint}/services/quote/get-quote-request-invoice-urls`,
    {
      body: JSON.stringify({
        quoteRequestId: quoteRequestId,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const removeInvoice = async (invoiceId) => {
  const request = new Request(
    `${entrypoint}/services/quote/delete-vat-invoice`,
    {
      body: JSON.stringify({
        invoiceId: invoiceId,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getHpiLookupFiles = async (quoteRequestId) => {
  const request = new Request(`${entrypoint}/services/quote/get-hpi-lookups`, {
    body: JSON.stringify({
      quoteRequestId: quoteRequestId,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getCollectionAgentZones = async (agentId) => {
  const request = new Request(
    `${entrypoint}/collection-agents-zones?collectionAgent.id=${agentId}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON['hydra:member']
    })
}

const handleLeadLocking = async (method, leadId) => {
  const request = new Request(`${entrypoint}/services/quote/lead-locking`, {
    body: JSON.stringify({
      leadId: leadId,
      method: method,
    }),
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return { ...responseJSON }
    })
}

const handleLeadLockingBeacon = async (method, leadId) => {
  navigator.sendBeacon(
    `${entrypoint}/services/quote/lead-locking`,
    JSON.stringify({
      leadId: leadId,
      method: method,
    })
  )
}

const handleLeadLockingNavigation = async () => {
  const inUseId = localStorage.getItem('inUseID')
  const originID = localStorage.getItem('originID')
  const userID = localStorage.getItem('user_id')

  if (inUseId && originID && userID && inUseId === userID) {
    handleLeadLocking('unlock', originID)

    localStorage.removeItem('inUseID')
    localStorage.removeItem('originID')
  }
}

const getShoreCarsReporting = async (from, to) => {
  const apiEndpoint = getRequestEndpointUrl('services/reporting/shore-cars')
  const request = new Request(
    `${entrypoint}/${apiEndpoint}?fromDate=${from.toISOString()}&toDate=${to.toISOString()}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const reassignLead = async (quoteRequestId, currentAgentId, newAgentId) => {
  const apiEndpoint = getRequestEndpointUrl('services/reassign-lead')
  const request = new Request(
    `${entrypoint}/${apiEndpoint}/${quoteRequestId}/currentAgent/${currentAgentId}/newAgent/${newAgentId}`,
    {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

export {
  getContactLog,
  getNotificationCount,
  getCAPQuote,
  getVehicleAdjustments,
  getAdjustmentsSettings,
  updateVehicleAdjustmentValue,
  getOptions,
  getAdminUsers,
  postUploadZones,
  getAutotraderLink,
  getRegLookup,
  createNewLead,
  updateVehicleDamage,
  getDamageListFromAPI,
  muteInbox,
  getCharities,
  getAddressLookup,
  searchInboundCall,
  hpiCheck,
  viewAgents,
  getPricingNotes,
  calculatePrices,
  getTemplates,
  getOwedFeesByDate,
  getOwedFeesByDateCsv,
  updateOwedFees,
  getDashboard,
  updateUserStatus,
  getLeadNotifications,
  getManualCap,
  calculateCommission,
  getCustomerNotes,
  uploadCustomerPhotos,
  getVehicleNotes,
  saveLeadNotifications,
  saveVehicleNote,
  assignAgentToLead,
  getUnassignAgentCancellationEmailTemplates,
  requestAgentQuote,
  unassignAgentFromLead,
  uploadVATInvoices,
  getInboxNotificationCounter,
  getTagsList,
  getQuoteRequestList,
  getPurchaseAgreement,
  getUsersData,
  saveUpdatedUser,
  createNewUser,
  updateLeadTypeAndTags,
  updateTooGoodToScrapTags,
  cancelLeadBooking,
  completeScrapBooking,
  completeSalvageBooking,
  getTemporaryUploadURLs,
  getTemporaryQuoteRequestInvoiceUrls,
  getHpiLookupFiles,
  getCollectionAgentZones,
  deleteLeadNotifications,
  handleLeadLocking,
  handleLeadLockingBeacon,
  handleLeadLockingNavigation,
  removeInvoice,
  updateLeadNotifications,
  updateLeadSource,
  getShoreCarsReporting,
  reassignLead,
}

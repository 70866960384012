import { CurrencyPoundRounded } from '@mui/icons-material'
import { FormControl, Grid, InputAdornment, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import {
  DateTimeInput,
  EditBase,
  Form,
  NumberInput,
  SelectInput,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
  WithRecord,
} from 'react-admin'
import { validateAutoSaveSingleInput } from '../../../../Utils/AutoSave'
import { getAvailableStatuses } from '../../../../Utils/HelperUtils'
import DuplicateLeadButton from '../../Actions/DuplicateLeadButton'
import LeadSourceButton from '../../Actions/LeadSourceButton'
import MTButton from '../../Actions/MTButton'
import DateTimePickerValue from '../LatestContact'
import { sendLog } from '../../../../Class/AuditLog'
import { handleLeadLocking } from '../../../../Utils/RestUtils'
import { DEFAULT_STATUS_LIST } from '../../../../Utils/ConstantsUtils'

const LeadIDPanel = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [tooGoodToScrap, setTooGoodToScrap] = useState()
  const [initialLoad, setInitialLoad] = useState(true)

  const tooGood = record.leadContact.vehicle.tooGoodToScrap

  if (tooGood !== tooGoodToScrap) {
    setTooGoodToScrap(tooGood)
  }

  const leadStatuses = getAvailableStatuses(record.status)

  useEffect(() => {
    // Lock lead here
    handleLeadLocking('lock', record.originId)
    sendLog(record.originId, 'Viewed Lead', 'LOG_REPORTING_LOG')
    setInitialLoad(false)
  }, [])

  let assigned =
    record.leadContact.vehicle.vehicleCollection &&
    record.leadContact.vehicle.vehicleCollection.offerSelected &&
    record.leadContact.vehicle.vehicleCollection.offerSelected.collector

  const assignedStyle = {
    backgroundColor: 'green',
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
  }

  return (
    <EditBase>
      <Form>
        <Grid container spacing={1} sx={{ marginBottom: '2px' }}>
          <Grid item flex={2} style={{ marginBottom: '10px' }}>
            <DuplicateLeadButton />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextInput
              label="Quote ID"
              readOnly={true}
              disabled={true}
              source="originId"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
          <Grid item flex={1}>
            <NumberInput
              disabled={true}
              source="leadContact.vehicle.vehicleSalvage.chosenValue"
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <CurrencyPoundRounded fontSize="small" />
                  </InputAdornment>
                ),
              }}
              label="Price"
              defaultValue="0"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
          <Grid item flex={1}>
            <NumberInput
              disabled={true}
              source="leadContact.vehicle.vehicleSalvage.calcCommission"
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <CurrencyPoundRounded fontSize="small" />
                  </InputAdornment>
                ),
              }}
              label="Commission"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <FormControl sx={{ width: '100%' }} size="small">
              <SelectInput
                alwaysOn
                select
                key={2}
                readOnly={true}
                disabled={true}
                label="Lead Status"
                source="status"
                name="status"
                placeholder="Select a status"
                choices={DEFAULT_STATUS_LIST}
                onChange={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
              />
            </FormControl>
          </Grid>
          <Grid item flex={1}>
            <DateTimeInput
              label="Lead Created"
              readOnly={true}
              disabled={true}
              source="dateCreated"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
        </Grid>
        <Grid item flex={1}>
          <DateTimePickerValue />
        </Grid>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextInput
              label="Lead Source"
              readOnly={true}
              disabled={true}
              source="leadContact.leadContactAttributes.leadSource.title"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
          <Grid item flex={1}>
            <WithRecord
              label="Assigned To"
              render={(record) => {
                let render = true
                let value = ''
                let style = {}
                if (assigned && render) {
                  render = false
                  value =
                    record.leadContact.vehicle.vehicleCollection.offerSelected
                      .collector.collectionAgentCompanyName
                  style = assignedStyle
                }

                return (
                  <TextField
                    key={1}
                    label="Assigned To"
                    readOnly={true}
                    disabled={true}
                    value={value}
                    InputProps={{ style: style }}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ marginTop: '-4px' }}>
          <Grid item flex={2}>
            <Grid container spacing={1}>
              <Grid item flex={1}>
                <LeadSourceButton />
              </Grid>
              <Grid item flex={1}>
                <MTButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </EditBase>
  )
}

export default LeadIDPanel

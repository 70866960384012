import { ZoomIn, ZoomOut } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import {
  AppBar as BaseAppBar,
  ToggleThemeButton,
  useSidebarState,
} from 'react-admin'
import { darkTheme } from '../Theme/darkTheme'

const AppBar = (props) => {
  // const classes = useStyles
  const [open, setSidebarState] = useSidebarState()
  const zoomIn = () => {
    if (
      document.body.style.zoom === '' ||
      document.body.style.zoom === '100%'
    ) {
      document.body.style.zoom = '110%'
    } else if (document.body.style.zoom === '85%') {
      document.body.style.zoom = '100%'
    }
  }

  const zoomOut = () => {
    if (
      document.body.style.zoom === '' ||
      document.body.style.zoom === '100%'
    ) {
      document.body.style.zoom = '85%'
    } else if (document.body.style.zoom === '110%') {
      document.body.style.zoom = '100%'
    }
  }
  return (
    <BaseAppBar
      {...props}
      color="primary"
      open={open}
      onLoad={() => {
        setSidebarState(false)
      }}
      position="fixed"
      sx={{
        '.MuiButtonBase-root': {
          border: '0 !important',
        },
      }}
    >
      <img src="/scrap-car-comparison.svg" alt="ScrapCarComparison" />
      <span
        style={{
          flex: 1,
        }}
      />
      <IconButton color="inherit" onClick={() => zoomIn()}>
        <ZoomIn fontSize="large" />
      </IconButton>
      <IconButton color="inherit" onClick={() => zoomOut()}>
        <ZoomOut fontSize="large" />
      </IconButton>
      <ToggleThemeButton darkTheme={darkTheme} />
    </BaseAppBar>
  )
}

export default AppBar

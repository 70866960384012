import React, { useEffect, useState } from 'react'
import { Box, Typography, Tooltip, IconButton, Stack } from '@mui/material'
import {
  Check,
  CheckRounded,
  Clear,
  Close,
  Help,
  Search,
} from '@mui/icons-material'
import {
  Edit,
  TextInput,
  TabbedForm,
  FormTab,
  Datagrid,
  TextField,
  List,
  BooleanInput,
  FormDataConsumer,
  SelectInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  useRecordContext,
  SimpleForm,
  required,
  RadioButtonGroupInput,
  SaveButton,
  DeleteButton,
  BooleanField,
} from 'react-admin'
import ZonesBulkUploadButton from '../../Components/CollectionAgent/ZonesBulkUploadButton'
import RulesSetPanel from '../../Components/Rules/RulesSetPanel'
import {
  CreateInDialogButton,
  EditInDialogButton,
} from '@react-admin/ra-form-layout'

import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import $ from 'jquery'
import { getAddressLookup } from '../../Utils/RestUtils'

const DatagridActionsColumn = ({ children }) => <>{children}</>

DatagridActionsColumn.propTypes = {
  children: PropTypes.any,
}

const Collection = () => {
  const record = useRecordContext()
  let collectionAgentOohCollection = 0
  let collectionAgentEveningCollection = 0

  if (record.collectionAgentOohCollection == 0) {
    collectionAgentOohCollection = 'Never'
  } else if (record.collectionAgentOohCollection == 1) {
    collectionAgentOohCollection = 'Sometimes'
  } else {
    collectionAgentOohCollection = 'Yes'
  }

  if (record.collectionAgentEveningCollection == 0) {
    collectionAgentEveningCollection = 'No'
  } else {
    collectionAgentEveningCollection = 'Yes'
  }

  return (
    <div>
      Estimated Collection: {record.collectionAgentEstimated} days
      <br />
      Guaranteed Collection: {record.collectionAgentGuaranteed} days
      <br />
      Out of hours Collection: {collectionAgentOohCollection}
      <br />
      Evening Collection: {collectionAgentEveningCollection}
    </div>
  )
}

const Weights = (props) => {
  const record = useRecordContext()
  const {
    collectionAgent0800,
    collectionAgent8011100,
    collectionAgent11011250,
    collectionAgent12511400,
    collectionAgent1400,
  } = record

  return (
    <div>
      0 - 800KG: &pound;{collectionAgent0800}
      <br />
      801 - 1100KG: &pound;{collectionAgent8011100}
      <br />
      1101 - 1250KG: &pound;{collectionAgent11011250}
      <br />
      1251 - 1400KG: &pound;{collectionAgent12511400}
      <br />
      1401 - 1600KG+: &pound;{collectionAgent1400}
    </div>
  )
}

const Postcodes = (props) => {
  const record = useRecordContext()
  const [postcodes, setPostcodes] = useState('')
  const [isFetching, setIsFetching] = useState(false)

  const grabPostcodes = async () => {
    const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT

    const request = new Request(
      `${entrypoint}/services/collection-agent/get-zone-postcodes?collectionZone=${record.originId}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'GET',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }

        setPostcodes(responseJSON.data)

        return responseJSON
      })
  }

  if (!isFetching && postcodes === '') {
    setIsFetching(true)
    grabPostcodes()
  }

  return (
    <div>
      <Typography
        sx={{
          fontSize: '1rem',
          fontWeight: '700',
        }}
      >
        Postcodes covered:
      </Typography>
      {postcodes}
    </div>
  )
}

Postcodes.propTypes = {
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  setPostcodes: PropTypes.func,
  postcodes: PropTypes.any,
}

const CollectOn = () => {
  const record = useRecordContext()
  return (
    <div>
      <p
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          margin: '0',
        }}
      >
        <span>Monday -</span>{' '}
        {record.collectionAgentDay1 ? <Check /> : <Clear />}
      </p>
      <br />
      <p
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          margin: '0',
        }}
      >
        <span>Tuesday -</span>{' '}
        {record.collectionAgentDay2 ? <Check /> : <Clear />}
      </p>
      <br />
      <p
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          margin: '0',
        }}
      >
        <span>Wednesday -</span>{' '}
        {record.collectionAgentDay3 ? <Check /> : <Clear />}
      </p>
      <br />
      <p
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          margin: '0',
        }}
      >
        <span>Thursday -</span>{' '}
        {record.collectionAgentDay4 ? <Check /> : <Clear />}
      </p>
      <br />
      <p
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          margin: '0',
        }}
      >
        <span>Friday -</span>{' '}
        {record.collectionAgentDay5 ? <Check /> : <Clear />}
      </p>
      <br />
      <p
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          margin: '0',
        }}
      >
        <span>Saturday -</span>{' '}
        {record.collectionAgentDay6 ? <Check /> : <Clear />}
      </p>
      <br />
      <p
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          margin: '0',
        }}
      >
        <span>Sunday -</span>{' '}
        {record.collectionAgentDay7 ? <Check /> : <Clear />}
      </p>
    </div>
  )
}

const CollectionZoneList = (props) => {
  const record = useRecordContext()
  const [defaultDay1, setDefaultDay1] = useState(false)
  const [defaultDay2, setDefaultDay2] = useState(false)
  const [defaultDay3, setDefaultDay3] = useState(false)
  const [defaultDay4, setDefaultDay4] = useState(false)
  const [defaultDay5, setDefaultDay5] = useState(false)
  const [defaultDay6, setDefaultDay6] = useState(false)
  const [defaultDay7, setDefaultDay7] = useState(false)
  const [defaultEstimatedTime, setDefaultEstimatedTime] = useState(null)
  const [defaultGuaranteedTime, setDefaultGuaranteedTime] = useState(null)
  const [defaultOutOfHours, setDefaultOutOfHours] = useState('0')
  const [defaultEvening, setDefaultEvening] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [postcodes, setPostcodes] = useState('')

  const fakeProps = {
    basePath: '/collection-agents-zones',
    resource: 'collection-agents-zones',
    hasEdit: true,
  }

  let zones = record.collectionAgentCollectionDays
  let lastZone = null
  if (zones) {
    lastZone = zones.slice(-1)[0]
  }

  useEffect(() => {
    if (lastZone && initialLoad) {
      setDefaultDay1(lastZone.collectionAgentDay1)
      setDefaultDay2(lastZone.collectionAgentDay2)
      setDefaultDay3(lastZone.collectionAgentDay3)
      setDefaultDay4(lastZone.collectionAgentDay4)
      setDefaultDay5(lastZone.collectionAgentDay5)
      setDefaultDay6(lastZone.collectionAgentDay6)
      setDefaultDay7(lastZone.collectionAgentDay7)
      setDefaultEstimatedTime(lastZone.collectionAgentEstimated)
      setDefaultGuaranteedTime(lastZone.collectionAgentGuaranteed)
      setDefaultOutOfHours(lastZone.collectionAgentOohCollection)
      setDefaultEvening(lastZone.collectionAgentEveningCollection)
      setInitialLoad(false)
    }
  })

  const handleCloseModal = () => {
    setShowModal(false)
    setPostcodes('')
  }

  const CollectionZoneForm = (props) => {
    const record = useRecordContext()
    let postcodeArray = []
    let postcodeString = ''

    if (
      record.collectionAgentCollectionDaysPostcodes &&
      typeof record.collectionAgentCollectionDaysPostcodes !== 'string'
    ) {
      record.collectionAgentCollectionDaysPostcodes.map((value) => {
        postcodeArray.push(value.postcode)
      })

      postcodeString = postcodeArray.join()

      record.collectionAgentCollectionDaysPostcodes = postcodeString
    }

    return (
      <SimpleForm
        {...props}
        redirect={false}
        toolbar={
          <div
            style={{
              padding: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <SaveButton />
            <DeleteButton redirect={false} />
          </div>
        }
      >
        <TextInput
          label="Collection zone name"
          source="collectionAgentZoneName"
          validate={required()}
        />
        <Typography>Active:</Typography>
        <BooleanInput
          source="active"
          label="Active"
          defaultValue={true}
          fullWidth
        />
        <Typography>Collect on:</Typography>
        <BooleanInput
          source="collectionAgentDay1"
          label="Monday"
          defaultValue={defaultDay1}
          fullWidth
        />
        <BooleanInput
          source="collectionAgentDay2"
          label="Tuesday"
          defaultValue={defaultDay2}
          fullWidth
        />
        <BooleanInput
          source="collectionAgentDay3"
          label="Wednesday"
          defaultValue={defaultDay3}
          fullWidth
        />
        <BooleanInput
          source="collectionAgentDay4"
          label="Thursday"
          defaultValue={defaultDay4}
          fullWidth
        />
        <BooleanInput
          source="collectionAgentDay5"
          label="Friday"
          defaultValue={defaultDay5}
          fullWidth
        />
        <BooleanInput
          source="collectionAgentDay6"
          label="Saturday"
          defaultValue={defaultDay6}
          fullWidth
        />
        <BooleanInput
          source="collectionAgentDay7"
          label="Sunday"
          defaultValue={defaultDay7}
          fullWidth
        />
        <SelectInput
          label="Estimated collection time"
          source="collectionAgentEstimated"
          emptyText="Please select"
          choices={[
            { id: '1', name: '1 day' },
            { id: '2', name: '2 days' },
            { id: '3', name: '3 days' },
            { id: '4', name: '4 days' },
            { id: '5', name: '5 days' },
            { id: '6', name: '6 days' },
            { id: '7', name: '7 days' },
            { id: '8', name: '8 days' },
            { id: '9', name: '9 days' },
            { id: '10', name: '10 days' },
          ]}
          defaultValue={defaultEstimatedTime}
          required={true}
        />
        <SelectInput
          label="Guaranteed collection time"
          source="collectionAgentGuaranteed"
          emptyText="Please select"
          choices={[
            { id: '1', name: '1 day' },
            { id: '2', name: '2 days' },
            { id: '3', name: '3 days' },
            { id: '4', name: '4 days' },
            { id: '5', name: '5 days' },
            { id: '6', name: '6 days' },
            { id: '7', name: '7 days' },
            { id: '8', name: '8 days' },
            { id: '9', name: '9 days' },
            { id: '10', name: '10 days' },
          ]}
          defaultValue={defaultGuaranteedTime}
          required={true}
        />
        <Typography>Out of hours collections:</Typography>
        <RadioButtonGroupInput
          source="collectionAgentOohCollection"
          label="Out of hours collections"
          choices={[
            { id: 2, name: 'Yes' },
            { id: 1, name: 'Sometimes' },
            { id: 0, name: 'Never' },
          ]}
          defaultValue={defaultOutOfHours}
        />
        <BooleanInput
          source="collectionAgentEveningCollection"
          label="Evening Collection"
          defaultValue={defaultEvening}
          fullWidth
        />
        <RadioButtonGroupInput
          source="pricingMethod"
          choices={[
            { id: 1, name: 'Price per ton' },
            { id: 0, name: 'Weight Brackets' },
          ]}
          required={true}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.pricingMethod === '1' && (
              <TextInput source="perTon" label="Price per ton" />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.pricingMethod === '0' && (
              <React.Fragment>
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  }}
                >
                  Weight Pricing
                </Typography>
                <Typography
                  style={{
                    marginBottom: '2rem',
                  }}
                >
                  Please put into each box your maximum price paid at this time
                  for each weight bracket. This is the maximum you will pay for
                  each vehicle including commission (WE DO NOT ADD COMMISSION TO
                  THIS PRICE).
                </Typography>
                <TextInput source="collectionAgent0800" label="0 - 800kg" />
                <TextInput
                  source="collectionAgent8011100"
                  label="801kg - 1100kg"
                />
                <TextInput
                  source="collectionAgent11011250"
                  label="1101kg - 1250kg"
                />
                <TextInput
                  source="collectionAgent12511400"
                  label="1251kg - 1400kg"
                />
                <TextInput
                  source="collectionAgent1400"
                  label="1401kg - 1600kg"
                />
              </React.Fragment>
            )
          }
        </FormDataConsumer>
        <TextInput
          label="Collection Agent Postcodes"
          source="collectionAgentCollectionDaysPostcodes"
          required={true}
          multiline={true}
        />
      </SimpleForm>
    )
  }

  const transformData = (data) => {
    let extraPostcodes = []
    let tmpArray = data.collectionAgentCollectionDaysPostcodes.split(',')

    for (let i = 0; i < tmpArray.length; i++) {
      extraPostcodes.push({
        postcode: tmpArray[i].trim(),
      })
    }

    data.collectionAgentCollectionDaysPostcodes = extraPostcodes
    data.collectionAgent = record.id

    setDefaultDay1(data.collectionAgentDay1)
    setDefaultDay2(data.collectionAgentDay2)
    setDefaultDay3(data.collectionAgentDay3)
    setDefaultDay4(data.collectionAgentDay4)
    setDefaultDay5(data.collectionAgentDay5)
    setDefaultDay6(data.collectionAgentDay6)
    setDefaultDay7(data.collectionAgentDay7)
    setDefaultEstimatedTime(data.collectionAgentEstimated)
    setDefaultGuaranteedTime(data.collectionAgentGuaranteed)
    setDefaultOutOfHours(data.collectionAgentOohCollection)
    setDefaultEvening(data.collectionAgentEveningCollection)

    return data
  }

  const createButton = (
    <CreateInDialogButton
      transform={transformData}
      inline
      fullWidth
      title="Create Collection Agent Zone"
      maxWidth="md"
      record={{ collectionAgent: record.id }}
    >
      <CollectionZoneForm {...props} />
    </CreateInDialogButton>
  )

  const editButton = (
    <EditInDialogButton
      title="Edit Collection Agent Zone"
      transform={transformData}
      redirect={false}
    >
      <CollectionZoneForm {...props} />
    </EditInDialogButton>
  )

  return (
    <List
      {...fakeProps}
      filter={{ 'collectionAgent.id': record.id }}
      actions={createButton}
      empty={createButton}
      perPage={999}
      fullWidth
    >
      <Datagrid
        expand={<Postcodes />}
        aria-expanded={true}
        isRowExpandable={(row) => true}
        toggleListItemExpand={(row) => true}
      >
        <TextField source="collectionAgentZoneName" label="Zone name" />
        <BooleanField label="Active" source="active" />
        <CollectOn label="Collect on" {...props} />
        <Collection label="Collection" {...props} />
        <Weights label="Weight Pricing" {...props} />
        <DatagridActionsColumn label="Actions">
          {editButton}
        </DatagridActionsColumn>
      </Datagrid>
    </List>
  )
}

const IconWithTooltip = (props) => (
  <Tooltip title={props.text}>
    <Help />
  </Tooltip>
)

IconWithTooltip.propTypes = {
  text: PropTypes.any,
}

const EditCollectionAgent = (props) => {
  const [addressList, setAddressList] = useState()

  const getAddressList = async () => {
    try {
      let postcode = $('[name="collectionAgentPostcode"]').val()
      const data = await getAddressLookup(postcode.replace(/\s+/g, ''))
      if (data.status === 'error') {
        notify(data.error, { type: 'error' })
      } else {
        setAddressList(data.address)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const clearAddress = () => {
    setAddressList([])
  }

  const populateAddress = (e) => {
    const addressParts = e.target.value.split(',')
    let inputsToSave = [
      {
        name: 'leadContact.postcode',
        value: $('[name="leadContact.postcode"]').val(),
      },
    ]

    if (typeof addressParts[0] !== 'undefined') {
      populateInput('collectionAgentAddress1', addressParts[0])
    }

    if (typeof addressParts[1] !== 'undefined') {
      populateInput('collectionAgentAddress2', addressParts[1])
    }

    if (typeof addressParts[3] !== 'undefined') {
      populateInput('collectionAgentTown', addressParts[3])
    }

    if (typeof addressParts[4] !== 'undefined') {
      populateInput('collectionAgentCounty', addressParts[4])
    }

    clearAddress()
  }

  const populateInput = (input, value) => {
    let field = document.querySelector('[name="' + input + '"]')

    const lastValue = field.value
    field.value = value
    const event = new Event('input', { bubbles: true })
    const tracker = field._valueTracker

    if (tracker) {
      tracker.setValue(lastValue)
    }

    field.dispatchEvent(event)
  }

  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="Agent Info">
          <Grid
            container
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 2fr)',
              gridRowStart: 1,
              gridRowEnd: 1,
              marginRight: '1rem',
              marginBottom: '1rem',
            }}
          >
            <Grid item>
              <Typography variant="h6" gutterBottom>
                Basic information
              </Typography>
              <Box flex={1} p="1em" fullWidth>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Company Name"
                          source="collectionAgentCompanyName"
                          fullWidth
                          required={true}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label={
                            <div>
                              SMS Sender
                              <IconWithTooltip text="It is used as a sender's name in the system SMS. It should be up to 11 characters." />
                            </div>
                          }
                          source="collectionAgentSmsName"
                          fullWidth
                          required={true}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <SelectInput
                          label={
                            <div>
                              Business type
                              <IconWithTooltip text="An ATF is a registered Authorized Treatment Facility, you must have your ATF no. to select this option. If you are not an ATF, you can select 'Specialist Buyer' and then enter your company registration no. below." />
                            </div>
                          }
                          source="collectionAgentBusinessType"
                          optionValue="id"
                          optionText="name"
                          choices={[
                            { id: 0, name: 'None' },
                            { id: 1, name: 'Registered ATF' },
                            { id: 2, name: 'Specialist Buyer' },
                            { id: 3, name: 'Specialist buyer and ATF' },
                          ]}
                          fullWidth
                        />
                      </Box>
                      <FormDataConsumer>
                        {({ formData, ...rest }) =>
                          formData.collectionAgentBusinessType == 1 && (
                            <Box flex={1} ml="0.5em">
                              <TextInput
                                label="Registered ATF no."
                                source="collectionAgentAtfNo"
                                fullWidth
                              />
                            </Box>
                          )
                        }
                      </FormDataConsumer>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Waste carries license no."
                          source="collectionAgentWasteLicenseNo"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label="Company reg no."
                          source="collectionAgentCompanyRegNo"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Typography variant="h6" gutterBottom>
                Freshbooks Details
              </Typography>
              <Box flex={1} p="1em" fullWidth>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Freshbooks Client ID"
                          source="freshbooksId"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          source="invoiceEmail"
                          fullWidth
                          required={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Typography variant="h6" gutterBottom>
                Main office address
              </Typography>
              <Box flex={1} p="1em" fullWidth>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Box display="flex">
                      <Box flex={1} mr="0">
                        <TextInput
                          label="Postcode"
                          source="collectionAgentPostcode"
                          fullWidth
                          required={true}
                          InputProps={{
                            endAdornment: (
                              <IconButton edge="end" onClick={getAddressList}>
                                <Search color="primary" />
                              </IconButton>
                            ),
                          }}
                        />
                        {addressList && addressList.length > 0 && (
                          <Stack direction="row">
                            <SelectInput
                              source="name"
                              label="Select Address list"
                              choices={addressList}
                              translateChoice={false}
                              onChange={(e) => populateAddress(e)}
                              optionValue="name"
                            />
                            <IconButton
                              style={{
                                alignSelf: 'baseline',
                                marginTop: '8px',
                              }}
                              onClick={clearAddress}
                            >
                              <Close />
                            </IconButton>
                          </Stack>
                        )}
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Address line 1"
                          source="collectionAgentAddress1"
                          fullWidth
                          required={true}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label="Address line 2"
                          source="collectionAgentAddress2"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Town"
                          source="collectionAgentTown"
                          fullWidth
                          required={true}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label="County"
                          source="collectionAgentCounty"
                          fullWidth
                          required={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <BooleanInput
                source="collectionAgentYardDiff"
                label="Different collection yard address"
                fullWidth
              />

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.collectionAgentYardDiff &&
                  formData.collectionAgentYardDiff == 1 && (
                    <ArrayInput source="collectionAgentYards">
                      <SimpleFormIterator>
                        <TextInput
                          source="collectionAgentYardPostcode"
                          label="Postcode"
                          required={true}
                        />
                        <TextInput
                          source="collectionAgentYardAddress1"
                          label="Address line 1"
                          required={true}
                        />
                        <TextInput
                          source="collectionAgentYardAddress2"
                          label="Address line 2"
                        />
                        <TextInput
                          source="collectionAgentYardTown"
                          label="Town"
                          required={true}
                        />
                        <TextInput
                          source="collectionAgentYardCounty"
                          label="County"
                          required={true}
                        />
                        <BooleanInput
                          source="collectionAgentYardPrimary"
                          label="Primary Address"
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>
            </Grid>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                Contact Information
              </Typography>
              <Box flex={1} p="1em" fullWidth>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Main contact name"
                          source="collectionAgentContactName"
                          fullWidth
                          required={true}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label={
                            <div>
                              Telephone number
                              <IconWithTooltip text="Admin direct contact." />
                            </div>
                          }
                          source="collectionAgentContactTelNum"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label={
                            <div>
                              Mobile number
                              <IconWithTooltip text="Leads forward to this number." />
                            </div>
                          }
                          source="collectionAgentContactAltTelNum"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label={
                            <div>
                              Customer Service number
                              <IconWithTooltip text="Number provided to customer." />
                            </div>
                          }
                          source="collectionAgentContactCustomerTelNum"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label="Lead Email"
                          source="collectionAgentContactEmail"
                          fullWidth
                          required={true}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label="Customer Services email"
                          source="collectionAgentCustomerServiceEmail"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          label={
                            <div>
                              Contact email
                              <IconWithTooltip text="It's used as a contact email when sending Assign Lead template. Use semicolon(;) to add several addresses." />
                            </div>
                          }
                          source="scfcContactEmail"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <TextInput
                          label="Managing Director email"
                          source="collectionAgentDirectorEmail"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Typography variant="h6" gutterBottom>
                Extra information
              </Typography>
              <Box flex={1} p="1em" fullWidth>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <BooleanInput
                          source="collectionAgentContactActive"
                          label="Active?"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <BooleanInput
                          source="collectionAgentPriceRulesEnabled"
                          label="Price rules enabled"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <BooleanInput
                          source="collectionAgentBms"
                          label="Collection Planner Active"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex" mb="1rem">
                      <Box flex={1} ml="0">
                        <BooleanInput
                          source="weightAdjust"
                          label={
                            <div>
                              Add Extra Weight
                              <IconWithTooltip text="No - system default will be used" />
                            </div>
                          }
                          fullWidth
                        />
                      </Box>
                      <Box flex={1}>
                        <FormDataConsumer>
                          {({ formData, ...rest }) =>
                            formData.weightAdjust &&
                            formData.weightAdjust == 1 && (
                              <Box flex={1}>
                                <NumberInput
                                  source="weightAdjustVal"
                                  label={
                                    <div>
                                      Weight % Adjustment
                                      <IconWithTooltip text="If 0, no adjustment will be made" />
                                    </div>
                                  }
                                  fullWidth
                                />
                              </Box>
                            )
                          }
                        </FormDataConsumer>
                      </Box>
                    </Box>
                    <Box display="flex" fullWidth>
                      <Box flex={1}>
                        <TextInput
                          label="Any additional notes?"
                          multiline={true}
                          source="collectionAgentContactNotes"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex" fullWidth>
                      <Box flex={1}>
                        <TextInput
                          label="Pricing notes"
                          multiline={true}
                          source="collectionAgentPricingNotes"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1}>
                        <SelectInput
                          label="Pay extra for alloy wheels"
                          source="collectionAgentExtraForAlloyWheels"
                          fullwidth
                          choices={[
                            { id: 1, name: 'No' },
                            { id: 14, name: '£5.00 per set' },
                            { id: 2, name: '£8.00 per set' },
                            { id: 3, name: '£10.00 per set' },
                            { id: 4, name: '£12.00 per set' },
                            { id: 5, name: '£14.00 per set' },
                            { id: 6, name: '£16.00 per set' },
                            { id: 7, name: '£18.00 per set' },
                            { id: 8, name: '£20.00 per set' },
                            { id: 9, name: '£22.00 per set' },
                            { id: 10, name: '£24.00 per set' },
                            { id: 11, name: '£26.00 per set' },
                            { id: 12, name: '£28.00 per set' },
                            { id: 13, name: '£30.00 per set' },
                            { id: 15, name: '£35.00 per set' },
                            { id: 16, name: '£40.00 per set' },
                            { id: 17, name: '£45.00 per set' },
                            { id: 18, name: '£50.00 per set' },
                          ]}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1}>
                        <BooleanInput
                          source="collectionAgentPayOverweight"
                          label="We are happy to add 15% for vehicles over 1600kg"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      Payment methods
                    </Typography>
                    <Box display="flex">
                      <Box flex={1}>
                        <BooleanInput
                          source="collectionAgentPaymentPaypal"
                          label="Paypal"
                          color="success"
                          options={{ checkedIcon: <CheckRounded /> }}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1}>
                        <BooleanInput
                          source="collectionAgentPaymentCheque"
                          label="Cheque"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1}>
                        <BooleanInput
                          source="collectionAgentPaymentTransfer"
                          label="Transfer"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1}>
                        <BooleanInput
                          source="collectionAgentPaymentCashRequest"
                          label="Cash on special request"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1}>
                        <SelectInput
                          label="Pay Extra for Drop Off at Depot"
                          source="collectionAgentExtraForDropOff"
                          fullwidth
                          choices={[
                            { id: 0, name: 'No' },
                            { id: 10, name: '£10' },
                            { id: 15, name: '£15' },
                            { id: 20, name: '£20' },
                            { id: 25, name: '£25' },
                            { id: 30, name: '£30' },
                            { id: 35, name: '£35' },
                            { id: 40, name: '£40' },
                          ]}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </FormTab>

        <FormTab label="Collection Zones">
          <ZonesBulkUploadButton {...props} />

          <Typography variant="h6" gutterBottom style={{ marginTop: '1.5rem' }}>
            Collection Zones
          </Typography>

          <CollectionZoneList {...props} />
        </FormTab>

        <FormTab label="Change Password"></FormTab>
      </TabbedForm>
      <RulesSetPanel {...props} />
    </Edit>
  )
}

export default EditCollectionAgent

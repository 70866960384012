import React, { useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'
import { Button, CircularProgress } from '@mui/material'
import { clearSalvageBookingsLeadStatus } from '../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const ClearStatusButton = ({
  sb,
  status,
  resetCustomStatusButton = false,
  buttonText,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [loading, setLoading] = useState(false)

  const handleButtonClick = async function () {
    if (!loading) {
      setLoading(true)
    }

    // Delete status in salvage bookings
    const responseStatus = await clearSalvageBookingsLeadStatus(
      sb.quote_request_id,
      status.slug,
      status.name
    )

    if (responseStatus) {
      refresh()
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        label="Reset No Contact"
        fullWidth
        disabled={loading}
        onClick={handleButtonClick}
        sx={{
          border: '2px solid #FFF !important',
          backgroundColor: `${resetCustomStatusButton ? '#F0F42F' : '#880000'}`,
          color: `${resetCustomStatusButton ? '#2A2A2A' : '#FFF'}`,
          height: '40px',
          fontWeight: '700',
          '&:hover': {
            backgroundColor: `${
              resetCustomStatusButton ? '#F0F42F' : '#880000'
            }`,
          },
        }}
      >
        {buttonText}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </React.Fragment>
  )
}

ClearStatusButton.propTypes = {
  sb: PropTypes.object,
  status: PropTypes.object,
  resetCustomStatusButton: PropTypes.bool,
  buttonText: PropTypes.string,
}

export default ClearStatusButton

import React, { useState } from 'react'
import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import { Box, Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { setAsPremiumSalvageBookingsLead } from '../../../../Utils/SalvageBookingsUtils'

const PremiumLeadPopup = ({
  setPopupPanelName,
  scPurchasePrice,
  scExtraWorkCost,
  scDesiredAuctionPrice,
}) => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const [loading, setLoading] = useState(false)
  const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT

  const handleButtonClick = async function (status) {
    if (!loading) {
      setLoading(true)
    }

    // Update send review status in salvage bookings
    const responseStatus = await setAsPremiumSalvageBookingsLead(
      parseInt(record.originId),
      status,
      document.querySelector('[id="reserve-assign-copart"]').value,
      scPurchasePrice ?? null,
      scExtraWorkCost ?? null,
      scDesiredAuctionPrice ?? null
    )

    if (responseStatus) {
      if (!responseStatus.copartError) {
        refresh()
        setLoading(false)
        setPopupPanelName('')
      } else {
        setLoading(false)
        notify(' ' + responseStatus.copartError, { type: 'error' })
      }
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'error' })
    }
  }

  return (
    <React.Fragment>
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: 700,
            fontSize: '25px',
            lineHeight: '26px',
            marginBottom: 10,
            textTransform: 'uppercase',
          }}
        >
          Is this a premium lead?
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            name="setSendReviewStatusNo"
            onClick={() => handleButtonClick('false')}
            sx={{
              fontWeight: 700,
              fontSize: '23px',
              lineHeight: '16.38px',
              padding: 4,
              backgroundColor: '#D70000',
              color: '#FFFFFF',
              border: '4px solid #FFFFFF !important',
              minWidth: '145px',
              marginRight: 4,
              '&:hover': {
                backgroundColor: '#D70000',
              },
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            name="setSendReviewStatusYes"
            onClick={() => handleButtonClick('true')}
            sx={{
              fontWeight: 700,
              fontSize: '23px',
              lineHeight: '16.38px',
              padding: 4,
              backgroundColor: '#00D715',
              color: '#FFFFFF',
              border: '4px solid #FFFFFF !important',
              minWidth: '145px',
              '&:hover': {
                backgroundColor: '#00D715',
              },
            }}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  )
}

PremiumLeadPopup.propTypes = {
  setPopupPanelName: PropTypes.func,
  scPurchasePrice: PropTypes.number,
  scExtraWorkCost: PropTypes.number,
  scDesiredAuctionPrice: PropTypes.number,
}

export default PremiumLeadPopup

import PropTypes from 'prop-types'

const SalvageTerms = (
  fee,
  registration,
  agreedPrice,
  customerFullName,
  makeModel
) => {
  /* eslint-disable */
  return (
    <>
      <h1>BMS Salvage - Terms & Conditions - Read word for word</h1>
      <p>
        So { customerFullName }, what I need to do now is read through the following statements to make this official
        for you and our affiliated buyer. This part of the call is scripted and also recorded. Please answer yes or no
        to the following questions, as this forms your purchase agreement and is legally binding.
      </p>
      <ul>
        <li>
          <span style={{color: "red"}}>{ customerFullName }</span> you confirm that you have legal permission to sell
          the vehicle, registration number <span style={{color: "red"}}>{ registration }</span> which is a “<span
          style={{color: "red"}}>{makeModel}</span>” [wait for confirmation from customer]
        </li>
        <li>
          You are confirming there is no finance outstanding on the vehicle. [wait for confirmation from customer]
        </li>
        <li>
          You agree that the description of the vehicle is as follows: <span style={{color: "red"}}>(Read back
          description notes)</span> [wait for confirmation from customer]
        </li>
        <li>
          You are agreeing to sell this vehicle to a company called “BMS Salvage” for
          <span style={{color: "red"}}>£{ agreedPrice }</span> This price is based on the vehicle being in the
          condition described. [wait for confirmation from customer]
        </li>
        <li>
          As soon as this call is completed, if for any reason you decide to cancel this agreement there will be an
          administration fee of <span style={{color: "red"}}>£{ fee }</span>. This is to cover the costs incurred by BMS
          Salvage to handle your request. Please confirm that you agree to these terms and would like to go ahead. [wait for confirmation from customer]
        </li>
      </ul>
      <p>
        Great, your booking is now officially confirmed this will now be sent to
        you via email for your records.
      </p>
      <p>
        What happens next, BMS Salvage who are buying the vehicle will deposit £20
        into your account by 6pm today (If evenings or weekends this will be by
        the next working day.) Once they have processed your payment, they will
        send you an email confirming this and how the collection process works so
        please read when you have a moment. If you don’t see this in your inbox
        then please check the junk folder.
      </p>
      <p>
        BMS Salvage use a company called Copart to collect and transport their
        vehicles so the company that you hear from next will be Copart. They aim
        to call you within 24 hours to discuss available collection dates.
      </p>
      <p>
        You will also receive a weblink from them via text message on the number
        provided. This will take you to their online booking portal, where you
        will be able to choose a collection date that suits you best. This speeds
        the collection process up, but if you prefer to speak to them, then just
        wait for their call.
      </p>
      <p>
        Before the driver arrives please ensure that the vehicle is free from any
        waste or personal property and also make sure you have any relevant
        paperwork ready for the collection
      </p>
      <p>
        On the day of collection, when the Copart driver is present you need to
        call BMS Salvage to let them know that the collection is taking place. The
        number to call them on is in the confirmation email and will also be
        texted to you shortly.
      </p>
      <p>
        BMS Salvage will speak to both you and the driver to confirm the
        collection and then process the payment of your remaining balance which
        will clear by 6pm. If for any reason you cannot be present for the
        collection, a friend or family member can hand the vehicle over for you.
        If no one can be present to meet the driver, Copart can still collect the
        vehicle with your permission as long as the keys and paperwork are
        accessible. In that case, you will still need to call BMS Salvage to
        confirm the collection has taken place. They will then process your
        payment once Copart have confirmed your vehicle as collected by the
        designated yard.
      </p>
      <p>Your booking is now complete. Do you have any questions for me?</p>
    </>
  )
  /* eslint-enable */
}

SalvageTerms.propTypes = {
  fee: PropTypes.string,
  registration: PropTypes.string,
  agreedPrice: PropTypes.string,
  customerFullName: PropTypes.string,
  makeModel: PropTypes.string,
}

export { SalvageTerms }

import React, { useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { useNotify, useRecordContext } from 'react-admin'
import { getPurchaseAgreement } from '../../../Utils/RestUtils'
import { AgentAssignValidator } from '../Edit/ViewAgentsPanel/AgentAssignValidator'
import PropTypes from 'prop-types'

const PurchaseAgreementDialog = ({ assignedTo }) => {
  let record = useRecordContext()
  const notify = useNotify()
  const [purchaseAgreementHtml, setPurchaseAgreementHtml] = useState(null)
  const [showDialog, setShowDialog] = useState(false)

  const getAgreement = async (data) => {
    const payload = await getPurchaseAgreement(data)
    setPurchaseAgreementHtml(payload.data.html)
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleClickViewPurchaseAgreement = () => {
    const errors = AgentAssignValidator(record, null)

    if (errors.length > 0) {
      notify(
        'Please fix below issues in order to view purchase agreement: \n\n' +
          errors.join(';\n'),
        { type: 'error', multiLine: true }
      )
    } else {
      getAgreement({
        id: record.originId,
        type: assignedTo,
        admin: true,
      })
    }
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="success"
        fullWidth
        onClick={handleClickViewPurchaseAgreement}
        disabled={!assignedTo}
      >
        View Purchase Agreement
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="View Purchase Agreement"
      >
        <DialogTitle>Purchase Agreement</DialogTitle>
        <DialogContent>
          <Card sx={{ backgroundColor: 'white', color: '#111' }}>
            <CardContent
              dangerouslySetInnerHTML={{
                __html: purchaseAgreementHtml,
              }}
            />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleCloseClick}
            endIcon={<Cancel />}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

PurchaseAgreementDialog.propTypes = {
  assignedTo: PropTypes.any,
}

export { PurchaseAgreementDialog }

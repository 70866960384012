import { useEffect, useState } from 'react'
import { useRecordContext } from 'react-admin'
import PropTypes from 'prop-types'
import { getCollectionAgentZones } from '../../Utils/RestUtils'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

const AgentZoneReferenceFieldEdit = (props) => {
  const record = useRecordContext()
  const [agentZones, setAgentZones] = useState([])
  const [currentZones, setCurrentZones] = useState([])
  const [currentAgentId, setCurrentAgentId] = useState(0)

  useEffect(() => {
    if (record.collectionAgentZones) {
      record.collectionAgentZones.map((agentZone) => {
        let agentId = 0
        if (typeof agentZone === 'object') {
          agentId = agentZone['@id']
        } else {
          agentId = agentZone
        }

        if (!currentZones.includes(agentId)) {
          currentZones.push(agentId)
        }
      })
    }
  }, [])

  const getAgentZones = async () => {
    let agentZonesResult = await getCollectionAgentZones(props.agent)
    setAgentZones(agentZonesResult)
  }

  if (agentZones.length === 0 && currentAgentId === 0) {
    getAgentZones()
  }

  if (currentAgentId !== props.agent) {
    getAgentZones()
    setCurrentAgentId(props.agent)
    setCurrentZones([])
  }

  const handleAgentSelect = (e) => {
    let value = e.target.value
    let index = currentZones.indexOf(value)
    let tmpZones = currentZones

    if (index > -1) {
      // only splice array when item is found
      tmpZones.splice(index, 1) // 2nd parameter means remove one item only
    } else {
      tmpZones.push(value)
    }

    setCurrentZones(tmpZones)
    props.setCurrentZones(tmpZones)
    if (props.testValue === 0) {
      props.setTestValue(1)
    } else {
      props.setTestValue(0)
    }
  }

  return (
    <FormGroup>
      {agentZones &&
        agentZones.map((zone, i) => {
          return (
            <FormControlLabel
              key={zone.id}
              control={
                <Checkbox
                  value={zone['@id']}
                  onChange={(e) => handleAgentSelect(e)}
                  key={i}
                  checked={currentZones.includes(zone['@id'])}
                />
              }
              label={zone.collectionAgentZoneName}
            />
          )
        })}
    </FormGroup>
  )
}

AgentZoneReferenceFieldEdit.propTypes = {
  agent: PropTypes.any,
  currentZones: PropTypes.any,
  setCurrentZones: PropTypes.func,
  testValue: PropTypes.any,
  setTestValue: PropTypes.func,
  zones: PropTypes.any,
  setZones: PropTypes.func,
  currentAgentId: PropTypes.any,
  setCurrentAgentId: PropTypes.func,
}

export default AgentZoneReferenceFieldEdit

import React, { useState } from 'react'
import { useNotify, useRecordContext } from 'react-admin'
import { Box, Button, Grid, Typography } from '@mui/material'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  redirectToSB,
  restoreSalvageBookingsLead,
} from '../../../../Utils/SalvageBookingsUtils'

const CancelledLeadActions = ({ sb }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [loading, setLoading] = useState(false)

  const handleButtonClick = async function (deleteCopartListing) {
    if (!loading) {
      setLoading(true)
    }

    // Restore lead in SB
    const responseStatus = await restoreSalvageBookingsLead(
      record.originId,
      deleteCopartListing
    )

    if (responseStatus) {
      redirectToSB(sb.quote_request_id)
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }
  return (
    <React.Fragment>
      {record.status !== 'Collection canceled' ? (
        <React.Fragment>
          <Box sx={{ textAlign: 'center' }} paddingY="2em" paddingX="20%">
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: '23px',
                lineHeight: '26px',
                marginBottom: 5,
                textTransform: 'uppercase',
                color: '#ED9C23',
              }}
            >
              CANCELLED IN SALVAGE BOOKINGS
            </Typography>
            <Typography
              sx={{
                fontWeight: 700,
                marginBottom: 5,
                textTransform: 'uppercase',
              }}
            >
              This lead has been reassigned to BMS Salvage. In order to rework
              this lead in Salvage Bookings please confirm if the lead needs to
              be resent to Copart as a new lead or if the original listing is
              still valid - Please check this with Copart before continuing.
            </Typography>
            <Box>
              <Button
                variant="contained"
                name="setSendReviewStatusNo"
                onClick={() => handleButtonClick(false)}
                sx={{
                  fontWeight: 700,
                  backgroundColor: '#ED9C23',
                  color: '#FFFFFF',
                  border: '2px solid #FFFFFF !important',
                  minWidth: '251px',
                  marginRight: 4,
                  '&:hover': {
                    backgroundColor: '#ED9C23',
                  },
                }}
              >
                USE EXISTING
              </Button>
              <Button
                variant="contained"
                name="setSendReviewStatusYes"
                onClick={() => handleButtonClick(true)}
                sx={{
                  fontWeight: 700,
                  backgroundColor: '#ED9C23',
                  color: '#FFFFFF',
                  border: '2px solid #FFFFFF !important',
                  minWidth: '251px',
                  '&:hover': {
                    backgroundColor: '#ED9C23',
                  },
                }}
              >
                RESEND
              </Button>
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  marginTop: 2,
                  marginBottom: 0,
                  textTransform: 'uppercase',
                  color: '#ED9C23',
                }}
              >
                Note:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                }}
              >
                If you resend the listing to Copart you will need to set a new
                reserve which will create a new lot number etc. Any previous
                payments made to the customer will still be showing in the
                system once you have uncancelled it.
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  marginTop: 4,
                  marginBottom: 0,
                  textTransform: 'uppercase',
                  color: '#ED9C23',
                }}
              >
                DEPOSITS THAT WERE REFUNDED:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                }}
              >
                If this customer has returned their deposit and now rebooked
                then it would be sensible to not send the customer a new deposit
                and only settle on collection. Make sure to change Deposit to
                ‘NO’
              </Typography>
            </Box>
          </Box>
        </React.Fragment>
      ) : (
        <Grid container padding="2em">
          <Grid item xs={10}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '23px',
                lineHeight: '26px',
                marginBottom: 5,
                textAlign: 'center',
                textTransform: 'uppercase',
                color: '#ED9C23',
              }}
            >
              Booking Cancelled
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              sx={{
                textAlign: 'center',
                fontWeight: 700,
                fontSize: '15px',
                textTransform: 'uppercase',
              }}
            >
              Cancelled at: {moment(sb.cancelled).format('DD/MM/YYYY HH:mm')}
            </Typography>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

CancelledLeadActions.propTypes = {
  sb: PropTypes.object,
}

export default CancelledLeadActions

const dateConverter = (date, addTimeZone, addTimeType = true) => {
  let options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }

  if (addTimeZone) {
    options = {
      ...options,
      timeZone: 'Europe/London',
    }
  }

  const dateFormatted = new Date(date).toLocaleString('en-GB', options)
  const timeSplit = dateFormatted.split(',')

  let hour = timeSplit[1].trim()

  if (addTimeType) {
    let timeType = 'am'
    if (timeSplit[1].trim().substring(0, 2) >= 12) {
      timeType = 'pm'
    }

    hour = `${hour}${timeType}`
  }

  return {
    date: timeSplit[0].trim(),
    hour: hour,
  }
}

const blockInvalidCharFromNumberInput = (e) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

const blockBlankCharFromInput = (e) =>
  [' '].includes(e.key) && e.preventDefault()

const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur()

  // Prevent the page/container scrolling
  e.stopPropagation()

  setTimeout(() => {
    e.target.focus()
  }, 0)
}

export {
  dateConverter,
  blockInvalidCharFromNumberInput,
  numberInputOnWheelPreventChange,
  blockBlankCharFromInput,
}

import { ExpandLess, ExpandMore, Refresh } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNotify, useRecordContext } from 'react-admin'
import { getAgentAssignedToLeadByOfferHistory } from '../../../../Utils/QuoteRequestUtils'
import { viewAgents } from '../../../../Utils/RestUtils'
import ListAgents from './AgentsList'
import CapList from './CapList'
import QuoteHistoryList from './QuoteHistoryList'
import SalvageExclusionList from './SalvageExclusionList'
import SalvageList from './SalvageList'
import './style/index.scss'

const ViewAgentsPanel = (props) => {
  const record = useRecordContext()
  const location = useLocation()
  const notify = useNotify()
  const [openAgentList, setOpenAgentList] = useState(false)
  const [canExpandAgentPanel, setCanExpandAgentPanel] = useState(false)
  const [agentsData, setAgentsData] = useState(null)
  const [updateViewAgentsDetails, setUpdateViewAgentsDetails] = useState(
    window.localStorage.getItem('showAgentsPanel') ? true : false
  )
  const [isLoading, setIsLoading] = useState(true)
  const [validationErrors, setValidationErrors] = useState([])
  const [assignedAgentDetails, setAssignedAgentDetails] = useState({
    id: 0,
    company: '',
  })
  const isSalvage = record.leadContact.vehicle.tooGoodToScrap

  const getViewAgents = async (refresh = false) => {
    setIsLoading(true)

    const agents = await viewAgents(record.id, refresh)
    setAgentsData(agents)
    props.setPostCodeChanged(false)
    setIsLoading(false)
  }

  if (props.postcodeChanged && !isLoading) {
    getViewAgents(true)
  }

  const updateAssignedAgentOnAgentsData = (agentId, agentCompanyName) => {
    //This is to avoid another API call as getting agents data is quite heavy and takes some time
    setAgentsData({
      ...agentsData,
      ['assigned']: agentId,
      ['company']: agentCompanyName,
    })
  }

  const getAssignedAgentDetails = () => {
    const offer = getAgentAssignedToLeadByOfferHistory(record.offerHistory)
    let collectionAgent = null

    if (offer !== undefined) {
      if (offer.collectionZone) {
        collectionAgent = offer.collectionZone.collectionAgent
      }

      if (offer.collectionAgent) {
        collectionAgent = offer.collectionAgent
      }

      setAssignedAgentDetails({
        id: offer !== undefined && collectionAgent ? collectionAgent.id : 0,
        company:
          offer !== undefined && collectionAgent
            ? collectionAgent.collectionAgentCompanyName
            : '',
        offerId:
          offer !== undefined && collectionAgent ? collectionAgent.id : 0,
      })
    }
  }

  const shouldDisplayAgentsList = () => {
    setCanExpandAgentPanel(true)
    setOpenAgentList(false)

    if (!isSalvage) {
      setOpenAgentList(true)
    }
  }

  window.onstorage = () => {
    if (window.localStorage.getItem('showAgentsPanel')) {
      setOpenAgentList(true)
      window.localStorage.removeItem('showAgentsPanel')
    }
  }

  const handleOpenAgentsList = () => {
    if (!canExpandAgentPanel) {
      notify(
        'Please fix below issues in order to view agents list: \n\n' +
          validationErrors.join(';\n'),
        { type: 'error', multiLine: true }
      )
      return
    }

    setOpenAgentList(!openAgentList)
  }

  useEffect(() => {
    //get agents data if needed
    if (!agentsData || updateViewAgentsDetails) {
      getViewAgents()
    }

    setUpdateViewAgentsDetails(false)

    //check wether view agents should be displayed or not
    shouldDisplayAgentsList()

    getAssignedAgentDetails()
  }, [updateViewAgentsDetails, record])

  useEffect(() => {
    getViewAgents()
  }, [location])

  if (isLoading) {
    return (
      <React.Fragment>
        <Card>
          <CardContent sx={{ textAlign: 'center' }}>
            <Stack
              direction="row"
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '0.5rem',
              }}
            >
              <CircularProgress
                color="primary"
                size={15}
                sx={{ marginRight: '1rem' }}
              />
              <Typography
                sx={{
                  fontSize: '16px',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
                variant="h1"
              >
                Loading...
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Box
        id="viewAgentsPanel"
        component="div"
        className={`view-agents__banner ${
          openAgentList ? 'view-agents__banner-with-border' : ''
        } ${canExpandAgentPanel ? '' : 'disabled'}`}
        onClick={handleOpenAgentsList}
      >
        <Box
          component="span"
          sx={{
            color: 'text.primary',
            fontSize: '16px',
            textAlign: 'center',
            fontWeight: 'bold',
            marginRight: '1rem',
          }}
        >
          View Agents
        </Box>
        {openAgentList ? (
          <ExpandLess color="primary" />
        ) : (
          <ExpandMore color="primary" />
        )}
      </Box>

      {openAgentList && (
        <Box component="div" className="view-agents__list-info">
          <Grid container spacing={2}>
            <Grid item xs={4} sx={{ textAlign: 'left' }}>
              <Box component="span" className="yellow bold">
                {record.leadContact.vehicle.registration} -
              </Box>
              <Box component="span">
                {record.leadContact.vehicle.make}&nbsp;
                {record.leadContact.vehicle.model}
              </Box>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'center' }}>
              <Box component="span" className="yellow bold uppercase">
                Collection Agents
              </Box>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right' }}>
              <Box component="span" className="yellow bold">
                {agentsData && agentsData['count'] ? agentsData['count'] : 0}
                &nbsp;
              </Box>
              <Box component="span">Total Agents / </Box>
              <Box component="span" className="yellow bold">
                {agentsData &&
                  agentsData['agents'] &&
                  agentsData['agents'].length}
                &nbsp;
              </Box>
              <Box component="span">Active Agents</Box>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right' }}>
              <Button endIcon={<Refresh />} onClick={() => getViewAgents(true)}>
                Refresh offers
              </Button>
            </Grid>
          </Grid>

          {agentsData && (
            <ListAgents
              agents={agentsData[0]}
              assignedAgentId={assignedAgentDetails.id}
              assignedAgentZoneId={assignedAgentDetails.offerId}
              assignedAgentCompanyName={assignedAgentDetails.company}
              updateAssignedAgentOnAgentsData={updateAssignedAgentOnAgentsData}
            />
          )}
          {agentsData &&
            agentsData[5] &&
            agentsData[5]['ruleset_match_count'] > 0 && (
              <CapList capData={agentsData[5]} />
            )}

          {agentsData &&
            agentsData[6] &&
            agentsData[6]['ruleset_match_count'] > 0 && (
              <SalvageExclusionList salvageExclusionData={agentsData[6]} />
            )}

          {agentsData &&
            agentsData[3] &&
            agentsData[3]['ruleset_match_count'] > 0 && (
              <SalvageList salvageData={agentsData[3]} />
            )}

          <QuoteHistoryList />
        </Box>
      )}
    </React.Fragment>
  )
}

ViewAgentsPanel.propTypes = {
  postcodeChanged: PropTypes.any,
  setPostCodeChanged: PropTypes.func,
}

export default ViewAgentsPanel

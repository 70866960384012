import { Stack } from '@mui/material'
import AddTagButton from '../TagPanel/AddTagButton'
import { Confirm, useNotify, useRecordContext, useRefresh } from 'react-admin'
import { useState } from 'react'
import Tags from './Tags'
import { removeTag } from '../../../../Utils/QuoteRequestUtils'

const TagPanel = (props) => {
  const handleDialogClose = () => setOpen(false)
  const [open, setOpen] = useState(false)
  const [quoteId, setQuote] = useState()
  const [tagId, setTag] = useState()
  const [key, setKey] = useState()
  const notify = useNotify()
  const refresh = useRefresh()
  const record = useRecordContext()

  const handleConfirm = async () => {
    const result = await removeTag({
      quoteID: quoteId,
      tag: tagId,
    })

    if (result.status === 'success') {
      setOpen(false)
      setKey(key + 1)
      refresh()
    } else {
      notify(result.error, { type: 'error' })
    }
  }
  return (
    <Stack
      direction="row"
      alignItems="center"
      pacing={1}
      sx={{
        padding: '10px 0 20px',
        width: '100%',
      }}
    >
      <Tags
        className="tags"
        source="tags"
        {...props}
        setOpen={setOpen}
        setQuote={setQuote}
        setTag={setTag}
      />
      <AddTagButton {...props} key={key} />
      <Confirm
        isOpen={open}
        title="Remove tag"
        content="Are you sure you want to remove this tag?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Stack>
  )
}

export default TagPanel

import React, { useState } from 'react'

import {
  DateInput,
  Button,
  TextInput,
  useCreate,
  useNotify,
  useRefresh,
  Form,
} from 'react-admin'

import { Cancel, Add, Save } from '@mui/icons-material'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'

function AddReminderButton(props) {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate('lead-notifications')
  const notify = useNotify()
  const refresh = useRefresh()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false)
          refresh()
        },
        onFailure: ({ error }) => {
          notify(error.message, { type: 'error' })
        },
      }
    )
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  return (
    <React.Fragment>
      <Button onClick={handleClick} startIcon={<Add />} label="Add Reminder" />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>Add a reminder</DialogTitle>

        <Form resource="rules-sets-rules" save={handleSubmit}>
          <React.Fragment>
            <DialogContent>
              <DateInput source="date" fullWidth />
              <TextInput
                label="Reminder note"
                source="payload"
                multiline={true}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                label="ra.action.cancel"
                onClick={handleCloseClick}
                disabled={loading}
              >
                <Cancel />
              </Button>
              <Button
                type="submit"
                variant="contained"
                startIcon={<Save />}
                label="Save"
              >
                Save
              </Button>
            </DialogActions>
          </React.Fragment>
        </Form>
      </Dialog>
    </React.Fragment>
  )
}

export default AddReminderButton

import { useRecordContext } from 'react-admin'
import ReactTimeAgo from 'react-time-ago'

const InboxTimeAgo = (props) => {
  const record = useRecordContext()
  const inputValues = props

  let uts = ''
  if (inputValues.timeType === 'inboxDate') {
    uts = record.inboxReceivedDateTime * 1000
  } else {
    uts = record.InboxLeadQuoteDateTime * 1000
  }

  // date greater than epoch
  if (uts > 0) {
    const dateObject = new Date(uts)
    const ReactDateFormat = dateObject.toISOString()
    return <ReactTimeAgo date={ReactDateFormat} locale="en-GB" />
  }
  // date == 0
  return '-'
}

export default InboxTimeAgo

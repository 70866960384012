import {
  Box,
  Button,
  FormControl,
  Input,
  Typography,
  TextField as MuiText,
  Tooltip,
  ButtonGroup,
} from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Grid from '@mui/material/Grid'
import { Datagrid, List, TextField, WithRecord, useTheme } from 'react-admin'
import React, { useState } from 'react'
import { LockOpen, ModeEdit } from '@mui/icons-material'
import { currentUserHasRole } from '../../Pages/Users'
import { CallButton } from '../../Components/QuoteRequest/CallButton'
import { grey, red } from '@mui/material/colors'

const SearchForm = () => {
  const [theme] = useTheme()
  const [registration, setRegistration] = useState('')
  const [phone, setPhone] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [filters, setFilters] = useState({
    archived: 0,
  })

  const isAdmin = currentUserHasRole('ROLE_ADMIN')
  const isManager = currentUserHasRole('ROLE_MANAGER')

  const updateRegistration = (event) => {
    const reg = event.target.value
    if (reg.length >= 3) {
      setRegistration(reg.replace(/\s/g, ''))
      setFilters({
        ...filters,
        'leadContact.vehicle.registration': reg.replace(/\s/g, ''),
      })
    } else {
      setRegistration('')
      delete filters['leadContact.vehicle.registration']
    }
  }

  const updatePhone = (event) => {
    const phone = event.target.value

    if (phone.length >= 3) {
      setPhone(phone)
      setFilters({
        ...filters,
        'leadContact.telNumber': phone,
      })
    } else {
      setPhone('')
      delete filters['leadContact.telNumber']
    }
  }

  const updateExpanded = () => {
    setExpanded(!expanded)
  }

  const rowStyles = (record) => {
    if (record) {
      if (record.scam === true) {
        return {
          backgroundColor: red[500],
          backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='30px' width='120px'><text x='10' y='20' fill='rgba(255,255,255,0.2)' font-family='arial' font-size='20'>SCAM</text></svg>")`,
        }
      }
      if (record.inUse && record.inUse !== '') {
        return {
          backgroundColor: grey[500],
          backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='30px' width='120px'><text x='10' y='20' fill='rgba(255,255,255,0.2)' font-family='arial' font-size='20'>IN USE</text></svg>")`,
        }
      }
    }
  }

  const SearchFilters = () => {
    return (
      <Box>
        <Accordion onChange={updateExpanded} expanded={expanded}>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>Advanced filters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl fullWidth>
              <MuiText
                label="Tel number"
                defaultValue={phone}
                onBlur={updatePhone}
                id="search-phone"
              />
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: '#828080',
        width: '100%',
        minWidth: '60%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '3rem',
      }}
    >
      <Box
        sx={{
          minWidth: '60%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FormControl fullWidth>
          <Input
            name="registration"
            label=" "
            placeholder="Search by registration"
            onKeyUp={updateRegistration}
            sx={{
              backgroundColor: theme.palette.background.default,
              border: 'none',
              lineHeight: '2rem',
              marginBottom: '10px',
              padding: '10px',
              width: '100%',
            }}
          />
        </FormControl>
        <SearchFilters />
      </Box>
      {(registration !== '' || phone !== '') && (
        <Grid container mt={2}>
          <Grid item xs={12}>
            <List
              resource="quote-requests"
              actions={null}
              filter={filters}
              perPage={5}
              pagination={false}
              sort={{ field: 'id', order: 'DESC' }}
              title=" "
              fullWidth
            >
              <Datagrid
                bulkActionButtons={false}
                resource="quotes"
                variant="outline"
                rowStyle={rowStyles}
              >
                <WithRecord
                  label="Lead ID"
                  render={(record) => {
                    if (record.inUse && record.inUse !== '') {
                      return (
                        <Typography>#{record && record.originId}</Typography>
                      )
                    }
                    return <Typography>#{record && record.originId}</Typography>
                  }}
                />
                <TextField
                  source="leadContact.fullName"
                  sortable={false}
                  label="Customer Name"
                  sx={{ textTransform: 'uppercase' }}
                />
                <TextField
                  source="leadContact.postcode"
                  sortable={false}
                  label="Postcode"
                  sx={{ textTransform: 'uppercase' }}
                />
                <TextField
                  source="leadContact.vehicle.registration"
                  sortable={false}
                  label="Reg"
                  sx={{ textTransform: 'uppercase' }}
                />
                <TextField
                  source="leadContact.vehicle.make"
                  sortable={false}
                  label="Make"
                  sx={{ textTransform: 'uppercase' }}
                />
                <TextField
                  source="leadContact.vehicle.model"
                  sortable={false}
                  label="Model"
                  sx={{ textTransform: 'uppercase' }}
                />
                <WithRecord
                  render={(record) => {
                    return (
                      <ButtonGroup
                        sx={{
                          display: 'flex',
                          gap: 1,
                          justifyContent: 'flex-end',
                        }}
                      >
                        {!record.inUse && (
                          <React.Fragment>
                            <CallButton source="telNumber" record={record} />
                            <Tooltip title="Edit lead" position="right">
                              <Button
                                href={
                                  '/#/quote-requests/' +
                                  encodeURIComponent(
                                    '/api/quote-requests/' + record.originId
                                  )
                                }
                                onClick={async () => {
                                  await handleLeadLocking(
                                    'lock',
                                    record.originId
                                  ).then(delayedRefreshEvent())
                                }}
                                label="Edit lead"
                                className="quote-list__table-body_edit-btn"
                                style={{
                                  fontSize: '0.6rem',
                                }}
                              >
                                <ModeEdit />
                              </Button>
                            </Tooltip>
                          </React.Fragment>
                        )}
                        {record.inUse &&
                          record.inUse !== '' &&
                          (isManager ||
                            isAdmin ||
                            record.inUse ===
                              `/api/users/${localStorage.getItem(
                                'user_id'
                              )}`) && (
                            <React.Fragment>
                              <Tooltip title="Edit lead" position="right">
                                <Button
                                  href={
                                    '/#/quote-requests/' +
                                    encodeURIComponent(
                                      '/api/quote-requests/' + record.originId
                                    )
                                  }
                                  label="Edit lead"
                                  className="quote-list__table-body_edit-btn"
                                >
                                  <ModeEdit />
                                </Button>
                              </Tooltip>
                              <Tooltip title="Unlock" position="right">
                                <Button
                                  label=""
                                  startIcon={<LockOpen sx={{ mr: '-10px' }} />}
                                  size="small"
                                  onClick={() => {
                                    unlockLead(record.originId, 'unlock')
                                  }}
                                  icon={null}
                                />
                              </Tooltip>
                            </React.Fragment>
                          )}
                      </ButtonGroup>
                    )
                  }}
                />
              </Datagrid>
            </List>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default SearchForm

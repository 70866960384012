import { useNotify } from 'react-admin'
import { Button, Card, CardContent, Typography } from '@mui/material'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import Grid from '@mui/material/Grid'
import { checkIfUserHasExternalRole } from '../../Utils/HelperUtils'
import { getNextLead } from '../../Utils/QuoteRequestUtils'
import Notifications from '../../Components/Dashboard/Notifications'
import LockedLeads from '../../Components/Dashboard/LockedLeads'
import SearchForm from '../../Components/Dashboard/SearchForm'

export default function Dashboard() {
  let isExternal = checkIfUserHasExternalRole()
  const notify = useNotify()
  const userId = localStorage.getItem('user_id')

  const handleButtonClick = async () => {
    const result = await getNextLead()
    if (result.status === 'success') {
      location.href = '/#/quote-requests/%2Fapi%2Fquote-requests%2F' + result.id
    } else {
      notify(result.message, { type: 'error' })
    }
  }

  return (
    <CardContent>
      <SearchForm />
      {isExternal && (
        <Grid container rowSpacing={2} mt={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent sx={{ mb: 1 }}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                      Get new lead
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      endIcon={<ArrowForwardIosRoundedIcon />}
                      onClick={handleButtonClick}
                    >
                      Get lead
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
        <Notifications userId={Number(userId)} />
        <LockedLeads userId={Number(userId)} />
      </Grid>
    </CardContent>
  )
}

import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { Datagrid, List, TextField } from 'react-admin'
import PropTypes from 'prop-types'
import VehicleDetails from '../Inbox/List/VehicleDetails'
import InboxTimeAgo from '../Inbox/List/TimeAgo'
import InboxPhoneContact from '../Inbox/List/PhoneContact'
import GotoQuoteButton from '../Inbox/List/GotoQuoteButton'

const Notifications = (props) => {
  return (
    <Grid item xs={6}>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Your Priority notifications
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List
            resource="inbox"
            actions={null}
            filter={{
              inboxUser: props.userId,
              inboxActive: true,
              inboxSubject: 'Follow up',
            }}
            perPage={5}
            pagination={false}
            fullWidth
            title=" "
          >
            <Datagrid bulkActionButtons={false}>
              <VehicleDetails label="Vehicle details" />

              <TextField label="Note" source="inboxSubject" />
              <InboxTimeAgo
                timeType="inboxDate"
                label="Contact age"
                source="inboxReceivedDateTime"
              />
              <InboxTimeAgo
                timeType="inboxLeadQuoteTime"
                label="Quote age"
                source="inboxLeadQuoteDateTime"
              />

              <InboxPhoneContact label="Tel" source="inboxTelNumber" />
              <GotoQuoteButton />
            </Datagrid>
          </List>
        </Grid>
      </Grid>
    </Grid>
  )
}

Notifications.propTypes = {
  userId: PropTypes.number,
}

export default Notifications

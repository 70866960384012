import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'

const DepositNotRequired = ({ isChequePayment }) => {
  return (
    <Grid item xs={12}>
      <Typography
        sx={{
          fontWeight: '700',
          letterSpacing: '0.46px',
          marginRight: '5px',
          textTransform: 'uppercase',
        }}
      >
        {isChequePayment
          ? 'Disabled for cheque payment'
          : 'Deposit not required'}
      </Typography>
    </Grid>
  )
}

DepositNotRequired.propTypes = {
  isChequePayment: PropTypes.bool,
}

export default DepositNotRequired

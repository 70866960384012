import { useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import {
  Button,
  Form,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import {
  addQuoteHistory,
  scrollToPanelById,
} from '../../../../Utils/QuoteRequestUtils'
import { getUptoPrice } from '../../../../Utils/PricingUtils'

const SubmitQuotePanel = (props) => {
  const { viewAgentsModal } = { ...props }
  const refresh = useRefresh()
  const notify = useNotify()
  const record = useRecordContext()
  const [quoteHistory, setQuoteHistory] = useState({
    quoteType: '',
    quote: '',
    user: window.localStorage.getItem('email'),
    quoteId: record.id,
  })

  const handleInputChange = async (e) => {
    const { name, value } = e.target

    if (name === 'quoteType' && value === 'upto') {
      let response = await getUptoPrice(record.originId)
      if (response.status === 'success') {
        setQuoteHistory({
          ...quoteHistory,
          quote: response.price,
          quoteType: 'upto',
        })
      } else {
        notify('Unable to generate Up to price', { type: 'error' })
      }
    } else {
      setQuoteHistory({
        ...quoteHistory,
        [name]: value,
      })
    }
  }

  const handleSubmit = async () => {
    if (quoteHistory.quoteType === '') {
      notify('Please select a quote type', { type: 'error' })
      return
    }

    const quoteAmount = parseFloat(quoteHistory.quote)
    if (isNaN(quoteAmount)) {
      notify('Please add a valid quote amount', { type: 'error' })
      return
    }

    setQuoteHistory({
      ...quoteHistory,
      quote: quoteAmount,
    })

    const result = await addQuoteHistory(quoteHistory)
    if (result.status === 'success') {
      refresh()

      //Clean up inputs
      setQuoteHistory({
        ...quoteHistory,
        quote: '',
        quoteType: '',
      })
    } else {
      notify(result.error, { type: 'error' })
    }
  }

  const handleViewAgentsClick = () => {
    window.localStorage.setItem('showAgentsPanel', true)
    window.dispatchEvent(new Event('storage'))

    scrollToPanelById('viewAgentsPanel')
  }

  return (
    <Form onSubmit={handleSubmit} className="submit-quote-form">
      <Grid container spacing={1} sx={{ marginTop: 2 }}>
        <Grid item flex={1}>
          <TextField
            name="quote"
            value={quoteHistory.quote}
            label="Amount £"
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item flex={2} sx={{ marginLeft: '1rem' }}>
          <FormControl>
            <RadioGroup
              name="quoteType"
              value={quoteHistory.quoteType}
              onChange={handleInputChange}
              row
            >
              <FormControlLabel
                key="scrap"
                value="scrap"
                control={<Radio checked={quoteHistory.quoteType === 'scrap'} />}
                label="Scrap"
              />
              <FormControlLabel
                key="interest"
                value="interest"
                control={
                  <Radio checked={quoteHistory.quoteType === 'interest'} />
                }
                label="Interest"
              />
              <FormControlLabel
                key="upto"
                value="upto"
                control={<Radio checked={quoteHistory.quoteType === 'upto'} />}
                label="Up to"
              />
              <FormControlLabel
                key="guaranteed"
                value="guaranteed"
                control={
                  <Radio checked={quoteHistory.quoteType === 'guaranteed'} />
                }
                label="Guaranteed"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          flex={1}
          sx={{ textAlign: 'right' }}
          className="submit-quote-form__button-grid"
        >
          {viewAgentsModal && (
            <Button onClick={() => handleViewAgentsClick()}>View Agents</Button>
          )}
          <Button type="submit" label="Submit Quote" />
        </Grid>
      </Grid>
    </Form>
  )
}

export default SubmitQuotePanel

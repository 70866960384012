const BMS_SALVAGE_AGENT_ID = 121
const SHORE_CARS_AGENT_ID = 86

const DEFAULT_USER_ROLES = [
  { id: 'ROLE_SALES_EXEC', name: 'Sales Exec', colour: '#CE93D8' },
  { id: 'ROLE_ADMIN', name: 'Super Admin', colour: '#FFA726' },
  { id: 'ROLE_MANAGER', name: 'Manager', colour: '#90CAF9' },
  { id: 'ROLE_SALVAGE', name: 'Salvage', colour: '#FF7F56' },
  { id: 'ROLE_SHORE', name: 'Shore Cars', colour: '#F9CA31' },
  { id: 'ROLE_SALES', name: 'Sales', colour: '##3AEFD9' },
  { id: 'ROLE_TEAM_LEAD', name: 'Team Lead', colour: '#B6F484' },
  { id: 'ROLE_EXTERNAL_SCRAP', name: 'External Scrap', colour: '#E8DF18' },
  { id: 'ROLE_EXTERNAL_SALVAGE', name: 'External Salvage', colour: '#CFEC5C' },
  {
    id: 'ROLE_SALVAGE_NOTIFICATIONS',
    name: 'Salvage Notifications',
    colour: '#465804',
  },
]

const EXTERNAL_USER_ROLES = ['EXTERNAL_SCRAP', 'EXTERNAL_SALVAGE']

const DEFAULT_STATUS_LIST = [
  {
    id: 'New inquiry',
    name: 'New inquiry',
  },
  {
    id: 'No reply',
    name: 'No reply',
  },
  {
    id: 'Awaiting photos',
    name: 'Awaiting photos',
  },
  {
    id: 'Quoted',
    name: 'Quoted',
  },
  {
    id: 'Price given',
    name: 'Price given',
  },
  {
    id: 'Last chance',
    name: 'Last chance',
  },
  {
    id: 'Offer accepted',
    name: 'Offer accepted',
  },
  {
    id: 'Lead sent',
    name: 'Lead sent',
  },
  {
    id: 'Lead received',
    name: 'Lead received',
  },
  {
    id: 'Lead declined',
    name: 'Lead declined',
  },
  {
    id: 'Booked in',
    name: 'Booked in',
  },
  {
    id: 'Collected',
    name: 'Collected',
  },
  {
    id: 'Collection canceled',
    name: 'Collection canceled',
  },
  {
    id: 'Incorrect number',
    name: 'Incorrect number',
  },
  {
    id: 'Salvage Auto No Reply',
    name: 'Salvage Auto No Reply',
  },
  {
    id: 'Quarantine',
    name: 'Quarantine',
  },
]

const DEFAULT_INBOX_FILTER_LIST = [
  {
    id: 'Salvage details have been filed out',
    name: 'Salvage details have been filled out',
  },
  {
    id: 'Follow up',
    name: 'Follow up',
  },
  {
    id: 'No Kerb Weight',
    name: 'No Kerb Weight',
  },
  {
    id: 'Accept',
    name: 'Accept',
  },
  {
    id: 'Yes',
    name: 'Yes',
  },
]

export {
  DEFAULT_USER_ROLES,
  DEFAULT_STATUS_LIST,
  BMS_SALVAGE_AGENT_ID,
  SHORE_CARS_AGENT_ID,
  DEFAULT_INBOX_FILTER_LIST,
  EXTERNAL_USER_ROLES,
}

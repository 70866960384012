import { useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'
import CancelIcon from '@mui/icons-material/Cancel'
import { deleteFeeSalvageBookingsLead } from '../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const DeleteFeeButton = ({ sb, fee }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [loading, setLoading] = useState(false)

  const handleButtonClick = async function () {
    if (!loading) {
      setLoading(true)
    }

    // Post new fee to salvage bookings
    const responseStatus = await deleteFeeSalvageBookingsLead(
      sb.quote_request_id,
      fee.id,
      fee.amount,
      fee.type
    )

    if (responseStatus) {
      refresh()
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  if (sb.cancelled) {
    return null
  }

  return (
    <CancelIcon
      aria-label="delete"
      disabled={loading}
      onClick={handleButtonClick}
      sx={{ cursor: 'pointer' }}
    />
  )
}

DeleteFeeButton.propTypes = {
  sb: PropTypes.object,
  fee: PropTypes.object,
}

export default DeleteFeeButton

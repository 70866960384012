import React, { useState } from 'react'
import { useNotify, useRecordContext } from 'react-admin'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { updateLeadTypeAndTags } from '../../../../Utils/RestUtils'
import PropTypes from 'prop-types'

const AssignAgentButton = ({ handleAssignAgent }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [openModal, setOpenModal] = useState(false)
  const isBookingCompleted = record.bookingCompleted
  const isSalvage = record.leadContact.vehicle.tooGoodToScrap

  const handleAssignButton = () => {
    if (isSalvage) {
      setOpenModal(true)

      return
    }

    handleAssignAgent()
  }

  const handleAssignFromModal = async () => {
    const response = await updateLeadTypeAndTags({
      quoteRequestId: record['@id'],
    })

    if (response.result === 'success') {
      handleAssignAgent()
    } else {
      notify(response.error_msg, { type: 'error' })
    }
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        className={`${
          isBookingCompleted ? '' : 'view-agents__table_assign-btn'
        }`}
        fullWidth
        disabled={isBookingCompleted}
        onClick={handleAssignButton}
      >
        Select
      </Button>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        className="view-agents__modal"
      >
        <DialogTitle>Select agent?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            By selecting an agent you are switching the lead from{' '}
            <strong>Salvage</strong> to <strong>Scrap</strong>.<br />
            Do you wish to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpenModal(false)}>
            No
          </Button>
          <Button onClick={handleAssignFromModal} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default AssignAgentButton

AssignAgentButton.propTypes = {
  handleAssignAgent: PropTypes.func,
}

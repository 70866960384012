import React from 'react'
import NotesListingPanel from './List'
import PriceAdjustPanel from './PriceAdjust'

const PricingNotesPanel = () => {
  return (
    <React.Fragment>
      <NotesListingPanel />
      <PriceAdjustPanel />
    </React.Fragment>
  )
}

export default PricingNotesPanel

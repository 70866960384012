import { ChevronRightRounded, ExpandMore } from '@mui/icons-material'
import {
  Collapse,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material'
import PropTypes, { array, element, oneOfType } from 'prop-types'
import { useState } from 'react'
import { useSidebarState } from 'react-admin'

const SubMenu = (props) => {
  const { children, label, defaultOpen = true } = props
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const [open, setOpen] = useSidebarState()
  const handleClick = () => {
    setIsOpen(!isOpen)
    if (!open) {
      setOpen(true)
    }
  }
  return [
    <MenuItem onClick={handleClick} key={3}>
      <ListItemIcon style={{ minWidth: '40px !important' }}>
        {!isOpen ? <ChevronRightRounded /> : <ExpandMore />}
      </ListItemIcon>
      <ListItemText
        primary={label}
        style={{
          fontWeight: 'bold',
        }}
      />
    </MenuItem>,
    <Collapse in={isOpen} timeout="auto" unmountOnExit key={4}>
      <List component="div" sx={{ pl: 2 }} disablePadding>
        {children}
      </List>
    </Collapse>,
  ]
}

SubMenu.propTypes = {
  children: oneOfType([element, array]),
  label: PropTypes.string,
  defaultOpen: PropTypes.bool,
}

export default SubMenu

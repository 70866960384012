import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useNotify, useRecordContext } from 'react-admin'
import { requestAgentQuote } from '../../../../Utils/RestUtils'

const RequestQuoteModal = ({ agentId }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [openModal, setOpenModal] = useState(false)
  const [exportToAgent, setExportToAgent] = useState(true)
  const isBookingCompleted = record.bookingCompleted

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleExportChange = () => {
    setExportToAgent((prev) => !prev)
  }

  const handleSendRequest = async () => {
    const response = await requestAgentQuote(
      agentId,
      record.originId,
      exportToAgent
    )

    if (response.result === 'success') {
      handleCloseModal()
    } else {
      notify(response.error_msg, { type: 'error' })
    }
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        className={`${
          isBookingCompleted
            ? 'buttonMarginTop'
            : 'view-agents__list-table_request-btn'
        }`}
        fullWidth
        disabled={isBookingCompleted}
        color="secondary"
        onClick={handleOpenModal}
      >
        Request Quotes
      </Button>

      <Dialog
        onClose={handleCloseModal}
        open={openModal}
        className="view-agents__modal"
      >
        <DialogTitle>Request Quote</DialogTitle>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <DialogContentText>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportToAgent}
                    name="export"
                    onClick={handleExportChange}
                  />
                }
                label="Export to agent email"
              />
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingTop: 0 }}>
          <Button onClick={handleSendRequest} autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default RequestQuoteModal

RequestQuoteModal.propTypes = {
  agentId: PropTypes.number,
}

import { useState, useEffect } from 'react'

import { Edit, TextInput, SelectInput, SimpleForm } from 'react-admin'

import { getOptions } from '../../Utils/RestUtils'

const EditRuleSet = (props) => {
  const [matchCriteria, setMatchCriteria] = useState()
  const [matchType, setMatchType] = useState()

  useEffect(() => {
    const getSelectOptions = async () => {
      setMatchCriteria(await getOptions('RuleSetsRules', 'getCriteriaOptions'))
      setMatchType(await getOptions('RuleSetsRules', 'getMatchOptions'))
    }
    getSelectOptions()
  }, [])

  return (
    <Edit {...props} redirect="false">
      <SimpleForm redirect="edit">
        {matchType != null ? (
          <SelectInput source="matchType" choices={matchType} />
        ) : (
          <p>Loading...</p>
        )}

        {matchCriteria != null ? (
          <SelectInput source="matchCriteria" choices={matchCriteria} />
        ) : (
          <p>Loading...</p>
        )}

        <TextInput source="matchValue" />
      </SimpleForm>
    </Edit>
  )
}

export default EditRuleSet

import { Card, CardContent, Typography } from '@mui/material'
import {
  FilterLiveSearch,
  FormDataConsumer,
  FunctionField,
  List,
  TextField,
  NumberInput,
  TextInput,
  SelectInput,
} from 'react-admin'
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid'

const FilterSidebar = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch
        label="Search Company Name"
        source="collectionAgent.collectionAgentCompanyName"
      />
      <FilterLiveSearch
        label="Search by Zone Name"
        source="collectionAgentZoneName"
      />
    </CardContent>
  </Card>
)

const CommissionForm = () => {
  return (
    <RowForm transform={transformData}>
      <TextField source="collectionAgent.collectionAgentCompanyName" />
      <TextField source="collectionAgentZoneName" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <NumberInput label="Total Price" source="collectionAgent0800" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.collectionAgent0800 && (
              <NumberInput
                source="customer"
                label="Customer"
                value={
                  formData.collectionAgent0800 -
                  (formData.commission0800 ? formData.commission0800 : 20)
                }
                disabled={true}
              />
            )
          }
        </FormDataConsumer>
        <TextInput label="Commission" source="commission0800" />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <NumberInput label="Total Price" source="collectionAgent8011100" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.collectionAgent8011100 && (
              <NumberInput
                source="customer"
                label="Customer"
                value={
                  formData.collectionAgent8011100 -
                  (formData.commission8011100 ? formData.commission8011100 : 20)
                }
                disabled={true}
              />
            )
          }
        </FormDataConsumer>
        <TextInput label="Commission" source="commission8011100" />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <NumberInput label="Total Price" source="collectionAgent11011250" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.collectionAgent11011250 && (
              <NumberInput
                source="customer"
                label="Customer"
                value={
                  formData.collectionAgent11011250 -
                  (formData.commission11011250
                    ? formData.commission11011250
                    : 20)
                }
                disabled={true}
              />
            )
          }
        </FormDataConsumer>
        <TextInput label="Commission" source="commission11011250" />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <NumberInput label="Total Price" source="collectionAgent12511400" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.collectionAgent12511400 && (
              <NumberInput
                source="customer"
                label="Customer"
                value={
                  formData.collectionAgent12511400 -
                  (formData.commission12511400
                    ? formData.commission12511400
                    : 20)
                }
                disabled={true}
              />
            )
          }
        </FormDataConsumer>
        <TextInput label="Commission" source="commission12511400" />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <NumberInput label="Total Price" source="collectionAgent1400" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.collectionAgent1400 && (
              <NumberInput
                source="customer"
                label="Customer"
                value={
                  formData.collectionAgent1400 -
                  (formData.commission1400 ? formData.commission1400 : 20)
                }
                disabled={true}
              />
            )
          }
        </FormDataConsumer>
        <TextInput label="Commission" source="commission1400" />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <SelectInput
          source="commissionZonePrefix"
          choices={[
            { id: 'pound', name: '£' },
            { id: 'percentage', name: '%' },
          ]}
        />
        <TextInput
          label="Zone Default Commission"
          source="commissionZoneDefault"
        />
      </div>
    </RowForm>
  )
}

const transformData = (data) => {
  data.collectionAgent = data.collectionAgent['@id']

  return data
}

const ListAgentsCommission = (props) => (
  <List
    title="Edit Zone Commissions"
    aside={<FilterSidebar />}
    actions={null}
    sx={{ flexDirection: 'column-reverse' }}
  >
    <EditableDatagrid mutationMode="undoable" editForm={<CommissionForm />}>
      <TextField
        label="Company Name"
        source="collectionAgent.collectionAgentCompanyName"
      />
      <TextField label="Zone Name" source="collectionAgentZoneName" />
      <FunctionField
        label="0 - 800 KG"
        render={(record) => (
          <div>
            <table>
              <tr>
                <th align="center">Total price</th>
                <th align="center">Customer</th>
                <th align="center">Commission</th>
              </tr>
              <tr>
                <td align="center">&pound;{record.collectionAgent0800}</td>
                <td align="center">
                  &pound;
                  {record.collectionAgent0800 -
                    (record.commission0800 === 0 ? 20 : record.commission0800)}
                </td>
                <td align="center">
                  &pound;
                  {record.commission0800 === 0 ? 20 : record.commission0800}
                </td>
              </tr>
            </table>
          </div>
        )}
      />
      <FunctionField
        label="801 KG - 1100 KG"
        render={(record) => (
          <div>
            <table>
              <tr>
                <th align="center">Total price</th>
                <th align="center">Customer</th>
                <th align="center">Commission</th>
              </tr>
              <tr>
                <td align="center">&pound;{record.collectionAgent8011100}</td>
                <td align="center">
                  &pound;
                  {record.collectionAgent8011100 -
                    (record.commission8011100 === 0
                      ? 20
                      : record.commission8011100)}
                </td>
                <td align="center">
                  &pound;
                  {record.commission8011100 === 0
                    ? 20
                    : record.commission8011100}
                </td>
              </tr>
            </table>
          </div>
        )}
      />
      <FunctionField
        label="1101 KG - 1250 KG"
        render={(record) => (
          <div>
            <table>
              <tr>
                <th align="center">Total price</th>
                <th align="center">Customer</th>
                <th align="center">Commission</th>
              </tr>
              <tr>
                <td align="center">&pound;{record.collectionAgent11011250}</td>
                <td align="center">
                  &pound;
                  {record.collectionAgent11011250 -
                    (record.commission11011250 === 0
                      ? 20
                      : record.commission11011250)}
                </td>
                <td align="center">
                  &pound;
                  {record.commission11011250 === 0
                    ? 20
                    : record.commission11011250}
                </td>
              </tr>
            </table>
          </div>
        )}
      />
      <FunctionField
        label="1251 KG - 1400 KG"
        render={(record) => (
          <div>
            <table>
              <tr>
                <th align="center">Total price</th>
                <th align="center">Customer</th>
                <th align="center">Commission</th>
              </tr>
              <tr>
                <td align="center">&pound;{record.collectionAgent12511400}</td>
                <td align="center">
                  &pound;
                  {record.collectionAgent12511400 -
                    (record.commission12511400 === 0
                      ? 20
                      : record.commission12511400)}
                </td>
                <td align="center">
                  &pound;
                  {record.commission12511400 === 0
                    ? 20
                    : record.commission12511400}
                </td>
              </tr>
            </table>
          </div>
        )}
      />
      <FunctionField
        label="1401 KG - 1600 KG"
        render={(record) => (
          <div>
            <table>
              <tr>
                <th align="center">Total price</th>
                <th align="center">Customer</th>
                <th align="center">Commission</th>
              </tr>
              <tr>
                <td align="center">&pound;{record.collectionAgent1400}</td>
                <td align="center">
                  &pound;
                  {record.collectionAgent1400 -
                    (record.commission1400 === 0 ? 20 : record.commission1400)}
                </td>
                <td align="center">
                  &pound;
                  {record.commission1400 === 0 ? 20 : record.commission1400}
                </td>
              </tr>
            </table>
          </div>
        )}
      />
      <FunctionField
        label="Zone Commission Default"
        render={(record) => (
          <div>
            <table>
              <tr>
                <th align="center">Default Unit</th>
                <th align="center">Default Commission</th>
              </tr>
              <tr>
                <td align="center">
                  {record.commissionZonePrefix === 'pound' ? '£' : '%'}
                </td>
                <td align="center">
                  {record.commissionZoneDefault === 0
                    ? 20
                    : record.commissionZoneDefault}
                </td>
              </tr>
            </table>
          </div>
        )}
      />
    </EditableDatagrid>
  </List>
)

const ListCollectionAgentZoneCommissions = (props) => {
  return (
    <CardContent>
      <Typography typography="h4" component="h1" mb={2}>
        Edit Zone Commissions
      </Typography>
      <ListAgentsCommission {...props} />
    </CardContent>
  )
}

export default ListCollectionAgentZoneCommissions

import React, { useState } from 'react'
import { Box, Button, CircularProgress, Grid } from '@mui/material'
import { useNotify } from 'react-admin'
import {
  cancelPendingPaymentSalvageBookingsLead,
  redirectToSB,
} from '../../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const BalancePending = ({ sb }) => {
  const notify = useNotify()
  const [loading, setLoading] = useState(false)

  const handleButtonClick = async function () {
    if (!loading) {
      setLoading(true)
    }

    // Cancel pending balance payment in SB
    const responseStatus = await cancelPendingPaymentSalvageBookingsLead(
      sb.payments.balance.id,
      sb.quote_request_id,
      'balance'
    )

    if (responseStatus) {
      redirectToSB(sb.quote_request_id)
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={6}>
        Balance Payment Pending
        <div>
          <strong>
            {new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: 'GBP',
            }).format(sb.payments.balance.amount)}
          </strong>
        </div>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            label="Cancel Pending Payment"
            fullWidth
            disabled={loading}
            onClick={handleButtonClick}
          >
            Cancel Pending Payment
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </Grid>
    </React.Fragment>
  )
}

BalancePending.propTypes = {
  sb: PropTypes.object,
}

export default BalancePending

import PropTypes from 'prop-types'
import PopupPanel from '../PopupPanel'
import { Grid } from '@mui/material'
import TopSectionPanel from '../TopSectionPanel'
import DetailsPanel from '../DetailsPanel'
import CancelBooking from '../CustomStatusPanel/CancelBooking'

const CancelledInSQSalvageBooking = ({
  sb,
  popupPanelName,
  setPopupPanelName,
  popupPanelProps,
}) => {
  return (
    <Grid
      container
      spacing={3}
      style={{
        display: 'grid',
        gridColumn: '1 / span 6',
        gridRowStart: 4,
        gridRowEnd: 4,
        position: 'relative',
      }}
      sx={{
        marginTop: '0 !important',
        marginLeft: '0 !important',
        padding: '30px',
        width: '100%',
        minHeight: `${
          popupPanelName === ''
            ? '100%'
            : popupPanelName === 'sendEmail'
            ? '700px'
            : '500px'
        }`,
      }}
    >
      <PopupPanel
        sb={sb}
        popupPanelName={popupPanelName}
        setPopupPanelName={setPopupPanelName}
        popupPanelProps={popupPanelProps}
      />
      {/*Top Section*/}
      <Grid
        container
        spacing={3}
        sx={{
          borderBottom: '1px solid #8B4000',
          padding: '10px 0 !important',
          marginLeft: '0 !important',
          width: '100%',
          opacity: `${
            popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
          }`,
        }}
      >
        <TopSectionPanel
          sb={sb}
          setPopupPanelName={setPopupPanelName}
          cancelled={true}
        />
      </Grid>
      {/*End of top section*/}

      {/*Lead Details*/}
      <Grid
        container
        spacing={3}
        sx={{
          borderBottom: '1px solid #8B4000',
          padding: '0px 0 !important',
          marginLeft: '0 !important',
          width: '100%',
          opacity: `${
            popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
          }`,
        }}
      >
        <DetailsPanel sb={sb} setPopupPanelName={setPopupPanelName} />
      </Grid>
      {/*End Lead Details*/}

      {/*Cancelled Lead section*/}
      <Grid
        container
        spacing={3}
        sx={{
          borderBottom: '1px solid #8B4000',
          padding: '20px 0 !important',
          marginLeft: '0 !important',
          width: '100%',
          opacity: `${
            popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
          }`,
        }}
      >
        <Grid item xs={3}>
          <strong style={{ color: 'red', fontSize: '2em' }}>
            Lead Cancelled
          </strong>
        </Grid>
        <Grid item xs={7}>
          <CancelBooking sb={sb} cancelledInSQ={true} />
        </Grid>
      </Grid>
      {/*End Cancelled Lead section*/}
    </Grid>
  )
}

CancelledInSQSalvageBooking.propTypes = {
  sb: PropTypes.object,
  popupPanelName: PropTypes.string,
  setPopupPanelName: PropTypes.func,
  popupPanelProps: PropTypes.object,
}
export default CancelledInSQSalvageBooking

import { useEffect, useState } from 'react'
import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import { Grid } from '@mui/material'
import {
  getTemporaryUploadURLs,
  uploadCustomerPhotos,
} from '../../../../Utils/RestUtils'
import ImageGallerySlider from './ImageGallerySlider'

const UploadList = () => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const [isUploading, setIsUploading] = useState(false)
  const [vehicleImageUrls, setVehicleImageUrls] = useState(null)

  let fetchingVehicleImageUrls = false

  const getVehicleImageUrls = async () => {
    fetchingVehicleImageUrls = true

    const response = await getTemporaryUploadURLs(record.originId)

    if (response.result === 'success') {
      setVehicleImageUrls(response.temporary_urls)
    } else {
      setVehicleImageUrls([])
      notify('Unable to get vehicle images', { type: 'error' })
    }

    fetchingVehicleImageUrls = false
  }

  useEffect(() => {
    if (vehicleImageUrls === null && !fetchingVehicleImageUrls) {
      getVehicleImageUrls()
    }
  }, [])

  const handleCapture = async (e) => {
    setIsUploading(true)

    const uploadError = {
      count: 0,
      errors: [],
    }

    const files = e.target.files
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData()
      formData.append('quoteRequestId', record['@id'])
      formData.append('file', files[i], files[i].name)

      const uploadResult = await uploadCustomerPhotos(formData)
      if (uploadResult.status && uploadResult.status === 500) {
        //Means BE exception thrown = unable to upload to S3
        notify(
          'An error has occurred while handling the uploaded photos. Please refresh the page and try again.',
          { type: 'error' }
        )
        setIsUploading(false)

        return
      }

      if (uploadResult.result === 'error') {
        uploadError.count += 1
        uploadError.errors.push(uploadResult.error_msg)
      }
    }

    if (uploadError.count > 0) {
      if (uploadError.count === files.length) {
        notify(
          'Unable to upload the selected images, please check error list below: \n\n' +
            uploadError.errors.join(';\n'),
          { type: 'error', multiLine: true }
        )
      } else {
        notify(
          'Some of the images were not uploaded, please check error list below: \n\n' +
            uploadError.errors.join(';\n'),
          { type: 'warning', multiLine: true }
        )
      }
    }

    refresh()
    setIsUploading(false)
    getVehicleImageUrls()
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
    >
      <ImageGallerySlider vehicleImageUrls={vehicleImageUrls} />
    </Grid>
  )
}

export default UploadList

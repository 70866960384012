import React, { useState, useEffect } from 'react'
import {
  EditBase,
  Form,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { useLocation } from 'react-router-dom'
import { Grid, IconButton, TextField } from '@mui/material'
import { validateAutoSaveSingleInput } from '../../../../Utils/AutoSave'
import {
  blockInvalidCharFromNumberInput,
  numberInputOnWheelPreventChange,
} from '../../../../Utils/ConversionUtils'
import { Search } from '@mui/icons-material'
import { getRegLookup } from '../../../../Utils/RestUtils'
import { TagPanel } from '../Panels'

const VehicleDetailsPanel = (props) => {
  const record = useRecordContext()
  const location = useLocation()
  const notify = useNotify()
  const refresh = useRefresh()
  const [image, setImage] = useState(
    record.leadContact.vehicle.vehicleInfo &&
      record.leadContact.vehicle.vehicleInfo.dvlaImage
  )

  const regLookup = async () => {
    let vehicleReg = document.querySelector(
      '[name="leadContact.vehicle.registration"]'
    )
    // remove unwanted chars
    let newReg = vehicleReg.value.replace(/[^a-zA-Z0-9]/g, '')

    if (newReg.length <= 2 || newReg.length > 8) {
      notify('Registration provided is not valid', { type: 'error' })
      return
    }

    const response = await getRegLookup({
      reg: newReg,
      quoteRequestId: record.id,
    })
    if (response.result === 'error') {
      notify(response.message, { type: 'error' })
    } else {
      refresh()
    }
  }

  useEffect(() => {
    setImage(
      record.leadContact.vehicle.vehicleInfo &&
        record.leadContact.vehicle.vehicleInfo.dvlaImage
    )
  }, [location])

  return (
    <EditBase>
      <Form>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextInput
              label="Vehicle Reg."
              source="leadContact.vehicle.registration"
              onInput={(e) =>
                (e.target.value = ('' + e.target.value).toUpperCase())
              }
              fullWidth
              InputProps={{
                endAdornment: (
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="Search"
                      onClick={regLookup}
                    >
                      <Search />
                    </IconButton>
                  </React.Fragment>
                ),
              }}
            />
          </Grid>
          <Grid item flex={1}>
            <TextInput
              name="leadContact.vehicle.make"
              fullWidth
              label="Make"
              value={record.leadContact.vehicle.make}
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
          <Grid item flex={2}>
            <TextInput
              name="leadContact.vehicle.model"
              fullWidth
              label="Model"
              value={record.leadContact.vehicle.model}
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.aspiration"
              readOnly={true}
              label="Aspiration"
              fullWidth
              value={
                record.leadContact.vehicle.vehicleInfo &&
                record.leadContact.vehicle.vehicleInfo.aspiration
              }
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.engineModelCode"
              readOnly={true}
              label="Engine Code"
              fullWidth
              value={
                record.leadContact.vehicle.vehicleInfo &&
                record.leadContact.vehicle.vehicleInfo.engineModelCode
              }
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.colour"
              fullWidth
              readOnly={true}
              label="Colour"
              value={
                record.leadContact.vehicle.vehicleInfo &&
                record.leadContact.vehicle.vehicleInfo.colour
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.modelSeries"
              readOnly={true}
              label="Series"
              fullWidth
              value={
                record.leadContact.vehicle.vehicleInfo &&
                record.leadContact.vehicle.vehicleInfo.modelSeries
              }
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.seats"
              readOnly={true}
              label="Seats"
              fullWidth
              value={
                record.leadContact.vehicle.vehicleInfo &&
                record.leadContact.vehicle.vehicleInfo.seats
              }
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.doors"
              fullWidth
              readOnly={true}
              label="Doors"
              value={
                record.leadContact.vehicle.vehicleInfo &&
                record.leadContact.vehicle.vehicleInfo.doors
              }
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.fuelType"
              fullWidth
              readOnly={true}
              label="Fuel"
              value={
                record.leadContact.vehicle.vehicleInfo &&
                record.leadContact.vehicle.fuelType
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.transmission"
              readOnly={true}
              label="Trans."
              fullWidth
              value={
                record.leadContact.vehicle.vehicleInfo &&
                record.leadContact.vehicle.vehicleInfo.transmission
              }
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.engineSize"
              readOnly={true}
              label="Engine CC"
              fullWidth
              value={
                record.leadContact.vehicle.vehicleInfo &&
                record.leadContact.vehicle.engineSize
              }
            />
          </Grid>
          <Grid item flex={1}>
            <Form>
              <TextInput
                name="leadContact.vehicle.vehicleInfo.kerbWeight"
                label="Kerb Weight"
                value={
                  record.leadContact.vehicle.vehicleInfo &&
                  record.leadContact.vehicle.vehicleInfo.kerbWeight
                }
                type={'number'}
                onWheel={numberInputOnWheelPreventChange}
                onKeyDown={blockInvalidCharFromNumberInput}
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
              />
            </Form>
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.previousKeepers"
              label="Keepers"
              inputProps={{
                readOnly: true,
              }}
              fullWidth
              value={
                record.leadContact.vehicle.vehicleInfo &&
                record.leadContact.vehicle.vehicleInfo.previousKeepers
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item flex={2}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.bodyStyle"
              readOnly={true}
              label="Body"
              fullWidth
              value={
                record.leadContact.vehicle.vehicleInfo &&
                record.leadContact.vehicle.vehicleInfo.bodyStyle
              }
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.registrationDate"
              readOnly={true}
              label="First reg."
              fullWidth
              value={
                record.leadContact.vehicle.vehicleInfo &&
                new Date(
                  record.leadContact.vehicle.vehicleInfo.registrationDate
                ).toLocaleDateString()
              }
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleDocumentation.dateCurrentKeeper"
              readOnly={true}
              label="Start curr."
              fullWidth
              value={
                record.leadContact.vehicle.vehicleDocumentation &&
                new Date(
                  record.leadContact.vehicle.vehicleDocumentation.dateCurrentKeeper
                ).toLocaleDateString()
              }
            />
          </Grid>
        </Grid>
        <Grid sx={{ mb: 2 }}>
          <TagPanel {...props} />
        </Grid>
        <Grid sx={{ backgroundColor: 'white' }}>
          <div
            style={{
              margin: 'auto',
              height: '100px',
              width: '255px',
              backgroundImage: "url('" + image + "'), url('missing-image.png')",
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </Grid>
      </Form>
    </EditBase>
  )
}

export default VehicleDetailsPanel

import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import { validateAutoSaveSingleInput } from '../../../../../Utils/AutoSave'
import { useNotify, useRecordContext, useRefresh, useUpdate } from 'react-admin'
import { getCharities } from '../../../../../Utils/RestUtils'
import {
  blockInvalidCharFromNumberInput,
  numberInputOnWheelPreventChange,
} from '../../../../../Utils/ConversionUtils'
import { getAgentAssignedToLeadByOfferHistory } from '../../../../../Utils/QuoteRequestUtils'

const ScrapBookingPanel = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [update, { isLoading }] = useUpdate()
  const [charities, setCharities] = useState()
  const [callActive, setCallActive] = useState()
  const [totalValue, setTotalValue] = useState(undefined)
  const [customerPrice, setCustomerPrice] = useState(undefined)
  const [initialTotalValue, setInitialTotalValue] = useState(undefined)
  const [commissionValue, setCommissionValue] = useState(undefined)
  const totalValueRef = useRef()
  const customerValueRef = useRef()
  const commissionRef = useRef()
  const quoteOfferHistory = getAgentAssignedToLeadByOfferHistory(
    record.offerHistory
  )

  const updateCommission = (inputValue) => {
    if (quoteOfferHistory && !isLoading) {
      const parsedValue = inputValue ? parseFloat(inputValue) : 0

      update('quotehistory', {
        id: quoteOfferHistory['@id'],
        data: { commission: parsedValue },
        previousData: quoteOfferHistory,
      }).then(() => {
        setTimeout(() => {
          refresh()
        }, 100)
      })
    }
  }

  const updateCustomerPrice = (inputValue) => {
    if (quoteOfferHistory && !isLoading) {
      const parsedValue = inputValue ? parseFloat(inputValue) : 0

      let newCommission = totalValue - parsedValue - record.donationAmount

      update('quotehistory', {
        id: quoteOfferHistory['@id'],
        data: {
          customerPrice: parsedValue,
          commission: newCommission,
        },
        previousData: quoteOfferHistory,
      }).then(() => {
        setTimeout(() => {
          refresh()
        }, 100)
      })
    }
  }

  const updateTotalValye = (inputValue) => {
    if (quoteOfferHistory && !isLoading) {
      const parsedValue = inputValue ? parseFloat(inputValue) : 0

      update('quotehistory', {
        id: quoteOfferHistory['@id'],
        data: { collectorPrice: parsedValue },
        previousData: quoteOfferHistory,
      }).then(() => {})
    }
  }

  const onTotalValueBlur = () => {
    if (isNaN(totalValue)) {
      setTotalValue(initialTotalValue)

      notify('Total value cannot be blank', { type: 'error' })
      return
    }

    let totalValueDiff = parseFloat(totalValue) - parseFloat(initialTotalValue)

    let currentCommission = quoteOfferHistory
      ? parseFloat(quoteOfferHistory.commission)
      : 0

    updateTotalValye(totalValue)
    updateCommission(parseFloat(currentCommission) + parseFloat(totalValueDiff))
  }

  const onCommissionBlur = () => {
    let customerPriceNew = parseFloat(totalValue) - parseFloat(commissionValue)
    updateCustomerPrice(customerPriceNew)
    updateCommission(commissionValue)
  }

  const onCustomerPriceBlur = () => {
    updateCustomerPrice(customerPrice)
  }

  const handleDonationBlur = async (e) => {
    await validateAutoSaveSingleInput(
      record,
      e.target,
      notify,
      refresh,
      'Donation amount'
    )
  }

  useEffect(() => {
    if (!charities && !callActive) {
      const getCharitiesList = async () => {
        setCallActive(!callActive)
        return await getCharities()
      }
      getCharitiesList().then((result) => {
        setCharities(result)
      })
    }

    if (quoteOfferHistory) {
      const commission = quoteOfferHistory
        ? parseFloat(quoteOfferHistory.commission)
        : 0

      const total = quoteOfferHistory
        ? parseFloat(quoteOfferHistory.collectorPrice)
        : 0

      const donationAmount = record.donationAmount
        ? parseFloat(record.donationAmount)
        : 0

      setCustomerPrice(total - donationAmount - commission)
      setCommissionValue(commission)
      setTotalValue(total)
      setInitialTotalValue(total)
    }
  }, [record, refresh])

  useLayoutEffect(() => {
    totalValueRef.current.addEventListener('blur', onTotalValueBlur)
    commissionRef.current.addEventListener('blur', onCommissionBlur)

    return () => {
      totalValueRef.current.removeEventListener('blur', onTotalValueBlur)
      commissionRef.current.removeEventListener('blur', onCommissionBlur)
    }
  })

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Grid
            style={{
              display: 'grid',
              gridColumn: '1 / span 12',
              gridRowStart: 1,
              gridRowEnd: 1,
            }}
          >
            <Grid
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 6fr)',
                gridRowStart: 1,
                gridRowEnd: 1,
              }}
            >
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 3',
                  gridRowStart: 1,
                  gridRowEnd: 1,
                  marginRight: '1rem',
                  marginBottom: '1rem',
                }}
              >
                Customer Details
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 3',
                  gridRowStart: 2,
                  gridRowEnd: 2,
                  marginRight: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  disabled={true}
                  aria-readonly={true}
                  name="leadContact.fullName"
                  label="Customer name"
                  defaultValue={record.leadContact.fullName}
                  sx={{
                    marginBottom: '-0.7rem !important',
                    marginRight: '1.4rem !important',
                  }}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '4 / span 3',
                  gridRowStart: 2,
                  gridRowEnd: 2,
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  disabled={true}
                  aria-readonly={true}
                  name="leadContact.telNumber"
                  label="Contact Number"
                  defaultValue={record.leadContact.telNumber}
                  sx={{
                    marginBottom: '-0.7rem !important',
                  }}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 4',
                  gridRowStart: 3,
                  gridRowEnd: 3,
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  disabled={true}
                  aria-readonly={true}
                  name="leadContact.email"
                  label="Email Address"
                  defaultValue={record.leadContact.email}
                  sx={{
                    marginBottom: '-0.7rem !important',
                  }}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 6',
                  gridRowStart: 4,
                  gridRowEnd: 4,
                  marginRight: '1rem',
                  marginBottom: '1rem',
                }}
              >
                Vehicle Details
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 2',
                  gridRowStart: 5,
                  gridRowEnd: 5,
                  marginRight: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  disabled={true}
                  aria-readonly={true}
                  name="leadContact.vehicle.registration"
                  label="Vehicle Reg"
                  defaultValue={record.leadContact.vehicle.registration}
                  sx={{
                    marginBottom: '-0.7rem !important',
                    marginRight: '1.4rem !important',
                  }}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '3 / span 2',
                  gridRowStart: 5,
                  gridRowEnd: 5,
                  marginRight: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  disabled={true}
                  aria-readonly={true}
                  name="leadContact.vehicle.model"
                  label="Model"
                  defaultValue={record.leadContact.vehicle.model}
                  sx={{
                    marginBottom: '-0.7rem !important',
                    marginRight: '1.4rem !important',
                  }}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '5 / span 2',
                  gridRowStart: 5,
                  gridRowEnd: 5,
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  disabled={true}
                  aria-readonly={true}
                  name="leadContact.vehicle.make"
                  label="Vehicle Make"
                  defaultValue={record.leadContact.vehicle.make}
                  sx={{
                    marginBottom: '-0.7rem !important',
                    marginRight: '1.4rem !important',
                  }}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 3',
                  gridRowStart: 6,
                  gridRowEnd: 6,
                  marginRight: '1rem',
                  marginBottom: '1rem',
                }}
              >
                Collection Location
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 2',
                  gridRowStart: 7,
                  gridRowEnd: 7,
                  marginRight: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  disabled={true}
                  aria-readonly={true}
                  name="leadContact.postcode"
                  label="Postcode"
                  defaultValue={record.leadContact.postcode}
                  sx={{
                    marginBottom: '-0.7rem !important',
                  }}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '3 / span 4',
                  gridRowStart: 7,
                  gridRowEnd: 7,
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  disabled={true}
                  aria-readonly={true}
                  name="leadContact.addressLine1"
                  label="Address Line 1"
                  defaultValue={record.leadContact.addressLine1}
                  sx={{
                    marginBottom: '-0.7rem !important',
                  }}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 4',
                  gridRowStart: 8,
                  gridRowEnd: 8,
                  marginRight: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  disabled={true}
                  aria-readonly={true}
                  name="leadContact.addressLine2"
                  label="Address Line 2"
                  defaultValue={record.leadContact.addressLine2}
                  sx={{
                    marginBottom: '-0.7rem !important',
                  }}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '5 / span 2',
                  gridRowStart: 8,
                  gridRowEnd: 8,
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  disabled={true}
                  aria-readonly={true}
                  name="leadContact.town"
                  label="Town"
                  defaultValue={record.leadContact.town}
                  sx={{
                    marginBottom: '-0.7rem !important',
                  }}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 2',
                  gridRowStart: 9,
                  gridRowEnd: 9,
                }}
              >
                <TextField
                  disabled={true}
                  aria-readonly={true}
                  name="leadContact.county"
                  label="County"
                  defaultValue={record.leadContact.county}
                  sx={{
                    marginBottom: '-0.7rem !important',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Grid
            style={{
              display: 'grid',
              gridColumn: '1 / span 12',
              gridRowStart: 1,
              gridRowEnd: 1,
              marginTop: '1rem',
            }}
          >
            <Grid
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(9, 9fr)',
                gridRowStart: 1,
                gridRowEnd: 1,
              }}
            >
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 3',
                  gridRowStart: 1,
                  gridRowEnd: 1,
                  marginRight: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  name="totalValue"
                  label="Total value £"
                  type={'number'}
                  disabled={quoteOfferHistory ? false : true}
                  inputRef={totalValueRef}
                  value={totalValue}
                  onWheel={numberInputOnWheelPreventChange}
                  onKeyDown={blockInvalidCharFromNumberInput}
                  onChange={(e) => setTotalValue(parseFloat(e.target.value))}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '4 / span 3',
                  gridRowStart: 1,
                  gridRowEnd: 1,
                  marginRight: '1rem',
                }}
              >
                <TextField
                  name="totalValue"
                  label="Customer Total £"
                  type={'number'}
                  disabled={true}
                  inputRef={customerValueRef}
                  value={customerPrice}
                  onWheel={numberInputOnWheelPreventChange}
                  onKeyDown={blockInvalidCharFromNumberInput}
                  onChange={(e) => setCustomerPrice(parseFloat(e.target.value))}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '7 / span 3',
                  gridRowStart: 1,
                  gridRowEnd: 1,
                }}
              >
                <TextField
                  name="commission"
                  label="Commission £"
                  type={'number'}
                  disabled={quoteOfferHistory ? false : true}
                  inputRef={commissionRef}
                  value={commissionValue}
                  onWheel={numberInputOnWheelPreventChange}
                  onKeyDown={blockInvalidCharFromNumberInput}
                  onChange={(e) => setCommissionValue(e.target.value)}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 3',
                  gridRowStart: 2,
                  gridRowEnd: 2,
                  marginRight: '1rem',
                }}
              >
                <TextField
                  name="donationAmount"
                  label="Charity donation £"
                  defaultValue={record.donationAmount}
                  type={'number'}
                  inputProps={{
                    step: 0.01,
                    min: 0,
                  }}
                  onKeyDown={blockInvalidCharFromNumberInput}
                  onBlur={handleDonationBlur}
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '4 / span 6',
                  gridRowStart: 2,
                  gridRowEnd: 2,
                }}
              >
                <FormControl sx={{ width: '100%' }} size="small">
                  <TextField
                    label="Charity Name"
                    name="donationReceiver"
                    select
                    defaultValue={
                      record.donationReceiver &&
                      record.donationReceiver['@id'] !== null
                        ? record.donationReceiver['@id']
                        : 'default'
                    }
                    onChange={async (e) =>
                      await validateAutoSaveSingleInput(
                        record,
                        e.target,
                        notify,
                        refresh,
                        'Selected Charity'
                      )
                    }
                  >
                    <MenuItem disabled value="default">
                      Charity name
                    </MenuItem>
                    {charities &&
                      charities.map((charity) => {
                        return (
                          <MenuItem key={charity.id} value={charity.id}>
                            {charity.name}
                          </MenuItem>
                        )
                      })}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Grid
            style={{
              display: 'grid',
              gridColumn: '1 / span 12',
              gridRowStart: 5,
              gridRowEnd: 5,
              marginTop: '1rem',
            }}
          >
            <Grid
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(8, 8fr)',
                gridRowStart: 5,
                gridRowEnd: 5,
              }}
            >
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 8',
                  gridRowStart: 4,
                  gridRowEnd: 4,
                }}
              >
                <TextField
                  name="collectorNote"
                  label="Notes to collector"
                  source="collectorNote"
                  multiline={true}
                  onBlur={async (e) =>
                    await validateAutoSaveSingleInput(
                      record,
                      e.target,
                      notify,
                      refresh,
                      'Notes to collector'
                    )
                  }
                  defaultValue={record.collectorNote}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}

export default ScrapBookingPanel

import React, { useState, useEffect } from 'react'

import {
  Datagrid,
  FormDataConsumer,
  FunctionField,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'

import { getOptions } from '../../Utils/RestUtils'

import { useRecordContext } from 'react-admin'

import {
  CreateInDialogButton,
  EditInDialogButton,
} from '@react-admin/ra-form-layout'
import PropTypes from 'prop-types'

const DatagridActionsColumn = ({ children }) => <>{children}</>

DatagridActionsColumn.propTypes = {
  children: PropTypes.any,
}

const RulesPanel = (props) => {
  const [matchCriteria, setMatchCriteria] = useState()
  const [matchType, setMatchType] = useState()
  const record = useRecordContext()
  const fakeProps = {
    resource: 'rules-sets-rules',
  }
  const selectTypes = [14, 16, 17, 18, 19, 20]
  const criteriaOptions = {
    14: [
      {
        id: '1',
        name: 'No Faults',
      },
      {
        id: '2',
        name: 'Faulty But Runs',
      },
      {
        id: '3',
        name: "Doesn't Run",
      },
    ],
    16: [
      {
        id: '1',
        name: 'No Faults',
      },
      {
        id: '2',
        name: 'Faulty But Runs',
      },
      {
        id: '3',
        name: "Doesn't Run",
      },
    ],
    17: [
      {
        id: '0',
        name: 'No',
      },
      {
        id: '1',
        name: 'Yes',
      },
    ],
    18: [
      {
        id: '1',
        name: 'No',
      },
      {
        id: '2',
        name: 'Yes',
      },
      {
        id: '3',
        name: 'Yes (Airbag deployed)',
      },
    ],
    19: [
      {
        id: '1',
        name: 'No',
      },
      {
        id: '2',
        name: 'CAT C',
      },
      {
        id: '3',
        name: 'CAT D',
      },
      {
        id: '4',
        name: 'CAT S',
      },
      {
        id: '5',
        name: 'CAT N',
      },
    ],
    20: [
      {
        id: '0',
        name: 'No',
      },
      {
        id: '1',
        name: 'Yes',
      },
    ],
  }

  const RulesForm = (props) => {
    return (
      <SimpleForm {...props}>
        {matchType != null ? (
          <SelectInput source="matchType" choices={matchType} />
        ) : (
          <p>Loading...</p>
        )}

        {matchCriteria != null ? (
          <SelectInput source="matchCriteria" choices={matchCriteria} />
        ) : (
          <p>Loading...</p>
        )}
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.matchType !== undefined &&
            selectTypes.includes(formData.matchType) && (
              <React.Fragment>
                <SelectInput
                  source="matchValue"
                  choices={criteriaOptions[formData.matchType]}
                />
              </React.Fragment>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.matchType !== undefined &&
            !selectTypes.includes(formData.matchType) && (
              <React.Fragment>
                <TextInput source="matchValue" />
              </React.Fragment>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    )
  }

  const createButton = (
    <CreateInDialogButton
      inline
      fullWidth
      title="Create Rule"
      record={{ ruleSets: record.id }} // pre-populates the employer_id to link the new customer to the current employer
    >
      <RulesForm {...props} />
    </CreateInDialogButton>
  )

  const editButton = (
    <EditInDialogButton title="Edit Rule">
      <RulesForm {...props} />
    </EditInDialogButton>
  )

  useEffect(() => {
    const getSelectOptions = async () => {
      setMatchCriteria(await getOptions('RuleSetsRules', 'getCriteriaOptions'))
      setMatchType(await getOptions('RuleSetsRules', 'getMatchOptions'))
    }
    getSelectOptions()
  }, [])

  return (
    <React.Fragment>
      <h3>Rules</h3>
      <List
        style={{
          width: '100%',
        }}
        {...fakeProps}
        actions={createButton}
        filter={{ 'ruleSets.id': record.id }}
        empty={createButton}
      >
        <Datagrid>
          {matchType != null ? (
            <FunctionField
              render={(record) => `${matchType[record.matchType].name}`}
              source="matchType"
            />
          ) : (
            <p>Loading...</p>
          )}
          {matchCriteria != null ? (
            <FunctionField
              render={(record) => `${matchCriteria[record.matchCriteria].name}`}
              source="matchCriteria"
            />
          ) : (
            <p>Loading...</p>
          )}
          <TextField source="matchValue" />
          <DatagridActionsColumn label="Actions">
            {editButton}
          </DatagridActionsColumn>
        </Datagrid>
      </List>
    </React.Fragment>
  )
}

export default RulesPanel

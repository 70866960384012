import { useState } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import { useNotify } from 'react-admin'
import { updateVehicleAdjustmentValue } from '../../Utils/RestUtils'
import AdjustmentSelect from './AdjustmentSelect'
import PropTypes from 'prop-types'

const Adjustment = ({ adjustment, adjustmentSettings }) => {
  let adjType = adjustmentSettings.adjustmentType
  let adjValues = adjustmentSettings.adjustmentValues

  //Override of adjust display name
  let adjustName = adjustmentSettings.adjustmentName
  if (adjustName === 'Keys') {
    adjustName = 'Two Keys'
  } else if (adjustName === 'MOT Fault') {
    adjustName = 'MOT Failure'
  } else if (adjustName === 'Insurance') {
    adjustName = 'Insurance Write Off'
  }

  const currentValue = Array.isArray(adjValues)
    ? adjValues[adjustment.value]
    : [adjValues, 'negative', 'percentage']

  let componentType = adjType === 'GROUPED' ? 'SWITCH' : 'SELECT'

  const defaultCheckedValue =
    componentType === 'SWITCH' ? adjustment.value !== 0 : currentValue[0] !== 0

  const [checked, setChecked] = useState(defaultCheckedValue)
  const [confirmed, setConfirmed] = useState(adjustment.confirmed)
  const [selected, setSelected] = useState(adjustment.value)
  const [adjustmentData, setAdjustmentData] = useState(currentValue)
  const notify = useNotify()

  const handleChange = async (e) => {
    e.stopPropagation()

    try {
      let adjustmentValue

      if (componentType === 'SWITCH') {
        adjustmentValue = !checked ? 1 : 0
      }

      if (componentType === 'SELECT') {
        adjustmentValue = parseInt(e.target.value)
        e.target.classList.add('MuiButton-containedSuccess')
        e.target.classList.remove('MuiButton-containedError')
      }

      setSelected(adjustmentValue)
      setChecked(!checked)
      setConfirmed(true)

      const response = await updateVehicleAdjustmentValue(
        adjustment['@id'],
        adjustmentValue
      )
      const updatedValue = Array.isArray(adjValues)
        ? adjValues[response.value]
        : [adjValues, 'negative', 'percentage']

      setAdjustmentData(updatedValue)
      window.localStorage.setItem('recalculate', 'true')
      window.dispatchEvent(new Event('storage'))
    } catch (error) {
      notify('Error updating value: ' + error, { type: 'error' })
    }
  }

  return componentType === 'SWITCH' ? (
    <FormControlLabel
      control={
        <Checkbox
          color={`${
            confirmed && checked
              ? 'success'
              : !confirmed && checked
              ? 'secondary'
              : 'error'
          }`}
          checked={checked}
          onClick={handleChange}
          inputProps={{ 'data-confirmed': confirmed }}
        />
      }
      label={adjustmentSettings.adjustmentName}
      className="vehicle-adjustment__label"
    />
  ) : (
    <AdjustmentSelect
      adjustmentSettings={adjustmentSettings}
      selectedIndex={selected}
      confirmed={confirmed}
      onChange={handleChange}
      adjustName={adjustName}
    />
  )
}

Adjustment.propTypes = {
  adjustment: PropTypes.any,
  adjustmentSettings: PropTypes.shape({
    adjustmentValues: PropTypes.array,
    adjustmentType: PropTypes.string,
    adjustmentName: PropTypes.string,
  }),
}

export default Adjustment

const callOutcome = [
  {
    value: 'answered',
    label: 'Answered',
  },

  {
    value: 'voicemail',
    label: 'Voicemail',
  },
  {
    value: 'no_answer',
    label: 'No Answer',
  },
  {
    value: 'left_message',
    label: 'Left Message',
  },
  {
    value: 'busy_tone',
    label: 'Busy Tone',
  },
  {
    value: 'faulty_number',
    label: 'Faulty Number',
  },
]

const notificationType = [
  {
    value: '1',
    label: 'Follow up',
  },
  {
    value: '2',
    label: 'Bank Details',
  },
  {
    value: '3',
    label: 'Finance clearance',
  },
  {
    value: '4',
    label: 'Chase pics',
  },
  {
    value: '5',
    label: 'Manual',
  },
  {
    value: '6',
    label: 'Need details 1',
  },
  {
    value: '7',
    label: 'Need details 2',
  },
  {
    value: '8',
    label: 'Need details 3',
  },
  {
    value: '9',
    label: 'Need details 4',
  },
  {
    value: '10',
    label: 'Need details 5',
  },
  {
    value: '11',
    label: 'Need details 6',
  },
]

export { callOutcome, notificationType }

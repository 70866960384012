import { CardContent, Typography } from '@mui/material'
import ListAllTags from '../../Components/Tag/ListAllTags'

const ListCharities = (props) => {
  return (
    <CardContent>
      <Typography typography="h4" component="h1" mb={2}>
        Tags
      </Typography>
      <ListAllTags {...props} />
    </CardContent>
  )
}

export default ListCharities

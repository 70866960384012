import React from 'react'

import {
  Create,
  SimpleForm,
  NumberInput,
  TextInput,
  BooleanInput,
  required,
  SelectInput,
  FormDataConsumer,
} from 'react-admin'

import AgentReferenceField from '../../Components/CollectionAgent/AgentReferenceField'

const CreateRuleSet = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          label="Rule Name"
          source="name"
          validate={required()}
          fullWidth
        />
        <BooleanInput source="active" fullWidth defaultValue={true} />
        <SelectInput
          source="type"
          choices={[
            { id: 'interest', name: 'Interest' },
            { id: 'price_adjustment', name: 'Price Adjustment' },
            { id: 'cap_lookup', name: 'CAP Lookup' },
            { id: 'salvage', name: 'Salvage' },
            { id: 'salvage_exclusion', name: 'Salvage Exclusion' },
            { id: 'price_notes', name: 'Price Notes' },
          ]}
          resettable={true}
          fullWidth
        />
        <SelectInput
          source="matchType"
          choices={[
            { id: 'match_all', name: 'All Rule must apply' },
            { id: 'match_any', name: 'Any Rule will trigger' },
          ]}
          resettable={true}
          fullWidth
        />
        <TextInput source="note" multiline={true} fullWidth />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type &&
            formData.type === 'salvage' && (
              <React.Fragment>
                <NumberInput source="commission" fullWidth />
                <NumberInput source="minPrice" fullWidth />
              </React.Fragment>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type &&
            formData.type !== 'salvage_exclusion' &&
            formData.type !== 'cap_lookup' && (
              <React.Fragment>
                <AgentReferenceField
                  source="collectionAgent"
                  reference="collection-agents"
                  allowEmpty
                  perPage={10000}
                  fullWidth
                />
              </React.Fragment>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type &&
            formData.type === 'price_adjustment' && (
              <React.Fragment>
                <SelectInput
                  label="Select Commission Unit"
                  source="commissionUnit"
                  choices={[
                    { id: 'pound', name: 'Pound' },
                    { id: 'percentage', name: 'Percentage' },
                  ]}
                  fullWidth
                  emptyText="Select Commission Unit"
                  required
                />
                <NumberInput
                  source="commissionValue"
                  label="Commission"
                  fullWidth
                />
              </React.Fragment>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export default CreateRuleSet

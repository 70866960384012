import React, { useState, useEffect } from 'react'
import {
  BooleanInput,
  Datagrid,
  List,
  NumberInput,
  SelectArrayInput,
  ReferenceArrayInput,
  SelectInput,
  TextField,
  useCreate,
  useUpdate,
  useRefresh,
} from 'react-admin'
import { getOptions } from '../../Utils/RestUtils'
import { useRecordContext, SimpleForm } from 'react-admin'
import {
  EditInDialogButton,
  CreateInDialogButton,
} from '@react-admin/ra-form-layout'
import PropTypes from 'prop-types'

const DatagridActionsColumn = ({ children }) => <>{children}</>

DatagridActionsColumn.propTypes = {
  children: PropTypes.any,
}

const RulesAdjustmentPanel = (props) => {
  const [matchCriteria, setMatchCriteria] = useState()
  const [matchType, setMatchType] = useState()
  const record = useRecordContext()
  const [create] = useCreate()
  const [update] = useUpdate()
  const refresh = useRefresh()
  const fakeProps = {
    resource: 'rules-adjustments',
  }

  useEffect(() => {
    const getSelectOptions = async () => {
      setMatchCriteria(await getOptions('RuleSetsRules', 'getCriteriaOptions'))
      setMatchType(await getOptions('RuleSetsRules', 'getMatchOptions'))
    }
    getSelectOptions()
  }, [])

  const postSave = (data) => {
    create(
      'rules-adjustments',
      { data },
      {
        onSuccess: (data) => {
          refresh()
        },
      }
    )
  }

  const editSave = (data) => {
    update('rules-adjustments', {
      id: data['@id'],
      data: data,
      previousData: props,
    }).then(() => {
      setTimeout(() => {
        refresh()
      }, 100)
    })
  }

  const CustomerForm = (props) => {
    return (
      <SimpleForm {...props} redirect={false} onSubmit={postSave}>
        <SelectInput
          label="Action"
          source="action"
          choices={[
            { id: 'add', name: 'Add' },
            { id: 'subtract', name: 'Subtract' },
          ]}
          fullWidth
          emptyText="Select Action"
          required
        />
        <NumberInput source="value" label="Value" />
        <SelectInput
          label="Adjust by"
          source="unit"
          choices={[
            { id: 'pound', name: '£ Value' },
            { id: 'percentage', name: '% Value' },
          ]}
          fullWidth
          emptyText="Adjust by"
          required
        />
        <BooleanInput source="priceOverride" label="Price Override" />
        <React.Fragment>
          <ReferenceArrayInput
            source="ruleSetAdjustmentWeights"
            reference="rules-weights"
            parse={(value) => value && value.map((v) => ({ id: v }))}
            format={(value) => value && value.map((v) => v.id)}
          >
            <SelectArrayInput label="Weights" />
          </ReferenceArrayInput>
        </React.Fragment>
      </SimpleForm>
    )
  }

  const CustomerFormEdit = (props) => {
    return (
      <SimpleForm {...props} redirect={false} onSubmit={editSave}>
        <SelectInput
          label="Action"
          source="action"
          choices={[
            { id: 'add', name: 'Add' },
            { id: 'subtract', name: 'Subtract' },
          ]}
          fullWidth
          emptyText="Select Action"
          required
        />
        <NumberInput source="value" label="Value" />
        <SelectInput
          label="Adjust by"
          source="unit"
          choices={[
            { id: 'pound', name: '£ Value' },
            { id: 'percentage', name: '% Value' },
          ]}
          fullWidth
          emptyText="Adjust by"
          required
        />
        <BooleanInput source="priceOverride" label="Price Override" />
        <React.Fragment>
          <ReferenceArrayInput
            source="ruleSetAdjustmentWeights"
            reference="rules-weights"
            parse={(value) => value && value.map((v) => ({ id: v }))}
            format={(value) => value && value.map((v) => v.id)}
          >
            <SelectArrayInput label="Weights" />
          </ReferenceArrayInput>
        </React.Fragment>
      </SimpleForm>
    )
  }

  const createButton = (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="md"
      title="Create Adjustment"
      record={{ ruleSet: record.id }} // pre-populates the employer_id to link the new customer to the current employer
    >
      <CustomerForm {...props} />
    </CreateInDialogButton>
  )

  const editButton = (
    <EditInDialogButton title="Edit Adjustment" redirect={false}>
      <CustomerFormEdit {...props} />
    </EditInDialogButton>
  )

  return (
    <React.Fragment>
      <h3>Adjustments</h3>
      <List
        style={{
          width: '100%',
        }}
        {...fakeProps}
        actions={createButton}
        filter={{ 'ruleSet.id': record.id }}
        empty={createButton}
      >
        <Datagrid>
          <TextField source="action" />
          <TextField source="value" />
          <TextField source="unit" />
          <DatagridActionsColumn label="Actions">
            {editButton}
          </DatagridActionsColumn>
        </Datagrid>
      </List>
    </React.Fragment>
  )
}

export default RulesAdjustmentPanel

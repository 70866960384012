import { useState } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import ContactLogPanel from '../ContactLogPanel'
import QuoteHistoryPanel from '../QuoteHistoryPanel'
import RemindersPanel from '../RemindersPanel'
import AuditLogPanel from '../../AuditLogPanel'
import EmailsPanel from '../../EmailsPanel'
import TextsPanel from '../../TextsPanel'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

const HistoryTabbedPanel = ({
  contactLogScreen,
  setContactLogScreen,
  historyPanelSelectedTab: selectedTab,
  setHistoryPanelSelectedTab: setSelectedTab,
}) => {
  const [contactLogData, setContactLogData] = useState(null)
  const [remindersData, setRemindersData] = useState(null)
  const [viewAgentsModal, setViewAgentsModal] = useState(false)

  if (contactLogScreen === 'add-contact-log') {
    setSelectedTab(1)
  }

  const handleChange = (event, newSelectedTab) => {
    if (selectedTab === 2 && viewAgentsModal) {
      setViewAgentsModal(false)
    }

    setSelectedTab(newSelectedTab)
  }

  return (
    <Box className="history-panel">
      {/*<AppBar position="static" className="history-panel__tabs">*/}
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="History tabbed panel"
      >
        <Tab className="history-panel__tabs-individual" label="QUOTE HISTORY" />
        <Tab className="history-panel__tabs-individual" label="CONTACT LOG" />
        <Tab className="history-panel__tabs-individual" label="LEAD HISTORY" />
        <Tab className="history-panel__tabs-individual" label="REMINDERS" />
        <Tab className="history-panel__tabs-individual" label="EMAILS" />
        <Tab className="history-panel__tabs-individual" label="TEXTS" />
      </Tabs>
      {/*</AppBar>*/}
      <TabPanel
        value={selectedTab}
        index={0}
        style={{
          maxHeight: '315px',
          overflow: 'scroll',
        }}
      >
        <QuoteHistoryPanel viewAgentsModal={viewAgentsModal} />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index={1}
        style={{
          maxHeight: '315px',
          overflow: 'scroll',
        }}
      >
        <ContactLogPanel
          setSelectedTab={setSelectedTab}
          setData={setContactLogData}
          data={contactLogData}
          setViewAgentsModal={setViewAgentsModal}
          contactLogScreen={contactLogScreen}
          setContactLogScreen={setContactLogScreen}
        />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index={2}
        style={{
          maxHeight: '315px',
          overflow: 'scroll',
        }}
      >
        <AuditLogPanel />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index={3}
        style={{
          maxHeight: '315px',
          overflow: 'scroll',
        }}
      >
        <RemindersPanel setData={setRemindersData} data={remindersData} />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index={4}
        style={{
          maxHeight: '315px',
          overflow: 'scroll',
        }}
      >
        <EmailsPanel />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index={5}
        style={{
          maxHeight: '315px',
          overflow: 'scroll',
        }}
      >
        <TextsPanel />
      </TabPanel>
    </Box>
  )
}

export default HistoryTabbedPanel

HistoryTabbedPanel.propTypes = {
  contactLogScreen: PropTypes.string,
  setContactLogScreen: PropTypes.func,
  setHistoryPanelSelectedTab: PropTypes.func,
  historyPanelSelectedTab: PropTypes.number,
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from '@mui/material'
import { Button, useNotify, useRefresh } from 'react-admin'
import RolesListDropdown from './RolesListDropdown'
import PasswordInputsBuilder from './PasswordInputsBuilder'
import { validateEditUser } from './hooks/userValidation'
import { saveUpdatedUser } from '../../Utils/RestUtils'
import PropTypes from 'prop-types'

const EditUserModal = ({ currentUser }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [openModal, setOpenModal] = useState(false)
  const [userDetails, setUserDetails] = useState({
    id: currentUser.id,
    displayName: currentUser.displayName,
    email: currentUser.email,
    password: '',
    repeatPassword: '',
    roles: currentUser.roles[0],
    disabled: currentUser.disabled,
    highValueUser: currentUser.highValueUser,
  })

  const handleOpenModal = () => {
    //reset values to default, in case of details changed and not saved
    setUserDetails({
      id: currentUser.id,
      displayName: currentUser.displayName,
      email: currentUser.email,
      password: '',
      repeatPassword: '',
      roles: currentUser.roles[0],
      disabled: currentUser.disabled,
      highValueUser: currentUser.highValueUser,
    })

    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleInputChange = (e) => {
    const inputName = e.target.name

    const inputValue =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value

    setUserDetails((prev) => {
      return {
        ...prev,
        [inputName]: inputValue,
      }
    })
  }

  const handleSaveUser = async () => {
    const error = validateEditUser(userDetails)
    if (error !== undefined) {
      notify(error, { type: 'error' })
      return
    }

    const response = await saveUpdatedUser({ ...userDetails })
    if (response.result === 'error' && response.error_msg) {
      notify(response.error_msg, { type: 'error' })
      return
    }

    handleCloseModal()

    refresh()
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleOpenModal}
        label="edit"
        className="users-list__table-edit-btn"
      />

      <Dialog
        onClose={handleCloseModal}
        open={openModal}
        className="view-users__modal"
      >
        <DialogTitle>Edit user</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: '30px' }}>
            Please add name and details of the new user below:
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                name="displayName"
                label="Display Name"
                value={userDetails.displayName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="email"
                label="Email"
                value={userDetails.email}
                onChange={handleInputChange}
              />
            </Grid>
            <PasswordInputsBuilder
              userDetails={userDetails}
              handleInputChange={handleInputChange}
            />
            <Grid item xs={6}>
              <RolesListDropdown
                currentUser={userDetails}
                handleInputChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup sx={{ float: 'right' }}>
                <FormControlLabel
                  name="disabled"
                  onChange={handleInputChange}
                  control={
                    <Switch checked={userDetails.disabled} color="warning" />
                  }
                  label="User Account Disabled?"
                  sx={{ marginRight: 0 }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup sx={{ float: 'left' }}>
                <FormControlLabel
                  name="highValueUser"
                  onChange={handleInputChange}
                  control={
                    <Switch
                      checked={userDetails.highValueUser}
                      color="warning"
                    />
                  }
                  label="High Value User?"
                  sx={{ marginRight: 0 }}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingTop: 0 }}>
          <Button label="Cancel" onClick={handleCloseModal} autoFocus />
          <Button label="Save" onClick={handleSaveUser} autoFocus />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

EditUserModal.propTypes = {
  currentUser: PropTypes.object,
}

export default EditUserModal

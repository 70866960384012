import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useNotify, useRecordContext } from 'react-admin'
import { sendLog } from '../../Class/AuditLog'
import { sendPurchaseAgreementEmail } from '../../Utils/QuoteRequestUtils'
import CompleteBookingButtonSalvage from './Edit/BookInPanel/Salvage/CompleteBookingButtonSalvage'
import CompleteBookingButtonScrap from './Edit/BookInPanel/Scrap/CompleteBookingButtonScrap'
import { AgentAssignValidator } from './Edit/ViewAgentsPanel/AgentAssignValidator'
import { PurchaseAgreementDialog } from './TermsAndConditions/PurchaseAgreementDialog'
import { Terms } from './TermsAndConditions/Terms'

const TermsAndConditions = (props) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [termsConfirmed, setTermsConfirmed] = useState(false)
  const [canCompleteBooking, setCanCompleteBooking] = useState(false)
  const [purchaseAgreementSent, setPurchaseAgreementSent] = useState(false)
  const [termsRead, setTermsRead] = useState(false)
  const isSalvage = record.leadContact.vehicle.tooGoodToScrap

  useEffect(() => {
    isBookingPossible()
  }, [])

  const isBookingPossible = () => {
    if (isSalvage) {
      setCanCompleteBooking(
        termsRead === true && termsConfirmed === true && purchaseAgreementSent
      )
    } else {
      setCanCompleteBooking(termsRead === true && termsConfirmed === true)
    }
  }

  const confirmTerms = (e) => {
    if (e.target.checked === true) {
      sendLog(record.originId, 'Terms and Conditions Read in Full').then(
        (response) => {
          if (response.hasOwnProperty('success')) {
            setTermsConfirmed(true)
            setCanCompleteBooking(true)
          } else {
            setTermsConfirmed(false)
            setCanCompleteBooking(false)
            notify(response.error, { type: response.type })
          }
        }
      )
    }
  }

  const sendPurchaseAgreement = () => {
    const errors = AgentAssignValidator(record, null)
    if (errors.length > 0) {
      notify(
        'Please fix below issues in order to send purchase agreement: \n\n' +
          errors.join(';\n'),
        { type: 'error', multiLine: true }
      )
    } else {
      let purpose = 'purchase_agreement_' + props.assignedTo

      sendPurchaseAgreementEmail(record.id, purpose).then((response) => {
        if (response.status === 'success') {
          setPurchaseAgreementSent(true)
        }

        notify(response.message, { type: response.status })
      })
    }
  }

  return (
    <Grid container sx={{}}>
      <Card sx={{ border: 'none' }}>
        <CardContent>
          <Terms assignedTo={props.assignedTo} setTermsRead={setTermsRead} />
        </CardContent>
      </Card>
      <Card
        sx={{
          height: '211px',
          width: `100%`,
          border: 'none',
        }}
      >
        <CardContent>
          <FormGroup>
            <FormControlLabel
              disabled={termsConfirmed}
              onChange={confirmTerms}
              control={<Checkbox />}
              label="TERMS & CONDITIONS READ IN FULL"
            />
          </FormGroup>

          {isSalvage !== 0 && (
            <React.Fragment>
              <Grid
                style={{
                  gridColumn: '1 / 1',
                  gridRowStart: 2,
                  gridRowEnd: 2,
                  marginBottom: '0.5rem',
                }}
              >
                <PurchaseAgreementDialog assignedTo={props.assignedTo} />
              </Grid>
              <Grid
                style={{
                  gridColumn: '1 / 1',
                  gridRowStart: 3,
                  gridRowEnd: 3,
                  marginBottom: '0.5rem',
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!canCompleteBooking || purchaseAgreementSent}
                  onClick={sendPurchaseAgreement}
                  fullWidth
                >
                  Send Purchase Agreement
                </Button>
              </Grid>
            </React.Fragment>
          )}
          <Grid style={{ gridColumn: '2 / 2', gridRowStart: 3, gridRowEnd: 3 }}>
            {isSalvage == 1 && (
              <CompleteBookingButtonSalvage
                termsRead={termsRead}
                termsConfirmed={termsConfirmed}
                purchaseAgreementSent={purchaseAgreementSent}
                assignedTo={props.assignedTo}
              />
            )}

            {isSalvage == 0 && (
              <CompleteBookingButtonScrap
                termsRead={termsRead}
                termsConfirmed={termsConfirmed}
              />
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

TermsAndConditions.propTypes = {
  assignedTo: PropTypes.any,
}

export default TermsAndConditions

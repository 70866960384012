import React, { useState } from 'react'
import { Grid, TextField, Typography } from '@mui/material'
import { useRecordContext } from 'react-admin'
import BalanceButton from './BalanceButton'
import PropTypes from 'prop-types'

const BalanceOutstandingByCheque = ({
  sb,
  setPopupPanelName,
  setPopupPanelProps,
}) => {
  const record = useRecordContext()
  const [chequePayeeName, setChequePayeeName] = useState('')
  const [chequePayeeNameValidationError, setChequePayeeNameValidationError] =
    useState(null)

  const handleChequePayeeNameChange = (values) => {
    setChequePayeeNameValidationError(null)
    setChequePayeeName(values.currentTarget.value)
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          {record.leadContact.vehicle.vehicleSalvage.chequePayment && (
            <React.Fragment>
              <Typography
                sx={{
                  fontWeight: '700',
                  letterSpacing: '0.46px',
                  marginRight: '5px',
                  textTransform: 'uppercase',
                }}
              >
                Outstanding:
              </Typography>
              <Typography
                sx={{
                  fontWeight: '700',
                  letterSpacing: '0.46px',
                  textTransform: 'capitalize',
                }}
              >
                {new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                }).format(sb.payments.outstandingBalance)}
              </Typography>
            </React.Fragment>
          )}
        </Grid>

        {!record.deposit &&
          record.leadContact.vehicle.vehicleSalvage.chequePayment && (
            <React.Fragment>
              <Grid item xs={6}>
                <TextField
                  label="Payee Name"
                  variant="outlined"
                  type="text"
                  fullWidth
                  onChange={handleChequePayeeNameChange}
                  error={chequePayeeNameValidationError}
                  helperText={chequePayeeNameValidationError ?? ' '}
                  sx={{
                    '& .MuiInputBase-root': {
                      marginBottom: 0,
                      height: '40px',
                      minHeight: '40px',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <BalanceButton
                  sb={sb}
                  setPopupPanelName={setPopupPanelName}
                  chequePayeeName={chequePayeeName}
                  setPopupPanelProps={setPopupPanelProps}
                  setChequePayeeNameValidationError={
                    setChequePayeeNameValidationError
                  }
                />
              </Grid>
            </React.Fragment>
          )}
      </Grid>
    </React.Fragment>
  )
}

BalanceOutstandingByCheque.propTypes = {
  sb: PropTypes.object,
  setPopupPanelName: PropTypes.func,
  setPopupPanelProps: PropTypes.func,
}

export default BalanceOutstandingByCheque

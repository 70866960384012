import { useEffect, useState } from 'react'
import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import { Button } from '@mui/material'
import { completeSalvageBooking } from '../../../../../Utils/RestUtils'
import { AgentAssignValidator } from '../../ViewAgentsPanel/AgentAssignValidator'
import CancelBookingModal from '../CancelBookingModal'
import PropTypes from 'prop-types'
import {
  BMS_SALVAGE_AGENT_ID,
  SHORE_CARS_AGENT_ID,
} from '../../../../../Utils/ConstantsUtils'

const CompleteBookingButtonSalvage = ({
  termsRead,
  termsConfirmed,
  purchaseAgreementSent,
  assignedTo,
}) => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [canCompleteBooking, setCanCompleteBooking] = useState(false)
  const isBookingCompleted = record.bookingCompleted

  useEffect(() => {
    setCanCompleteBooking(
      termsConfirmed === true && purchaseAgreementSent === true
    )
  }, [termsRead, termsConfirmed, purchaseAgreementSent, refresh])

  const handleCompleteBooking = async () => {
    const agentId =
      assignedTo === 'salvage'
        ? BMS_SALVAGE_AGENT_ID
        : assignedTo === 'cars'
        ? SHORE_CARS_AGENT_ID
        : 0

    if (agentId === 0) {
      notify('Assigned to not selected', { type: 'error' })
      return
    }

    const errors = await AgentAssignValidator(record, agentId)
    if (errors === false) {
      //Means that it is under outstanding finance and agent didn't want to proceed
      return
    }

    if (errors.length > 0) {
      notify(
        'Please fix below issues in order to assign an agent: \n\n' +
          errors.join(';\n'),
        { type: 'error', multiLine: true }
      )
      return
    }

    const bookingResponse = await completeSalvageBooking({
      quoteRequestId: record['@id'],
    })

    if (bookingResponse.result === 'success') {
      refresh()
      notify('Booking successfully assigned', { type: 'success' })
    } else {
      notify(bookingResponse.error_msg, { type: 'error' })
    }
  }

  return !isBookingCompleted ? (
    <Button
      variant="contained"
      disabled={!canCompleteBooking && !isBookingCompleted}
      fullWidth
      onClick={handleCompleteBooking}
    >
      Assign & Complete Booking
    </Button>
  ) : (
    <CancelBookingModal />
  )
}

export default CompleteBookingButtonSalvage

CompleteBookingButtonSalvage.propTypes = {
  termsRead: PropTypes.bool,
  termsConfirmed: PropTypes.bool,
  purchaseAgreementSent: PropTypes.bool,
  assignedTo: PropTypes.any,
}

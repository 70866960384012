import React, { useState } from 'react'
import {
  Button,
  Form,
  useNotify,
  useRecordContext,
  useRefresh,
  useTheme,
} from 'react-admin'
import { Grid, MenuItem, TextField, Typography, Card } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { addContactLog } from '../../../../Utils/QuoteRequestUtils'
import { callOutcome, notificationType } from './DropdownValues'
import dayjs from 'dayjs'
import ModalOptions from './ModalOptions'
import './style/index.scss'
import PropTypes from 'prop-types'

const AddContactLog = ({
  setSelectedTab,
  setViewAgentsModal,
  setContactLogScreen,
}) => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [theme] = useTheme()
  const [dateTime, setDateTime] = useState(dayjs())
  const [contactLog, setContactLog] = useState({
    quoteType: '',
    quote: '',
    payload: '',
    date: '',
    time: '',
    type: 2,
    outcome: '',
    segment: 'na',
    user: window.localStorage.getItem('email'),
    quoteId: record.id,
    priority: '',
    reminder: '',
  })

  let contactAddStyle = {
    background: 'black',
    borderColor: 'white',
    borderWidth: '5px',
    borderStyle: 'solid',
    display: 'block',
    padding: '20px',
    position: 'relative',
    zIndex: '99999',
  }

  const handleOutcomeChange = (e) => {
    const { value } = e.target

    setContactLog((prevLog) => {
      prevLog.payload = value
      prevLog.outcome = value
      prevLog.reminder = ''

      return {
        ...prevLog,
      }
    })

    if (value.toLowerCase() === 'answered') {
      handleNewContactLog(false)
    } else if (value.toLowerCase() === 'faulty_number') {
      handleNewContactLog(true)
    }
  }

  const handleDropdownChange = (e) => {
    const { name, value } = e.target

    setContactLog({
      ...contactLog,
      [name]: value,
    })
  }

  const handleNotificationTypeChange = (e) => {
    const { innerText } = e.target

    setContactLog((prevLog) => {
      prevLog.reminder = innerText
      return {
        ...prevLog,
      }
    })
  }

  const handleNewContactLog = async (redirectToQuoteHistory = true) => {
    const dateTimeSplit = dateTime.$d.toLocaleString('en-GB').split(',')

    const contactLogData = {
      ...contactLog,
      date: dateTimeSplit[0].trim(),
      time: dateTimeSplit[1].trim(),
    }

    const result = await addContactLog(contactLogData)
    if (result.status === 'success') {
      refresh()

      if (redirectToQuoteHistory) {
        //To display View agents modal on Quote history panel
        setViewAgentsModal(true)

        //to auto change tab to quote history
        setSelectedTab(0)
      }
    } else {
      notify(result.error, { type: 'error' })
    }

    setContactLogScreen('list')
  }

  return (
    <React.Fragment>
      <div
        style={{
          background: 'black',
          height: 'calc(100vh + 50px)',
          opacity: '0.5',
          display: 'block',
          position: 'fixed',
          left: '0',
          top: '-50px',
          width: '100vw',
          zIndex: '99998',
        }}
      ></div>
      <Card className="add-contact-log-modal">
        <Typography
          variant="h5"
          sx={{
            marginTop: 0,
            marginBottom: '1rem',
            fontWeight: 700,
            fontSize: '14px',
            textTransform: 'uppercase',
            lineHeight: '1.334',
          }}
        >
          Add a Contact Log
        </Typography>
        <Form onSubmit={handleNewContactLog} className="reminder-form">
          <Grid container spacing={1}>
            <Grid container spacing={1} xs={6} sx={{ paddingRight: 2 }}>
              <Grid item flex={1} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField name="date" {...props} required />
                    )}
                    label="Contact time"
                    value={dateTime}
                    disabled={true}
                    readOnly={true}
                    ampm={false}
                    inputFormat="DD/MM/YYYY HH:mm"
                    onChange={(newDateTime) => {
                      setDateTime(newDateTime)
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item flex={1} xs={12}>
                <TextField
                  name="outcome"
                  select
                  label="Call Outcome"
                  onChange={handleOutcomeChange}
                  value={contactLog.outcome}
                  required
                >
                  {callOutcome.map((option, key) => (
                    <MenuItem key={key} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item flex={1} xs={12}>
                <TextField
                  name="priority"
                  select
                  label="Notification Type"
                  onChange={handleDropdownChange}
                  value={contactLog.priority}
                  required
                >
                  {notificationType.map((option, key) => (
                    <MenuItem
                      key={key}
                      value={option.value}
                      onClick={handleNotificationTypeChange}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={0} xs={6}>
              <Grid item flex={1}>
                <TextField
                  name="reminder"
                  label="Set Reminder"
                  multiline
                  rows={4}
                  sx={{
                    height: '100%',
                    '& .MuiInputBase-root': {
                      height: '100%',
                    },
                  }}
                  onChange={handleDropdownChange}
                />
              </Grid>
            </Grid>
            <Grid item flex={1} xs={12} className="reminder-form__button-grid">
              <Button
                type="submit"
                label="Issue Quote"
                color="primary"
                sx={{ float: 'right' }}
                size="large"
              />
              <Button
                onClick={() => setContactLogScreen('list')}
                type="button"
                sx={{ float: 'right', marginRight: '10px' }}
                label="Cancel"
                color="primary"
                size="large"
              />
            </Grid>
          </Grid>
        </Form>
      </Card>
      <ModalOptions setContactLogScreen={setContactLogScreen} />
    </React.Fragment>
  )
}

AddContactLog.propTypes = {
  setContactLogScreen: PropTypes.func,
  setSelectedTab: PropTypes.func,
  setViewAgentsModal: PropTypes.func,
}

export default AddContactLog

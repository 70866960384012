import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useRecordContext } from 'react-admin'
import DepositRefunded from './DepositRefunded'
import DepositRefundUnrecoverable from './DepositRefundUnrecoverable'
import DepositPaid from './DepositPaid'
import DepositPending from './DepositPending'
import DepositNotMade from './DepositNotMade'
import DepositNotRequired from './DepositNotRequired'
import DepositOutstanding from './DepositOutstanding'
import PropTypes from 'prop-types'

const DepositPayment = ({ sb }) => {
  const record = useRecordContext()

  if (sb) {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: '#ED9C23',
                fontWeight: '700',
                letterSpacing: '0.46px',
                marginRight: '5px',
                textTransform: 'uppercase',
              }}
            >
              Deposit Payment:
            </Typography>
          </Grid>

          {sb.payments.deposit ? (
            sb.payments.deposit.payment_paid ? (
              sb.cancelled ? (
                sb.payments.deposit.refund_status ? (
                  sb.payments.deposit.refund_status.status == 'refunded' ? (
                    <DepositRefunded payment={sb.payments.deposit} />
                  ) : (
                    <DepositRefundUnrecoverable payment={sb.payments.deposit} />
                  )
                ) : (
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        color: '#FF0B08',
                        fontWeight: '700',
                        letterSpacing: '0.46px',
                        textTransform: 'capitalize',
                      }}
                    >
                      Deposit Payment Refund Outstanding
                    </Typography>
                    <DepositPaid payment={sb.payments.deposit} />
                  </Grid>
                )
              ) : (
                <DepositPaid payment={sb.payments.deposit} />
              )
            ) : (
              <DepositPending sb={sb} />
            )
          ) : sb.cancelled ? (
            <DepositNotMade />
          ) : (
            sb.assigned_to_copart_at &&
            (!record.deposit ||
            record.leadContact.vehicle.vehicleSalvage.chequePayment ? (
              <DepositNotRequired
                isChequePayment={
                  record.leadContact.vehicle.vehicleSalvage.chequePayment
                }
              />
            ) : (
              <DepositOutstanding sb={sb} />
            ))
          )}
        </Grid>
      </React.Fragment>
    )
  }
}

DepositPayment.propTypes = {
  sb: PropTypes.object,
}

export default DepositPayment

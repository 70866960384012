import {
  Edit,
  TextInput,
  SimpleForm,
  PasswordInput,
  BooleanInput,
  SelectArrayInput,
} from 'react-admin'
import { DEFAULT_USER_ROLES } from '../../Utils/ConstantsUtils'

const formValidator = (values) => {
  const errors = {}

  if (values.password !== values.passwordConfirmation) {
    errors.password = 'Passwords do not match'
  }

  if (values.roles.length < 2) {
    errors.roles = 'You need to to add at least one role'
  }

  return errors
}

const transformData = (data) => {
  delete data.passwordConfirmation
  if (data.password === '') {
    delete data.password
  }

  return data
}

const EditUser = (props) => {
  return (
    <Edit {...props} transform={transformData}>
      <SimpleForm validate={formValidator}>
        <TextInput
          label="Display name"
          source="displayName"
          fullWidth
          required
        />
        <TextInput label="Email" source="email" fullWidth required />
        <PasswordInput
          label="New password"
          id="password"
          name="password"
          source=""
          fullWidth
        />
        <PasswordInput
          label="Repeat new password"
          id="passwordConfirmation"
          name="passwordConfirmation"
          source=""
          fullWidth
        />
        <SelectArrayInput
          label="Roles"
          source="roles"
          choices={DEFAULT_USER_ROLES}
          required
        />
        <BooleanInput source="disabled" label="User account disabled?" />
        <BooleanInput
          source="highValueUser"
          label="Include in high value leads?"
          name="highValueUser"
        />
      </SimpleForm>
    </Edit>
  )
}

export default EditUser

import React, { useState } from 'react'
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import moment from 'moment'
import {
  blockInvalidCharFromNumberInput,
  numberInputOnWheelPreventChange,
} from '../../../../Utils/ConversionUtils'
import PropTypes from 'prop-types'
import {
  BMS_SALVAGE_AGENT_ID,
  SHORE_CARS_AGENT_ID,
} from '../../../../Utils/ConstantsUtils'
import ReassignLeadButton from '../ReassignLeadButton'

const Reserve = ({ sb, setPopupPanelName }) => {
  const [loading, setLoading] = useState(false)
  const [reserveValidationError, setReserveValidationError] = useState(null)
  const [reserve, setReserve] = useState('')

  const handleReserveChange = (event) => {
    setReserveValidationError('')
    if (event.target.value <= 0) {
      setReserveValidationError('Reserve must be more than 0')
    }

    setReserve(event.target.value)
  }

  const handleButtonClick = () => {
    setLoading(true)

    if (reserve === '') {
      setReserveValidationError('Reserve must be more than 1')
      setLoading(false)
      return
    }

    setPopupPanelName('premiumLead')
  }

  return (
    <Grid
      container
      xs={12}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      {sb && sb.quarantined ? (
        <Typography
          sx={{
            fontWeight: '700',
            lineHeight: '26px',
            letterSpacing: '0.46px',
            textTransform: 'capitalize',
          }}
        >
          Lead is currently quarantined, unquarantine to proceed
        </Typography>
      ) : !sb || sb.assigned_to_copart_at == null ? (
        <React.Fragment>
          <Grid item xs={4} sx={{ paddingRight: 3 }}>
            <TextField
              id="reserve-assign-copart"
              label="Reserve"
              variant="outlined"
              type="number"
              value={reserve}
              onWheel={numberInputOnWheelPreventChange}
              onKeyDown={blockInvalidCharFromNumberInput}
              onChange={handleReserveChange}
              error={reserveValidationError}
              helperText={reserveValidationError ?? ' '}
              sx={{
                '& .MuiInputBase-root': {
                  marginBottom: 0,
                  height: '40px',
                  minHeight: '40px',
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="secondary"
              label="Set reserve & assign to copart"
              fullWidth
              disabled={loading}
              onClick={handleButtonClick}
              sx={{
                border: '2px solid #FFF !important',
                backgroundColor: '#13A310',
                color: '#FFF',
                height: '40px',
                fontWeight: '700',
                textTransform: 'uppercase',
                '&:hover': {
                  backgroundColor: '#13A310',
                },
              }}
            >
              Set reserve & assign to copart
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <ReassignLeadButton
              currentAgentId={BMS_SALVAGE_AGENT_ID}
              newAgentId={SHORE_CARS_AGENT_ID}
              buttonLabel="Transfer To Shore Cars"
            />
          </Grid>
        </React.Fragment>
      ) : sb && sb.reserve != null ? (
        <React.Fragment>
          <Typography
            sx={{
              color: '#13A310',
              fontWeight: '700',
              lineHeight: '26px',
              letterSpacing: '0.46px',
              marginRight: '5px',
              textTransform: 'uppercase',
            }}
          >
            Assigned:{' '}
          </Typography>
          <Typography
            sx={{
              fontWeight: '700',
              letterSpacing: '0.46px',
              lineHeight: '26px',
              textTransform: 'uppercase',
            }}
          >
            {new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: 'GBP',
            }).format(sb.reserve)}{' '}
            Reserve
          </Typography>
          <Typography
            sx={{
              fontWeight: '400',
              lineHeight: '26px',
              letterSpacing: '0.46px',
              marginLeft: 1,
            }}
          >
            {moment(sb.assigned_to_copart_at).format('DD/MM/YYYY HH:mm')}
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {sb == null && (
            <Typography sx={{ fontWeight: '700' }}>
              Reserve Needs to be Set
            </Typography>
          )}
        </React.Fragment>
      )}
    </Grid>
  )
}

Reserve.propTypes = {
  sb: PropTypes.object,
  setPopupPanelName: PropTypes.func,
}

export default Reserve

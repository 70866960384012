const checkIfUserHasExternalRole = () => {
  const roles = localStorage.getItem('permissions').split(',')
  return (
    roles.includes('ROLE_EXTERNAL_SALVAGE') ||
    roles.includes('ROLE_EXTERNAL_SCRAP') ||
    roles.includes('ROLE_SALVAGE_NOTIFICATIONS')
  )
}

const getAvailableStatuses = (currentStatus) => {
  const roles = localStorage.getItem('permissions').split(',')
  let statuses = []

  const noReply = [
    'New inquiry',
    'Quarantine',
    'Incorrect number',
    'No reply',
    'Salvage Auto No Reply',
  ]
  const incorrectNumber = [
    'Incorrect number',
    'New inquiry',
    'Quarantine',
    'No reply',
    'Salvage Auto No Reply',
  ]
  const awaitingPhotos = [
    'Awaiting photos',
    'New inquiry',
    'Quarantine',
    'Incorrect number',
    'No reply',
    'Price given',
    'Salvage Auto No Reply',
  ]
  const quoted = [
    'Quoted',
    'New inquiry',
    'Quarantine',
    'Incorrect number',
    'No reply',
    'Awaiting photos',
    'Salvage Auto No Reply',
  ]
  const priceGiven = [
    'Price given',
    'New inquiry',
    'Quarantine',
    'Incorrect number',
    'No reply',
    'Awaiting photos',
    'Quoted',
    'Offer accepted',
    'Salvage Auto No Reply',
  ]
  const newInquiry = ['New inquiry', 'Quarantine', 'Offer accepted']
  const quarantine = ['Quarantine']
  const lastChance = [
    'Last chance',
    'Incorrect number',
    'No reply',
    'Price given',
    'Salvage Auto No Reply',
  ]
  const offerAccepted = [
    'Offer accepted',
    'Incorrect number',
    'No reply',
    'Awaiting photos',
    'Quoted',
    'Price given',
    'Salvage Auto No Reply',
  ]
  const leadSent = ['Lead sent', 'Price given', 'Offer accepted']
  const leadReceived = ['Lead received', 'Lead sent']
  const leadDeclined = ['Lead declined', 'Lead sent', 'Lead received']

  const bookedIn = ['Booked in', 'Lead sent', 'Lead received', 'Lead declined']
  const collected = [
    'Collected',
    'Lead sent',
    'Lead received',
    'Lead declined',
    'Booked in',
    'Quarantine',
    'Quarantine',
    'Quarantine',
  ]
  const salvageAutoNoReply = ['Salvage Auto No Reply']

  if (noReply.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'No reply' })
  }
  if (incorrectNumber.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Incorrect number' })
  }
  if (awaitingPhotos.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Awaiting photos' })
  }
  if (quoted.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Quoted' })
  }
  if (priceGiven.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Price given' })
  }
  if (newInquiry.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'New inquiry' })
  }
  if (quarantine.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Quarantine' })
  }
  if (lastChance.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Last chance' })
  }
  if (offerAccepted.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Offer accepted' })
  }
  if (leadSent.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Lead sent' })
  }
  if (leadReceived.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Lead received' })
  }
  if (leadDeclined.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Lead declined' })
  }

  if (roles.includes('ROLE_ADMIN')) {
    const collectionCanceled = [
      'Collection canceled',
      'Lead sent',
      'Lead received',
      'Lead declined',
      'Booked in',
      'Collected',
    ]

    if (collectionCanceled.indexOf(currentStatus) > -1) {
      statuses.push({ name: 'Collection canceled' })
    }
  }

  if (bookedIn.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Booked In' })
  }
  if (collected.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Collected' })
  }
  if (salvageAutoNoReply.indexOf(currentStatus) > -1) {
    statuses.push({ name: 'Salvage Auto No Reply' })
  }

  return statuses
}

export { checkIfUserHasExternalRole, getAvailableStatuses }

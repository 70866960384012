import React, { useState } from 'react'
import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { unassignAgentFromLead } from '../../../../Utils/RestUtils'
import PropTypes from 'prop-types'

const CancelAssignmentModal = ({
  assignedAgentId,
  assignedAgentZoneId,
  assignedAgentCompanyName,
  selectedAgentId,
  selectedAgentZoneId,
  selectedAgentCompanyName,
  handleAssignAgent,
  updateAssignedAgentOnAgentsData,
}) => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const [openModal, setOpenModal] = useState(false)
  const isSameAgentSelect = assignedAgentId == selectedAgentId
  const isBookingCompleted = record.bookingCompleted

  const handleReassignAgent = async () => {
    const response = await unassignAgentFromLead({
      leadId: record['@id'],
      agentId: assignedAgentId,
    })

    if (response.result === 'success') {
      if (!isSameAgentSelect) {
        handleAssignAgent()
      } else {
        updateAssignedAgentOnAgentsData(0, '')

        refresh()
      }

      setOpenModal(false)
    } else {
      notify(response.error_msg, { type: 'error' })
    }
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        className={`${
          isBookingCompleted
            ? ''
            : isSameAgentSelect
            ? 'view-agents__table_unassign-btn'
            : 'view-agents__table_assign-btn'
        }`}
        fullWidth
        disabled={isBookingCompleted}
        onClick={() => setOpenModal(true)}
      >
        {isSameAgentSelect ? 'Selected' : 'Select'}
      </Button>

      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        className="view-agents__modal"
      >
        <DialogTitle>Reselect agent?</DialogTitle>
        <DialogContent>
          {isSameAgentSelect ? (
            <DialogContentText>
              Would you like to unselect
              <strong> {assignedAgentCompanyName}</strong>?
            </DialogContentText>
          ) : (
            <DialogContentText>
              Would you like to unselect
              <strong> {assignedAgentCompanyName}</strong> and select to
              <strong> {selectedAgentCompanyName}</strong>?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpenModal(false)}>
            No
          </Button>
          <Button onClick={handleReassignAgent} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default CancelAssignmentModal

CancelAssignmentModal.propTypes = {
  assignedAgentId: PropTypes.number,
  assignedAgentZoneId: PropTypes.number,
  assignedAgentCompanyName: PropTypes.string,
  selectedAgentId: PropTypes.number,
  selectedAgentZoneId: PropTypes.number,
  selectedAgentCompanyName: PropTypes.string,
  handleAssignAgent: PropTypes.func,
  updateAssignedAgentOnAgentsData: PropTypes.func,
}

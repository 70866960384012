import { TextInput, SimpleForm, BooleanInput, Create } from 'react-admin'

const CreateCharity = (props) => {
  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <TextInput
          label="Charity Title"
          source="charityTitle"
          fullWidth
          required
        />
        <BooleanInput source="showInDropdown" label="Show in Dropdown?" />
        <BooleanInput source="visible" label="Visible?" />
      </SimpleForm>
    </Create>
  )
}

export default CreateCharity

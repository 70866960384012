import SBUrlHandler from './SBUrlHandler'
import { handleLeadLocking } from './RestUtils'

const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT

const SALVAGE_BOOKINGS_FEE_TYPES = [
  {
    value: 'v5c',
    label: 'V5C Transfer',
    defaultAmount: '5',
  },
  {
    value: 'bodywork_chip',
    label: 'Bodywork Chip',
  },
  {
    value: 'mechanical_chip',
    label: 'Mechanical Chip',
  },
  {
    value: 'collection',
    label: 'Collection Charge',
  },
  {
    value: 'missing_key',
    label: 'Missing Key',
  },
  {
    value: 'v5c_replacement',
    label: 'V5C Replacement',
    defaultAmount: '25',
  },
  {
    value: 'no_service_history',
    label: 'No Service History',
    defaultAmount: '20',
  },
  {
    value: 'rubbish_removal',
    label: 'Rubbish Removal',
  },
  {
    value: 'professional_valet',
    label: 'Professional Valet',
  },
]

const cancelSalvageBookingsLead = async (
  quoteRequestId,
  cancellationReason
) => {
  try {
    const request = new Request(
      `${entrypoint}/services/quote/salvage-booking/lead/cancel`,
      {
        body: JSON.stringify({
          quoteRequestId: quoteRequestId,
          cancellationReason: cancellationReason,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'PATCH',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error) {
          throw Error(responseJSON.Error)
        }

        return true
      })
  } catch (error) {
    return false
  }
}

const quarantineSalvageBookingsLead = async (
  quoteRequestId,
  notQuarantined
) => {
  try {
    const request = new Request(
      `${entrypoint}/services/quote/salvage-booking/lead/quarantine`,
      {
        body: JSON.stringify({
          quoteRequestId: quoteRequestId,
          quarantine: `${notQuarantined ? 'true' : 'false'}`,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'PATCH',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }

        return true
      })
  } catch (error) {
    return false
  }
}

const addFeeSalvageBookingsLead = async (quoteRequestId, type, amount) => {
  try {
    const request = new Request(
      `${entrypoint}/services/quote/salvage-booking/fee`,
      {
        body: JSON.stringify({
          quoteRequestId: quoteRequestId,
          type: type,
          amount: amount,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'POST',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }

        return true
      })
  } catch (error) {
    return false
  }
}

const deleteFeeSalvageBookingsLead = async (
  quoteRequestId,
  feeId,
  feeAmount,
  feeType
) => {
  try {
    const request = new Request(
      `${entrypoint}/services/quote/salvage-booking/fee`,
      {
        body: JSON.stringify({
          quoteRequestId: quoteRequestId,
          id: feeId,
          amount: feeAmount,
          type: feeType,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'DELETE',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }

        return true
      })
  } catch (error) {
    return false
  }
}

const cancelPendingPaymentSalvageBookingsLead = async (
  paymentBalanceId,
  quoteRequestId,
  paymentType
) => {
  try {
    const request = new Request(
      `${entrypoint}/services/quote/salvage-booking/payment`,
      {
        body: JSON.stringify({
          id: paymentBalanceId,
          quoteRequestId: quoteRequestId,
          type: paymentType,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'DELETE',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }

        return true
      })
  } catch (error) {
    return false
  }
}

const changePaymentTypeSalvageBookingsLead = async (
  quoteRequestId,
  paymentBalanceId,
  originalType,
  newType
) => {
  try {
    const request = new Request(
      `${entrypoint}/services/quote/salvage-booking/payment/change-type`,
      {
        body: JSON.stringify({
          quoteRequestId: quoteRequestId,
          paymentId: paymentBalanceId,
          originalType: originalType,
          newType: newType,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'PATCH',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }

        return true
      })
  } catch (error) {
    return false
  }
}

const payBalanceSalvageBookingsLead = async (
  quoteRequestId,
  amount,
  paymentType,
  chequePayeeName
) => {
  try {
    const request = new Request(
      `${entrypoint}/services/quote/salvage-booking/payment`,
      {
        body: JSON.stringify({
          quoteRequestId: quoteRequestId,
          amount: amount,
          paymentType: paymentType,
          chequePayeeName: chequePayeeName,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'POST',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }

        return true
      })
  } catch (error) {
    return false
  }
}

const setReviewStatusSalvageBookingsLead = async (quoteRequestId, status) => {
  try {
    const request = new Request(
      `${entrypoint}/services/quote/salvage-booking/lead/send-review-status`,
      {
        body: JSON.stringify({
          quoteRequestId: quoteRequestId,
          status: status,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'PATCH',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }

        return true
      })
  } catch (error) {
    return false
  }
}

const restoreSalvageBookingsLead = async (
  quoteRequestId,
  deleteCopartListing
) => {
  try {
    const request = new Request(
      `${entrypoint}/services/quote/salvage-booking/lead/restore`,
      {
        body: JSON.stringify({
          quoteRequestId: quoteRequestId,
          deleteCopartListing: deleteCopartListing,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'PATCH',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error) {
          throw Error(responseJSON.Error)
        }

        return true
      })
  } catch (error) {
    return false
  }
}

const setAsPremiumSalvageBookingsLead = async (
  quoteRequestId,
  status,
  reserve,
  scPurchasePrice,
  scExtraWorkCost,
  scDesiredAuctionPrice
) => {
  try {
    const request = new Request(
      `${entrypoint}/services/quote/salvage-booking/lead/assign-to-copart`,
      {
        body: JSON.stringify({
          quoteRequestId: quoteRequestId,
          premium_status: status,
          reserve: reserve,
          sc_purchase_price: scPurchasePrice,
          sc_extra_work_cost: scExtraWorkCost,
          sc_desired_auction_price: scDesiredAuctionPrice,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'PATCH',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }
        if (responseJSON.copartError !== undefined) {
          return { copartError: responseJSON.copartError }
        }

        return true
      })
  } catch (error) {
    return false
  }
}

const clearSalvageBookingsLeadStatus = async (
  quoteRequestId,
  statusSlug,
  statusName
) => {
  try {
    const request = new Request(
      `${entrypoint}/services/quote/salvage-booking/lead/status`,
      {
        body: JSON.stringify({
          quoteRequestId: quoteRequestId,
          slug: statusSlug,
          name: statusName,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'DELETE',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }

        return true
      })
  } catch (error) {
    return false
  }
}

const setSalvageBookingsLeadStatus = async (
  quoteRequestId,
  statusSlug,
  statusName
) => {
  try {
    const request = new Request(
      `${entrypoint}/services/quote/salvage-booking/lead/status`,
      {
        body: JSON.stringify({
          quoteRequestId: quoteRequestId,
          slug: statusSlug,
          name: statusName,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        method: 'POST',
      }
    )

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }

        return true
      })
  } catch (error) {
    return false
  }
}

const redirectToSB = (quoteRequestId, page = '') => {
  handleLeadLocking('unlock', quoteRequestId)
  location.href = SBUrlHandler(quoteRequestId, page)
}

export {
  SALVAGE_BOOKINGS_FEE_TYPES,
  cancelSalvageBookingsLead,
  quarantineSalvageBookingsLead,
  addFeeSalvageBookingsLead,
  deleteFeeSalvageBookingsLead,
  cancelPendingPaymentSalvageBookingsLead,
  changePaymentTypeSalvageBookingsLead,
  payBalanceSalvageBookingsLead,
  setReviewStatusSalvageBookingsLead,
  restoreSalvageBookingsLead,
  setAsPremiumSalvageBookingsLead,
  clearSalvageBookingsLeadStatus,
  setSalvageBookingsLeadStatus,
  redirectToSB,
}

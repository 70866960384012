const blue = {
  50: '#F7FFFF',

  100: '#DEFFFF',

  200: '#9EF4FF',

  300: '#92E8FF',

  400: '#85DBFF',

  500: '#78CEFF',

  600: '#6BC1F2',

  700: '#5FB5E6',

  800: '#52A8D9',

  900: '#459BCC',

  A100: '#82b1ff',

  A200: '#448aff',

  A400: '#2979ff',

  A700: '#2962ff',
}

export default blue

import { useState } from 'react'
import { BooleanInput, Edit, FormTab, TabbedForm, TextInput } from 'react-admin'
import EmailPreview from './partial/EmailPreview'

const EditEmailContent = (props) => {
  const [subject, setSubject] = useState()
  const [message, setMessage] = useState('...')
  const [to, setTo] = useState('...')
  const [from, setFrom] = useState('...')

  return (
    <Edit
      {...props}
      aside={
        <EmailPreview message={message} subject={subject} to={to} from={from} />
      }
      actions={false}
      className="edit-email"
    >
      <TabbedForm>
        <FormTab label="Edit Message">
          <TextInput
            onChange={(e) => setSubject(e.target.value)}
            onFocus={(e) => setSubject(e.target.value)}
            label="Subject"
            source="header"
            fullWidth
            name="header"
          />
          <TextInput
            source="body"
            onChange={(e) => setMessage(e.target.value)}
            onFocus={(e) => setMessage(e.target.value)}
            name="body"
            fullWidth
            multiline
          />
        </FormTab>
        <FormTab label="Message Config">
          <TextInput
            source="receiver"
            name="receiver"
            onChange={(e) => setTo(e.target.value)}
            onFocus={(e) => setTo(e.target.value)}
            fullWidth
          />
          <TextInput source="purpose" name="purpose" fullWidth />
          <TextInput source="targetLabel" name="targetLabel" fullWidth />
          <TextInput
            source="availablePlaceholders"
            name="availablePlaceholders"
            fullWidth
          />
          <TextInput
            source="category"
            name="category"
            onChange={(e) => setFrom(e.target.value)}
            onFocus={(e) => setFrom(e.target.value)}
            fullWidth
          />
          <BooleanInput
            name="enabledInContactLog"
            source="enabledInContactLog"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default EditEmailContent

import { CardContent, Typography } from '@mui/material'
import {
  BooleanField,
  CloneButton,
  Datagrid,
  EditButton,
  FilterLiveSearch,
  FunctionField,
  List,
  TextField,
} from 'react-admin'

const filters = [<FilterLiveSearch source="smsBody" alwaysOn key={0} />]

const ListSMSContent = (props) => {
  return (
    <CardContent>
      <Typography typography="h4" component="h1" mb={2}>
        SMS Message Templates
      </Typography>
      <List {...props} title="SMS Message Contents" filters={filters}>
        <Datagrid bulkActionButtons={false}>
          <TextField
            label="Receiver"
            source="receiver"
            style={{ textTransform: 'capitalize' }}
          />
          <FunctionField
            label="Message content"
            render={(record) => {
              return new DOMParser()
                .parseFromString(record.smsBody, 'text/html')
                .querySelector('html').textContent
            }}
            style={{ textTransform: 'capitalize' }}
          />
          ;
          <TextField label="Target Label" source="smsTargetLabel" />
          <FunctionField
            label="Message purpose"
            render={(record) => {
              return record.smsPurpose.replaceAll('_', ' ')
            }}
            style={{ textTransform: 'capitalize' }}
          />
          <BooleanField
            label="Enabled in contact log"
            source="enabledInContactLog"
          />
          ;
          <EditButton />
          <CloneButton />
        </Datagrid>
      </List>
    </CardContent>
  )
}

export default ListSMSContent

import {
  ArrayInput,
  Edit,
  FormTab,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin'

const AdjustmentValueFields = () => {
  return (
    <ArrayInput source="adjustmentValues">
      <SimpleFormIterator>
        <TextInput source="value" label="Value" />
        <TextInput source="impact" label="Impact" />
        <TextInput source="type" label="Type" />
        <TextInput source="label" label="Label" />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

const EditAdjustmentsSettings = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="Settings">
          <TextInput
            label="Adjustment name"
            source="adjustmentName"
            validate={required()}
          />
          <TextInput
            label="Adjustment identifier"
            source="adjustmentIdentifier"
          />
          <SelectInput
            source="adjustmentType"
            choices={[
              { id: 'SINGLE_BOOLEAN', name: 'Boolean' },
              { id: 'SINGLE_RANGE', name: 'Range' },
              { id: 'GROUPED', name: 'Group' },
            ]}
          />

          {/* Re-enable overrides once db work completed */}
          {/* <ReferenceInput
                    label="Overrides"
                    reference="settings/adjustment-overrides"
                    resource="settings/adjustment-overrides"
                    source="overrides">
                    <AutocompleteInput
                        optionText="override"
                        shouldRenderSuggestions={(val) => {
                            return val.trim().length > 2;
                        }}
                        helperText="Please enter the override to lookup, autocomplete will populate after the first 2 characters"
                    />
                </ReferenceInput> */}
        </FormTab>
        <FormTab label="Adjustment Values">
          <AdjustmentValueFields />
        </FormTab>
        <FormTab label="Overrides"></FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default EditAdjustmentsSettings

import React, { useState } from 'react'

import {
  Edit,
  SelectInput,
  TextInput,
  FormDataConsumer,
  SimpleForm,
  ArrayInput,
  BooleanInput,
  SimpleFormIterator,
  SaveButton,
  DeleteButton,
} from 'react-admin'

import AgentReferenceField from '../../Components/CollectionAgent/AgentReferenceField'
import AgentZoneReferenceFieldEdit from '../../Components/CollectionAgent/AgentZoneReferenceFieldEdit'
import RulesPanel from '../../Components/Rules/RulesPanel'
import { Toolbar } from '@mui/material'
import RulesAdjustmentPanel from '../../Components/Rules/RulesAdjustmentPanel'

const AdditionalPostcodesField = () => {
  return (
    <ArrayInput source="ruleSetsAdditionalPostcodes">
      <SimpleFormIterator>
        <TextInput source="postcode" label="Postcode" />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

const SaveToolBar = () => (
  <Toolbar
    style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <SaveButton alwaysEnable />
    <DeleteButton />
  </Toolbar>
)

const EditRuleSet = (props) => {
  const [currentZones, setCurrentZones] = useState(undefined)
  const [testValue, setTestValue] = useState(0)
  const [zones, setZones] = useState(undefined)
  const [currentAgentId, setCurrentAgentId] = useState(0)
  const [currentRuleAdjustments, setCurrentRuleAdjustments] =
    useState(undefined)

  const transformData = (data) => {
    data.collectionAgentZones = currentZones

    return data
  }

  return (
    <Edit {...props} transform={transformData}>
      <SimpleForm toolbar={<SaveToolBar />}>
        <TextInput label="Rule Name" source="name" fullWidth />
        <BooleanInput source="active" fullWidth />
        <SelectInput
          source="type"
          choices={[
            { id: 'interest', name: 'Interest' },
            { id: 'price_adjustment', name: 'Price Adjustment' },
            { id: 'cap_lookup', name: 'CAP Lookup' },
            { id: 'salvage', name: 'Salvage' },
            { id: 'salvage_exclusion', name: 'Salvage Exclusion' },
            { id: 'price_notes', name: 'Price Notes' },
            { id: 'cap_exclusion', name: 'CAP Exclusion' },
          ]}
          resettable={true}
          fullWidth
        />
        <SelectInput
          source="matchType"
          choices={[
            { id: 'match_all', name: 'All rules must apply' },
            { id: 'match_any', name: 'Any rules will trigger' },
          ]}
          resettable={true}
          fullWidth
        />
        <TextInput source="note" multiline={true} fullWidth />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type &&
            formData.type === 'salvage' && (
              <React.Fragment>
                <TextInput source="commission" defaultValue="0.00" fullWidth />
                <TextInput source="minPrice" defaultValue="0.00" fullWidth />
              </React.Fragment>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type &&
            formData.type !== 'salvage_exclusion' &&
            formData.type !== 'cap_exclusion' &&
            formData.type !== 'cap_lookup' && (
              <React.Fragment>
                <AgentReferenceField
                  source="collectionAgent"
                  reference="collection-agents"
                  allowEmpty
                  perPage={10000}
                  fullWidth
                />
              </React.Fragment>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.collectionAgent &&
            formData.type !== 'salvage_exclusion' &&
            formData.type !== 'cap_exclusion' &&
            formData.type !== 'cap_lookup' && (
              <React.Fragment>
                <AgentZoneReferenceFieldEdit
                  source="collectionAgentZones"
                  reference="collection-agents-zones"
                  allowEmpty
                  value={currentZones}
                  perPage={10000}
                  agent={formData.collectionAgent}
                  currentZones={currentZones}
                  setCurrentZones={setCurrentZones}
                  testValue={testValue}
                  setTestValue={setTestValue}
                  zones={zones}
                  setZones={setZones}
                  currentAgentId={currentAgentId}
                  setCurrentAgentId={setCurrentAgentId}
                  fullWidth
                />
              </React.Fragment>
            )
          }
        </FormDataConsumer>
        <input
          style={{
            display: 'none',
          }}
          name="testing"
          type="number"
          value={testValue}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type &&
            formData.type !== 'salvage_exclusion' &&
            formData.type !== 'cap_exclusion' &&
            formData.type !== 'cap_lookup' && (
              <React.Fragment>
                <AdditionalPostcodesField />
              </React.Fragment>
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type &&
            formData.type === 'price_adjustment' && (
              <React.Fragment>
                <SelectInput
                  label="Select Commission Unit"
                  source="commissionUnit"
                  choices={[
                    { id: 'pound', name: 'Pound' },
                    { id: 'percentage', name: 'Percentage' },
                  ]}
                  fullWidth
                  emptyText="Select Commission Unit"
                  required
                />
                <TextInput
                  source="commissionValue"
                  label="Commission Value"
                  defaultValue="0"
                  fullWidth
                />
              </React.Fragment>
            )
          }
        </FormDataConsumer>
        <RulesPanel {...props} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type &&
            formData.type === 'price_adjustment' && (
              <RulesAdjustmentPanel
                {...props}
                setCurrentRuleAdjustments={setCurrentRuleAdjustments}
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}

export default EditRuleSet

import React from 'react'
import { useRecordContext } from 'react-admin'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { dateConverter } from '../../Utils/ConversionUtils'
import './Edit/LeadHistoryPanel/style/index.scss'

const formatDate = (dateFromApi) => {
  const { date, hour } = dateConverter(dateFromApi, true)

  return (
    <React.Fragment>
      <div>{date}</div>
      <div className="table-body__hour">{hour}</div>
    </React.Fragment>
  )
}

const AuditLogPanel = () => {
  const record = useRecordContext()

  return (
    <TableContainer sx={{ maxHeight: '90%', overflow: 'scroll' }}>
      <Table className="table" stickyHeader>
        <TableHead className="table-header">
          <TableRow>
            <TableCell className="table-header__individual">
              Date / Time
            </TableCell>
            <TableCell className="table-header__individual">Action</TableCell>
            <TableCell className="table-header__individual">User</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.auditLogs.map((row) => (
            <TableRow key={row.id}>
              <TableCell className="table-body">
                {formatDate(row.createdAt)}
              </TableCell>
              <TableCell className="table-body">{row.description}</TableCell>
              <TableCell className="table-body table-body__user">
                {row.user && row.user.displayName}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AuditLogPanel

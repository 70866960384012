const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT

const getSalvageCancellationFee = async (quoteRequestId) => {
  const request = new Request(
    `${entrypoint}/services/cancellation-fee/get-cancellation-fee-salvage`,
    {
      body: JSON.stringify({
        quoteRequestId: quoteRequestId,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

export { getSalvageCancellationFee }

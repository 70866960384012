import { Datagrid, EditButton, List, TextField } from 'react-admin'

const ListAllTags = (props) => {
  return (
    <List {...props} title="Tags">
      <Datagrid>
        <TextField label="Tag Name" source="tagName" />
        <EditButton {...props} />
      </Datagrid>
    </List>
  )
}

export default ListAllTags

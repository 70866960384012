import React, { useState } from 'react'
import {
  ListBase,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { addTags } from '../../../../Utils/QuoteRequestUtils'
import { Add } from '@mui/icons-material'
import { Button, Menu, MenuItem, MenuList } from '@mui/material'

const AddTagButton = () => {
  const notify = useNotify()
  const refresh = useRefresh()
  const record = useRecordContext()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  let [tags, setTags] = useState([])

  const TagList = () => {
    const { data } = useListContext()
    let output = []
    if (record && data && tags.length === 0) {
      data.map((tag, tagIndex) => {
        let isDisabled = !record.tags.every(
          (quoteTag) => !(quoteTag['@id'] === tag['@id'])
        )

        output.push(
          <MenuItem
            key={tagIndex}
            disabled={isDisabled}
            dense={true}
            onClick={() => handleSubmit(tag['@id'])}
          >
            {tag.tagName}
          </MenuItem>
        )
      })

      if (tags.length === 0) {
        setTags(output)
      }
    }

    return <MenuList variant="selectedMenu">{data && tags}</MenuList>
  }

  const handleSubmit = async (tag) => {
    try {
      const formData = { quoteId: record['@id'], tag: [tag] }
      const result = await addTags(formData)
      setAnchorEl(null)

      if (result.status === 'success') {
        refresh()
      } else {
        notify(result.error, { type: 'error' })
      }
    } catch (e) {
      notify(e, { type: 'error' })
    }
  }
  const handleClick = (event) => {
    setTags([])
    setAnchorEl(event.currentTarget)
  }

  return (
    <React.Fragment>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        startIcon={<Add />}
        onClick={handleClick}
        variant="text"
        sx={{
          marginLeft: '0.5rem',
        }}
      >
        Add Tag
      </Button>
      <Menu
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        id="tag-menu"
      >
        <ListBase emptyWhileLoading resource="tags" perPage={100}>
          <TagList />
        </ListBase>
      </Menu>
    </React.Fragment>
  )
}

export default AddTagButton

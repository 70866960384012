import React, { useState } from 'react'
import { useNotify } from 'react-admin'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import {
  cancelPendingPaymentSalvageBookingsLead,
  redirectToSB,
} from '../../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const PartialPending = ({ sb }) => {
  const notify = useNotify()
  const [loading, setLoading] = useState(false)

  const handleButtonClick = async function () {
    if (!loading) {
      setLoading(true)
    }

    // Cancel pending partial payment in SB
    const responseStatus = await cancelPendingPaymentSalvageBookingsLead(
      sb.payments.partial.id,
      sb.quote_request_id,
      'partial'
    )

    if (responseStatus) {
      redirectToSB(sb.quote_request_id)
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: '700',
            letterSpacing: '0.46px',
            marginRight: '5px',
            textTransform: 'uppercase',
          }}
        >
          Pending:
        </Typography>
        <Typography
          sx={{
            fontWeight: '700',
            letterSpacing: '0.46px',
            textTransform: 'capitalize',
          }}
        >
          {new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
          }).format(sb.payments.partial.amount)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={6} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="primary"
            label="Pay Partial"
            fullWidth
            disabled={loading}
            onClick={handleButtonClick}
            sx={{
              border: '2px solid #FFF !important',
              backgroundColor: '#880000',
              color: '#FFF',
              height: '40px',
              fontWeight: '700',
              '&:hover': {
                backgroundColor: '#880000',
              },
            }}
          >
            Cancel Partial
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

PartialPending.propTypes = {
  sb: PropTypes.object,
}

export default PartialPending

import { useState } from 'react'
import {
  EditBase,
  Form,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { Grid, TextField } from '@mui/material'
import { validateAutoSaveSingleInput } from '../../../../Utils/AutoSave'
import {
  blockInvalidCharFromNumberInput,
  numberInputOnWheelPreventChange,
} from '../../../../Utils/ConversionUtils'
import { TagPanel } from '../Panels'

const VehicleDetailsPanel = (props) => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [image, setImage] = useState(
    record.leadContact.vehicle.vehicleInfo.dvlaImage
  )

  return (
    <EditBase>
      <Form>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextInput
              label="Vehicle Reg."
              source="leadContact.vehicle.registration"
              onInput={(e) =>
                (e.target.value = ('' + e.target.value).toUpperCase())
              }
              fullWidth
              readOnly={true}
              disabled={true}
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.make"
              fullWidth
              label="Make"
              value={record.leadContact.vehicle.make}
              readOnly={true}
              disabled={true}
            />
          </Grid>
          <Grid item flex={2}>
            <TextField
              name="leadContact.vehicle.model"
              fullWidth
              label="Model"
              value={record.leadContact.vehicle.model}
              readOnly={true}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.aspiration"
              label="Aspiration"
              fullWidth
              value={record.leadContact.vehicle.vehicleInfo.aspiration}
              readOnly={true}
              disabled={true}
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.engineModelCode"
              label="Engine Code"
              fullWidth
              value={record.leadContact.vehicle.vehicleInfo.engineModelCode}
              readOnly={true}
              disabled={true}
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.colour"
              fullWidth
              label="Colour"
              value={record.leadContact.vehicle.vehicleInfo.colour}
              readOnly={true}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.modelSeries"
              label="Series"
              fullWidth
              value={record.leadContact.vehicle.vehicleInfo.modelSeries}
              readOnly={true}
              disabled={true}
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.seats"
              readOnly={true}
              label="Seats"
              fullWidth
              value={record.leadContact.vehicle.vehicleInfo.seats}
              disabled={true}
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.doors"
              fullWidth
              readOnly={true}
              label="Doors"
              value={record.leadContact.vehicle.vehicleInfo.doors}
              disabled={true}
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.fuelType"
              fullWidth
              label="Fuel"
              value={record.leadContact.vehicle.fuelType}
              readOnly={true}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.transmission"
              label="Trans."
              fullWidth
              value={record.leadContact.vehicle.vehicleInfo.transmission}
              readOnly={true}
              disabled={true}
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.engineSize"
              label="Engine CC"
              fullWidth
              value={record.leadContact.vehicle.engineSize}
              readOnly={true}
              disabled={true}
            />
          </Grid>
          <Grid item flex={1}>
            <Form>
              <TextInput
                name="leadContact.vehicle.vehicleInfo.kerbWeight"
                label="Kerb Weight"
                value={
                  record.leadContact.vehicle.vehicleInfo &&
                  record.leadContact.vehicle.vehicleInfo.kerbWeight
                }
                type={'number'}
                onWheel={numberInputOnWheelPreventChange}
                onKeyDown={blockInvalidCharFromNumberInput}
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
                readOnly={true}
                disabled={true}
              />
            </Form>
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.previousKeepers"
              label="Keepers"
              inputProps={{
                readOnly: true,
              }}
              fullWidth
              value={
                record.leadContact.vehicle.vehicleInfo &&
                record.leadContact.vehicle.vehicleInfo.previousKeepers
              }
              readOnly={true}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item flex={2}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.bodyStyle"
              label="Body"
              fullWidth
              value={record.leadContact.vehicle.vehicleInfo.bodyStyle}
              readOnly={true}
              disabled={true}
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleInfo.registrationDate"
              label="First reg."
              fullWidth
              value={new Date(
                record.leadContact.vehicle.vehicleInfo.registrationDate
              ).toLocaleDateString()}
              readOnly={true}
              disabled={true}
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="leadContact.vehicle.vehicleDocumentation.dateCurrentKeeper"
              label="Start curr."
              fullWidth
              value={
                record.leadContact.vehicle.vehicleDocumentation &&
                new Date(
                  record.leadContact.vehicle.vehicleDocumentation.dateCurrentKeeper
                ).toLocaleDateString()
              }
              readOnly={true}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid sx={{ mb: 2 }}>
          <TagPanel {...props} />
        </Grid>
        <Grid sx={{ backgroundColor: 'white' }}>
          <div
            style={{
              margin: 'auto',
              height: '100px',
              width: '255px',
              backgroundImage: "url('" + image + "'), url('missing-image.png')",
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </Grid>
      </Form>
    </EditBase>
  )
}

export default VehicleDetailsPanel

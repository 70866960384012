import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  TextField,
} from 'react-admin'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

const ListAllCharities = (props) => {
  return (
    <List {...props} title="Charities">
      <Datagrid>
        <TextField label="Charity Title" source="charityTitle" />
        <FunctionField
          label="Show In Dropdown"
          render={(record) => {
            if (record.showInDropdown) {
              return <CheckIcon />
            } else {
              return <CloseIcon />
            }
          }}
        />
        <FunctionField
          label="Visible"
          render={(record) => {
            if (record.visible) {
              return <CheckIcon />
            } else {
              return <CloseIcon />
            }
          }}
        />
        <EditButton {...props} />
      </Datagrid>
    </List>
  )
}

export default ListAllCharities

import React, { useState } from 'react'
import { useNotify, useRecordContext } from 'react-admin'
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import {
  cancelSalvageBookingsLead,
  redirectToSB,
} from '../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const CancelBooking = ({ sb, cancelledInSQ = false }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [loading, setLoading] = useState(false)
  const [validationError, setValidationError] = useState(false)
  const [cancellationStatus, setCancellationStatus] = useState(
    sb.cancellation_reason !== null ? sb.cancellation_reason : 'select'
  )

  const handleChange = (event) => {
    setValidationError(false)
    setCancellationStatus(event.target.value)
  }

  const handleButtonClick = async function () {
    if (cancellationStatus === 'select') {
      setValidationError(true)
      notify('Cancellation status must be set', { type: 'error' })
      return
    }

    if (!loading) {
      setLoading(true)
    }

    // Cancel lead in SB
    const responseStatus = await cancelSalvageBookingsLead(
      record.originId,
      cancellationStatus
    )

    if (responseStatus) {
      redirectToSB(sb.quote_request_id)
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  return (
    <React.Fragment>
      <Grid
        container
        xs={12}
        sx={{
          padding: '0 !important',
          width: '100%',
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            alignItems: 'center',
            display: 'flex',
            paddingRight: '12px',
          }}
        >
          <TextField
            label="Status"
            name="cancellation"
            select
            value={cancellationStatus}
            error={validationError}
            onChange={handleChange}
            sx={{
              lineHeight: '1rem',
              borderRadius: '4px',
              height: '40px !important',
              minHeight: '40px !important',
              margin: '0 0 3px 0 !important',
              '& > .MuiOutlinedInput-root': {
                minHeight: '40px !important',
                fontWeight: '700',
              },
            }}
          >
            <MenuItem key="select" value="select" selected>
              Select Cancellation Reason
            </MenuItem>
            <MenuItem key="copart_cannot_collect" value="copart_cannot_collect">
              Copart Cannot Collect
            </MenuItem>
            <MenuItem key="no_contact" value="no_contact">
              No Contact
            </MenuItem>
            <MenuItem
              key="vehicle_not_as_described"
              value="vehicle_not_as_described"
            >
              Faults on Inspection
            </MenuItem>
            <MenuItem
              key="vehicle_sold_elsewhere"
              value="vehicle_sold_elsewhere"
            >
              Vehicle Sold Elsewhere
            </MenuItem>
            <MenuItem
              key="customer_keeping_vehicle"
              value="customer_keeping_vehicle"
            >
              Customer Keeping Vehicle
            </MenuItem>
            <MenuItem key="reassigned_to_shore" value="reassigned_to_shore">
              Reassigned to Shore
            </MenuItem>
            <MenuItem key="possible_scam" value="possible_scam">
              Possible Scam
            </MenuItem>
            <MenuItem key="overpriced" value="overpriced">
              Overpriced
            </MenuItem>
            <MenuItem key="reassigned_to_bms" value="reassigned_to_bms">
              Reassigned to BMS
            </MenuItem>
            <MenuItem key="other" value="other">
              Other
            </MenuItem>
          </TextField>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            alignItems: 'center',
            display: 'flex',
            paddingLeft: '12px',
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            label="Cancel Booking"
            fullWidth
            disabled={loading}
            onClick={handleButtonClick}
            sx={{
              border: '2px solid #FFF !important',
              backgroundColor: '#880000',
              color: '#FFF',
              height: '40px',
              fontWeight: '700',
              '&:hover': {
                backgroundColor: '#880000',
              },
            }}
          >
            {cancelledInSQ ? <>Accept Cancellation</> : <>Cancel Booking</>}
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

CancelBooking.propTypes = {
  sb: PropTypes.object,
  cancelledInSQ: PropTypes.bool,
}

export default CancelBooking

import { Grid, Typography } from '@mui/material'

const DepositNotMade = () => {
  return (
    <Grid item xs={12}>
      <Typography
        sx={{
          fontWeight: '700',
          letterSpacing: '0.46px',
          marginRight: '5px',
          textTransform: 'uppercase',
        }}
      >
        No deposit payment made
      </Typography>
    </Grid>
  )
}

export default DepositNotMade

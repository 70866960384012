import PropTypes from 'prop-types'
import PopupPanel from '../PopupPanel'
import { Grid } from '@mui/material'
import TopSectionPanel from '../TopSectionPanel'
import CustomStatusPanel from '../CustomStatusPanel'
import PaymentPanel from '../PaymentPanel'
import FeesPanel from '../FeesPanel'
import CancelledLeadActions from './CancelledLeadActions'

const CancelledSalvageBooking = ({
  sb,
  popupPanelName,
  setPopupPanelName,
  popupPanelProps,
  setPopupPanelProps,
}) => {
  return (
    <Grid
      container
      spacing={3}
      style={{
        display: 'grid',
        gridColumn: '1 / span 6',
        gridRowStart: 4,
        gridRowEnd: 4,
        position: 'relative',
      }}
      sx={{
        marginTop: '0 !important',
        marginLeft: '0 !important',
        padding: '30px',
        width: '100%',
        minHeight: `${
          popupPanelName === ''
            ? '100%'
            : popupPanelName === 'sendEmail'
            ? '700px'
            : '500px'
        }`,
      }}
    >
      <PopupPanel
        sb={sb}
        popupPanelName={popupPanelName}
        setPopupPanelName={setPopupPanelName}
        popupPanelProps={popupPanelProps}
      />
      {/*Top Section*/}
      <Grid
        container
        spacing={3}
        sx={{
          borderBottom: '1px solid #8B4000',
          padding: '10px 0 !important',
          marginLeft: '0 !important',
          width: '100%',
          opacity: `${
            popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
          }`,
        }}
      >
        <TopSectionPanel
          sb={sb}
          setPopupPanelName={setPopupPanelName}
          cancelled={true}
        />
      </Grid>
      {/*End of top section*/}

      {/*Cancelled Lead section*/}
      <Grid
        container
        spacing={3}
        sx={{
          borderBottom: '1px solid #8B4000',
          padding: '20px 0 !important',
          marginLeft: '0 !important',
          width: '100%',
          opacity: `${
            popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
          }`,
        }}
      >
        <CancelledLeadActions sb={sb} />
      </Grid>
      {/*End Cancelled Lead section*/}

      {/*Custom Status section*/}
      <Grid
        container
        spacing={3}
        sx={{
          borderBottom: '1px solid #8B4000',
          padding: '20px 0 !important',
          marginLeft: '0 !important',
          width: '100%',
          opacity: `${
            popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
          }`,
        }}
      >
        <CustomStatusPanel sb={sb} cancelled={true} />
      </Grid>
      {/*End Custom Status section*/}

      {/*Payment section*/}
      {sb && sb.assigned_to_copart_at && sb.quarantined == null && (
        <Grid
          container
          spacing={3}
          sx={{
            borderBottom: '1px solid #8B4000',
            padding: '20px 0 !important',
            marginLeft: '0 !important',
            width: '100%',
            opacity: `${
              popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
            }`,
          }}
        >
          <PaymentPanel
            sb={sb}
            setPopupPanelName={setPopupPanelName}
            setPopupPanelProps={setPopupPanelProps}
          />
        </Grid>
      )}
      {/*End Payment section*/}

      {/*Fee section*/}
      <Grid
        container
        spacing={3}
        sx={{
          padding: '20px 0 !important',
          marginLeft: '0 !important',
          width: '100%',
          opacity: `${
            popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
          }`,
        }}
      >
        <FeesPanel sb={sb} />
      </Grid>

      {/*End Fee section*/}
    </Grid>
  )
}

CancelledSalvageBooking.propTypes = {
  sb: PropTypes.object,
  popupPanelName: PropTypes.string,
  setPopupPanelName: PropTypes.func,
  popupPanelProps: PropTypes.object,
  setPopupPanelProps: PropTypes.func,
}
export default CancelledSalvageBooking

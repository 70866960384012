import { Card, CardContent, Typography } from '@mui/material'
import '../style/sms.scss'
import PropTypes from 'prop-types'
import { useRecordContext } from 'react-admin'

const SMSPreview = (props) => {
  let record = useRecordContext()
  let message = '...'

  if (record) {
    message = record.smsBody
    if (props.message && props.message !== record.smsBody) {
      message = props.message
    }
  }

  const placeholders = {
    '{{SITE_PHONE_NUMBER}}': '01243000000',
    '{{SITE_NAME}}': 'Scrap Car Comparison',
    '{{SITE_BASE_URL}}': 'https://www.scrapcarcomparison.co.uk/',
    '{{QUOTE_URL}}': 'https://www.scrap.pro/1023/',
    '{{HAVE_HIGHER_OFFER_URL}}': 'https://www.scrap.pro/higher-offer/',
    '{{$FRONTEND_API_WEBSITE}}': 'Scrap Car Comparison',
    '{{OFFER_ID}}': '0002341',
    '{{OFFER}}': '&pound;1200.00',
    '{{QUOTE_GUARANTEED_PRICE}}': '&pound;1200.00',
    '{{COLLECTION_AGENT_COMPANY_NAME}}': 'Copart',
    '{{COLLECTION_AGENT_PHONE_NUMBER}}': '0800-CO-PART',
    '{{DATE}}': '01/01/2022',
    '{{TIME_FROM}}': '08:00',
    '{{TIME_TO}}': '09:45',
    '{{BUYER_LOGIN}}': 'https://www.systemquotes.co.uk/buyer-login/',
    '{{EXTEND_FOR_24H}}': 'https://www.systemquotes.co.uk/extend/',
  }

  Object.entries(placeholders).forEach(([key, value]) => {
    message = message.replaceAll(key, value)
  })

  // Decode the entities so they appear correctly in the preview
  message = new DOMParser()
    .parseFromString(message, 'text/html')
    .querySelector('html').textContent

  return (
    <Card
      style={{
        marginLeft: '15px',
        width: '320px',
        fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Preview
        </Typography>
        <div className="imessage">
          {message && <p className="from-them">{message}</p>}
        </div>
      </CardContent>
    </Card>
  )
}

SMSPreview.propTypes = {
  message: PropTypes.string,
  record: PropTypes.object,
}

export default SMSPreview

import React from 'react'
import { Button, CircularProgress, Grid } from '@mui/material'
import PropTypes from 'prop-types'

const AddFeeButton = ({ loading, handleButtonClick }) => {
  return (
    <React.Fragment>
      <Grid item xs={12} sx={{ marginTop: -1 }}>
        <Button
          variant="contained"
          label="Record Fee"
          fullWidth
          disabled={loading}
          onClick={handleButtonClick}
          sx={{
            border: '2px solid #FFF !important',
            backgroundColor: '#ED9C23',
            color: '#FFF',
            height: '40px',
            fontWeight: '700',
            '&:hover': {
              backgroundColor: '#ED9C23',
            },
          }}
        >
          Record Fee
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Grid>
    </React.Fragment>
  )
}

AddFeeButton.propTypes = {
  loading: PropTypes.bool,
  handleButtonClick: PropTypes.func,
}

export default AddFeeButton

import SetNewStatusButton from './SetNewStatusButton'
import PropTypes from 'prop-types'
import ClearStatusButton from './ClearStatusButton'

const SetOnHoldButton = ({ sb }) => {
  if (sb != null) {
    if (sb.lead_statuses.some((status) => status.slug === 'on_hold')) {
      let status = sb.lead_statuses.find((status) => status.slug === 'on_hold')
      return (
        <ClearStatusButton
          sb={sb}
          status={status}
          resetCustomStatusButton={false}
          buttonText="Clear On Hold Status"
        />
      )
    }
    if (sb.reserve) {
      return
    }
  }

  return (
    <SetNewStatusButton
      sb={sb}
      label="Set On Hold"
      name="On Hold"
      slug="on_hold"
      resetButton="false"
    />
  )
}

SetOnHoldButton.propTypes = {
  sb: PropTypes.object,
}

export default SetOnHoldButton

import {
  ArrayInput,
  Create,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'

const AdjustmentValueFields = () => {
  return (
    <ArrayInput source="adjustmentValues">
      <SimpleFormIterator>
        <TextInput source="value" label="Value" />
        <TextInput source="impact" label="Impact" />
        <TextInput source="type" label="Type" />
        <TextInput source="label" label="Label" />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

const CreateAdjustmentsSettings = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          label="Adjustment name"
          source="adjustmentName"
          validate={required()}
        />
        <TextInput
          label="Adjustment identifier"
          source="adjustmentIdentifier"
        />
        <NumberInput label="Adjustment Priority" source="adjustmentPriority" />
        <SelectInput
          source="adjustmentType"
          choices={[
            { id: 'SINGLE_BOOLEAN', name: 'Boolean' },
            { id: 'SINGLE_RANGE', name: 'Range' },
            { id: 'GROUPED', name: 'Group' },
          ]}
        />
        <AdjustmentValueFields />
        {/* Re-enable overrides once db work completed */}
        {/* <ReferenceInput
                    label="Overrides"
                    reference="settings/adjustment-overrides"
                    resource="settings/adjustment-overrides"
                    source="overrides">
                    <AutocompleteInput
                        optionText="override"
                        shouldRenderSuggestions={(val) => {
                            return val.trim().length > 2;
                        }}
                        helperText="Please enter the override to lookup, autocomplete will populate after the first 2 characters"
                    />
                </ReferenceInput> */}
      </SimpleForm>
    </Create>
  )
}

export default CreateAdjustmentsSettings

import { CardContent, Typography } from '@mui/material'
import ListAllCharities from '../../Components/Charity/ListAllCharities'

const ListCharities = (props) => {
  return (
    <CardContent>
      <Typography typography="h4" component="h1" mb={2}>
        Charities
      </Typography>
      <ListAllCharities {...props} />
    </CardContent>
  )
}

export default ListCharities

import React from 'react'
import { Grid, Button } from '@mui/material'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import SendReviewStatus from './SendReviewStatus'
import PropTypes from 'prop-types'
import SBUrlHandler from '../../../../Utils/SBUrlHandler'
import { handleLeadLocking } from '../../../../Utils/RestUtils'

const TopSectionPanel = ({ sb, setPopupPanelName, cancelled }) => {
  return (
    <React.Fragment>
      <Grid
        item
        xs={8}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '0 !important',
        }}
      >
        {sb && (
          <a
            href={SBUrlHandler(sb.quote_request_id)}
            onClick={() => handleLeadLocking('unlock', sb.quote_request_id)}
          >
            <Button
              sx={{
                border: '2px solid #FFF !important',
                backgroundColor: '#B7B7B7 !important',
                color: '#2A2A2A !important',
                borderRadius: '4px',
                height: '40px',
                '&:hover': {
                  backgroundColor: '#B7B7B7',
                },
              }}
              variant="contained"
              color="secondary"
            >
              <KeyboardDoubleArrowLeftIcon fontSize="large" />
              Back to Salvage Bookings
            </Button>
          </a>
        )}
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0 !important',
        }}
      >
        {!cancelled && <SendReviewStatus sb={sb} />}
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0 !important',
        }}
      >
        <Button
          sx={{
            border: '2px solid #FFF !important',
            backgroundColor: '#B7B7B7',
            color: '#2A2A2A',
            height: '40px',
            '&:hover': {
              backgroundColor: '#B7B7B7',
            },
          }}
          variant="contained"
          color="secondary"
          onClick={() => setPopupPanelName('sendEmail')}
        >
          Send email
        </Button>
      </Grid>
    </React.Fragment>
  )
}

TopSectionPanel.propTypes = {
  sb: PropTypes.object,
  setPopupPanelName: PropTypes.func,
  cancelled: PropTypes.bool,
}

export default TopSectionPanel

import React from 'react'
import { CardContent, Typography } from '@mui/material'
import {
  Datagrid,
  List,
  SearchInput,
  SelectInput,
  TextField,
  useGetList,
  useRefresh,
  WithRecord,
  required,
  BooleanField,
} from 'react-admin'
import { useGetIdentity } from 'ra-core'
import ContactMethod from '../../Components/Inbox/List/ContactMethod'
import VehicleDetails from '../../Components/Inbox/List/VehicleDetails'
import InboxTimeAgo from '../../Components/Inbox/List/TimeAgo'
import InboxPhoneContact from '../../Components/Inbox/List/PhoneContact'
import GotoQuoteButton from '../../Components/Inbox/List/GotoQuoteButton'
import { useRecursiveTimeout } from '../../Components/Inbox/List/hooks/recursiveTimeout'
import {
  DEFAULT_INBOX_FILTER_LIST,
  EXTERNAL_USER_ROLES,
} from '../../Utils/ConstantsUtils'
import MuteInboxButton from '../../Components/Inbox/List/MuteInboxButton'
import { getCurrentUser } from '../Users'
import './RemoveAllFiltersItem.css'

const InboxList = (props) => {
  const refresh = useRefresh()
  const { identity, isLoading: identityLoading } = useGetIdentity()
  useRecursiveTimeout(() => refresh(), 15000)

  const isUserASuperAdmin = localStorage
    .getItem('permissions')
    .includes('ROLE_ADMIN')

  const hasExternalRole = EXTERNAL_USER_ROLES.some((role) =>
    localStorage.getItem('permissions').includes(role)
  )

  const { user } = getCurrentUser()

  let userFilterArray = [
    { id: identity.id, name: identity.fullName },
    { id: 'null', name: 'Inbound' },
  ]

  if (isUserASuperAdmin) {
    const { data: users, isLoading } = useGetList('users', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'ASC' },
    })

    if (users) {
      users.map((user, i) => {
        userFilterArray.push({ id: user.originId, name: user.displayName })
      })
    }
  }

  if (!isUserASuperAdmin) {
    let index = userFilterArray.findIndex((item) => item.name === 'Everything')

    if (index !== -1) {
      userFilterArray.splice(index, 1)
    }
  }

  const inboxFilters = [
    <SearchInput
      placeholder="Search by reg"
      key={1}
      name="inboxVehicleReg"
      source="inboxVehicleReg"
      alwaysOn
    />,
    <SelectInput
      select
      key={2}
      alwaysOn
      label="Reminder type"
      source="inboxSubject"
      name="inboxSubject"
      placeholder="Select a reminder type"
      choices={DEFAULT_INBOX_FILTER_LIST}
    />,
    <SelectInput
      select
      alwaysOn
      key={3}
      label="User"
      source="inboxUser"
      name="inboxUser"
      placeholder="Select a user"
      choices={userFilterArray}
      validate={!isUserASuperAdmin ? required() : null}
    />,
  ]

  return (
    <CardContent>
      <Typography typography="h4" component="h1" mb={2}>
        Notifications
      </Typography>

      {!identityLoading && (
        <React.Fragment>
          <List
            {...props}
            perPage={25}
            exporter={false}
            hasCreate={false}
            actions={<></>}
            filters={inboxFilters}
            filterDefaultValues={{
              isDropped: false,
              inboxActive: true,
              inboxUser: identity.id,
            }}
            title="Inbox Items"
          >
            <Datagrid bulkActionButtons={false}>
              <TextField label="Quote#" source="inboxQuote.id" />
              <TextField label="Status" source="inboxQuote.status" />
              <BooleanField label="Archived" source="inboxQuote.archived" />
              <ContactMethod
                label="Latest contact method"
                source="inboxNotifySMS"
              />

              <VehicleDetails label="Vehicle details" />

              <TextField label="Note" source="inboxSubject" />
              <TextField label="Main user" source="inboxUser.displayName" />
              <InboxTimeAgo
                timeType="inboxDate"
                label="Contact age"
                source="inboxReceivedDateTime"
              />
              <InboxTimeAgo
                timeType="inboxLeadQuoteTime"
                label="Quote age"
                source="inboxLeadQuoteDateTime"
              />
              <InboxPhoneContact label="Tel" source="inboxTelNumber" />
              <WithRecord
                render={(record) => {
                  if (
                    isUserASuperAdmin ||
                    (user?.highValueUser &&
                      record.inboxUser &&
                      record.inboxUser.id === parseInt(user.originId, 10) &&
                      !hasExternalRole)
                  ) {
                    return <MuteInboxButton />
                  }
                  return <></>
                }}
              />
              <GotoQuoteButton />
            </Datagrid>
          </List>
        </React.Fragment>
      )}
    </CardContent>
  )
}

export default InboxList

import React from 'react'
import { Box } from '@mui/material'
import CustomerHappyPopup from './CustomerHappyPopup'
import PremiumLeadPopup from './PremiumLead'
import LeadCancelledPopup from './LeadCancelledPopup'
import PropTypes from 'prop-types'
import SendEmailPopup from './SendEmailPopup'

const PopupPanel = ({
  sb,
  popupPanelName,
  setPopupPanelName,
  popupPanelProps,
}) => {
  return (
    <React.Fragment>
      {popupPanelName !== '' && (
        <Box
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 9999,
          }}
        >
          {popupPanelName === 'leadCancelled' && (
            <LeadCancelledPopup sb={sb} setPopupPanelName={setPopupPanelName} />
          )}

          {popupPanelName === 'customerHappy' && (
            <CustomerHappyPopup
              setPopupPanelName={setPopupPanelName}
              {...popupPanelProps}
            />
          )}

          {popupPanelName === 'premiumLead' && (
            <PremiumLeadPopup
              setPopupPanelName={setPopupPanelName}
              {...popupPanelProps}
            />
          )}

          {popupPanelName === 'sendEmail' && (
            <SendEmailPopup setPopupPanelName={setPopupPanelName} />
          )}
        </Box>
      )}
    </React.Fragment>
  )
}

PopupPanel.propTypes = {
  sb: PropTypes.object,
  popupPanelName: PropTypes.string,
  setPopupPanelName: PropTypes.func,
  popupPanelProps: PropTypes.obj,
}

export default PopupPanel

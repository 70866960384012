import { useEffect, useState } from 'react'
import { useNotify, useRecordContext } from 'react-admin'
import maphilight from 'maphilight'
import $ from 'jquery'
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import './style/lead-details.scss'
import {
  getDamageListFromAPI,
  updateVehicleDamage,
} from '../../Utils/RestUtils'
import { AddCircle, Cancel } from '@mui/icons-material'

const map = (
  <map name="hover-map">
    <area
      className="hover-area"
      data-section="psf-window"
      shape="poly"
      coords="115, 223, 116, 184, 117, 169, 119, 161, 132, 179, 141, 192, 139, 203, 139, 246, 138, 279, 122, 278, 116, 274"
    />
    <area
      className="hover-area"
      data-section="psf-mirror"
      shape="poly"
      coords="105, 193, 93, 196, 83, 197, 85, 186, 93, 179, 105, 176, 112, 178, 114, 181"
    />
    <area
      className="hover-area"
      data-section="psf-door"
      shape="poly"
      coords="105, 153, 118, 161, 114, 282, 104, 285, 105, 192, 112, 187, 112, 183, 111, 174, 109, 177, 105, 177"
    />
    <area
      className="hover-area"
      data-section="psf-wheel"
      shape="circle"
      coords="61, 92, 36"
    />
    <area
      className="hover-area"
      data-section="psf-wing"
      shape="poly"
      coords="105, 152, 103, 127, 100, 108, 100, 62, 105, 46, 113, 39, 114, 32, 129, 20, 130, 26, 121, 31, 116, 36, 116, 45, 123, 52, 137, 51, 146, 41, 147, 30, 141, 24, 125, 27, 120, 26, 135, 18, 149, 25, 156, 35, 156, 51, 148, 74, 124, 126, 128, 133, 120, 143, 115, 157"
    />
    <area
      className="hover-area"
      data-section="psf-light"
      shape="poly"
      coords="141, 26, 146, 30, 144, 40, 140, 47, 128, 50, 118, 47, 117, 41, 119, 34, 125, 28, 132, 24"
    />

    <area
      className="hover-area"
      data-section="psr-window"
      shape="poly"
      coords="138, 288, 138, 335, 142, 366, 137, 385, 132, 393, 125, 397, 120, 378, 117, 340, 116, 290, 127, 288"
    />
    <area
      className="hover-area"
      data-section="psr-door"
      shape="poly"
      coords="104, 283, 115, 283, 117, 317, 118, 344, 119, 370, 105, 370"
    />
    <area
      className="hover-area"
      data-section="psr-wheel"
      shape="circle"
      coords="66, 393, 36"
    />
    <area
      className="hover-area"
      data-section="psr-wing"
      shape="poly"
      coords="142, 464, 138, 440, 141, 413, 148, 387, 152, 381, 145, 376, 141, 365, 139, 382, 135, 391, 126, 397, 120, 391, 117, 369, 106, 370, 108, 415, 114, 446, 119, 455, 122, 451, 126, 454, 134, 461"
    />
    <area
      className="hover-area"
      data-section="psr-light"
      shape="poly"
      coords="119, 456, 123, 453, 126, 455, 133, 461, 147, 466, 151, 469, 152, 474, 133, 471, 126, 466"
    />

    <area
      className="hover-area"
      data-section="dsf-window"
      shape="poly"
      coords="268, 236, 268, 204, 267, 194, 272, 183, 288, 161, 290, 183, 291, 232, 291, 275, 284, 278, 268, 279"
    />
    <area
      className="hover-area"
      data-section="dsf-mirror"
      shape="poly"
      coords="296, 187, 294, 172, 300, 178, 310, 178, 320, 185, 323, 196, 315, 198"
    />
    <area
      className="hover-area"
      data-section="dsf-door"
      shape="poly"
      coords="290, 187, 290, 171, 287, 162, 302, 151, 302, 178, 293, 172, 295, 187, 302, 192, 302, 282, 293, 284, 293, 272, 291, 267, 291, 219"
    />
    <area
      className="hover-area"
      data-section="dsf-wheel"
      shape="circle"
      coords="347, 92, 36"
    />
    <area
      className="hover-area"
      data-section="dsf-wing"
      shape="poly"
      coords="302, 151, 303, 129, 305, 122, 307, 71, 306, 54, 294, 37, 292, 31, 276, 20, 275, 26, 282, 29, 289, 35, 291, 43, 288, 50, 278, 53, 266, 48, 258, 39, 262, 27, 270, 25, 285, 30, 283, 24, 267, 17, 254, 29, 249, 40, 252, 58, 280, 125, 277, 133, 287, 143, 289, 153, 288, 162"
    />
    <area
      className="hover-area"
      data-section="dsf-light"
      shape="poly"
      coords="290, 40, 287, 48, 278, 51, 268, 47, 263, 41, 261, 34, 263, 27, 270, 25, 279, 27, 286, 33"
    />

    <area
      className="hover-area"
      data-section="dsr-window"
      shape="poly"
      coords="268, 288, 280, 288, 291, 290, 287, 368, 284, 390, 280, 397, 271, 390, 264, 369, 267, 334"
    />
    <area
      className="hover-area"
      data-section="dsr-door"
      shape="poly"
      coords="291, 283, 303, 283, 300, 370, 292, 370, 287, 369, 290, 336"
    />
    <area
      className="hover-area"
      data-section="dsr-wheel"
      shape="circle"
      coords="342, 393, 36"
    />
    <area
      className="hover-area"
      data-section="dsr-wing"
      shape="poly"
      coords="266, 418, 262, 399, 255, 382, 261, 376, 265, 366, 266, 382, 272, 391, 282, 397, 286, 385, 289, 369, 301, 370, 299, 408, 296, 432, 288, 455, 283, 452, 277, 457, 271, 461, 265, 464, 269, 437"
    />
    <area
      className="hover-area"
      data-section="dsr-light"
      shape="poly"
      coords="254, 473, 257, 467, 263, 464, 270, 463, 277, 459, 282, 454, 286, 454, 288, 457, 283, 463, 277, 468, 269, 473"
    />

    <area
      className="hover-area"
      data-section="ps-sill"
      shape="poly"
      coords="51, 134, 57, 136, 67, 136, 72, 133, 71, 355, 64, 353, 58, 352, 51, 355"
    />
    <area
      className="hover-area"
      data-section="ds-sill"
      shape="poly"
      coords="334, 134, 339, 136, 348, 136, 355, 133, 354, 355, 347, 352, 339, 353, 334, 356"
    />

    <area
      className="hover-area"
      data-section="roof"
      shape="poly"
      coords="205, 176, 224, 177, 243, 180, 260, 185, 265, 191, 267, 208, 268, 243, 268, 288, 268, 335, 266, 355, 261, 376, 256, 382, 241, 385, 219, 387, 192, 387, 170, 386, 150, 383, 143, 371, 140, 339, 138, 307, 138, 277, 139, 246, 139, 209, 142, 189, 154, 181, 177, 178, 196, 176"
    />
    <area
      className="hover-area"
      data-section="front"
      shape="poly"
      coords="152, 28, 139, 17, 156, 13, 186, 9, 216, 8, 244, 12, 263, 16, 269, 17, 257, 26, 250, 36, 249, 45, 253, 61, 285, 126, 274, 123, 249, 111, 225, 106, 193, 105, 158, 111, 133, 123, 123, 124, 155, 61, 158, 45, 156, 35"
    />
    <area
      className="hover-area"
      data-section="rear"
      shape="poly"
      coords="195, 425, 217, 425, 249, 422, 267, 418, 269, 429, 266, 454, 266, 464, 258, 466, 254, 471, 252, 475, 222, 477, 180, 477, 152, 474, 151, 468, 141, 463, 141, 454, 138, 439, 139, 427, 141, 417, 172, 423"
    />

    <area
      className="hover-area"
      data-section="f-bumper"
      shape="poly"
      coords="143, 9, 159, 6, 182, 3, 206, 3, 234, 4, 260, 9, 285, 15, 299, 27, 301, 34, 297, 37, 289, 28, 276, 19, 246, 12, 220, 9, 193, 9, 166, 11, 136, 17, 122, 25, 111, 36, 110, 36, 105, 32, 112, 23, 119, 17"
    />
    <area
      className="hover-area"
      data-section="f-windscreen"
      shape="poly"
      coords="118, 154, 121, 144, 130, 132, 147, 123, 167, 118, 197, 117, 228, 117, 253, 121, 277, 132, 291, 152, 279, 165, 270, 178, 264, 189, 255, 182, 235, 178, 216, 176, 191, 176, 169, 179, 151, 182, 143, 189, 132, 170"
    />
    <area
      className="hover-area"
      data-section="r-bumper"
      shape="poly"
      coords="206, 479, 228, 478, 253, 476, 273, 470, 284, 462, 290, 452, 293, 446, 296, 450, 290, 463, 286, 469, 281, 475, 270, 479, 249, 482, 223, 484, 192, 484, 163, 481, 134, 478, 121, 473, 115, 463, 110, 449, 114, 447, 117, 455, 123, 463, 130, 469, 148, 473, 171, 476"
    />
    <area
      className="hover-area"
      data-section="r-windscreen"
      shape="poly"
      coords="201, 388, 218, 388, 241, 386, 255, 383, 267, 419, 234, 424, 200, 425, 168, 423, 138, 418, 148, 392, 152, 382, 174, 387"
    />
  </map>
)

const DamageSelector = (props) => {
  const [damages, setDamages] = useState([])
  const record = useRecordContext()
  const notify = useNotify()

  const mapHi = maphilight

  useEffect(() => {
    if (record) {
      getDamageList(record.id)
    }
    var self = this

    $('.hover-area').on('click', function () {
      $('.selector .section').hide()
      var section = $(this).data('section')

      $('.section#' + section).show()
      $('.right-panel').show()
    })

    $('#select-area').on('change', function () {
      $('.selector .section').hide()
      var section = $(this).find(':selected').data('section')
      $('.section#' + section).show()
      $.colorbox.resize()
    })

    var resizeTimer

    function resizeColorBox() {
      if (resizeTimer) {
        clearTimeout(resizeTimer)
      }
      resizeTimer = setTimeout(function () {
        if (jQuery('#cboxOverlay').is(':visible')) {
          jQuery.colorbox.resize()
        }
      }, 200)
    }

    //$(window).resize(resizeColorBox);
    window.addEventListener('orientationchange', resizeColorBox, false)

    $('#car-hover').maphilight({
      fillColor: '66c7f2',
      fillOpacity: 0.7,
      stroke: false,
    })

    // concat damages
    function processDamages() {
      var crashed_damage = false
      var crashed = [
        '168',
        '124',
        '149',
        '54',
        '110',
        '132',
        '157',
        '9',
        '22',
        '33',
        '46',
        '65',
        '78',
        '89',
        '102',
      ]

      var damage_code = []
      var damage_list = []
      $('#selected .item').each(function (index) {
        var dID = $(this).find('input').val()
        damage_code.push(dID)
        damage_list.push(dID + '#' + $(this).text())
        if (crashed.indexOf(dID) != -1) {
          crashed_damage = true
        }
      })
      $('#damageData').val(damage_code.join('|'))
      $('#car_damage_list').val(damage_list.join('|'))

      if (crashed_damage && $('#car_crash_damaged').val() <= 1) {
        updateRadioButton('2', 'car_crash_damaged')
      }
    }
  }, [])

  const addToDamageList = async (e) => {
    let damageId = e.target.querySelector('input').value
    const { test } = await updateVehicleDamage(record.id, damageId, 'add')

    setDamages([
      ...damages,
      {
        index: damageId,
        value: e.target.dataset.section + '- ' + e.target.textContent,
      },
    ])
    window.localStorage.setItem('recalculate', 'true')
    window.dispatchEvent(new Event('storage'))
  }

  const getDamageList = async (vehicleIri) => {
    try {
      const vehicle = await getDamageListFromAPI(vehicleIri)

      const dmgList = vehicle.vehicleCondition
        ? vehicle.vehicleCondition.damageList
        : []

      if (dmgList) {
        formatDamageList(dmgList)
      }
    } catch (error) {
      notify(error, { type: 'error' })
    }
  }

  const removeFromDamageList = async (id) => {
    const input = document.getElementById('damage-' + id)

    const { test } = await updateVehicleDamage(
      record.id,
      input.getAttribute('data-section'),
      'remove'
    )

    input.remove()
    window.localStorage.setItem('recalculate', 'true')
    window.dispatchEvent(new Event('storage'))
  }

  const formatDamageList = async (damageList) => {
    try {
      let selected = []

      if (damageList != '') {
        let damages = damageList.split('|')
        let carDamageMobile = false

        damages.forEach((damage) => {
          let tmp = damage.split('#')
          if (tmp.length != 2) {
            carDamageMobile = true
          }

          if (carDamageMobile) {
            if (tmp[2]) {
              selected.push({
                index: tmp[0].trim() + '#' + tmp[1].trim(),
                value: tmp[1].trim() + ' x ' + tmp[2].trim(),
              })
            }
          } else {
            let tmp2 = tmp[0].split(':')
            let keyValue = tmp2[0].trim()
            let label = tmp[1].trim()

            if (label !== '') {
              selected.push({ index: keyValue, value: label })
              tmp2 = []
            }
          }

          tmp = []
        })
      }

      setDamages(selected)
    } catch (error) {
      notify(error, { type: 'error' })
    }
  }

  return (
    <Grid container spacing={2} p={2} className="scratches-popup">
      <Grid item xs={12} p={2}>
        <Typography variant="subtitle1">
          Please click on the area of the car and then select the &quot;damage
          type&quot; from the pop up list. You can repeat this process for as
          many defects as necessary.
        </Typography>
      </Grid>

      <Grid container className="control-group w100 applicable_to_tgts hidden">
        <Grid item xs={6} className="left-panel">
          <div className="places hidden-xs hidden-sm">
            <img
              src="/car.png"
              alt=""
              id="car-hover"
              useMap="#hover-map"
              className="map_host"
            />
            {map}
          </div>
          <select name="" id="select-area" style={{ display: 'none' }}>
            <option value="" disabled defaultValue style={{ display: 'none' }}>
              Please select the affected area
            </option>
            <option value="" data-section="roof">
              Roof
            </option>
            <option value="" data-section="front">
              Front
            </option>
            <option value="" data-section="rear">
              Rear
            </option>

            <option value="" data-section="f-bumper">
              Front Bumper
            </option>
            <option value="" data-section="f-windscreen">
              Front Windscreen
            </option>
            <option value="" data-section="r-bumper">
              Rear Bumper
            </option>
            <option value="" data-section="r-windscreen">
              Rear Windscreen
            </option>

            <option value="" data-section="psf-window">
              Near Side Front Window
            </option>
            <option value="" data-section="psf-mirror">
              Near Side Mirror
            </option>
            <option value="" data-section="psf-door">
              Near Side Front Door
            </option>
            <option value="" data-section="psf-wheel">
              Near Side Front Wheel
            </option>
            <option value="" data-section="psf-wing">
              Near Side Front Wing
            </option>
            <option value="" data-section="psf-light">
              Near Side Front Light
            </option>

            <option value="" data-section="psr-window">
              Near Side Rear Window
            </option>
            <option value="" data-section="psr-door">
              Near Side Rear Door
            </option>
            <option value="" data-section="psr-wheel">
              Near Side Rear Wheel
            </option>
            <option value="" data-section="psr-wing">
              Near Side Rear Wing
            </option>
            <option value="" data-section="psr-light">
              Near Side Rear Light
            </option>

            <option value="" data-section="dsf-window">
              Off Side Front Window
            </option>
            <option value="" data-section="dsf-mirror">
              Off Side Mirror
            </option>
            <option value="" data-section="dsf-door">
              Off Side Front Door
            </option>
            <option value="" data-section="dsf-wheel">
              Off Side Front Wheel
            </option>
            <option value="" data-section="dsf-wing">
              Off Side Front Wing
            </option>
            <option value="" data-section="dsf-light">
              Off Side Front Light
            </option>

            <option value="" data-section="dsr-window">
              Off Side Rear Window
            </option>
            <option value="" data-section="dsr-door">
              Off Side Rear Door
            </option>
            <option value="" data-section="dsr-wheel">
              Off Side Rear Wheel
            </option>
            <option value="" data-section="dsr-wing">
              Off Side Rear Wing
            </option>
            <option value="" data-section="dsr-light">
              Off Side Rear Light
            </option>

            <option value="" data-section="ps-sill">
              Near Side Sill
            </option>
            <option value="" data-section="ds-sill">
              Off Side Sill
            </option>
          </select>
        </Grid>
        <Grid item xs={6} className="right-panel">
          <div className="selector-container">
            <div className="selector" id="selector">
              <Card className="section" id="roof" style={{ display: 'block' }}>
                <CardHeader title="Roof" />
                <CardContent>
                  <div>
                    <div
                      data-section="Roof"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="163"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Roof"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="164"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Roof"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="165"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Roof"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="166"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Roof"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="167"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Roof"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="168"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Roof"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="169"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Roof"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="170"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="front">
                <CardHeader title="Front" />
                <CardContent>
                  <div>
                    <div
                      data-section="Front"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="119"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Front"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="120"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Front"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="121"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Front"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="122"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Front"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="123"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Front"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="124"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Front"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="125"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Front"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="126"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="rear">
                <CardHeader title="Rear" />
                <CardContent>
                  <div>
                    <div
                      data-section="Rear"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="144"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Rear"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="145"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Rear"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="146"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Rear"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="147"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Rear"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="148"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Rear"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="149"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Rear"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="150"
                        />
                      </span>
                    </div>
                    <div
                      data-section="Rear"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="151"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="ps-sill">
                <CardHeader title="Passengers Side Sill" />
                <CardContent>
                  <div>
                    <div
                      data-section="P/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="49"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="50"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="51"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="52"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="53"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="54"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="55"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="56"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="ds-sill">
                <CardHeader title="Drivers Side Sill" />
                <CardContent>
                  <div>
                    <div
                      data-section="D/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="105"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="106"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="107"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="108"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="109"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="110"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="111"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S Sill"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="112"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="f-windscreen">
                <CardHeader title="Front Windscreen" />
                <CardContent>
                  <div>
                    <div
                      data-section="F Windscreen"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Chipped Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="135"
                        />
                      </span>
                    </div>
                    <div
                      data-section="F Windscreen"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Cracked Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="136"
                        />
                      </span>
                    </div>
                    <div
                      data-section="F Windscreen"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Smashed Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="137"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="r-windscreen">
                <CardHeader title="Rear Windscreen" />
                <CardContent>
                  <div>
                    <div
                      data-section="R Windscreen"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Chipped Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="160"
                        />
                      </span>
                    </div>
                    <div
                      data-section="R Windscreen"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Cracked Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="161"
                        />
                      </span>
                    </div>
                    <div
                      data-section="R Windscreen"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Smashed Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="162"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="f-bumper">
                <CardHeader title="Front Bumper" />
                <CardContent>
                  <div>
                    <div
                      data-section="F Bumper"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="127"
                        />
                      </span>
                    </div>
                    <div
                      data-section="F Bumper"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="128"
                        />
                      </span>
                    </div>
                    <div
                      data-section="F Bumper"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="129"
                        />
                      </span>
                    </div>
                    <div
                      data-section="F Bumper"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="130"
                        />
                      </span>
                    </div>
                    <div
                      data-section="F Bumper"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="131"
                        />
                      </span>
                    </div>
                    <div
                      data-section="F Bumper"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="132"
                        />
                      </span>
                    </div>
                    <div
                      data-section="F Bumper"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="133"
                        />
                      </span>
                    </div>
                    <div
                      data-section="F Bumper"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="134"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <div className="section" id="r-bumper">
                <h3>Rear Bumper</h3>
                <div
                  data-section="R Bumper"
                  className="item"
                  onClick={(e) => addToDamageList(e)}
                >
                  <AddCircle />
                  Small scratch/scuff (under 19cm)
                  <span style={{ display: 'none' }}>
                    <input type="text" name="damageData[]" defaultValue="152" />
                  </span>
                </div>
                <div
                  data-section="R Bumper"
                  className="item"
                  onClick={(e) => addToDamageList(e)}
                >
                  <AddCircle />
                  Medium scratch/scuff (20cm+)
                  <span style={{ display: 'none' }}>
                    <input type="text" name="damageData[]" defaultValue="153" />
                  </span>
                </div>
                <div
                  data-section="R Bumper"
                  className="item"
                  onClick={(e) => addToDamageList(e)}
                >
                  <AddCircle />
                  Small dent/scrape (under 10cm)
                  <span style={{ display: 'none' }}>
                    <input type="text" name="damageData[]" defaultValue="154" />
                  </span>
                </div>
                <div
                  data-section="R Bumper"
                  className="item"
                  onClick={(e) => addToDamageList(e)}
                >
                  <AddCircle />
                  Medium dent/scrape (10–20cm)
                  <span style={{ display: 'none' }}>
                    <input type="text" name="damageData[]" defaultValue="155" />
                  </span>
                </div>
                <div
                  data-section="R Bumper"
                  className="item"
                  onClick={(e) => addToDamageList(e)}
                >
                  <AddCircle />
                  Large dent/scrape (20cm+)
                  <span style={{ display: 'none' }}>
                    <input type="text" name="damageData[]" defaultValue="156" />
                  </span>
                </div>
                <div
                  data-section="R Bumper"
                  className="item"
                  onClick={(e) => addToDamageList(e)}
                >
                  <AddCircle />
                  Crash damage (mark all panels affected).
                  <span style={{ display: 'none' }}>
                    <input type="text" name="damageData[]" defaultValue="157" />
                  </span>
                </div>
                <div
                  data-section="R Bumper"
                  className="item"
                  onClick={(e) => addToDamageList(e)}
                >
                  <AddCircle />
                  Requires respray/poorpaint
                  <span style={{ display: 'none' }}>
                    <input type="text" name="damageData[]" defaultValue="158" />
                  </span>
                </div>
                <div
                  data-section="R Bumper"
                  className="item"
                  onClick={(e) => addToDamageList(e)}
                >
                  <AddCircle />
                  Rust
                  <span style={{ display: 'none' }}>
                    <input type="text" name="damageData[]" defaultValue="159" />
                  </span>
                </div>
              </div>

              <div className="section" id="psf-window">
                <h3>Passengers Side Front Window</h3>
                <div
                  data-section="P/S/F Window"
                  className="item"
                  onClick={(e) => addToDamageList(e)}
                >
                  <AddCircle />
                  Chipped Window
                  <span style={{ display: 'none' }}>
                    <input type="text" name="damageData[]" defaultValue="1" />
                  </span>
                </div>
                <div
                  data-section="P/S/F Window"
                  className="item"
                  onClick={(e) => addToDamageList(e)}
                >
                  <AddCircle />
                  Cracked Window
                  <span style={{ display: 'none' }}>
                    <input type="text" name="damageData[]" defaultValue="2" />
                  </span>
                </div>
                <div
                  data-section="P/S/F Window"
                  className="item"
                  onClick={(e) => addToDamageList(e)}
                >
                  <AddCircle />
                  Smashed Window
                  <span style={{ display: 'none' }}>
                    <input type="text" name="damageData[]" defaultValue="3" />
                  </span>
                </div>
              </div>

              <Card className="section" id="psf-light">
                <CardHeader title="Passengers Side Front Light" />
                <CardContent>
                  <div>
                    <div
                      data-section="P/S/FLight"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Chipped Light
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="113"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FLight"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Cracked Light
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="114"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FLight"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Smashed Light
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="115"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="psf-door">
                <CardHeader title="Passengers Side Front Door" />
                <CardContent>
                  <div>
                    <div
                      data-section="P/S/FDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="4"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="5"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="6"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="7"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="8"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="9"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="10"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="11"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="psf-mirror">
                <CardHeader title="Passengers Side Mirror" />
                <CardContent>
                  <div>
                    <div
                      data-section="P/S/FMirror"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Broken Glass
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="171"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FMirror"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Cracked Casing
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="172"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FMirror"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Smashed/Missing
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="173"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="psf-wing">
                <CardHeader title="Passengers Side Front Wing" />
                <CardContent>
                  <div>
                    <div
                      data-section="P/S/FWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="17"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="18"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="19"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="20"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="21"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="22"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="23"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="24"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="psf-wheel">
                <CardHeader title="Passengers Side Front Wheel" />
                <CardContent>
                  <div>
                    <div
                      data-section="P/S/FWheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Flat tyre
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="12"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FWheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Tyre worn
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="13"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FWheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Alloy curbed
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="14"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FWheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Wheel damaged from crash
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="15"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/FWheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Wheel not properly attached
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="16"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="psr-window">
                <CardHeader title="Passengers Side Rear Window" />
                <CardContent>
                  <div>
                    <div
                      data-section="P/S/RWindow"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Chipped Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="25"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWindow"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Cracked Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="26"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWindow"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Smashed Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="27"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="psr-light">
                <CardHeader title="Passengers Side Rear Light" />
                <CardContent>
                  <div>
                    <div
                      data-section="P/S/RLight"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Chipped Light
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="138"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RLight"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Cracked Light
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="139"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RLight"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Smashed Light
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="140"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="psr-door">
                <CardHeader title="Passengers Side Rear Door" />
                <CardContent>
                  <div>
                    <div
                      data-section="P/S/RDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="28"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="29"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="30"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="31"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="32"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="33"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="34"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RDoor"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="35"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="psr-wing">
                <CardHeader title="Passengers Side Rear Wing" />
                <CardContent>
                  <div>
                    <div
                      data-section="P/S/RWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="41"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="42"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="43"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="44"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="45"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="46"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="47"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="48"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="psr-wheel">
                <CardHeader title="Passengers Side Rear Wheel" />
                <CardContent>
                  <div>
                    <div
                      data-section="P/S/RWheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Flat tyre
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="36"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Tyre worn
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="37"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Alloy curbed
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="38"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Wheel damaged from crash
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="39"
                        />
                      </span>
                    </div>
                    <div
                      data-section="P/S/RWheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Wheel not properly attached
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="40"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="dsf-window">
                <CardHeader title="Drivers Side Front Window" />
                <CardContent>
                  <div>
                    <div
                      data-section="D/S/F Window"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Chipped Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="57"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Window"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Cracked Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="58"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Window"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Smashed Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="59"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="dsf-light">
                <CardHeader title="Drivers Side Front Light" />
                <CardContent>
                  <div>
                    <div
                      data-section="D/S/F Light"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Chipped Light
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="116"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Light"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Cracked Light
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="117"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Light"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Smashed Light
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="118"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="dsf-door">
                <CardHeader title="Drivers Side Front Door" />
                <CardContent>
                  <div>
                    <div
                      data-section="D/S/F Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="60"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="61"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="62"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="63"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="64"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="65"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="66"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="67"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="dsf-mirror">
                <CardHeader title="Drivers Side Mirror" />
                <CardContent>
                  <div>
                    <div
                      data-section="D/S/F Mirror"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Broken Glass
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="174"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Mirror"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Cracked Casing
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="175"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Mirror"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Smashed/Missing
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="176"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="dsf-wing">
                <CardHeader title="Drivers Side Front Wing" />
                <CardContent>
                  <div>
                    <div
                      data-section="D/S/F Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="73"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="74"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="75"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="76"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="77"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="78"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="79"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="80"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="dsf-wheel">
                <CardHeader title="Drivers Side Front Wheel" />
                <CardContent>
                  <div>
                    <div
                      data-section="D/S/F Wheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Flat tyre
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="68"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Wheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Tyre worn
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="69"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Wheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Alloy curbed
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="70"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Wheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Wheel damaged from crash
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="71"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/F Wheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Wheel not properly attached
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="72"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="dsr-window">
                <CardHeader title="Drivers Side Rear Window" />
                <CardContent>
                  <div>
                    <div
                      data-section="D/S/R Window"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Chipped Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="81"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Window"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Cracked Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="82"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Window"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Smashed Window
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="83"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="dsr-light">
                <CardHeader title="Drivers Side Rear Light" />
                <CardContent>
                  <div>
                    <div
                      data-section="D/S/R Light"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Chipped Light
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="141"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Light"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Cracked Light
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="142"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Light"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Smashed Light
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="143"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="dsr-door">
                <CardHeader title="Drivers Side Rear Door" />
                <CardContent>
                  <div>
                    <div
                      data-section="D/S/R Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="84"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="85"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="86"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="87"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="88"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="89"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="90"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Door"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="91"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="dsr-wing">
                <CardHeader title="Drivers Side Rear Wing" />
                <CardContent>
                  <div>
                    <div
                      data-section="D/S/R Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small scratch/scuff (under 19cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="97"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium scratch/scuff (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="98"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Small dent/scrape (under 10cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="99"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Medium dent/scrape (10–20cm)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="100"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Large dent/scrape (20cm+)
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="101"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Crash damage (mark all panels affected).
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="102"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Requires respray/poorpaint
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="103"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Wing"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Rust
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="104"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="section" id="dsr-wheel">
                <CardHeader title="Drivers Side Rear Wheel" />
                <CardContent>
                  <div>
                    <div
                      data-section="D/S/R Wheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Flat tyre
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="92"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Wheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Tyre worn
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="93"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Wheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Alloy curbed
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="94"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Wheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Wheel damaged from crash
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="95"
                        />
                      </span>
                    </div>
                    <div
                      data-section="D/S/R Wheel"
                      className="item"
                      onClick={(e) => addToDamageList(e)}
                    >
                      <AddCircle />
                      Wheel not properly attached
                      <span style={{ display: 'none' }}>
                        <input
                          type="text"
                          name="damageData[]"
                          defaultValue="96"
                        />
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="selected-container">
            <Card className="selected" id="selected" sx={{ mt: 2 }}>
              <CardHeader title="Damage" />
              <CardContent>
                {Array.isArray(damages) &&
                  damages.map((damage, i) => {
                    if (damage.value) {
                      return (
                        <div
                          data-section={damage.index}
                          className="item"
                          key={i}
                          id={`damage-${i}`}
                          onClick={(e) => removeFromDamageList(i)}
                        >
                          <Cancel />
                          {damage.value}
                        </div>
                      )
                    }
                  })}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default DamageSelector

import { useState } from 'react'
import { ReferenceInput, SelectInput } from 'react-admin'

const AgentReferenceField = (props) => {
  const [version, setVersion] = useState(0)

  return (
    <div
      className={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ReferenceInput
        key={version}
        sort={{ field: 'collectionAgentCompanyName', order: 'ASC' }}
        {...props}
      >
        <SelectInput
          resettable={true}
          optionText="collectionAgentCompanyName"
        />
      </ReferenceInput>
    </div>
  )
}

export default AgentReferenceField

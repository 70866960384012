const scrap_terms = (
  <>
    <h1>Scrap - Terms & Conditions - Read word for word</h1>
    <p>
      So, just to summarise... (Collector Name) will call you within 48 hours to
      arrange the collection of your vehicle, reg (Vehicle Registration). They
      do reserve the right to charge you in the event of a failed collection so
      please let them or us know ASAP if your situation changes
    </p>
    <p>
      They will pay you (Amount) by (Payment Type). Please allow 48 hours after
      the car has been collected for this to clear.
    </p>
    <p>
      Before they arrive, please just make sure the car is empty. Also, please
      have photo ID ready for the day.
    </p>
    <p>
      I will send you all this information in an email shortly, but if you have
      any questions in the meantime please do not hesitate to give me a call.
    </p>
    <p>
      One final thing before I let you go. Here at Scrap Car Comparison we don’t
      charge you a penny for our service. What we like to do is support
      charities with their fundraising efforts, and this month we are raising
      money for [insert charity name], who [insert what charity do]
    </p>
    <p>
      The minimum donation is £1, but you can give as little or as much as you
      like. You don’t have to pay me any money over the phone, the donation will
      just come off of the money you are getting for your car.
    </p>
    <p>Would you like to be included in this today?”</p>
    <p>
      <strong>[Customer objects, which they rarely do]</strong>
    </p>
    <p>OK no problem, I’ll just put you down for a pound then.</p>
  </>
)

export { scrap_terms }

import { Button, useRecordContext } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { Pageview } from '@mui/icons-material'
import { removeLeadReplyTagFromInboxNotificationList } from '../../../Utils/QuoteRequestUtils'

const GotoQuoteButton = () => {
  const navigate = useNavigate()
  const record = useRecordContext()
  const quoteID = record.inboxQuote.id

  const handleLinkClick = async () => {
    //remove tag reply
    await removeLeadReplyTagFromInboxNotificationList(record)

    window.open(
      '/#/quote-requests/%2Fapi%2Fquote-requests%2F' + quoteID,
      '_blank'
    )
    self.focus()
  }
  return (
    <Button variant="text" onClick={handleLinkClick}>
      <Pageview sx={{ mr: 0.5 }} />
      Quote
    </Button>
  )
}

export default GotoQuoteButton

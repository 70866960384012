import React from 'react'
import { Grid, Typography } from '@mui/material'
import DeleteFeeButton from './DeleteFeeButton'
import PropTypes from 'prop-types'

const CurrentFees = ({ sb }) => {
  if (sb == null || sb.fees[0] == null) {
    return null
  }

  const DisplayFee = (props) => {
    const receivedProps = { ...props }

    const feesToProcess = sb.fees.slice(
      receivedProps.startFromIndex,
      receivedProps.startFromIndex + 3
    )

    return (
      <Grid
        item
        xs={3}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          marginTop: 8,
        }}
      >
        <Grid container spacing={5}>
          {feesToProcess.map((fee) => {
            return (
              <Grid
                key={`single-fee-${fee.id}`}
                item
                xs={12}
                sx={{ alignItems: 'center', display: 'flex' }}
              >
                <DeleteFeeButton sb={sb} fee={fee} />

                <Typography
                  sx={{
                    fontWeight: '400',
                    letterSpacing: '0.46px',
                    marginLeft: 3,
                  }}
                >
                  {fee.readable_type}:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '700',
                    letterSpacing: '0.46px',
                    marginLeft: 1,
                  }}
                >
                  {new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: 'GBP',
                  }).format(fee.amount)}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    )
  }

  return (
    <React.Fragment>
      {sb.fees.map((fee, idx) => {
        if (idx === 0 || idx % 3 === 0) {
          return <DisplayFee key={`display-fee-${idx}`} startFromIndex={idx} />
        }
      })}
    </React.Fragment>
  )
}

CurrentFees.propTypes = {
  sb: PropTypes.object,
}

export default CurrentFees

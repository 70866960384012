import React, { useEffect, useState } from 'react'
import { Button, Form, useNotify, useRecordContext } from 'react-admin'
import {
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import {
  getTemplateMapping,
  getTemplates,
} from '../../../../Utils/QuoteRequestUtils'
import {
  sendEmailTemplated,
  sendSmsTemplated,
} from '../../../../Utils/CommunicationsUtils'
import ModalOptions from './ModalOptions'
import './style/index.scss'
import PropTypes from 'prop-types'

const SendEmailText = ({ setContactLogScreen }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [textForm, setTextForm] = useState({
    templateId: '',
    templateName: '',
    smsContent: '',
    leadId: record.id,
  })
  const [templatesList, setTemplatesList] = useState([])
  const [emailForm, setEmailForm] = useState({
    templateId: '',
    templateName: '',
    title: '',
    content: '',
    cc: '',
    bcc: '',
    leadId: record.id,
  })
  const [displayPreview, setDisplayPreview] = useState('none')
  const [emailTemplatePurpose, setEmailTemplatePurpose] = useState('')
  const [smsTemplatePurpose, setSmsTemplatePurpose] = useState('')

  const handleEmailInputChange = (e) => {
    const { name, value } = e.target

    setEmailForm({
      ...emailForm,
      [name]: value,
    })
  }

  const handleTemplateChange = async (e) => {
    const { name, value } = e.target
    const ids = value.split('-')
    const emailTemplateMapped = await getTemplateMapping(
      'email',
      ids[0],
      record.id
    )

    const textTemplateMapped = await getTemplateMapping(
      'sms',
      ids[1],
      record.id
    )

    if (emailTemplateMapped.result === 'error') {
      notify('Unable to generate email preview', { type: 'error' })

      setDisplayPreview('none')
    } else {
      setDisplayPreview('block')
      setEmailTemplatePurpose(emailTemplateMapped.purpose)
    }

    setSmsTemplatePurpose(textTemplateMapped.purpose)

    setTextForm({
      ...textForm,
      templateId: ids[1],
      templateName: textTemplateMapped.templateName,
      smsContent: textTemplateMapped.content,
    })

    setEmailForm({
      ...emailForm,
      templateId: ids[0],
      templateName: emailTemplateMapped.templateName,
      title: emailTemplateMapped.subject,
      content: emailTemplateMapped.content,
    })
  }

  const handleSendEmail = async () => {
    const result = await sendEmailTemplated({
      leadId: emailForm.leadId,
      cc: emailForm.cc,
      bcc: emailForm.bcc,
      purpose: emailTemplatePurpose,
    })

    return result.status === 'success'
  }

  const getTemplateDataFromApi = async () => {
    const result = await getTemplates('email-text')
    setTemplatesList(result)
  }

  useEffect(() => {
    getTemplateDataFromApi()
  }, [])

  const handleTextInputChange = (e) => {
    const { name, value } = e.target

    setTextForm({
      ...textForm,
      [name]: value,
    })
  }

  const handleSendText = async () => {
    const result = await sendSmsTemplated({
      leadId: textForm.leadId,
      purpose: smsTemplatePurpose,
    })

    return result.status === 'success'
  }

  function handleSendEmailText() {
    const textResult = handleSendText()
    const emailResult = handleSendEmail()

    if (textResult && emailResult) {
      notify('Text & email successfully sent', { type: 'success' })
      setContactLogScreen('list')
    }

    if (!textResult && emailResult) {
      notify('Text failed to send', { type: 'error' })
    }

    if (textResult && !emailResult) {
      notify('Email failed to send', { type: 'error' })
    }
  }

  return (
    <React.Fragment>
      <Typography
        variant="h5"
        component="h2"
        className="contact-log__header-title"
      >
        Send Email & Text
      </Typography>
      <Form onSubmit={handleSendEmailText} className="reminder-form">
        <Grid container spacing={1} xs={12} sx={{ marginBottom: '10px' }}>
          <Grid container rowSpacing={2} xs={6} sx={{ paddingRight: 2 }}>
            <Grid item flex={1} xs={12}>
              <TextField
                name="template"
                select
                label="Template"
                onChange={handleTemplateChange}
                value={emailForm.templateId}
                required
              >
                {templatesList.map((option, key) => (
                  <MenuItem
                    key={key}
                    value={option.emailTemplateId + '-' + option.smsId}
                  >
                    {option.emailTemplateTargetLabel}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item flex={1} xs={12}>
              <TextField
                name="title"
                label="Title / Subject"
                onChange={handleEmailInputChange}
                value={emailForm.title}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item flex={1} xs={12}>
              <TextField
                name="cc"
                label="CC"
                onChange={handleEmailInputChange}
              />
            </Grid>
            <Grid item flex={1} xs={12}>
              <TextField
                name="bcc"
                label="BCC"
                onChange={handleEmailInputChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} xs={6}>
            <Grid item flex={1} xs={12}>
              <TextField
                name="text-preview"
                label="Text Preview"
                multiline
                rows={7}
                value={textForm.smsContent}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1} xs={12}>
          <Grid item flex={1} xs={12}>
            <InputLabel
              variant="filled"
              className="preview-email__label"
              sx={{ display: displayPreview }}
            >
              Email Preview
            </InputLabel>
            <Paper
              sx={{
                background: 'white',
                overflow: 'auto',
                display: displayPreview,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: emailForm.content }} />
            </Paper>
          </Grid>
          <Grid item flex={1} xs={12}>
            <Button
              color="primary"
              size="large"
              type="submit"
              label="Send Email & Text"
              sx={{ float: 'right' }}
            />
            <Button
              color="primary"
              size="large"
              type="button"
              label="Cancel"
              onClick={() => setContactLogScreen('list')}
              sx={{ float: 'right', marginRight: '10px' }}
            />
          </Grid>
        </Grid>
      </Form>
      <ModalOptions setContactLogScreen={setContactLogScreen} />
    </React.Fragment>
  )
}

SendEmailText.propTypes = {
  setContactLogScreen: PropTypes.func,
}

export default SendEmailText

import { Grid, Typography } from '@mui/material'

const PartialNotRequired = () => {
  return (
    <Grid item xs={12} sx={{ marginTop: 1 }}>
      <Typography
        sx={{
          fontWeight: '700',
          lineHeight: '26px',
          letterSpacing: '0.46px',
          marginRight: '5px',
          textTransform: 'uppercase',
        }}
      >
        Disabled for cheque payment
      </Typography>
    </Grid>
  )
}

export default PartialNotRequired

import React from 'react'
import ListContactLog from './List'
import './style/index.scss'
import '../../style/contact-logs.scss'
import PropTypes from 'prop-types'

const ContactLogPanel = ({ contactLogScreen, setContactLogScreen }) => {
  return (
    <React.Fragment>
      <div id="contact-log-section"></div>
      {contactLogScreen === 'list' && (
        <ListContactLog setContactLogScreen={setContactLogScreen} />
      )}
    </React.Fragment>
  )
}

ContactLogPanel.propTypes = {
  contactLogScreen: PropTypes.string,
  setContactLogScreen: PropTypes.func,
  setSelectedTab: PropTypes.func,
  setViewAgentsModal: PropTypes.func,
}

export default ContactLogPanel

import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import AssignToCopart from './AssignToCopart'
import PopupPanel from '../PopupPanel'
import { useState } from 'react'
import LeadDetails from './LeadDetails'

const ShoreCarsBooking = ({ sb }) => {
  const [popupPanelName, setPopupPanelName] = useState('')
  const [popupPanelProps, setPopupPanelProps] = useState({})
  return (
    <Grid
      container
      spacing={3}
      style={{
        display: 'grid',
        gridColumn: '1 / span 6',
        gridRowStart: 4,
        gridRowEnd: 4,
        position: 'relative',
      }}
      sx={{
        marginTop: '0 !important',
        marginLeft: '0 !important',
        padding: '30px',
        width: '100%',
        minHeight: `${popupPanelName === '' ? '100%' : '500px'}`,
      }}
    >
      <PopupPanel
        sb={sb}
        popupPanelName={popupPanelName}
        setPopupPanelName={setPopupPanelName}
        popupPanelProps={popupPanelProps}
      />
      <Grid
        container
        spacing={1}
        sx={{
          padding: '20px 0 !important',
          marginLeft: '0 !important',
          width: '100%',
          opacity: `${
            popupPanelName !== '' && popupPanelName !== 'sendEmail' ? 0.05 : 1
          }`,
        }}
      >
        {sb && sb.assigned_to_copart_at ? (
          <LeadDetails sb={sb} />
        ) : (
          <AssignToCopart
            sb={sb}
            setPopupPanelName={setPopupPanelName}
            setPopupPanelProps={setPopupPanelProps}
          />
        )}
      </Grid>
    </Grid>
  )
}

ShoreCarsBooking.propTypes = {
  sb: PropTypes.object,
}

export default ShoreCarsBooking

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { Button, Create, Form, TextInput, useNotify } from 'react-admin'
import { Cancel, Save } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { createNewLead } from '../../Utils/RestUtils'
import { useSearchParams } from 'react-router-dom'
import { getDuplicatesByReg } from '../../Utils/QuoteRequestUtils'

const CreateQuoteRequest = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [duplicates, setDuplicates] = useState(null)
  const notify = useNotify()

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const leadSources = [
    {
      number: '01243201010',
      name: 'Scrap Car Comparison - Main',
    },
    {
      number: '441243201010',
      name: 'Scrap Car Comparison - Main',
    },
    {
      number: '01243219178',
      name: 'Scrap Car Comparison - Facebook',
    },
    {
      number: '441243219178',
      name: 'Scrap Car Comparison - Facebook',
    },
    {
      number: '01243974692',
      name: 'National Scrap Car - Main',
    },
    {
      number: '441243974692',
      name: 'National Scrap Car - Main',
    },
    {
      number: '01243219177',
      name: 'National Scrap Car - Adwords',
    },
    {
      number: '441243219177',
      name: 'National Scrap Car - Adwords',
    },
    {
      number: '02380008800',
      name: 'BMS Salvage - Main',
    },
    {
      number: '442380008800',
      name: 'BMS Salvage - Main',
    },
    {
      number: '01243201011',
      name: 'BMS Cars - Workshop',
    },
    {
      number: '441243201011',
      name: 'BMS Cars - Workshop',
    },
    {
      number: '01243202015',
      name: 'BMS Cars - Sales',
    },
    {
      number: '441243202015',
      name: 'BMS Cars - Sales',
    },
    {
      number: '01243202012',
      name: 'Shore Cars - Main',
    },
    {
      number: '441243202012',
      name: 'Shore Cars - Main',
    },
    {
      number: '02380001801',
      name: 'Scrap Cars For Cash - Main',
    },
    {
      number: '442380001801',
      name: 'Scrap Cars For Cash - Main',
    },
  ]

  const handleSubmit = async (values) => {
    let results = []
    setIsLoading(true)

    for (var i = 0; i < leadSources.length; i++) {
      if (leadSources[i].number === values.dialled) {
        results.push(leadSources[i])
      }
    }

    const result = await createNewLead({
      postcode: values.postcode,
      registration_num: values.registration,
      lead_source: results.length > 0 ? results[0].name : 'Calls in',
      referring_url: 'manual',
    })

    if (result.result === 'success') {
      if (result.dvla === 'failure') {
        setIsLoading(false)

        notify(
          'There was a problem adding a new lead - ' +
            result.error +
            ' - Double check the reg with the customer, otherwise please speak to your line manager',
          {
            type: 'error',
          }
        )
      } else {
        location.href =
          '/#/quote-requests/%2Fapi%2Fquote-requests%2F' + result.lead_id
      }
    } else {
      setIsLoading(false)

      notify('There was a problem adding a new lead - ' + result.error, {
        type: 'error',
      })
    }
  }

  const checkDuplicateByReg = async (reg) => {
    let duplictatesData = await getDuplicatesByReg({
      registration: reg,
    })

    if (duplictatesData.status === 'success') {
      if (duplictatesData.data.matches.length > 0) {
        setDuplicates(duplictatesData.data.matches)
        setShowDialog(true)
      }
    }
  }

  let reg = ''
  let number = ''
  let dialled = ''
  const [params] = useSearchParams()
  reg = params.get('registration')
  number = params.get('number')
  dialled = params.get('dialled')

  return (
    <Create {...props}>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Add/Update collection zone"
      >
        {duplicates && (
          <React.Fragment>
            <DialogTitle>Duplicates found!</DialogTitle>
            <DialogContent>
              <Typography>
                We have detected one or more leads with this registration:{' '}
              </Typography>
              {duplicates.map((duplicate, i) => {
                return (
                  <Typography key={i}>
                    <Button
                      key={i}
                      variant="contained"
                      onClick={() => {
                        location.href = duplicate.url
                      }}
                    >
                      View lead {duplicate.primary === true ? '(Primary)' : ''}{' '}
                      {duplicate.id}
                    </Button>
                  </Typography>
                )
              })}
            </DialogContent>
            <DialogActions>
              <Button label="ra.action.cancel" onClick={handleCloseClick}>
                <Cancel />
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
      <Form onSubmit={handleSubmit}>
        <React.Fragment>
          <Grid
            container
            spacing={3}
            style={{
              padding: '1rem',
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <h3
                style={{
                  margin: '0',
                }}
              >
                Create a new lead
              </h3>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <TextInput
                  source="registration"
                  defaultValue={reg}
                  value={reg}
                  onBlur={(e) => checkDuplicateByReg(e.target.value)}
                  required
                  name="registration"
                />
                <TextInput source="postcode" required name="postcode" />
                <TextInput
                  source="number"
                  defaultValue={number}
                  value={number}
                  name="number"
                  sx={{
                    display: 'none !important',
                  }}
                />
                <TextInput
                  source="dialled"
                  defaultValue={dialled}
                  name="dialled"
                  hidden={true}
                  sx={{
                    display: 'none !important',
                  }}
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <Button
                type="submit"
                variant="contained"
                startIcon={
                  isLoading ? <CircularProgress size={16} /> : <Save />
                }
                disabled={isLoading}
                label="Save"
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </Form>
    </Create>
  )
}

CreateQuoteRequest.propTypes = {
  location: PropTypes.object,
}

export default CreateQuoteRequest

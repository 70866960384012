import { CardContent, Typography } from '@mui/material'
import {
  BooleanField,
  CloneButton,
  Datagrid,
  EditButton,
  FilterLiveSearch,
  FunctionField,
  List,
  TextField,
} from 'react-admin'

const filters = [
  <FilterLiveSearch label="Search" source="body" alwaysOn key="live-filter" />,
]

const ListEmailContent = (props) => {
  function getRenderedMessage() {
    return (record) => {
      return (
        new DOMParser()
          .parseFromString(record.body, 'text/html')
          .querySelector('html')
          .textContent.substring(0, 100) + '...'
      )
    }
  }

  function getRenderedCategory() {
    return (record) => {
      return record.category.replaceAll('_', ' ')
    }
  }

  const caps = { textTransform: 'capitalize' }
  return (
    <CardContent>
      <Typography typography="h4" component="h1" mb={2}>
        Email Templates
      </Typography>
      <List
        {...props}
        title="Email Template Contents"
        filters={filters}
        filter={{ templateUpdate: 1 }}
      >
        <Datagrid>
          <TextField label="Receiver" source="receiver" style={caps} />
          <TextField label="Subject" source="header" />
          <FunctionField label="Message" render={getRenderedMessage()} />;
          <TextField label="Target" source="targetLabel" />
          <FunctionField
            label="Category"
            render={getRenderedCategory()}
            style={caps}
          />
          <BooleanField
            label="Enabled in contact log"
            source="enabledInContactLog"
          />
          ;
          <EditButton />
          <CloneButton />
        </Datagrid>
      </List>
    </CardContent>
  )
}

export default ListEmailContent

import { useState } from 'react'
import { Button } from '@mui/material'
import { useRecordContext, useRefresh, useUpdate } from 'react-admin'

const MarkScamButton = () => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const [update, { isLoading, error }] = useUpdate()
  const [isScam, setIsScam] = useState(false)

  const toggleScam = () => {
    if (record) {
      update('lead-contacts', {
        id: record.leadContact['@id'],
        data: { scam: !record.leadContact.scam },
        previousData: record.leadContact,
      }).then(() => {
        // I am sorry
        setTimeout(() => {
          refresh()
        }, 100)
      })
    }
  }

  if (error) {
    return (
      <Button fullWidth disabled={true} color="error">
        ERROR
      </Button>
    )
  }

  return (
    <Button
      fullWidth
      onClick={() => toggleScam()}
      disabled={isLoading}
      variant={record.leadContact.scam ? 'contained' : 'outlined'}
      color={record.leadContact.scam ? 'error' : 'primary'}
    >
      Scam
    </Button>
  )
}

export default MarkScamButton

import React, { useState } from 'react'
import './style/index.scss'
import {
  Button,
  Form,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { Grid, TextField, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { addContactLog } from '../../../../Utils/QuoteRequestUtils'
import { sendLog } from '../../../../Class/AuditLog'

const AddReminder = (props) => {
  const { updateRemindersList, setReminderScreen } = { ...props }
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [dateTime, setDateTime] = useState(null)
  const [reminder, setReminder] = useState({
    quoteType: '',
    quote: '',
    payload: '',
    date: '',
    time: '',
    type: 1,
    outcome: undefined,
    segment: 'na',
    user: window.localStorage.getItem('email'),
    quoteId: record.id,
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setReminder({
      ...reminder,
      [name]: value,
    })
  }

  const handleNewReminder = async () => {
    const dateTimeSplit = dateTime.$d.toLocaleString('en-GB').split(',')
    const reminderData = {
      ...reminder,
      date: dateTimeSplit[0].trim(),
      time: dateTimeSplit[1].trim(),
    }

    const result = await addContactLog(reminderData)
    sendLog(record.originId, 'Reminder added')
    if (result.status === 'success') {
      refresh()
      updateRemindersList(record)
      setReminderScreen('list')
    } else {
      notify(result.error, { type: 'error' })
    }
  }

  return (
    <React.Fragment>
      <Typography
        variant="h5"
        sx={{
          marginTop: 0,
          marginBottom: '1rem',
          fontWeight: 700,
          fontSize: '14px',
          textTransform: 'uppercase',
          lineHeight: '1.334',
        }}
      >
        Add Reminder
      </Typography>
      <Form onSubmit={handleNewReminder} className="reminder-form">
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => (
                  <TextField name="date" {...props} required />
                )}
                label="Reminder time"
                value={dateTime}
                ampm={false}
                inputFormat="DD/MM/YYYY HH:mm"
                onChange={(newDateTime) => {
                  setDateTime(newDateTime)
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item flex={1}>
            <TextField
              name="payload"
              label="Set Reminder"
              multiline
              rows={4}
              required
              onChange={handleInputChange}
              sx={{
                '& .MuiInputBase-inputMultiline': {
                  padding: '5px 0px 0px 0px !important',
                },
              }}
            />
          </Grid>
          <Grid item flex={1} xs={12} className="reminder-form__button-grid">
            <Button type="submit" label="Add Reminder" />
            <Button
              onClick={() => setReminderScreen('list')}
              type="button"
              sx={{ float: 'right', marginRight: '10px' }}
              label="Cancel"
            />
          </Grid>
        </Grid>
      </Form>
    </React.Fragment>
  )
}

export default AddReminder

import React, { useState } from 'react'

import {
  Button,
  FileField,
  FileInput,
  Form,
  SelectInput,
  useRecordContext,
  useRefresh,
} from 'react-admin'

import { Add, Cancel, Save } from '@mui/icons-material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import { postUploadZones } from '../../Utils/RestUtils'
import PropTypes from 'prop-types'

function ZonesBulkUploadButton(props) {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const record = useRecordContext()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleSubmit = async (values) => {
    const formData = new FormData()
    formData.append(
      'agent',
      typeof record !== 'undefined' ? record.id : undefined
    )
    formData.append('type', values.type)
    formData.append('action', values.action)
    formData.append(
      'file',
      typeof values.file !== 'undefined' ? values.file.rawFile : ''
    )

    await postUploadZones(formData)

    setShowDialog(false)
    refresh()
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        label="Bulk upload/ update zones"
      >
        <Add />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Add/Update collection zone"
      >
        <DialogTitle>Bulk upload/ update zones</DialogTitle>

        <Form resource="rules-sets-rules" onSubmit={handleSubmit}>
          <React.Fragment>
            <DialogContent>
              <FileInput
                source="file"
                label="Collection Zones CSV"
                accept=".csv"
              >
                <FileField source="src" title="title" />
              </FileInput>
              <SelectInput
                source="type"
                label="Pricing Method"
                optionValue="id"
                optionText="name"
                choices={[
                  { id: 'ppt', name: 'Price Per Ton' },
                  { id: 'weight', name: 'Weight Bracket' },
                ]}
                fullWidth
                resettable={true}
              />
              <SelectInput
                source="action"
                label="Action"
                optionValue="id"
                optionText="name"
                choices={[
                  { id: 'replace', name: 'Replace all' },
                  { id: 'add', name: 'Add/ Append' },
                ]}
                fullWidth
                resettable={true}
              />
            </DialogContent>
            <DialogActions>
              <Button label="ra.action.cancel" onClick={handleCloseClick}>
                <Cancel />
              </Button>
              <Button
                type="submit"
                variant="contained"
                startIcon={<Save />}
                label="Save"
              >
                Save
              </Button>
            </DialogActions>
          </React.Fragment>
        </Form>
      </Dialog>
    </React.Fragment>
  )
}

ZonesBulkUploadButton.propTypes = {
  id: PropTypes.number,
}

export default ZonesBulkUploadButton

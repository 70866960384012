import React, { useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Modal,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import PropTypes from 'prop-types'

const ImageGallerySlider = (props) => {
  const vehicleImageUrls = props.vehicleImageUrls
  const [openImageModal, setOpenImageModal] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const [currentImageIndex, setCurrentImageIndex] = useState(null)
  const [showNextButton, setShowNextButton] = useState(true)
  const [showPreviousButton, setShowPreviousButton] = useState(true)
  const [imageNumber, setImageNumber] = useState(0)
  const [numberOfImages, setNumberOfImages] = useState(0)

  let imageArray = []

  const handleOpenPDF = (url) => {
    window.open(url, '_blank')
  }

  const handleOpenImageModal = (imageUrl, index) => {
    if (imageUrl !== currentImage) {
      setCurrentImage(imageUrl)
      setCurrentImageIndex(index)
      handleImageButtons(index)
    }

    if (openImageModal === false) {
      setOpenImageModal(true)
    }
  }
  const handleCloseImageModal = () => {
    if (currentImage !== '') {
      setCurrentImage('')
      setCurrentImageIndex(null)
    }

    if (openImageModal === true) {
      setOpenImageModal(false)
    }
  }

  const handleImageButtons = (index) => {
    let imageNumber = index + 1
    let imageArrayLength = imageArray.length

    setImageNumber(imageNumber)
    setNumberOfImages(imageArrayLength)
    setShowNextButton(true)
    setShowPreviousButton(true)

    if (imageNumber === imageArrayLength) {
      setShowNextButton(false)

      if (imageArrayLength > 1) {
        setShowPreviousButton(true)
      }
    }

    if (index === 0) {
      setShowPreviousButton(false)

      if (imageArrayLength > 1) {
        setShowNextButton(true)
      }
    }
  }

  const handleKeyDown = (e) => {
    if (openImageModal === true && e.key === 'ArrowRight') {
      changeImage('next')
    }

    if (openImageModal === true && e.key === 'ArrowLeft') {
      changeImage('previous')
    }
  }

  const changeImage = (direction) => {
    let newIndex

    if (direction === 'next') {
      newIndex = currentImageIndex + 1
    } else {
      newIndex = currentImageIndex - 1
    }

    if (imageArray[newIndex] !== undefined) {
      setCurrentImage(imageArray[newIndex])
      setCurrentImageIndex(newIndex)
      handleImageButtons(newIndex)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    <React.Fragment>
      <ImageList cols={5}>
        {vehicleImageUrls &&
          vehicleImageUrls
            .slice(0)
            .reverse()
            .map((imageUrl, index) => {
              if (imageUrl.indexOf('pdf') === -1) {
                imageArray.push(imageUrl)
                const modelIndex = imageArray.indexOf(imageUrl)
                return (
                  <ImageListItem
                    key={index}
                    onClick={() => {
                      handleOpenImageModal(imageUrl, modelIndex)
                    }}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <img src={imageUrl} loading="lazy" alt={imageUrl} />
                  </ImageListItem>
                )
              } else {
                return (
                  <ImageListItem
                    key={index}
                    onClick={() => {
                      handleOpenPDF(imageUrl)
                    }}
                    sx={{
                      cursor: 'pointer',
                      justifySelf: 'center',
                      alignSelf: 'center',
                    }}
                  >
                    <img
                      alt={imageUrl}
                      height={50}
                      src="/pdf_placeholder.png"
                    />
                    <ImageListItemBar
                      position="bottom"
                      title={imageUrl.split('/').slice(-1)}
                    />
                  </ImageListItem>
                )
              }
            })}
      </ImageList>
      <Modal open={openImageModal} onClose={handleCloseImageModal}>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
            maxWidth: '1920px',
            width: '85%',
            height: '85%',
          }}
        >
          <img
            src={`${currentImage}`}
            style={{
              border: '2px solid #000',
              maxWidth: '100%',
              maxHeight: '100%',
              display: 'block',
              margin: 'auto',
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: '0',
              right: '0',
            }}
          />
          <IconButton
            style={{
              position: 'absolute',
              top: '-60px',
              right: '0',
            }}
            onClick={handleCloseImageModal}
          >
            <CloseIcon
              style={{
                fill: 'white',
                fontSize: '40px',
              }}
            />
          </IconButton>
          <Typography
            variant={'h3'}
            style={{
              position: 'absolute',
              top: '-60px',
              left: '0',
              color: 'white',
            }}
          >
            {imageNumber + ' / ' + numberOfImages}
          </Typography>
          {showNextButton && (
            <IconButton
              onClick={() => {
                changeImage('next')
              }}
              style={{
                position: 'absolute',
                right: '-60px',
                top: '50%',
              }}
            >
              <ArrowForwardIos
                style={{
                  fill: 'white',
                  fontSize: '40px',
                }}
              />
            </IconButton>
          )}
          {showPreviousButton && (
            <IconButton
              onClick={() => {
                changeImage('previous')
              }}
              style={{
                position: 'absolute',
                left: '-60px',
                top: '50%',
              }}
            >
              <ArrowBackIos
                style={{
                  fill: 'white',
                  fontSize: '40px',
                }}
              />
            </IconButton>
          )}
        </Box>
      </Modal>
    </React.Fragment>
  )
}

ImageGallerySlider.propTypes = {
  vehicleImageUrls: PropTypes.array,
}

export default ImageGallerySlider

import React, { useState } from 'react'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { useNotify, useRecordContext } from 'react-admin'
import {
  payBalanceSalvageBookingsLead,
  redirectToSB,
} from '../../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const DepositOutstanding = ({ sb }) => {
  const notify = useNotify()
  const record = useRecordContext()
  const [loading, setLoading] = useState(false)

  const handleButtonClick = async function () {
    if (!loading) {
      setLoading(true)
    }

    // Post new payment to salvage bookings
    const responseStatus = await payBalanceSalvageBookingsLead(
      sb.quote_request_id,
      record.depositAmount,
      'deposit',
      ''
    )

    if (responseStatus) {
      redirectToSB(sb.quote_request_id)
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  if (sb.payments.balance != null) {
    return (
      <Grid item xs={12}>
        Balance already paid
      </Grid>
    )
  }

  if (
    record.quotePaymentDetails.acc === '00000000' ||
    record.quotePaymentDetails.sort === '000000'
  ) {
    return (
      <Grid item xs={12}>
        Check account details
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          sx={{
            fontWeight: '700',
            letterSpacing: '0.46px',
            marginRight: '5px',
            textTransform: 'uppercase',
          }}
        >
          Outstanding:
        </Typography>
        <Typography
          sx={{
            fontWeight: '700',
            letterSpacing: '0.46px',
            textTransform: 'capitalize',
          }}
        >
          {new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
          }).format(record.depositAmount)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="contained"
          color="primary"
          label="Pay Deposit"
          fullWidth
          disabled={loading}
          onClick={handleButtonClick}
          sx={{
            border: '2px solid #FFF !important',
            backgroundColor: '#13A310',
            height: '40px',
            fontWeight: '700',
            '&:hover': {
              backgroundColor: '#13A310',
            },
          }}
        >
          Pay Deposit
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Grid>
    </React.Fragment>
  )
}

DepositOutstanding.propTypes = {
  sb: PropTypes.object,
}

export default DepositOutstanding

import { lightGreen, pink, red, yellow } from '@mui/material/colors'
import { defaultTheme } from 'react-admin'
import { blue } from './colors'

export const lightTheme = {
  ...defaultTheme,
  palette: {
    mode: 'light',
    secondary: pink,
    background: {
      default: '#FAF9F6',
    },
    good: {
      main: lightGreen[500],
      light: lightGreen[300],
      dark: lightGreen[700],
    },
    bad: {
      main: '#AA2929',
      light: red[300],
      dark: red[700],
    },
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 10,
    fontFamily: [
      'SF Pro Text',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    RaFilterForm: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
          marginTop: '10px',
          '& .MuiSwitch-root': {
            marginLeft: '10px',
          },
          '& .MuiOutlinedInput-root': {
            marginBottom: '0',
          },
        },
      },
    },
    RaFilterFormInput: {
      styleOverrides: {
        root: {
          marginBottom: '0 !important',
          display: 'flex',
          alignItems: 'center',
          '& .RaFilterFormInput-hideButton': { marginBottom: '0' },
        },
      },
    },
    RaTextInput: {
      variant: 'outlined',
    },
    RaSelectInput: {
      helperText: ' ',
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontWeight: 700,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
        sx: {
          borderRadius: '0',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px 20px 8px',
          '&:last-child': {
            paddingBottom: '8px',
          },
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          backgroundColor: 'white',
          height: 'unset',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontWeight: 700,
          '&.add-contact-log-modal': {
            background: '#FFFFFF',
            border: '5px solid red',
            padding: '20px',
            position: 'relative',
            zIndex: '999999',
            animation: 'alert 1s ease 0s 1 normal forwards',
          },
        },
      },
    },
    MuiStack: {
      defaultProps: {
        alignItems: 'center',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        fontWeight: 500,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        helperText: ' ',
        size: 'small',
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          flex: '1',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          marginTop: '0.15rem',
          marginBottom: '0.7rem',
          paddingTop: '0',
          paddingBottom: '0',
          minHeight: '35px',
          fontWeight: 500,
        },
        notchedOutline: {
          borderWidth: '1.5px',
          textTransform: 'uppercase',
          color: yellow,
          fontWeight: 'bold',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          display: 'none',
          '&.Mui-error': {
            display: 'block',
            marginTop: '-10px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginTop: '4px',
        },
      },
      defaultProps: {
        sx: {
          textTransform: 'uppercase',
          fontWeight: 'bold',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          minHeight: '35px',
          marginTop: '2px',
          padding: '6px 21px',
        },
        variants: [
          {
            props: { variant: 'dashboard-link' },
            style: {
              fontSize: '14px',
              textTransform: 'none',
              textDecoration: 'none',
              padding: '0',
              color: '#000',
              '> span': {
                color: blue['A400'],
                marginLeft: '1rem',
              },
            },
          },
        ],
        contained: {
          border: '1px solid !important',
          minHeight: '35px',
        },
        outlined: {
          borderWidth: '1.5px !important',
          minHeight: '35px',
        },
      },
    },
  },
}

import { TextInput, SimpleForm, Create } from 'react-admin'

const CreateSetting = (props) => {
  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <TextInput label="Name" source="name" fullWidth required />
        <TextInput label="Value" source="value" fullWidth required />
        <TextInput label="Identifier" source="identifier" fullWidth required />
      </SimpleForm>
    </Create>
  )
}

export default CreateSetting

const SBUrlHandler = (quoteRequestID, page) => {
  const salvageBookingsUrl = process.env.REACT_APP_SALVAGE_BOOKINGS_URL
  if (!page) {
    return salvageBookingsUrl + '/sqReturn/' + quoteRequestID
  } else {
    return salvageBookingsUrl + '/' + page
  }
}

export default SBUrlHandler

import React from 'react'
import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import { Button, CircularProgress, IconButton } from '@mui/material'
import { setSalvageBookingsLeadStatus } from '../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const SetNewStatusButton = ({
  slug,
  name,
  validationError,
  label,
  icon,
  setValidationError,
  resetButton,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [loading, setLoading] = React.useState(false)
  const record = useRecordContext()

  const handleButtonClick = async function () {
    if (name === '') {
      setValidationError(true)
      notify('Status must be entered', { type: 'error' })
      return
    }
    if (validationError) {
      return
    }
    if (!loading) {
      setLoading(true)
    }

    // Post new status to salvage bookings
    const responseStatus = await setSalvageBookingsLeadStatus(
      record.originId,
      slug,
      name
    )

    if (responseStatus) {
      refresh()
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  return (
    <React.Fragment>
      {icon ? (
        <IconButton disabled={loading} onClick={handleButtonClick}>
          {icon}
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="primary"
          label={label}
          fullWidth
          disabled={loading}
          onClick={handleButtonClick}
          sx={{
            border: '2px solid #FFF !important',
            backgroundColor: `${
              resetButton === 'true' ? '#F0F42F' : '#ED9C23'
            }`,
            color: `${resetButton === 'true' ? '#2A2A2A' : '#FFF'}`,
            height: '40px',
            fontWeight: '700',
            '&:hover': {
              backgroundColor: `${
                resetButton === 'true' ? '#F0F42F' : '#ED9C23'
              }`,
            },
          }}
        >
          {label}
        </Button>
      )}
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </React.Fragment>
  )
}

SetNewStatusButton.propTypes = {
  sb: PropTypes.object,
  slug: PropTypes.string,
  name: PropTypes.string,
  validationError: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.node,
  setValidationError: PropTypes.func,
  resetButton: PropTypes.string,
}

export default SetNewStatusButton

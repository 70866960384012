import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp'
import { useRecordContext } from 'react-admin'
import { getLeadNotifications } from '../../../../Utils/RestUtils'
import { dateConverter } from '../../../../Utils/ConversionUtils'
import ModalOptions from './ModalOptions'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

const ListContactLog = ({ setContactLogScreen }) => {
  const [data, setData] = useState([])
  const record = useRecordContext()

  const getDataFromApi = async (record) => {
    const result = await getLeadNotifications(
      record.originId,
      2,
      'targetTimestamp',
      'DESC'
    )

    const newDataArray = result.map((row) => {
      const { date, hour } = dateConverter(row.targetTimestamp, false, false)

      return {
        ...row,
        date: date,
        hour: hour,
      }
    })

    setData(newDataArray)
  }

  useEffect(() => {
    getDataFromApi(record)
  }, [])

  return (
    <React.Fragment>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="table-header__individual">From</TableCell>
              <TableCell className="table-header__individual">To</TableCell>
              <TableCell className="table-header__individual">
                Date / Time
              </TableCell>
              <TableCell className="table-header__individual">
                Message
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell className="table-body">
                    <Typography
                      variant="body2"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <ExitToAppSharpIcon
                        className="table-body table-body__icon"
                        sx={{
                          marginRight: '.75rem',
                          transform: 'scaleY(1.4)',
                        }}
                        color="primary"
                      />
                      {row.admin && row.admin.displayName}
                    </Typography>
                  </TableCell>
                  <TableCell className="table-body table-body__to">
                    {record.leadContact.contactName}
                  </TableCell>
                  <TableCell className="table-body">
                    <div>{row.date}</div>
                    <div className="table-body table-body__hour">
                      {row.hour}
                    </div>
                  </TableCell>
                  <TableCell className="table-body table-body__message">
                    {row.payload}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalOptions
        setContactLogScreen={setContactLogScreen}
        listLength={data.length}
      />
    </React.Fragment>
  )
}

ListContactLog.propTypes = {
  setContactLogScreen: PropTypes.func,
}

export default ListContactLog

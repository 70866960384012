import jwtDecode from 'jwt-decode'

const uiAuthProvider = {
  // authentication
  login: ({ email, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_API_PLATFORM_ENDPOINT}/tokens/authenticate`,
      {
        method: 'POST',
        body: JSON.stringify({ email: email, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      }
    )

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(({ token, refreshToken }) => {
        const decodedToken = jwtDecode(token)
        localStorage.setItem('token', token)
        localStorage.setItem('refreshToken', refreshToken)
        localStorage.setItem('user_id', decodedToken.userID)
        localStorage.setItem('permissions', decodedToken.roles)
        localStorage.setItem('display_name', decodedToken.displayName)
        localStorage.setItem('email', decodedToken.username)
        return Promise.resolve()
      })
  },
  checkError: (err) => {
    if ([401, 403].includes(err?.status || err?.response?.status)) {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  checkAuth: async () => {
    try {
      if (
        localStorage.getItem('token') &&
        new Date().getTime() / 1000 <
          jwtDecode(localStorage.getItem('token'))?.exp - 10
      ) {
        return Promise.resolve()
      } else {
        if (localStorage.getItem('refreshToken')) {
          const request = new Request(
            `${process.env.REACT_APP_API_PLATFORM_ENDPOINT}/tokens/refresh`,
            {
              method: 'POST',
              body: JSON.stringify({
                refreshToken: localStorage.getItem('refreshToken'),
              }),
              headers: new Headers({ 'Content-Type': 'application/json' }),
            }
          )

          return await fetch(request)
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(response.statusText)
              }
              return response.json()
            })
            .then(({ token, refreshToken }) => {
              const decodedToken = jwtDecode(token)
              localStorage.setItem('token', token)
              localStorage.setItem('refreshToken', refreshToken)
              localStorage.setItem('user_id', decodedToken.userID)
              localStorage.setItem('permissions', decodedToken.roles)
              localStorage.setItem('display_name', decodedToken.displayName)
              localStorage.setItem('email', decodedToken.username)
              return Promise.resolve()
            })
        } else {
          this.logout()
        }
      }
    } catch (e) {
      // override possible jwtDecode error
      return Promise.reject()
    }
  },
  logout: () => {
    localStorage.clear()
    return Promise.resolve()
  },
  getIdentity: () => {
    return Promise.resolve({
      id: localStorage.getItem('user_id'),
      fullName: localStorage.getItem('display_name'),
    })
  },
  // authorization
  getPermissions: () => {
    const role = localStorage.getItem('permissions')
    return role ? Promise.resolve(role) : Promise.reject()
  },
}

export const authProvider = uiAuthProvider

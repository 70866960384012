import React from 'react'
import { Button, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { PhoneInTalkRounded } from '@mui/icons-material'

const DialButton = ({
  number,
  goToEditPage,
  originId,
  addMarginTop = false,
  removeReplyTagFunc = null,
}) => {
  const navigate = useNavigate()
  const editLink = '/quote-requests/%2Fapi%2Fquote-requests%2F' + originId

  //remove all blank spaces to show number altogether as stated in UI
  if (number !== null) {
    number = number.replace(/ /g, '')
  }

  const handleDialNumberButtonClick = async () => {
    if (removeReplyTagFunc) {
      await removeReplyTagFunc()
    }

    window.location = 'tel:' + number + '&accountcode=' + originId
    if (goToEditPage) {
      navigate(editLink, {
        replace: true,
        state: {
          contactLogScreen: 'add-contact-log',
        },
      })
    }
  }

  return (
    <React.Fragment>
      <Tooltip title="Call lead" position="right">
        <Button
          onClick={() => handleDialNumberButtonClick()}
          color="primary"
          style={{
            whiteSpace: 'nowrap',
            backgroundColor: '#F7FC03',
            color: '#454D5E',
            fontSize: '0.6rem',
          }}
        >
          <PhoneInTalkRounded />
        </Button>
      </Tooltip>
    </React.Fragment>
  )
}

export default DialButton

DialButton.propTypes = {
  number: PropTypes.string,
  goToEditPage: PropTypes.bool,
  originId: PropTypes.number,
  addMarginTop: PropTypes.bool,
  removeReplyTagFunc: PropTypes.func,
}

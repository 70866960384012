import React from 'react'
import { WithRecord, useRecordContext } from 'react-admin'
import DialButton from '../../QuoteRequest/Modals/DialButton'
import { removeLeadReplyTagFromInboxNotificationList } from '../../../Utils/QuoteRequestUtils'

const InboxPhoneContact = () => {
  const record = useRecordContext()
  const hasNumber = record.inboxTelNumber

  const handleRemoveTagReply = async () => {
    await removeLeadReplyTagFromInboxNotificationList(record)
  }

  return (
    <React.Fragment>
      {hasNumber ? (
        <WithRecord
          label="Tel"
          render={(record) => {
            if (record.inUseBy && record.inUseBy !== '') {
              return <></>
            }
            return (
              <DialButton
                number={record.inboxTelNumber}
                goToEditPage={true}
                originId={record.inboxQuote.id}
                addMarginTop={false}
                removeReplyTagFunc={handleRemoveTagReply}
              />
            )
          }}
        />
      ) : null}
    </React.Fragment>
  )
}

export default InboxPhoneContact

import DuplicateLeadDialog from './DuplicateLeads/DuplicateLeadDialog'
import React, { useEffect, useState } from 'react'
import { useRecordContext } from 'react-admin'
import { Button } from '@mui/material'

const DuplicateLeadButton = () => {
  const record = useRecordContext()
  const [duplicates, setDuplicates] = useState([])
  const [duplicateQuotes, setDuplicateQuotes] = useState([])
  const [localError, setLocalError] = useState(false)
  const [open, setOpen] = useState(false)
  const [fetched, setFetched] = useState(false)
  const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT
  const params = new URLSearchParams({ quoteID: record.id })

  const request = new Request(
    `${entrypoint}/services/quote/duplicate-check?${params}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  const handleOpen = () => setOpen(true)

  useEffect(() => {
    if (!fetched) {
      fetch(request)
        .then((response) => response.json())
        .then((responseJSON) => {
          if (responseJSON.Error !== undefined) {
            setLocalError(responseJSON.Error)
            setFetched(true)
            throw Error(responseJSON.Error)
          }

          if (
            !duplicates ||
            duplicates.length === 0 ||
            JSON.stringify(responseJSON.duplicatesInfo) !==
              JSON.stringify(duplicateQuotes)
          ) {
            setDuplicateQuotes(responseJSON.duplicatesInfo)
            setDuplicates(responseJSON.duplicates)
            setFetched(true)
          }
        })
    }
  }, [fetched])

  let buttonOutput = null

  if (duplicates && duplicates.length === 1) {
    buttonOutput = (
      <Button variant="contained" onClick={handleOpen} fullWidth>
        No Duplicate Leads
      </Button>
    )
  } else if (record && record.primaryLead === true) {
    buttonOutput = (
      <Button
        variant="contained"
        color="success"
        onClick={handleOpen}
        fullWidth
      >
        Primary Lead
      </Button>
    )
  } else if (duplicates && duplicates.length > 1) {
    buttonOutput = (
      <Button variant="contained" color="error" onClick={handleOpen} fullWidth>
        Duplicate Leads
      </Button>
    )
  }

  if (localError) {
    buttonOutput = (
      <Button fullWidth disabled={true} color="error">
        ERROR
      </Button>
    )
  }

  return (
    <React.Fragment>
      {buttonOutput ? (
        buttonOutput
      ) : (
        <Button variant="contained" onClick={handleOpen} fullWidth>
          Loading&hellip;
        </Button>
      )}
      {duplicateQuotes && (
        <DuplicateLeadDialog
          open={open}
          duplicateQuotes={duplicateQuotes}
          duplicates={duplicates}
          setOpen={setOpen}
          setFetchedDuplicates={setFetched}
        />
      )}
    </React.Fragment>
  )
}

export default DuplicateLeadButton

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const CapList = ({ capData }) => {
  return (
    <React.Fragment>
      <Typography
        variant="h1"
        sx={{
          fontSize: '16px',
          fontWeight: '700',
          textTransform: 'uppercase',
          lineHeight: '2',
          marginTop: '1rem',
        }}
      >
        Cap Lookup
      </Typography>
      <TableContainer className="view-agents__blue-table">
        <Table stripe="odd" stickyHeader hoverRow borderAxis="xBetween">
          <TableHead>
            <TableRow>
              <TableCell align="left">Ruleset ID</TableCell>
              <TableCell align="left">Ruleset Name</TableCell>
              <TableCell align="left">Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {capData['list_of_rulesets'].map((rule) => (
              <TableRow key={rule[0].id}>
                <TableCell align="left">{rule[0].id}</TableCell>
                <TableCell align="left">{rule[0].name}</TableCell>
                <TableCell align="left">
                  {rule[0].note && rule[0].note.length > 0
                    ? rule[0].note
                    : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default CapList

CapList.propTypes = {
  capData: PropTypes.array,
}

import React, { useState } from 'react'
import { Grid, TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'

const PasswordInputsBuilder = ({ userDetails, handleInputChange }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField
          type={showPassword ? 'text' : 'password'}
          name="password"
          label="Password"
          value={userDetails.password}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="edit-user-modal__password-toggle"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          type={showRepeatPassword ? 'text' : 'password'}
          name="repeatPassword"
          label="Repeat Password"
          value={userDetails.repeatPassword}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowRepeatPassword((prev) => !prev)}
                  className="edit-user-modal__password-toggle"
                >
                  {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </React.Fragment>
  )
}

PasswordInputsBuilder.propTypes = {
  userDetails: PropTypes.object,
  handleInputChange: PropTypes.func,
}

export default PasswordInputsBuilder

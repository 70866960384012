import * as React from 'react'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { getLatestContactTime } from '../../../../Utils/QuoteRequestUtils'
import { useRecordContext } from 'react-admin'
import { useState } from 'react'
import { useEffect } from 'react'

export default function DateTimePickerValue() {
  const record = useRecordContext()
  const [latestContact, setLatestContact] = useState(null)

  const getLatestContact = async () => {
    let response = await getLatestContactTime(record.id, 2)
    setLatestContact(response.latestContact)
  }

  useEffect(() => {
    getLatestContact()
  }, [record])

  return (
    <React.Fragment>
      {latestContact && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            readOnly={true}
            inputFormat="DD/MM/YYYY HH:mm:ss"
            label="Latest contact"
            value={dayjs(latestContact.date)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )}
    </React.Fragment>
  )
}

DateTimePickerValue.propTypes = {
  datetime: PropTypes.any,
}

import React from 'react'
import moment from 'moment'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

const CurrentStatus = ({ currentLeadStatus }) => {
  return (
    <React.Fragment>
      <Typography
        sx={{
          background: 'transparent',
          color: '#ED9C23',
          lineHeight: '26px',
          letterSpacing: '0.46 px',
          marginRight: '5px',
          fontWeight: '700',
        }}
      >
        Status:
      </Typography>
      {(currentLeadStatus === null || currentLeadStatus.slug === 'custom') && (
        <Typography
          sx={{
            background: 'transparent',
            fontWeight: '400',
            lineHeight: '26px',
            letterSpacing: '0.46 px',
          }}
        >
          Normal
        </Typography>
      )}

      {currentLeadStatus !== null &&
        (currentLeadStatus.slug === 'action_required' ||
          currentLeadStatus.slug === 'on_hold') && (
          <React.Fragment>
            <Typography
              sx={{
                background: 'transparent',
                fontWeight: '700',
                letterSpacing: '0.46 px',
                textTransform: 'uppercase',
              }}
            >
              {currentLeadStatus.name}:
            </Typography>
            <Typography
              sx={{
                background: 'transparent',
                fontWeight: '700',
                letterSpacing: '0.46 px',
                marginRight: 1,
                marginLeft: 1,
              }}
            >
              Last Attempt:
            </Typography>
            <Typography
              sx={{
                background: 'transparent',
                fontWeight: '400',
                letterSpacing: '0.46 px',
              }}
            >
              {moment(currentLeadStatus.updated_at).format('DD/MM/YYYY HH:mm')}
            </Typography>
          </React.Fragment>
        )}
    </React.Fragment>
  )
}

CurrentStatus.propTypes = {
  currentLeadStatus: PropTypes.any,
}

export default CurrentStatus

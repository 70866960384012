import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import { validateAutoSaveSingleInput } from '../../../../Utils/AutoSave'
import {
  RadioButtonGroupInput,
  useNotify,
  useRecordContext,
  useRefresh,
  Form,
} from 'react-admin'
import { getCharities, getPricingNotes } from '../../../../Utils/RestUtils'
import { blockInvalidCharFromNumberInput } from '../../../../Utils/ConversionUtils'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

const AssignedToPanel = (props) => {
  const record = useRecordContext()
  const [charities, setCharities] = useState()
  const [callActive, setCallActive] = useState()
  const notify = useNotify()
  const refresh = useRefresh()

  useEffect(() => {
    if (!charities && !callActive) {
      const getCharitiesList = async () => {
        setCallActive(!callActive)
        return await getCharities()
      }
      getCharitiesList().then((result) => {
        setCharities(result)
      })
    }
  }, [])

  const [openModal, setOpenModal] = useState(false)
  const [reason, setReason] = useState('')
  const handleAssignedToChange = async (e) => {
    //check here.
    let notes = await getPricingNotes(record.id)
    if (e.target.value !== 'cars') {
      let matches = notes.matches
      for (let i = 0; i < matches.length; i++) {
        if (notes.matches[i].collection_agent_name === 'SHORE CARS') {
          setOpenModal(true)
          setReason(notes.matches[i].rule_set_note)
        }
      }
    }

    props.setAssignedTo(e.target.value)
    await validateAutoSaveSingleInput(record, e.target, notify, refresh)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setReason('')
  }

  return (
    <React.Fragment>
      <Form>
        <Grid
          style={{
            display: 'grid',
            gridColumn: '1 / span 12',
            gridRowStart: 1,
            gridRowEnd: 1,
          }}
        >
          <Grid
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 2fr)',
              gridRowStart: 1,
              gridRowEnd: 1,
              marginRight: '1rem',
              marginBottom: '1rem',
            }}
          >
            <Grid
              style={{
                display: 'grid',
                gridColumn: '1',
                gridRowStart: 1,
                gridRowEnd: 1,
                marginRight: '1rem',
                marginBottom: '1rem',
              }}
            >
              <Typography className="collection-panel__title">
                Assigned To
              </Typography>
              <RadioButtonGroupInput
                source="leadContact.vehicle.vehicleSalvage.assignedTo"
                label="Assigned To"
                row
                onChange={handleAssignedToChange}
                choices={[
                  { id: 'salvage', name: 'BMS Salvage' },
                  { id: 'cars', name: 'Shore Cars' },
                ]}
              />
              <Dialog
                onClose={() => setOpenModal(false)}
                open={openModal}
                className="view-agents__modal"
              >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                  This car has been flagged as a good fit for Shore Cars for the
                  following reason:
                  <br />
                  <br />
                  <strong>{reason}</strong>
                  <br />
                  <br />
                  Please book in with Shore Cars unless a manager says
                  otherwise.
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleCloseModal}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid
              style={{
                display: 'grid',
                gridColumn: '2',
                gridRowStart: 1,
                gridRowEnd: 1,
                marginRight: '1rem',
                marginBottom: '1rem',
              }}
            >
              <TextField
                disabled={true}
                aria-readonly={true}
                name="leadContact.fullName"
                label="Customer Name"
                defaultValue={record.leadContact.fullName}
                value={record.leadContact.fullName}
                sx={{
                  marginBottom: '-0.7rem !important',
                  marginRight: '1.4rem !important',
                  marginTop: '25px',
                }}
              />
            </Grid>
            <Grid
              style={{
                display: 'grid',
                gridColumn: '3',
                gridRowStart: 1,
                gridRowEnd: 1,
                marginRight: '1rem',
                marginBottom: '1rem',
              }}
            >
              <TextField
                disabled={true}
                aria-readonly={true}
                name="leadContact.vehicle.registration"
                label="Vehicle Reg"
                defaultValue={record.leadContact.vehicle.registration}
                sx={{
                  marginBottom: '-0.7rem !important',
                  marginRight: '1.4rem !important',
                  marginTop: '25px',
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          style={{
            display: 'grid',
            gridColumn: '1 / span 12',
            gridRowStart: 1,
            gridRowEnd: 1,
          }}
        >
          <Grid
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 2fr)',
              gridRowStart: 1,
              gridRowEnd: 1,
              marginRight: '1rem',
              marginBottom: '1rem',
            }}
          >
            <Grid
              style={{
                display: 'grid',
                gridColumn: '1',
                gridRowStart: 1,
                gridRowEnd: 1,
                marginRight: '1rem',
                marginBottom: '1rem',
              }}
            >
              <TextField
                disabled={true}
                aria-readonly={true}
                name="leadContact.vehicle.make"
                label="Vehicle Make"
                defaultValue={record.leadContact.vehicle.make}
                sx={{
                  marginBottom: '-0.7rem !important',
                  marginRight: '1.4rem !important',
                }}
              />
            </Grid>
            <Grid
              style={{
                display: 'grid',
                gridColumn: '2',
                gridRowStart: 1,
                gridRowEnd: 1,
                marginRight: '1rem',
                marginBottom: '1rem',
              }}
            >
              <TextField
                disabled={true}
                aria-readonly={true}
                name="leadContact.vehicle.model"
                label="Vehicle Model"
                defaultValue={record.leadContact.vehicle.model}
                sx={{
                  marginBottom: '-0.7rem !important',
                  marginRight: '1.4rem !important',
                }}
              />
            </Grid>
            <Grid
              style={{
                display: 'grid',
                gridColumn: '3',
                gridRowStart: 1,
                gridRowEnd: 1,
                marginRight: '1rem',
                marginBottom: '1rem',
              }}
            >
              <TextField
                disabled={true}
                aria-readonly={true}
                name="leadContact.vehicle.vehicleInfo.colour"
                label="Colour"
                defaultValue={
                  record.leadContact.vehicle.vehicleInfo &&
                  record.leadContact.vehicle.vehicleInfo.colour
                }
                sx={{
                  marginBottom: '-0.7rem !important',
                  marginRight: '1.4rem !important',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: 'grid',
            gridColumn: '1 / span 12',
            gridRowStart: 1,
            gridRowEnd: 1,
          }}
        >
          <Grid
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 2fr)',
              gridRowStart: 1,
              gridRowEnd: 1,
              marginRight: '1rem',
              marginBottom: '1rem',
            }}
          >
            <Grid
              style={{
                display: 'grid',
                gridColumn: '1',
                gridRowStart: 1,
                gridRowEnd: 1,
                marginRight: '1rem',
                marginBottom: '1rem',
              }}
            >
              <TextField
                disabled={true}
                aria-readonly={true}
                label="Chosen value £"
                type={'number'}
                inputProps={{
                  step: 0.01,
                  min: 0,
                }}
                defaultValue={
                  record.leadContact.vehicle.vehicleSalvage &&
                  record.leadContact.vehicle.vehicleSalvage.chosenValue
                }
                value={
                  record.leadContact.vehicle.vehicleSalvage &&
                  record.leadContact.vehicle.vehicleSalvage.chosenValue
                }
              />
            </Grid>
            <Grid
              style={{
                display: 'grid',
                gridColumn: '2',
                gridRowStart: 1,
                gridRowEnd: 1,
                marginRight: '1rem',
                marginBottom: '1rem',
              }}
            >
              <TextField
                name="donationAmount"
                label="Charity donation £"
                type={'number'}
                inputProps={{
                  step: 0.01,
                  min: 0,
                }}
                onKeyDown={blockInvalidCharFromNumberInput}
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh,
                    'Donation amount'
                  )
                }
                defaultValue={record.donationAmount}
              />
            </Grid>
            <Grid
              style={{
                display: 'grid',
                gridColumn: '3',
                gridRowStart: 1,
                gridRowEnd: 1,
                marginRight: '1rem',
                marginBottom: '1rem',
              }}
            >
              <FormControl sx={{ width: '100%' }} size="small">
                <TextField
                  label="Charity Name"
                  name="donationReceiver"
                  select
                  defaultValue={
                    record.donationReceiver &&
                    record.donationReceiver['@id'] !== null
                      ? record.donationReceiver['@id']
                      : ''
                  }
                  onChange={async (e) =>
                    await validateAutoSaveSingleInput(
                      record,
                      e.target,
                      notify,
                      refresh,
                      'Selected Charity'
                    )
                  }
                >
                  <MenuItem disabled value="default">
                    Charity name
                  </MenuItem>
                  {charities &&
                    charities.map((charity) => {
                      return (
                        <MenuItem key={charity.id} value={charity.id}>
                          {charity.name}
                        </MenuItem>
                      )
                    })}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </React.Fragment>
  )
}

AssignedToPanel.propTypes = {
  assignedTo: PropTypes.any,
  setAssignedTo: PropTypes.func,
}

export default AssignedToPanel

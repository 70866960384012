import { List, Table, TableCell, TableContainer, TableRow } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useNotify, useRecordContext } from 'react-admin'
import { blue } from '../../../../Theme/colors'
import { dateConverter } from '../../../../Utils/ConversionUtils'
import {
  deleteLeadNotifications,
  getLeadNotifications,
  saveLeadNotifications,
} from '../../../../Utils/RestUtils'
import './styles/index.scss'
import { useGetIdentity } from 'ra-core'
import { sendLog } from '../../../../Class/AuditLog'

const AdminNotesPanel = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const [adminNotes, setAdminNotes] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const newAdminNoteRef = useRef()
  const updateNoteRef = useRef()
  const { identity, isLoading: identityLoading } = useGetIdentity()

  const saveNewAdminNote = async () => {
    if (newAdminNoteRef.current.value.length <= 0) {
      return
    }

    const response = await saveLeadNotifications({
      quoteRequestId: record.originId,
      payload: newAdminNoteRef.current.value,
      noteType: 3,
    })

    if (response.result === 'success') {
      //reload notes
      getAdminNotesFromApi()
      document.getElementById('new-note').value = ''
    } else {
      notify(response.error_msg, { type: 'error' })
    }
  }

  const getAdminNotesFromApi = async () => {
    const result = await getLeadNotifications(record.originId, 3)

    const newDataArray = result.map((row) => {
      const { date, hour } = dateConverter(row.targetTimestamp)

      return {
        ...row,
        date: date,
        hour: hour,
      }
    })

    setAdminNotes(() => newDataArray)
  }

  const handleButtonClick = () => {
    saveNewAdminNote()
  }

  const deleteAdminNotes = async (note) => {
    const response = await deleteLeadNotifications(note.id)
    if (response.result === 'success') {
      //reload notes
      sendLog(
        record.originId,
        identity.fullName + ' deleted note: ' + note.payload
      )
      getAdminNotesFromApi()
    } else {
      notify(response.error_msg, { type: 'error' })
    }
  }

  const updateAdminNote = async (note) => {
    const payload = updateNoteRef.current.value

    const deleteResponse = await deleteLeadNotifications(note.id)
    if (deleteResponse.result !== 'success') {
      notify(deleteResponse.error_msg, { type: 'error' })
    }

    const updateResponse = await saveLeadNotifications({
      quoteRequestId: record.originId,
      payload: payload,
      noteType: 3,
    })

    if (updateResponse.result === 'success') {
      //reload notes
      getAdminNotesFromApi()
      sendLog(
        record.originId,
        identity.fullName +
          ' changed note from: ' +
          note.payload +
          ' -> ' +
          payload
      )
      document.getElementById('new-note').value = ''
      setShowDialog(false)
    } else {
      notify(updateResponse.error_msg, { type: 'error' })
    }
  }

  const handleDeleteNote = (note) => {
    deleteAdminNotes(note)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleEditClick = () => {
    setShowDialog(true)
  }

  const handleUpdateNote = (note) => {
    updateAdminNote(note)
  }

  useEffect(() => {
    if (adminNotes === null) {
      getAdminNotesFromApi()
    }
  }, [])

  const NotesList = () => {
    return (
      <React.Fragment>
        {adminNotes &&
          adminNotes.map((note) => {
            return (
              <TableRow key={note.id}>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    color: blue[900],
                  }}
                >
                  {note.date} {note.timestamp}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {note.admin.displayName}
                </TableCell>
                <TableCell
                  sx={{
                    textTransform: 'uppercase',
                    maxWidth: '700px',
                    wordBreak: 'break-word',
                  }}
                >
                  {note.payload}
                </TableCell>
              </TableRow>
            )
          })}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <List
        sx={{
          padding: '15px',
          border: '1.5px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
          boxShadow: 'none',
          background: 'none',
          marginBottom: '1rem',
        }}
      >
        <TableContainer sx={{ maxHeight: '220px', overflow: 'scroll' }}>
          <Table size="small" stickyHeader>
            <NotesList />
          </Table>
        </TableContainer>
      </List>
    </React.Fragment>
  )
}

export default AdminNotesPanel

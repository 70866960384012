import { MenuItem, TextField } from '@mui/material'
import {} from '../../Utils/RestUtils'
import PropTypes from 'prop-types'
import { DEFAULT_USER_ROLES } from '../../Utils/ConstantsUtils'

const RolesListDropdown = ({ currentUser, handleInputChange }) => {
  const currentUserRoles =
    typeof currentUser.roles === 'string'
      ? currentUser.roles
      : currentUser.roles[0]

  return (
    <TextField
      name="roles"
      label="roles"
      select
      onChange={handleInputChange}
      value={currentUserRoles}
    >
      {currentUserRoles === 'selectRole' && (
        <MenuItem key={0} value="selectRole" disabled>
          Please select a role
        </MenuItem>
      )}

      {DEFAULT_USER_ROLES.map((role) => {
        return (
          <MenuItem key={role.id} value={role.id}>
            {role.name}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

RolesListDropdown.propTypes = {
  currentUser: PropTypes.object,
  handleInputChange: PropTypes.func,
}

export default RolesListDropdown

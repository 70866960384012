import { Datagrid, EditButton, TextField } from 'react-admin'
import { ListLive } from '@react-admin/ra-realtime'

const ListAllSettings = (props) => {
  return (
    <ListLive {...props} title="Settings">
      <Datagrid>
        <TextField label="Name" source="name" />
        <TextField label="Value" source="value" />
        <EditButton {...props} />
      </Datagrid>
    </ListLive>
  )
}

export default ListAllSettings

import { Stack } from '@mui/material'
import Tags from './Tags'

const TagPanel = (props) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      pacing={1}
      sx={{
        padding: '10px 0 20px',
        width: '100%',
      }}
    >
      <Tags className="tags" source="tags" {...props} />
    </Stack>
  )
}

export default TagPanel

import { Edit, TextInput, SimpleForm, BooleanInput } from 'react-admin'

const EditCharity = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          label="Charity Title"
          source="charityTitle"
          fullWidth
          required
        />
        <BooleanInput source="showInDropdown" label="Show in Dropdown?" />
        <BooleanInput source="visible" label="Visible?" />
      </SimpleForm>
    </Edit>
  )
}

export default EditCharity

import { useState } from 'react'
import { Button, Grid } from '@mui/material'
import './VehicleAdjustments.scss'
import DamageSelector from '../QuoteRequest/DamageSelector'
import PropTypes from 'prop-types'
import Adjustment from './Adjustment'
import { CancelOutlined } from '@mui/icons-material'

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Grid hidden={value !== index} {...other}>
      {value === index && children}
    </Grid>
  )
}

const VehicleAdjustments = ({ props, adjustments, adjustmentsSettings }) => {
  const [damageOpen, setDamageOpen] = useState(0)

  const sortedAdjustments = []

  adjustments.forEach((vehicleAdjustment) => {
    const matchedAdjustmentSettings = adjustmentsSettings.find((setting) => {
      return setting['@id'] === vehicleAdjustment.adjustment
    })

    const adjustmentIdentifier =
      matchedAdjustmentSettings.adjustmentIdentifier === 'damage'
        ? 'damage'
        : 'general'
    const identifierIndex = sortedAdjustments.findIndex(
      (el) => el.type === adjustmentIdentifier
    )

    if (identifierIndex === -1) {
      sortedAdjustments.push({
        type: adjustmentIdentifier ?? 'general',
        adjustments: [
          {
            adjustment: vehicleAdjustment,
            adjustmentSettings: matchedAdjustmentSettings,
          },
        ],
      })
    } else {
      sortedAdjustments[identifierIndex].adjustments[
        matchedAdjustmentSettings.adjustmentName
      ] = {
        adjustment: vehicleAdjustment,
        adjustmentSettings: matchedAdjustmentSettings,
      }
    }
  })

  const damageSelector = sortedAdjustments.map((adjustmentType, parentIndex) =>
    adjustmentType.type === 'damage' ? (
      <DamageSelector props key={adjustmentType.type + parentIndex} />
    ) : (
      ''
    )
  )

  const toggleDamage = () => {
    if (!damageOpen) {
      setDamageOpen(true)
    } else {
      setDamageOpen(false)
    }
  }

  sortedAdjustments[0].adjustments[
    sortedAdjustments[0].adjustments[0].adjustmentSettings.adjustmentName
  ] = {
    adjustmentSettings: sortedAdjustments[0].adjustments[0].adjustmentSettings,
    adjustment: sortedAdjustments[0].adjustments[0].adjustment,
  }

  return (
    <Grid item xs={12}>
      <Grid
        container
        className="vehicle-adjustments__general"
        rowSpacing={1}
        columnSpacing={2}
      >
        <Grid item>
          <Adjustment
            adjustment={
              sortedAdjustments[0].adjustments['Engine Condition'].adjustment
            }
            adjustmentSettings={
              sortedAdjustments[0].adjustments['Engine Condition']
                .adjustmentSettings
            }
            key={'vehicle-general' + 1}
          />
        </Grid>
        <Grid item>
          <Adjustment
            adjustment={
              sortedAdjustments[0].adjustments['Dismantled'].adjustment
            }
            adjustmentSettings={
              sortedAdjustments[0].adjustments['Dismantled'].adjustmentSettings
            }
            key={'vehicle-general' + 2}
          />
        </Grid>
        <Grid item>
          <Adjustment
            adjustment={sortedAdjustments[0].adjustments['Gear Box'].adjustment}
            adjustmentSettings={
              sortedAdjustments[0].adjustments['Gear Box'].adjustmentSettings
            }
            key={'vehicle-general' + 3}
          />
        </Grid>
        <Grid item>
          <Adjustment
            adjustment={
              sortedAdjustments[0].adjustments['Insurance'].adjustment
            }
            adjustmentSettings={
              sortedAdjustments[0].adjustments['Insurance'].adjustmentSettings
            }
            key={'vehicle-general' + 4}
          />
        </Grid>
        <Grid item>
          <Adjustment
            adjustment={sortedAdjustments[0].adjustments['Repaired'].adjustment}
            adjustmentSettings={
              sortedAdjustments[0].adjustments['Repaired'].adjustmentSettings
            }
            key={'vehicle-general' + 5}
          />
        </Grid>
        <Grid item>
          <Adjustment
            adjustment={
              sortedAdjustments[0].adjustments['Crash Damaged'].adjustment
            }
            adjustmentSettings={
              sortedAdjustments[0].adjustments['Crash Damaged']
                .adjustmentSettings
            }
            key={'vehicle-general' + 6}
          />
        </Grid>
        <Grid
          className="vehicle-adjustments__lights"
          item
          style={{
            display: 'flex',
            alignItems: 'end',
          }}
        >
          <Adjustment
            adjustment={
              sortedAdjustments[0].adjustments['EML Light'].adjustment
            }
            adjustmentSettings={
              sortedAdjustments[0].adjustments['EML Light'].adjustmentSettings
            }
            key={'vehicle-general' + 7}
          />
          <Adjustment
            adjustment={
              sortedAdjustments[0].adjustments['Airbag Light'].adjustment
            }
            adjustmentSettings={
              sortedAdjustments[0].adjustments['Airbag Light']
                .adjustmentSettings
            }
            key={'vehicle-general' + 8}
          />
          <Adjustment
            adjustment={
              sortedAdjustments[0].adjustments['ABS Light'].adjustment
            }
            adjustmentSettings={
              sortedAdjustments[0].adjustments['ABS Light'].adjustmentSettings
            }
            key={'vehicle-general' + 9}
          />
          <Adjustment
            adjustment={
              sortedAdjustments[0].adjustments['Other Light'].adjustment
            }
            adjustmentSettings={
              sortedAdjustments[0].adjustments['Other Light'].adjustmentSettings
            }
            key={'vehicle-general' + 10}
          />
        </Grid>
        <Grid item style={{ width: '100%' }}></Grid>
        <Grid item>
          <label>Scratches/ Dents</label>
          <br />
          <Button className="open-damage" onClick={toggleDamage}>
            Open damage report
          </Button>
        </Grid>
        <Grid item style={{ width: '100%' }}></Grid>
        <Grid item>
          <Adjustment
            adjustment={sortedAdjustments[0].adjustments['Interior'].adjustment}
            adjustmentSettings={
              sortedAdjustments[0].adjustments['Interior'].adjustmentSettings
            }
            key={1}
          />
        </Grid>
        <Grid item>
          <Adjustment
            adjustment={sortedAdjustments[0].adjustments['Leather'].adjustment}
            adjustmentSettings={
              sortedAdjustments[0].adjustments['Leather'].adjustmentSettings
            }
            key={2}
          />
        </Grid>
        <Grid item>
          <Adjustment
            adjustment={sortedAdjustments[0].adjustments['SAT Nav'].adjustment}
            adjustmentSettings={
              sortedAdjustments[0].adjustments['SAT Nav'].adjustmentSettings
            }
            key={3}
          />
        </Grid>
        <Grid item>
          <Adjustment
            adjustment={sortedAdjustments[0].adjustments['Keys'].adjustment}
            adjustmentSettings={
              sortedAdjustments[0].adjustments['Keys'].adjustmentSettings
            }
            key={4}
          />
        </Grid>
        <Grid item>
          <Adjustment
            adjustment={sortedAdjustments[0].adjustments['History'].adjustment}
            adjustmentSettings={
              sortedAdjustments[0].adjustments['History'].adjustmentSettings
            }
            key={5}
          />
        </Grid>
        <Grid
          className="vehicle-adjustments__faults"
          item
          style={{ width: '100%' }}
        >
          <Grid
            container
            spacing={0}
            display="grid"
            style={{
              gridTemplateColumns: 'repeat(6, 1fr)',
              marginTop: '1rem',
            }}
          >
            <Grid item>
              <Adjustment
                adjustment={
                  sortedAdjustments[0].adjustments['MOT Fault'].adjustment
                }
                adjustmentSettings={
                  sortedAdjustments[0].adjustments['MOT Fault']
                    .adjustmentSettings
                }
                key={13}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className="vehicle-adjustments__faults"
          item
          style={{ width: '100%' }}
        >
          <Grid
            container
            spacing={0}
            display="grid"
            style={{
              gridTemplateColumns: 'repeat(6, 1fr)',
              marginTop: '1rem',
            }}
          >
            <Grid item>
              <Adjustment
                adjustment={
                  sortedAdjustments[0].adjustments['Steering Fault'].adjustment
                }
                adjustmentSettings={
                  sortedAdjustments[0].adjustments['Steering Fault']
                    .adjustmentSettings
                }
                key={6}
              />
            </Grid>
            <Grid item>
              <Adjustment
                adjustment={
                  sortedAdjustments[0].adjustments['Wiper Fault'].adjustment
                }
                adjustmentSettings={
                  sortedAdjustments[0].adjustments['Wiper Fault']
                    .adjustmentSettings
                }
                key={7}
              />
            </Grid>
            <Grid item>
              <Adjustment
                adjustment={
                  sortedAdjustments[0].adjustments['Brake Fault'].adjustment
                }
                adjustmentSettings={
                  sortedAdjustments[0].adjustments['Brake Fault']
                    .adjustmentSettings
                }
                key={8}
              />
            </Grid>
            <Grid item>
              <Adjustment
                adjustment={
                  sortedAdjustments[0].adjustments['Door Fault'].adjustment
                }
                adjustmentSettings={
                  sortedAdjustments[0].adjustments['Door Fault']
                    .adjustmentSettings
                }
                key={9}
              />
            </Grid>
            <Grid item>
              <Adjustment
                adjustment={
                  sortedAdjustments[0].adjustments['Window Fault'].adjustment
                }
                adjustmentSettings={
                  sortedAdjustments[0].adjustments['Window Fault']
                    .adjustmentSettings
                }
                key={10}
              />
            </Grid>
            <Grid item>
              <Adjustment
                adjustment={
                  sortedAdjustments[0].adjustments['Exhaust Fault'].adjustment
                }
                adjustmentSettings={
                  sortedAdjustments[0].adjustments['Exhaust Fault']
                    .adjustmentSettings
                }
                key={11}
              />
            </Grid>
            <Grid item>
              <Adjustment
                adjustment={
                  sortedAdjustments[0].adjustments['Convertible Fault']
                    .adjustment
                }
                adjustmentSettings={
                  sortedAdjustments[0].adjustments['Convertible Fault']
                    .adjustmentSettings
                }
                key={12}
              />
            </Grid>
            <Grid item>
              <Adjustment
                adjustment={
                  sortedAdjustments[0].adjustments['Blower Fault'].adjustment
                }
                adjustmentSettings={
                  sortedAdjustments[0].adjustments['Blower Fault']
                    .adjustmentSettings
                }
                key={14}
              />
            </Grid>
            <Grid item>
              <Adjustment
                adjustment={
                  sortedAdjustments[0].adjustments['Water Leak'].adjustment
                }
                adjustmentSettings={
                  sortedAdjustments[0].adjustments['Water Leak']
                    .adjustmentSettings
                }
                key={15}
              />
            </Grid>
            <Grid item>
              <Adjustment
                adjustment={
                  sortedAdjustments[0].adjustments['Airbag Fault'].adjustment
                }
                adjustmentSettings={
                  sortedAdjustments[0].adjustments['Airbag Fault']
                    .adjustmentSettings
                }
                key={16}
              />
            </Grid>
            <Grid item>
              <Adjustment
                adjustment={
                  sortedAdjustments[0].adjustments['Major Interior fault']
                    .adjustment
                }
                adjustmentSettings={
                  sortedAdjustments[0].adjustments['Major Interior fault']
                    .adjustmentSettings
                }
                key={17}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={`vehicle-adjustments__damage ${damageOpen ? 'open' : ''}`}
      >
        <hr />
        <Grid
          item
          sx={{
            width: '100%',
            textAlign: 'right',
          }}
        >
          <Button
            onClick={toggleDamage}
            endIcon={<CancelOutlined />}
            variant="text"
          >
            Close
          </Button>
        </Grid>
        {damageSelector}
      </Grid>
    </Grid>
  )
}

TabPanel.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
}
VehicleAdjustments.propTypes = {
  props: PropTypes.any,
  adjustments: PropTypes.any,
  adjustmentsSettings: PropTypes.any,
}

export default VehicleAdjustments

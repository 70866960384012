import PropTypes, { array, element, oneOfType } from 'prop-types'
import { Button } from '@mui/material'

const CsvExport = ({ data, fileName }) => {
  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
    let str = 'Registration,Chosen value,Postcode\r\n'

    for (let i = 0; i < array.length; i++) {
      let line = ''
      for (let index in array[i]) {
        if (line !== '') line += ','

        line +=
          array[i][index] !== null && array[i][index] !== undefined
            ? array[i][index]
            : 'N/A'
      }
      str += line + '\r\n'
    }
    return str
  }

  const downloadCSV = () => {
    const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' })
    const csvURL = URL.createObjectURL(csvData)
    const link = document.createElement('a')
    link.href = csvURL
    link.download = `${fileName}.csv`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return <Button onClick={downloadCSV}>Export</Button>
}

CsvExport.propTypes = {
  data: oneOfType([element, array]),
  fileName: PropTypes.string,
}

export default CsvExport

const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT

const getUptoPrice = async (quoteId) => {
  const request = new Request(`${entrypoint}/services/pricing/upto-price`, {
    body: JSON.stringify({
      quoteId: quoteId,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const calculateAdjustmentPrice = async (quoteId, mode, adjustmentValue) => {
  const request = new Request(
    `${entrypoint}/services/quote/calculate-high-adjustment-values`,
    {
      body: JSON.stringify({
        quoteId: quoteId,
        mode: mode,
        adjustmentValue: adjustmentValue,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}
export { getUptoPrice, calculateAdjustmentPrice }

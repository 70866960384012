import React from 'react'
import { Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import moment from 'moment'

const DepositRefundUnrecoverable = ({ payment }) => {
  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          sx={{
            color: '#FF0B08',
            fontWeight: '700',
            letterSpacing: '0.46px',
            marginRight: '5px',
            textTransform: 'uppercase',
          }}
        >
          Refund Unrecoverable:
        </Typography>
        <Typography
          sx={{
            fontWeight: '700',
            letterSpacing: '0.46px',
            textTransform: 'capitalize',
          }}
        >
          {new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
          }).format(payment.amount)}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 0.5 }}>
        <Typography
          sx={{
            color: '#FF0B08',
            fontWeight: '400',
            letterSpacing: '0.46px',
            marginRight: '5px',
            textTransform: 'uppercase',
          }}
        >
          {moment(payment.refund_status.updated_at).format('DD/MM/YYYY HH:mm')}
        </Typography>
      </Grid>
    </React.Fragment>
  )
}

DepositRefundUnrecoverable.propTypes = {
  payment: PropTypes.object,
}

export default DepositRefundUnrecoverable

import { createElement } from 'react'
import {
  DashboardMenuItem,
  Menu,
  MenuItemLink,
  useResourceDefinitions,
} from 'react-admin'
import PropTypes from 'prop-types'
import SubMenu from './Menu/SubMenu'
import { Divider } from '@mui/material'
import { checkIfUserHasExternalRole } from '../Utils/HelperUtils'
import { useGetIdentity } from 'ra-core'
import { Add, Notifications } from '@mui/icons-material'
import { useSearchParams } from 'react-router-dom'

const CustomMenu = (props) => {
  const resources = useResourceDefinitions()
  let isExternal = checkIfUserHasExternalRole()
  const { identity, isLoading: identityLoading } = useGetIdentity()
  let menuItems = {}
  let createLink = '?'
  let reg = ''
  let number = ''
  let dialled = ''
  const [params] = useSearchParams()
  reg = params.get('registration')
  number = params.get('number')
  dialled = params.get('dialled')

  if (reg) {
    createLink += 'registration=' + reg
  }

  if (number != '') {
    createLink += '&number=' + number
  }

  if (dialled != '') {
    createLink += '&dialled=' + dialled
  }

  Object.keys(resources).map((name) => {
    if (resources[name].options.showMenu !== false) {
      let menu = 'main'

      if (resources[name].options.parent !== undefined) {
        menu = resources[name].options.parent
      }

      if (!menuItems.hasOwnProperty(menu)) {
        menuItems[menu] = []
      }

      let filter = '?'

      if (!identityLoading) {
        if (name === 'quotes') {
          filter +=
            'filter={"archived": false, "status": "New inquiry", "tagName": "SALVAGE"}'
        }

        if (name === 'inbox') {
          filter +=
            'filter={"isDropped": false, "inboxActive": true, "inboxUser":"' +
            identity.id +
            '"}'
        }

        menuItems[menu].push(
          <MenuItemLink
            key={name}
            resource={name}
            to={`/${name}${filter}`}
            primaryText={
              (resources[name].options && resources[name].options.label) || name
            }
            leftIcon={
              resources[name].icon ? createElement(resources[name].icon) : <></>
            }
            onClick={props.onMenuClick}
          />
        )
      }
    }
  })

  return (
    <Menu {...props} hasDashboard={true} sx={{ w: 100 }}>
      <DashboardMenuItem />
      <Menu.Item
        to={'/quote-requests/create' + createLink}
        primaryText="Create new lead"
        leftIcon={<Add />}
      />
      {isExternal && !identityLoading && (
        <Menu.Item
          to={`/inbox?filter={"isDropped": false, "inboxActive": true, "inboxUser":"${identity.id}"}`}
          primaryText="My Notifications"
          leftIcon={<Notifications />}
        />
      )}
      {!isExternal &&
        Object.entries(menuItems).map(([key, value], i) => {
          if (key === 'main') {
            return [menuItems[key], <Divider key={Math.random() * 100} />]
          }
          if (key !== 'main' && key !== 'end') {
            return [
              <SubMenu
                key={Math.random() * 100}
                defaultOpen={false}
                label={key}
              >
                {menuItems[key]}
              </SubMenu>,
              <Divider key={Math.random() * 100} />,
            ]
          }
          if (key === 'end') {
            return menuItems[key]
          }
        })}
    </Menu>
  )
}

CustomMenu.propTypes = {
  onMenuClick: PropTypes.func,
}

export default CustomMenu

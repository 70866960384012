import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Card, CardContent, CardHeader, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useRecordContext } from 'react-admin'
import { getAgentAssignedToLeadByOfferHistory } from '../../../../Utils/QuoteRequestUtils'
import { TermsAndConditions } from '../../index'
import AssignedToPanel from '../AssignedToPanel'
import { PaymentDetailsPanel, PriceTypePanel } from '../Panels'
import VehicleNotesPanel from '../VehicleNotesPanel'
import ScrapBookingPanel from './Scrap/scrap'
import './style/index.scss'
import { blueGrey } from '@mui/material/colors'

const BookInPanel = ({ props, notes, saveNewVehicleNote, setNotes }) => {
  const record = useRecordContext()
  const [openBookingPanel, setOpenBookingPanel] = useState(false)
  const [viewBookingPanel, setViewBookingPanel] = useState(false)
  const [assignedTo, setAssignedTo] = useState()
  const isSalvage = record.leadContact.vehicle.tooGoodToScrap

  useEffect(() => {
    if (isSalvage) {
      setOpenBookingPanel(true)
      setViewBookingPanel(true)
    } else {
      const offer = getAgentAssignedToLeadByOfferHistory(record.offerHistory)
      if (offer !== undefined) {
        setOpenBookingPanel(true)
        setViewBookingPanel(true)
      } else {
        setOpenBookingPanel(false)
        setViewBookingPanel(false)
      }
    }
    if (record.leadContact.vehicle.vehicleSalvage) {
      setAssignedTo(record.leadContact.vehicle.vehicleSalvage.assignedTo)
    }
  }, [record])

  return (
    <React.Fragment>
      <Grid
        style={{
          gridColumn: '1 / span 6',
          gridRowStart: 13,
          gridRowEnd: 13,
        }}
      >
        <Button
          variant="contained"
          sx={{
            boxSizing: 'border-box',
            width: '100%',
            lineHeight: '70px',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '18px',
            letterSpacing: '0.15px',
            color: 'primary',
          }}
          onClick={() => setOpenBookingPanel(!openBookingPanel)}
        >
          Book in vehicle
          {openBookingPanel ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Grid>
      {isSalvage !== 0 && openBookingPanel && (
        <React.Fragment>
          <Grid
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 2fr)',
              gridColumn: '1 / span 6',
              gridColumnGap: 6,
              gridRowGap: 1,
              gridRowStart: 13,
              gridRowEnd: 13,
            }}
          >
            <Grid style={{ gridColumn: 1, gridRowStart: 7, gridRowEnd: 7 }}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <AssignedToPanel
                    assignedTo={setAssignedTo}
                    setAssignedTo={setAssignedTo}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid style={{ gridColumn: 2, gridRowStart: 7, gridRowEnd: 7 }}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <CardHeader
                    title="Vehicle Notes"
                    sx={{
                      padding: '10px',
                      marginBottom: '1rem',
                    }}
                    titleTypographyProps={{
                      sx: {
                        marginBottom: '-15px',
                        textTransform: 'uppercase',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: blueGrey,
                      },
                    }}
                  />
                  <VehicleNotesPanel
                    notes={notes}
                    saveNewVehicleNote={saveNewVehicleNote}
                    editable={false}
                    setNotes={setNotes}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 2fr)',
              gridColumn: '1 / span 2',
              gridRowStart: 14,
              gridRowEnd: 14,
              gap: 6,
              marginTop: '0.5rem',
              gridTemplateAreas: `"pricetype terms" 
              "payment terms"`,
            }}
          >
            <Grid style={{ gridArea: 'pricetype' }}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <PriceTypePanel />
                </CardContent>
              </Card>
            </Grid>
            <Grid style={{ gridArea: 'payment' }}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <PaymentDetailsPanel />
                </CardContent>
              </Card>
            </Grid>
            <Grid style={{ gridArea: 'terms' }}>
              <Card
                className={
                  record.leadContact.vehicle.vehicleSalvage && !assignedTo
                    ? 'disable-panel'
                    : ''
                }
              >
                <TermsAndConditions assignedTo={assignedTo} />
              </Card>
            </Grid>
          </Grid>
        </React.Fragment>
      )}

      {isSalvage !== 1 && openBookingPanel && (
        <Grid id="scrapbookingsPanel" container>
          <Grid
            id="bookingsPanel"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(1, 2fr)',
              gridColumn: 1,
              gridRowStart: 14,
              gridRowEnd: 14,
              maxWidth: '50%',
              width: '100%',
            }}
          >
            <Grid style={{ gridColumn: 1, gridRowStart: 1, gridRowEnd: 1 }}>
              <ScrapBookingPanel />
            </Grid>
          </Grid>
          <Grid
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(1, 2fr)',
              gridColumn: 2,
              gridRowStart: 14,
              gridRowEnd: 14,
              maxWidth: '50%',
            }}
          >
            <Grid
              style={{
                gridColumn: 1,
                gridRowStart: 1,
                gridRowEnd: 1,
              }}
              className={!viewBookingPanel ? 'disable-panel' : ''}
            >
              <TermsAndConditions />
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

BookInPanel.propTypes = {
  props: PropTypes.any,
  notes: PropTypes.object,
  saveNewVehicleNote: PropTypes.func,
  setNotes: PropTypes.func,
}

export default BookInPanel

import { Card, CardContent } from '@mui/material'
import { useEffect, useState } from 'react'
import { SalvageTerms, scrap_terms, ShoreTerms } from './content/index'
import PropTypes from 'prop-types'
import { useRecordContext } from 'react-admin'
import { getSalvageCancellationFee } from '../../../Utils/CancellationFeeUtils'

const Terms = ({ setTermsRead, assignedTo }) => {
  let record = useRecordContext()
  const [termsAndConditions, setTermsAndConditions] = useState(scrap_terms)

  const updateTerms = async (assignedAgent) => {
    const feeRequest = await getSalvageCancellationFee(record.originId)

    let cancellationFee = '(UNKNOWN - please check with line manager)'

    if (feeRequest.success === true) {
      cancellationFee = feeRequest.fee
    }

    let terms = scrap_terms

    if (record.leadContact.vehicle.tooGoodToScrap) {
      let registration = record.leadContact.vehicle.registration

      if (record.leadContact.vehicle.vehicleInfo.additionalRegistration) {
        registration =
          record.leadContact.vehicle.registration +
          ' / ' +
          record.leadContact.vehicle.vehicleInfo.additionalRegistration +
          ' (*Private plate)'
      }

      if (assignedAgent === 'cars') {
        terms = ShoreTerms(cancellationFee)
      } else if (assignedAgent === 'salvage') {
        terms = SalvageTerms(
          cancellationFee,
          registration ?? '(VEHICLE REGISTRATION)',
          record.leadContact.vehicle.vehicleSalvage.chosenValue ??
            '(AGREED PRICE)',
          record.leadContact.fullName
            ? record.leadContact.fullName.toUpperCase()
            : '(CUSTOMER FULL NAME)',
          record.leadContact.vehicle.make +
            ' ' +
            record.leadContact.vehicle.model ?? '(MAKE MODEL)'
        )
      }
    }

    setTermsAndConditions(terms)
  }

  useEffect(() => {
    if (
      record.leadContact.vehicle.tooGoodToScrap &&
      record.leadContact.vehicle.assignedTo !== assignedTo
    ) {
      updateTerms(assignedTo)
    }
  }, [record, assignedTo])

  const handleScrollEvent = (e) => {
    const tgt = e.target
    const currentValue = tgt.scrollHeight - tgt.clientHeight - tgt.scrollTop
    // Have removed this as I think this is the cause of "Can't complete booking issue" - need to look into this.
    setTermsRead(true)
  }

  return (
    <Card sx={{ backgroundColor: 'white', color: '#111' }}>
      <CardContent
        sx={{
          maxHeight: '400px',
          overflow: 'auto',
        }}
        onScroll={(e) => handleScrollEvent(e)}
      >
        {termsAndConditions}
      </CardContent>
    </Card>
  )
}

Terms.propTypes = {
  terms: PropTypes.string,
  setTermsRead: PropTypes.func,
  assignedTo: PropTypes.any,
}

export { Terms }

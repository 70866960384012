import { Grid } from '@mui/material'
import PropTypes from 'prop-types'

const CustomSBMessage = ({ message }) => {
  return (
    <Grid
      container
      spacing={3}
      style={{
        display: 'flex',
        justifyContent: 'center',
        gridColumn: '1 / span 6',
        gridRowStart: 4,
        gridRowEnd: 4,
        position: 'relative',
      }}
      sx={{
        marginTop: '0 !important',
        marginLeft: '0 !important',
        padding: '30px',
        width: '100%',
        background: '#2B2B2B',
        border: '2px solid #FFF',
        fontWeight: 700,
      }}
    >
      {message}
    </Grid>
  )
}

CustomSBMessage.propTypes = {
  message: PropTypes.any,
}

export default CustomSBMessage

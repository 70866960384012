import {
  TextInput,
  SimpleForm,
  PasswordInput,
  BooleanInput,
  SelectArrayInput,
  Create,
} from 'react-admin'
import { DEFAULT_USER_ROLES } from '../../Utils/ConstantsUtils'

const passwordValidate = (values) => {
  const errors = {}

  if (values.password !== values.passwordConfirmation) {
    errors.password = 'Passwords do not match'
  }

  return errors
}

const CreateUser = (props) => {
  return (
    <Create {...props} redirect="list">
      <SimpleForm validate={passwordValidate}>
        <TextInput
          label="Display name"
          source="displayName"
          fullWidth
          required
        />
        <TextInput label="Email" source="email" fullWidth required />
        <PasswordInput
          label="Password"
          id="password"
          name="password"
          source=""
          fullWidth
          required
        />
        <PasswordInput
          label="Repeat password"
          id="passwordConfirmation"
          name="passwordConfirmation"
          source=""
          fullWidth
          required
        />
        <SelectArrayInput
          label="Roles"
          source="roles"
          choices={DEFAULT_USER_ROLES}
          required
        />
        <BooleanInput
          source="disabled"
          name="disabled"
          label="User account disabled?"
        />
        <BooleanInput
          source="highValueUser"
          label="Include in high value leads?"
          name="highValueUser"
        />
      </SimpleForm>
    </Create>
  )
}

export default CreateUser

const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT

const getMitelCallDataByQuoteId = async (quoteId) => {
  const request = new Request(
    `${entrypoint}/services/mitel/view-by-quote?quoteId=${quoteId}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    }
  )

  return fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

export { getMitelCallDataByQuoteId }

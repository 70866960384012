import React from 'react'
import { Grid } from '@mui/material'
import LotDetails from './LotDetails'
import Reserve from './Reserve'
import PropTypes from 'prop-types'

const DetailsPanel = ({ sb, setPopupPanelName }) => {
  return (
    <React.Fragment>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: 'center',
          display: 'flex',
          paddingLeft: '0 !important',
          margin: '0 !important',
        }}
      >
        <LotDetails sb={sb} />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Reserve sb={sb} setPopupPanelName={setPopupPanelName} />
      </Grid>
    </React.Fragment>
  )
}

DetailsPanel.propTypes = {
  sb: PropTypes.object,
  setPopupPanelName: PropTypes.func,
}

export default DetailsPanel

import React from 'react'
import { Grid } from '@mui/material'
import BalancePayment from './BalancePayment'
import DepositPayment from './DepositPayment'
import PartialPayment from './PartialPayment'
import FinancePayment from './FinancePayment'
import PropTypes from 'prop-types'

const PaymentPanel = ({ sb, setPopupPanelName, setPopupPanelProps }) => {
  return (
    <React.Fragment>
      <Grid
        item
        xs={3}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          paddingLeft: '0 !important',
        }}
      >
        <DepositPayment sb={sb} />
      </Grid>

      <Grid
        item
        xs={3}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
        }}
      >
        <PartialPayment sb={sb} />
      </Grid>

      <Grid
        item
        xs={3}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
        }}
      >
        <FinancePayment sb={sb} />
      </Grid>

      <Grid
        item
        xs={3}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
        }}
      >
        <BalancePayment
          sb={sb}
          setPopupPanelName={setPopupPanelName}
          setPopupPanelProps={setPopupPanelProps}
        />
      </Grid>
    </React.Fragment>
  )
}

PaymentPanel.propTypes = {
  sb: PropTypes.object,
  setPopupPanelName: PropTypes.func,
  setPopupPanelProps: PropTypes.func,
}

export default PaymentPanel

const placeholders = {
  '{{SITE_PHONE_NUMBER}}': '01243000000',
  '{{SITE_NAME}}': 'Scrap Car Comparison',
  '{{SITE_BASE_URL}}': 'https://www.scrapcarcomparison.co.uk/',
  '{{QUOTE_URL}}': 'https://www.scrap.pro/1023/',
  '{{HAVE_HIGHER_OFFER_URL}}': 'https://www.scrap.pro/higher-offer/',
  '{{$FRONTEND_API_WEBSITE}}': 'Scrap Car Comparison',
  '{{$FRONTEND_API_NAME}}': 'Scrap Car Comparison',
  '{{OFFER_ID}}': '0002341',
  '{{OFFER}}': '&pound;1200.00',
  '{{QUOTE_GUARANTEED_PRICE}}': '&pound;1200.00',
  '{{COLLECTION_AGENT_COMPANY_NAME}}': 'Copart',
  '{{CUSTOMER_NAME}}': 'Bea Potter',
  '{{COLLECTION_AGENT_PHONE_NUMBER}}': '0800-CO-PART',
  '{{DATE}}': '01/01/2022',
  '{{TIME_FROM}}': '08:00',
  '{{TIME_TO}}': '09:45',
  '{{BUYER_LOGIN}}': 'https://www.systemquotes.co.uk/buyer-login/',
  '{{EXTEND_FOR_24H}}': 'https://www.systemquotes.co.uk/extend/',
}

export default placeholders

import { useState, useEffect } from 'react'
import { getShoreCarsReporting } from '../../Utils/RestUtils'
import {
  Alert,
  Box,
  Card,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  CardContent,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CsvExport from '../../Components/Export/CsvExport'

function ListReport() {
  const [reportResponse, setReportResponse] = useState(null)
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
  )
  const [toDate, setToDate] = useState(new Date())

  async function loadReportData() {
    setReportResponse(await getShoreCarsReporting(fromDate, toDate))
  }

  useEffect(() => {
    loadReportData()
  }, [])

  async function handleFromDateChange(selectedDate) {
    if (!selectedDate.isValid()) return
    setFromDate(selectedDate.toDate())

    setReportResponse(
      await getShoreCarsReporting(selectedDate.toDate(), toDate)
    )
  }

  async function handleToDateChange(selectedDate) {
    if (!selectedDate.isValid()) return
    setToDate(selectedDate.toDate())

    setReportResponse(
      await getShoreCarsReporting(fromDate, selectedDate.toDate())
    )
  }

  const OwedFeesList = () => {
    if (reportResponse.reportingData.length === 0) {
      return (
        <Alert icon={false} severity="error">
          No results found.
        </Alert>
      )
    }

    return (
      <div>
        <Box style={{ display: 'flex', marginTop: '10px' }}>
          <Card style={{ flex: '1 1 auto' }}>
            <Table sx={{ padding: 2 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Registration
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Chosen value
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Postcode</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(reportResponse.reportingData).map((key) => (
                  <TableRow key={key}>
                    <TableCell sx={{ padding: 2 }}>
                      {reportResponse.reportingData[key].registration}
                    </TableCell>
                    <TableCell>
                      {reportResponse.reportingData[key].chosenValue ?? 'N/A'}
                    </TableCell>
                    <TableCell>
                      {reportResponse.reportingData[key].postcode}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </Box>
      </div>
    )
  }

  return (
    <CardContent>
      <Typography typography="h4" component="h1" mb={2}>
        Reporting
      </Typography>
      <Typography typography="h5" component="h1" mb={2}>
        Please select a date
      </Typography>
      {reportResponse != null && (
        <Box
          sx={{
            display: 'flex',
            marginTop: '10px',
            marginBottom: '10px',
            gap: '1rem',
          }}
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                inputFormat="DD/MM/YYYY"
                label="From"
                value={dayjs(fromDate)}
                onChange={handleFromDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>

          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                inputFormat="DD/MM/YYYY"
                minDate={fromDate}
                maxDate={
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                  )
                }
                label="From"
                value={dayjs(toDate)}
                onChange={handleToDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
          {reportResponse.reportingData.length !== 0 && (
            <Box>
              <CsvExport
                data={reportResponse.reportingData}
                fileName="report"
              />
            </Box>
          )}
        </Box>
      )}

      {reportResponse != null ? <OwedFeesList /> : <p>Loading...</p>}
    </CardContent>
  )
}

export default ListReport

import React from 'react'
import { Grid } from '@mui/material'
import AddFee from './AddFee'
import CurrentFees from './CurrentFees'
import PropTypes from 'prop-types'

const FeesPanel = ({ sb }) => {
  return (
    <React.Fragment>
      <Grid
        item
        xs={3}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          paddingLeft: '0 !important',
        }}
      >
        <AddFee sb={sb} />
      </Grid>

      <CurrentFees sb={sb} />
    </React.Fragment>
  )
}

FeesPanel.propTypes = {
  sb: PropTypes.object,
}

export default FeesPanel

import React from 'react'
import { Grid } from '@mui/material'
import CurrentStatus from './CurrentStatus'
import SetOnHoldButton from './SetOnHoldButton'
import SetNoContactButtons from './SetNoContactButtons'
import ClearStatusButton from './ClearStatusButton'
import PropTypes from 'prop-types'

const StatusPanel = ({ sb }) => {
  let currentOnHoldLeadStatus = null
  let currentNoContactLeadStatus = null

  if (sb) {
    sb.lead_statuses.forEach((stat) => {
      if (stat.slug === 'on_hold') {
        currentOnHoldLeadStatus = stat
      }
      if (stat.slug === 'action_required') {
        currentNoContactLeadStatus = stat
      }
    })
  }

  return (
    <React.Fragment>
      <Grid
        item
        xs={4}
        sx={{
          alignItems: 'center',
          display: 'flex',
          paddingLeft: '0 !important',
        }}
      >
        {currentOnHoldLeadStatus || currentNoContactLeadStatus ? (
          <React.Fragment>
            {currentOnHoldLeadStatus && (
              <CurrentStatus currentLeadStatus={currentOnHoldLeadStatus} />
            )}
            {currentNoContactLeadStatus && (
              <CurrentStatus currentLeadStatus={currentNoContactLeadStatus} />
            )}
          </React.Fragment>
        ) : (
          <CurrentStatus currentLeadStatus={null} />
        )}
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          alignItems: 'center',
          display: 'flex',
          paddingLeft: '16px !important',
        }}
      >
        <SetOnHoldButton sb={sb} />
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <SetNoContactButtons
          sb={sb}
          currentLeadStatus={currentNoContactLeadStatus}
        />
      </Grid>
      {currentNoContactLeadStatus &&
        currentNoContactLeadStatus.slug === 'action_required' && (
          <Grid
            item
            xs={2}
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <ClearStatusButton
              sb={sb}
              status={currentNoContactLeadStatus}
              buttonText="Clear No Contact"
            />
          </Grid>
        )}
    </React.Fragment>
  )
}

StatusPanel.propTypes = {
  sb: PropTypes.object,
}

export default StatusPanel

import React from 'react'
import { Grid } from '@mui/material'
import EmptyCustomStatus from './EmptyCustomStatus'
import PropTypes from 'prop-types'
import Quarantine from './Quarantine'
import CancelBooking from './CancelBooking'
import FilledCustomStatus from './FilledCustomStatus'

const CustomStatusPanel = ({ sb, cancelled = false }) => {
  let customStatus = null
  if (sb) {
    sb.lead_statuses.forEach((stat) => {
      if (stat.slug == 'custom') {
        customStatus = stat
        return
      }
    })
  }

  return (
    <React.Fragment>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: 'center',
          display: 'flex',
          paddingLeft: '0 !important',
        }}
      >
        {customStatus == null ? (
          <EmptyCustomStatus sb={sb} />
        ) : (
          <FilledCustomStatus sb={sb} customStatus={customStatus} />
        )}
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Quarantine sb={sb} />
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {sb && !cancelled && <CancelBooking sb={sb} />}
      </Grid>
    </React.Fragment>
  )
}

CustomStatusPanel.propTypes = {
  sb: PropTypes.object,
  cancelled: PropTypes.bool,
}

export default CustomStatusPanel

import { useRef, useState } from 'react'

import {
  Button,
  Datagrid,
  FunctionField,
  List,
  TextField,
  TextInput,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'

import { Add } from '@mui/icons-material'

import { addContactLog } from '../../Utils/QuoteRequestUtils'

import { ButtonGroup, Grid } from '@mui/material'
import ReactTimeAgo from 'react-time-ago'
import PropTypes from 'prop-types'

function AdminNotes() {
  const record = useRecordContext()
  const quoteID = useRef(record.id)
  const notify = useNotify()
  const refresh = useRefresh()
  const { isLoading } = useListContext()
  const logsProps = {
    resource: 'lead-notifications',
  }

  let [note, setNote] = useState('')

  const handleSubmit = async (values) => {
    let request = {
      payload: note,
      type: 3,
      quoteId: quoteID.current,
      user: window.localStorage.getItem('email'),
    }
    const result = await addContactLog(request)

    if (result.status === 'success') {
      refresh()
    } else {
      notify(result.error, { type: 'error' })
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <List
          {...logsProps}
          actions={null}
          aside={
            <Grid item xs={3} ml={2}>
              <ButtonGroup
                orientation="vertical"
                variant="outlined"
                disableElevation={true}
                fullWidth
              >
                <TextInput
                  label="Note"
                  source="payload"
                  size="large"
                  // required  removed as its not validating and causing QR edit page to submit
                  multiline
                  fullWidth
                  onChange={(e) => setNote(e.target.value)}
                />
                <Button
                  onClick={handleSubmit}
                  fullWidth
                  size="large"
                  label="Add Note"
                >
                  <Add />
                </Button>
              </ButtonGroup>
            </Grid>
          }
          filter={{ 'quoteRequest.id': record.id, noteType: 3 }}
          sort={{ field: 'targetTimestamp', order: 'ASC' }}
          title=" "
          emptyWhileLoading={true}
        >
          <Datagrid bulkActionButtons={false} isLoading={isLoading}>
            <TextField source="admin.displayName" label="Made by" />
            <TextField source="payload" label="Note" />
            <FunctionField
              label="When"
              render={(record) => <TimeAgo record={record} />}
            />
            <TextField source="targetTimestamp" label="" size="large" />
          </Datagrid>
        </List>
      </Grid>
    </Grid>
  )
}

export default AdminNotes

const TimeAgo = (props) => {
  const { record } = props
  const date = new Date(record.targetTimestamp)

  return record && <ReactTimeAgo date={date} locale="en-GB" />
}

TimeAgo.propTypes = {
  record: PropTypes.object,
}

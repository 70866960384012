import React from 'react'
import { Grid, Stack, Switch, Typography } from '@mui/material'
import {
  Form,
  FormDataConsumer,
  RadioButtonGroupInput,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { validateAutoSaveSingleInput } from '../../../../Utils/AutoSave'
import { updateTooGoodToScrapTags } from '../../../../Utils/RestUtils'
import {
  blockInvalidCharFromNumberInput,
  numberInputOnWheelPreventChange,
} from '../../../../Utils/ConversionUtils'
import './style/index.scss'

const CollectionPanel = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const vehicleDetails = record.leadContact.vehicle

  const handleTooGoodToScrap = async (e) => {
    await Promise.all([
      validateAutoSaveSingleInput(record, e.target, notify, refresh),
      updateTooGoodToScrapTags({
        quoteRequestId: record['@id'],
        tooGoodToScrap: record.leadContact.vehicle.tooGoodToScrap,
      }),
    ]).then(() => {
      setTimeout(() => {
        refresh()
      }, 100)
    })
  }

  const motLeftParse = (value) => {
    if (value > 12) {
      return 12
    }

    return value
  }

  return (
    <Form>
      <Grid
        container
        direction="row"
        flexWrap="wrap"
        columns={8}
        rows={2}
        spacing={2}
        className="collection-panel"
      >
        <Grid item xs={2}>
          <Typography className="collection-panel__title">Salvage?</Typography>
          <RadioButtonGroupInput
            source="leadContact.vehicle.tooGoodToScrap"
            label="Salvage?"
            onChange={async (e) =>
              await validateAutoSaveSingleInput(
                record,
                e.target,
                notify,
                refresh
              )
            }
            color="good"
            choices={[
              { id: 0, name: 'No' },
              { id: 1, name: 'Yes' },
            ]}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className="collection-panel__title">
            Alloy Wheels?
          </Typography>
          <RadioButtonGroupInput
            source="leadContact.vehicle.vehicleInfo.alloyWheels"
            label="Alloy Wheels?"
            onChange={async (e) =>
              await validateAutoSaveSingleInput(
                record,
                e.target,
                notify,
                refresh
              )
            }
            color="good"
            choices={[
              { id: 1, name: 'Yes' },
              { id: 2, name: 'No' },
              { id: 3, name: 'Unsure' },
            ]}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className="collection-panel__title">
            V5 Present?
          </Typography>
          <RadioButtonGroupInput
            source="leadContact.vehicle.vehicleDocumentation.v5"
            label="V5 Present?"
            onChange={async (e) =>
              await validateAutoSaveSingleInput(
                record,
                e.target,
                notify,
                refresh
              )
            }
            color="good"
            choices={[
              { id: false, name: 'No' },
              { id: true, name: 'Yes' },
            ]}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className="collection-panel__title">
            HGV Access?
          </Typography>
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <RadioButtonGroupInput
                source="leadContact.vehicle.vehicleInfo.hgvAccess"
                label="Salvage?"
                onChange={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
                color="good"
                choices={[
                  { id: false, name: 'No' },
                  { id: true, name: 'Yes' },
                ]}
              />
            </div>
          </React.Fragment>
        </Grid>
        <Grid item xs={2}>
          <Typography className="collection-panel__title">
            Does it roll?
          </Typography>
          <RadioButtonGroupInput
            source="leadContact.vehicle.vehicleCondition.roll"
            label="Does it roll?"
            onChange={async (e) =>
              await validateAutoSaveSingleInput(
                record,
                e.target,
                notify,
                refresh
              )
            }
            color="good"
            choices={[
              { id: false, name: 'No' },
              { id: true, name: 'Yes' },
            ]}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className="collection-panel__title">
            Parts missing?
          </Typography>
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <RadioButtonGroupInput
                source="leadContact.vehicle.vehicleCondition.partsRemoved"
                label="Parts missing?"
                onChange={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
                color="good"
                choices={[
                  { id: false, name: 'No' },
                  { id: true, name: 'Yes' },
                ]}
              />
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.leadContact.vehicle.vehicleCondition &&
                  formData.leadContact.vehicle.vehicleCondition.partsRemoved &&
                  formData.leadContact.vehicle.vehicleCondition.partsRemoved !==
                    'false' && (
                    <React.Fragment>
                      <TextInput
                        label="Parts removed details"
                        source="leadContact.vehicle.vehicleCondition.partsRemovedDetails"
                        name="leadContact.vehicle.vehicleCondition.partsRemovedDetails"
                        onChange={async (e) =>
                          await validateAutoSaveSingleInput(
                            record,
                            e.target,
                            notify,
                            refresh
                          )
                        }
                        multiline
                      />
                    </React.Fragment>
                  )
                }
              </FormDataConsumer>
            </div>
          </React.Fragment>
        </Grid>
        <Grid item xs={2}>
          <Typography className="collection-panel__title">
            MOT Remaining?
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography>No</Typography>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <Switch
                  name="motRemaining"
                  label="Yes"
                  style={{ alignItems: 'space-between' }}
                  checked={
                    formData.leadContact.vehicle.vehicleDocumentation &&
                    formData.leadContact.vehicle.vehicleDocumentation.motLeft &&
                    formData.leadContact.vehicle.vehicleDocumentation.motLeft >
                      0
                  }
                />
              )}
            </FormDataConsumer>
            <Typography>Yes</Typography>
            <TextInput
              source="leadContact.vehicle.vehicleDocumentation.motLeft"
              label="Months"
              type={'number'}
              parse={motLeftParse}
              onKeyDown={blockInvalidCharFromNumberInput}
              onWheel={numberInputOnWheelPreventChange}
              defaultValue={
                vehicleDetails.vehicleDocumentation &&
                vehicleDetails.vehicleDocumentation.motLeft
              }
              sx={{
                marginBottom: '-0.7rem !important',
                maxWidth: '80px',
                marginLeft: '1rem !important',
              }}
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh,
                  'MOT Remaining'
                )
              }
            />
          </Stack>
        </Grid>
      </Grid>
    </Form>
  )
}

export default CollectionPanel

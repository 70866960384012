import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import { Button, Tooltip } from '@mui/material'
import { ArrowDownward } from '@mui/icons-material'
import { muteInbox } from '../../../Utils/RestUtils'

const MuteInboxButton = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()

  const handleMuteClick = async (v) => {
    const result = await muteInbox(v)
    if (result.status === true) {
      refresh()
    } else {
      notify('Failed to mute', { type: 'error' })
    }
  }

  return (
    <Button onClick={() => handleMuteClick(record.originId)}>
      <Tooltip title="Drop down">
        <ArrowDownward />
      </Tooltip>
    </Button>
  )
}

export default MuteInboxButton

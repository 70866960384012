import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const SalvageExclusionList = ({ salvageExclusionData }) => {
  return (
    <React.Fragment>
      <Typography
        variant="h1"
        sx={{
          fontSize: '16px',
          fontWeight: '700',
          textTransform: 'uppercase',
          lineHeight: '2',
          marginTop: '1rem',
        }}
      >
        Salvage Exclusion
      </Typography>
      <TableContainer className="view-agents__blue-table">
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" className="uppercase yellow">
                Ruleset ID
              </TableCell>
              <TableCell align="left" className="">
                Ruleset name
              </TableCell>
              <TableCell align="left">Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salvageExclusionData['list_of_rulesets'].map((rule) => (
              <TableRow key={rule[0].id}>
                <TableCell align="left">{rule[0].id}</TableCell>
                <TableCell align="left">{rule[0].rule_set_name}</TableCell>
                <TableCell align="left">
                  {rule[0].rule_set_note && rule[0].rule_set_note.length > 0
                    ? rule[0].note
                    : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default SalvageExclusionList

SalvageExclusionList.propTypes = {
  salvageExclusionData: PropTypes.array,
}

import React from 'react'
import {
  Email,
  NotificationsActive,
  Public,
  Sms,
  Timer,
} from '@mui/icons-material'
import { useRecordContext } from 'react-admin'

const ContactMethod = () => {
  const record = useRecordContext()
  const smsIcon = record.inboxNotifySMS
  const emailIcon = record.inboxNotifyEmail
  const remindIcon = record.inboxNotifyReminder
  const otherIcon = record.inboxNotifyOther
  const webIcon = record.InboxNotifyWebsite

  return (
    <React.Fragment>
      {smsIcon ? <Sms /> : <Sms opacity="0.05" />}
      &nbsp;
      {emailIcon ? <Email /> : <Email opacity="0.05" />}
      &nbsp;
      {remindIcon ? <Timer /> : <Timer opacity="0.05" />}
      &nbsp;
      {otherIcon ? (
        <NotificationsActive />
      ) : (
        <NotificationsActive opacity="0.05" />
      )}
      &nbsp;
      {webIcon ? <Public /> : <Public opacity="0.05" />}
    </React.Fragment>
  )
}

export default ContactMethod

import { Chip, Stack } from '@mui/material'
import { useRecordContext } from 'react-admin'
import PropTypes from 'prop-types'

const Tags = ({ setOpen, setTag, setQuote }) => {
  const record = useRecordContext()
  const handleClick = (tag) => {
    setQuote(record.id)
    setTag(tag)
    setOpen(true)
  }
  return (
    <Stack direction="row" spacing={1}>
      {record.tags.map((tag, index) => (
        <Chip
          className="tags__chip"
          label={tag.tagName}
          color="primary"
          key={index}
          onDelete={() => handleClick(tag['@id'])}
        />
      ))}
    </Stack>
  )
}

Tags.propTypes = {
  setOpen: PropTypes.func,
  setQuote: PropTypes.func,
  setTag: PropTypes.func,
}

export default Tags

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import { useRecordContext } from 'react-admin'
import { dateConverter } from '../../../../Utils/ConversionUtils'

const formatDate = (dateFromApi) => {
  const { date, hour } = dateConverter(dateFromApi, false, false)

  return (
    <React.Fragment>
      {date} {hour}
    </React.Fragment>
  )
}

const QuoteHistoryList = () => {
  const record = useRecordContext()

  return (
    <React.Fragment>
      <Typography
        variant="h1"
        sx={{
          fontSize: '16px',
          fontWeight: '700',
          textTransform: 'uppercase',
          lineHeight: '2',
          marginTop: '1rem',
        }}
        className="view-agents__list-title"
      >
        Quote History
      </Typography>
      <TableContainer className="view-agents__red-table">
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" className="uppercase yellow">
                Date / Time
              </TableCell>
              <TableCell align="left" className="">
                Coll. Price
              </TableCell>
              <TableCell align="left"> Cust. Price</TableCell>
              <TableCell align="left"> Commission</TableCell>
              <TableCell align="left">Coll. Agent</TableCell>
              <TableCell align="left">Method</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.offerHistory.map((row, key) => (
              <TableRow key={key}>
                <TableCell>{formatDate(row.timeAdded)}</TableCell>
                <TableCell>£{row.collectorPrice.toFixed(2)}</TableCell>
                <TableCell>£{row.customerPrice.toFixed(2)}</TableCell>
                <TableCell>£{row.commission.toFixed(2)}</TableCell>
                <TableCell>
                  {row.collector && row.collector.collectionAgentCompanyName}
                </TableCell>
                <TableCell>{row.source}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default QuoteHistoryList

import { useNotify, useRecordContext } from 'react-admin'
import { Button } from '@mui/material'
import {
  payBalanceSalvageBookingsLead,
  redirectToSB,
  setReviewStatusSalvageBookingsLead,
} from '../../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'
import moment from 'moment/moment'

const PayBalanceButton = ({
  setPopupPanelName,
  chequePayeeName,
  setChequePayeeNameValidationError,
  setPopupPanelProps,
  sb,
  loading,
  setLoading,
}) => {
  const record = useRecordContext()
  const notify = useNotify()

  const handleButtonClick = async function () {
    if (
      record.leadContact.vehicle.vehicleSalvage.chequePayment &&
      !chequePayeeName
    ) {
      setChequePayeeNameValidationError("Please enter the cheque payee's name")
      return
    }

    if (
      moment().diff(moment(sb.collected_at), 'days') >= 14 ||
      sb.send_review_status !== null
    ) {
      if (!loading) {
        setLoading(true)
      }

      // If lead was collected more than 14 days ago, set review status to false
      if (moment().diff(moment(sb.collected_at), 'days') >= 14) {
        const setReviewStatusResponseStatus =
          await setReviewStatusSalvageBookingsLead(
            parseInt(record.originId),
            'false'
          )

        if (!setReviewStatusResponseStatus) {
          setLoading(false)
          notify('Something went wrong, please try again', { type: 'warning' })
          return
        }
      }

      // Post new payment to salvage bookings
      const payBalanceResponseStatus = await payBalanceSalvageBookingsLead(
        parseInt(record.originId),
        0,
        'balance',
        chequePayeeName ?? ''
      )
      setLoading(false)
      if (payBalanceResponseStatus) {
        redirectToSB(sb.quote_request_id)
      } else {
        notify('Something went wrong, please try again', { type: 'warning' })
      }
    } else {
      // Open review status popup panel
      if (chequePayeeName) {
        setPopupPanelProps({
          chequePayeeName: chequePayeeName,
        })
      }
      setPopupPanelName('customerHappy')
    }
  }

  return (
    <Button
      variant="contained"
      color="primary"
      label="Pay Balance"
      fullWidth
      disabled={loading}
      onClick={handleButtonClick}
      sx={{
        border: '2px solid #FFF !important',
        backgroundColor: '#13A310',
        color: '#FFF',
        height: '40px',
        fontWeight: '700',
        '&:hover': {
          backgroundColor: '#13A310',
        },
      }}
    >
      Pay Balance{' '}
      {record.leadContact.vehicle.vehicleSalvage.chequePayment && 'by cheque'}
    </Button>
  )
}

PayBalanceButton.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  sb: PropTypes.object,
  setPopupPanelName: PropTypes.func,
  chequePayeeName: PropTypes.string,
  setChequePayeeNameValidationError: PropTypes.func,
  setPopupPanelProps: PropTypes.func,
}

export default PayBalanceButton

import Grid from '@mui/material/Grid'
import { Button, Typography } from '@mui/material'
import { Datagrid, List, TextField, WithRecord } from 'react-admin'
import React from 'react'
import { LockOpen } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { handleLeadLocking } from '../../Utils/RestUtils'

const LockedLeads = (props) => {
  const unlockLead = async (leadId, method) => {
    await handleLeadLocking(method, leadId).then(() => {
      refresh()
    })
  }

  return (
    <Grid item xs={6}>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Current leads you are locked in
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List
            resource="quote-requests"
            actions={null}
            filter={{ 'inUse.id': props.userId }}
            perPage={10}
            pagination={false}
            fullWidth
            title=" "
          >
            <Datagrid
              bulkActionButtons={false}
              resource="quote-requests"
              variant="outline"
            >
              <TextField
                source="originId"
                sortable={false}
                label="Quote ID"
                sx={{ textTransform: 'uppercase' }}
              />
              <TextField
                source="leadContact.fullName"
                sortable={false}
                label="Customer Name"
                sx={{ textTransform: 'uppercase' }}
              />
              <TextField
                source="leadContact.postcode"
                sortable={false}
                label="Postcode"
                sx={{ textTransform: 'uppercase' }}
              />
              <TextField
                source="leadContact.vehicle.registration"
                sortable={false}
                label="Reg"
                sx={{ textTransform: 'uppercase' }}
              />
              <WithRecord
                render={(record) => {
                  return (
                    <React.Fragment>
                      <Button
                        label=""
                        startIcon={<LockOpen sx={{ mr: '-10px' }} />}
                        size="small"
                        onClick={() => {
                          unlockLead(record.originId, 'unlock')
                        }}
                        icon={null}
                      />
                    </React.Fragment>
                  )
                }}
              />
            </Datagrid>
          </List>
        </Grid>
      </Grid>
    </Grid>
  )
}

LockedLeads.propTypes = {
  userId: PropTypes.number,
}

export default LockedLeads

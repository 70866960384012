import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { getQuoteRequestList } from '../../../../Utils/RestUtils'
import { checkIfUserHasExternalRole } from '../../../../Utils/HelperUtils'

const getQuoteList = (tableRef, phoneNumber, registration) => {
  const dataFetchedRef = useRef(false)
  const hasUserExternalRole = checkIfUserHasExternalRole()
  const [data, setData] = useState(undefined)
  const [distanceBottom, setDistanceBottom] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [loadMoreData, setLoadMoreData] = useState(true)
  const [totalRecords, setTotalRecords] = useState(0)
  const [quoteListFilters, setQuoteListFilters] = useState({
    page: 1,
    itemsPerPage: 20,
    status: 'All Statuses',
    tag: 'All Tags',
    search: '',
    archive: false,
    historic: false,
    update: 0,
    tagSetOn: '',
    phoneNumber: phoneNumber || '',
    registration: registration || '',
  })

  const getQuotesToDisplay = async () => {
    setQuoteListFilters((prev) => {
      return {
        ...prev,
        page: prev.page + 1,
      }
    })

    const extraLink = Object.keys(quoteListFilters)
      .map((key) => key + '=' + quoteListFilters[key])
      .join('&')

    const response = await getQuoteRequestList(extraLink)
    setTotalRecords(response['totalRecords'])

    if (quoteListFilters.page === 1) {
      setLoadMoreData(true)
      setDistanceBottom(0)
      setData(response['data'])
    } else {
      setData((prev) => [...prev, ...response['data']])
    }

    if (
      response['data'].length <= 0 ||
      (response['data'].length > 0 &&
        response['data'].length < quoteListFilters.itemsPerPage)
    ) {
      //when no more data to load prevent on scroll down to call API
      setLoadMoreData(false)
    }

    setIsLoading(false)
  }

  const scrollListener = useCallback(() => {
    let bottom = tableRef.current.scrollHeight - tableRef.current.clientHeight
    if (!distanceBottom) {
      setDistanceBottom(Math.round((bottom / 100) * 10))
    }

    if (loadMoreData && tableRef.current.scrollTop > bottom - distanceBottom) {
      //prevent to load multiple times if user keeps scroll down while loading fresh data
      setQuoteListFilters((prev) => {
        return {
          ...prev,
          update: 1,
        }
      })
    }
  }, [distanceBottom, loadMoreData])

  useLayoutEffect(() => {
    tableRef.current.addEventListener('scroll', scrollListener)
    return () => {
      tableRef.current.removeEventListener('scroll', scrollListener)
    }
  }, [scrollListener])

  useEffect(() => {
    //Prevent React local dev strict mode to load / call API data twice
    if (dataFetchedRef.current && !data) return
    dataFetchedRef.current = true

    //allow to fetch more data
    setLoadMoreData(true)

    // Load more data if not currently loading
    // Also prevents if user keeps scroll down, only loads data once per time
    if (!isLoading) {
      setIsLoading(true)

      getQuotesToDisplay()
    }
  }, [quoteListFilters])

  return {
    data,
    quoteListFilters,
    setQuoteListFilters,
    totalRecords,
    isLoading,
  }
}

export { getQuoteList }

import React from 'react'
import { Grid, Typography } from '@mui/material'
import ClearStatusButton from '../StatusPanel/ClearStatusButton'
import moment from 'moment'
import PropTypes from 'prop-types'

const FilledCustomStatus = ({ customStatus, sb }) => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              background: 'transparent',
              lineHeight: '26px',
              letterSpacing: '0.46 px',
              color: '#ED9C23',
              fontWeight: '700',
            }}
          >
            Custom Status:
          </Typography>
          <Typography
            sx={{
              background: 'transparent',
              lineHeight: '26px',
              letterSpacing: '0.46 px',
              fontWeight: '400',
              marginLeft: 1,
            }}
          >
            {customStatus.name}{' '}
            {moment(customStatus.updated_at).format('DD/MM/YYYY HH:mm')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <ClearStatusButton
            sb={sb}
            status={customStatus}
            resetCustomStatusButton={true}
            buttonText="Clear Custom Status"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

FilledCustomStatus.propTypes = {
  customStatus: PropTypes.object,
  sb: PropTypes.object,
}

export default FilledCustomStatus

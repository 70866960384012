import React, { useState } from 'react'
import { CircularProgress } from '@mui/material'
import PayBalanceButton from './PayBalanceButton'
import ConvertPartialToBalanceButton from './ConvertPartialToBalanceButton'
import PropTypes from 'prop-types'

const BalanceButton = ({
  sb,
  setPopupPanelName,
  setPopupPanelProps,
  chequePayeeName,
  setChequePayeeNameValidationError,
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <React.Fragment>
      {sb.payments.outstandingBalance == 0 &&
      sb.payments.partial != null &&
      sb.payments.partial.payment_paid != null ? (
        <ConvertPartialToBalanceButton
          sb={sb}
          loading={loading}
          setLoading={setLoading}
        />
      ) : (
        <PayBalanceButton
          sb={sb}
          loading={loading}
          setLoading={setLoading}
          setPopupPanelName={setPopupPanelName}
          chequePayeeName={chequePayeeName}
          setChequePayeeNameValidationError={setChequePayeeNameValidationError}
          setPopupPanelProps={setPopupPanelProps}
        />
      )}
      {loading && (
        <CircularProgress
          size={22}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </React.Fragment>
  )
}

BalanceButton.propTypes = {
  sb: PropTypes.object,
  setPopupPanelName: PropTypes.func,
  chequePayeeName: PropTypes.string,
  setChequePayeeNameValidationError: PropTypes.func,
  setPopupPanelProps: PropTypes.func,
}

export default BalanceButton

import { useRecordContext } from 'react-admin'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import './Edit/LeadHistoryPanel/style/index.scss'
import dayjs from 'dayjs'

const EmailsPanel = () => {
  const record = useRecordContext()

  return (
    <TableContainer sx={{ maxHeight: '90%', overflow: 'scroll' }}>
      <Table className="table" stickyHeader>
        <TableHead className="table-header">
          <TableRow>
            <TableCell className="table-header__individual">
              Date/Time
            </TableCell>
            <TableCell className="table-header__individual">Message</TableCell>
            <TableCell className="table-header__individual">
              Inbound/ Outbound
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.emails &&
            record.emails.toReversed().map((row) => (
              <TableRow key={row.id}>
                <TableCell className="table-body table-body__user">
                  {dayjs.unix(row.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                </TableCell>
                <TableCell className="table-body">
                  {row.sentReceived === true
                    ? row.body.split('From:')[0]
                    : row.body.split('}')[row.body.split('}').length - 1]}
                </TableCell>
                <TableCell className="table-body table-body__user">
                  {row.sentReceived === true ? 'Inbound' : 'Outbound'}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default EmailsPanel

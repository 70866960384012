import React, { useEffect, useState } from 'react'
import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material'
import {
  cancelLeadBooking,
  getUnassignAgentCancellationEmailTemplates,
} from '../../../../Utils/RestUtils'
import { scrollToPanelById } from '../../../../Utils/QuoteRequestUtils'

const CancelBookingModal = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [openModal, setOpenModal] = useState(false)
  const [cancellationTemplates, setCancellationTemplates] = useState(null)
  const [templateSelected, setTemplateSelected] = useState(
    cancellationTemplates
      ? cancellationTemplates[0]
      : { targetLabel: '', body: '' }
  )
  const isBookingCompleted = record.bookingCompleted
  const isSalvage = record.leadContact.vehicle.tooGoodToScrap
  const isUserASuperAdmin = localStorage
    .getItem('permissions')
    .includes('ROLE_ADMIN')

  const getCancellationTemplates = async () => {
    const response = await getUnassignAgentCancellationEmailTemplates()

    setCancellationTemplates(response.data)
    setTemplateSelected(response.data[0])
  }

  const handleCancelBooking = async () => {
    const response = await cancelLeadBooking({
      quoteRequestId: record['@id'],
      emailTemplateId: templateSelected.id,
    })

    if (response.result === 'success') {
      refresh()
      setOpenModal(false)

      if (!isSalvage) {
        scrollToPanelById('collectionAgentsPanel')
      }
    } else {
      notify(response.error_msg, { type: 'error' })
    }
  }

  const handleEmailTemplateChange = (e) => {
    const inputValue = e.target.value

    const selectedTemplateObj = cancellationTemplates.filter((template) => {
      return template.targetLabel === inputValue
    })

    setTemplateSelected(selectedTemplateObj[0])
  }

  useEffect(() => {
    if (!cancellationTemplates) {
      getCancellationTemplates()
    }
  }, [])

  return (
    <React.Fragment>
      <Button
        variant="contained"
        fullWidth
        disabled={isBookingCompleted && !isUserASuperAdmin}
        onClick={() => setOpenModal(true)}
      >
        Cancel Booking
      </Button>

      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        className="cancel-booking__modal"
      >
        <DialogTitle>Cancel Booking?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select an email template to send to the original collector.
          </DialogContentText>
          <DialogContentText sx={{ marginTop: '20px' }}>
            Email Template:
            <TextField
              name="template"
              select
              onChange={handleEmailTemplateChange}
              value={templateSelected.targetLabel}
            >
              {cancellationTemplates &&
                cancellationTemplates.map((option) => (
                  <MenuItem key={option.id} value={option.targetLabel}>
                    {option.targetLabel}
                  </MenuItem>
                ))}
            </TextField>
          </DialogContentText>
          <DialogContentText sx={{ marginTop: '20px' }}>
            <div className="bold">Email Preview:</div>
            <div dangerouslySetInnerHTML={{ __html: templateSelected.body }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelBooking} autoFocus>
            Cancel & Send Email
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default CancelBookingModal

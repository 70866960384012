import React, { useEffect, useState } from 'react'
import { useNotify, useRecordContext } from 'react-admin'
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import {
  getTemplateMapping,
  getTemplates,
  sendEmail,
} from '../../../../Utils/QuoteRequestUtils'
import PropTypes from 'prop-types'
import Editor from 'react-simple-wysiwyg'

const SendEmailPopup = ({ setPopupPanelName }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [loading, setLoading] = useState(false)
  const [emailTemplatesList, setEmailTemplatesList] = useState([])
  const [emailForm, setEmailForm] = useState({
    templateId: 'select',
    templateName: '',
    title: '',
    content: '',
    cc: '',
    bcc: '',
    leadId: record.id,
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setEmailForm({
      ...emailForm,
      [name]: value,
    })
  }

  const handleTemplateChange = async (e) => {
    const { value } = e.target

    const templateMapped = await getTemplateMapping('email', value, record.id)
    if (templateMapped.result === 'error') {
      notify(templateMapped.error_msg, { type: 'error' })
      return
    }

    setEmailForm({
      ...emailForm,
      templateId: value,
      templateName: templateMapped.templateName,
      title: templateMapped.subject,
      content: templateMapped.content,
    })
  }

  const handleSendEmail = async () => {
    //Check if required fields are selected / filled
    if (emailForm.templateId === 'select') {
      notify('Please select a template', { type: 'error' })
      return
    }

    if (emailForm.title.trim() === '') {
      notify('Please add a title / subject', { type: 'error' })
      return
    }

    const result = await sendEmail(emailForm)
    if (result.status === 'success') {
      setPopupPanelName('')
    } else {
      notify(result.error, { type: 'error' })
    }
  }

  const getDataFromApi = async () => {
    setLoading(true)

    const result = await getTemplates('sbemail')
    setEmailTemplatesList(result)

    setLoading(false)
  }

  useEffect(() => {
    if (!loading && emailTemplatesList.length == 0) {
      getDataFromApi()
    }
  }, [])

  return (
    <React.Fragment>
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
          textAlign: 'center',
        }}
      >
        <Card
          style={{
            width: '693px',
            height: '693px',
            padding: '20px',
          }}
        >
          <Grid container spacing={2}>
            <Grid container item xs={12} mb={2} alignItems={'center'}>
              <Grid item xs={4} textAlign={'left'}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    textTransform: 'uppercase',
                  }}
                >
                  Send Email
                </Typography>
              </Grid>
              <Grid item xs={8} textAlign={'right'}>
                <Button
                  sx={{
                    border: '2px solid #FFF !important',
                    color: '#B7B7B7',
                    width: '130px',
                    height: '40px',
                    '&:hover': {
                      backgroundColor: 'inherit',
                    },
                  }}
                  onClick={() => setPopupPanelName('')}
                >
                  Close
                </Button>
              </Grid>
            </Grid>

            <Grid container item xs={12} alignItems={'center'}>
              <Grid item xs={4} textAlign={'left'}>
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Template:
                </Typography>
              </Grid>
              <Grid item xs={8} textAlign={'left'}>
                <TextField
                  name="template"
                  select
                  onChange={handleTemplateChange}
                  value={emailForm.templateId}
                  required
                  sx={{
                    '& > .MuiOutlinedInput-root': {
                      fontWeight: '700',
                    },
                  }}
                >
                  <MenuItem key="select" value="select" selected disabled>
                    Select a template
                  </MenuItem>
                  {emailTemplatesList.map((option, key) => (
                    <MenuItem key={key} value={option.id}>
                      {option.targetLabel}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid container item xs={12} alignItems={'center'}>
              <Grid item xs={4} textAlign={'left'}>
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Title / Subject:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="title"
                  value={emailForm.title}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} alignItems={'center'}>
              <Grid item xs={4} textAlign={'left'}>
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  CC:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="cc"
                  value={emailForm.cc}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} alignItems={'center'}>
              <Grid item xs={4} textAlign={'left'}>
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  BCC:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="bcc"
                  value={emailForm.bcc}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={4} textAlign={'left'}>
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Content:
                </Typography>
              </Grid>
              <Grid item xs={8} textAlign={'left'}>
                <Card
                  variant="outlined"
                  sx={{
                    height: '260px',
                    overflow: 'auto',
                    borderColor: '#767676 !important',
                  }}
                >
                  <Editor
                    name="content"
                    value={emailForm.content}
                    onChange={handleInputChange}
                  />
                </Card>
              </Grid>
            </Grid>

            <Grid item xs={12} textAlign={'right'}>
              <Button
                sx={{
                  border: '2px solid #FFF !important',
                  backgroundColor: '#B7B7B7',
                  color: '#2A2A2A',
                  height: '40px',
                  width: '130px',
                  '&:hover': {
                    backgroundColor: '#B7B7B7',
                  },
                }}
                variant="contained"
                color="secondary"
                onClick={handleSendEmail}
              >
                Send email
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </React.Fragment>
  )
}

SendEmailPopup.propTypes = {
  setPopupPanelName: PropTypes.func,
}

export default SendEmailPopup

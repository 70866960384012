import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Card, CardContent, Chip, Grid } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useEffect, useState } from 'react'
import { Loading, useRecordContext } from 'react-admin'
import { getMitelCallDataByQuoteId } from '../../../../Utils/MitelApi'
import './style/index.scss'

const MitelDataPanel = () => {
  const record = useRecordContext()
  const [mitelData, setMitelData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [openMitelPanel, setOpenMitelPanel] = useState(false)
  const isAdmin =
    localStorage.getItem('permissions').includes('ROLE_ADMIN') ||
    localStorage.getItem('permissions').includes('ROLE_MANAGER')

  if (!isAdmin) {
    return
  }

  const getMitelData = async () => {
    setIsLoading(() => true)
    const result = await getMitelCallDataByQuoteId(record.originId)
    setMitelData(result)
    setIsLoading(() => false)
  }

  useEffect(() => {
    if (!isLoading && isAdmin && !mitelData) {
      getMitelData()
    }
  }, [])

  if (isLoading) {
    return (
      <React.Fragment>
        <Card>
          <Loading />
        </Card>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Card
        sx={{
          width: '100%',
          marginTop: '1rem',
        }}
      >
        <CardContent sx={{ padding: '20px !important' }}>
          <Box
            component="div"
            sx={{
              fontSize: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 700,
              cursor: 'pointer',
              marginBottom: '20px',
            }}
            onClick={() => setOpenMitelPanel(!openMitelPanel)}
          >
            <Box component="span">MITEL PHONE DATA</Box>
            {openMitelPanel ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>

          {openMitelPanel && mitelData && (
            <Grid style={{ gridColumn: 1, gridRowStart: 2, gridRowEnd: 2 }}>
              <TableContainer>
                <Table className="mitel-table">
                  <TableHead className="mitel-table-header">
                    <TableRow>
                      <TableCell className="mitel-table-header__individual">
                        #
                      </TableCell>
                      <TableCell className="mitel-table-header__individual">
                        REC ID
                      </TableCell>
                      <TableCell className="mitel-table-header__individual">
                        DIRECTION
                      </TableCell>
                      <TableCell className="mitel-table-header__individual">
                        STATUS
                      </TableCell>
                      <TableCell className="mitel-table-header__individual">
                        FROM
                      </TableCell>
                      <TableCell className="mitel-table-header__individual">
                        TO
                      </TableCell>
                      <TableCell className="mitel-table-header__individual">
                        EXT.
                      </TableCell>
                      <TableCell className="mitel-table-header__individual">
                        CALL STARTED
                      </TableCell>
                      <TableCell className="mitel-table-header__individual">
                        ANSWER TIME
                      </TableCell>
                      <TableCell className="mitel-table-header__individual">
                        CALL LENGTH
                      </TableCell>
                      <TableCell className="mitel-table-header__individual">
                        EVENT
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mitelData.summary.count > 0 ? (
                      mitelData.calls.map((row, idx) => (
                        <TableRow key={row.CallRecID}>
                          <TableCell className="mitel-table-body">
                            {idx + 1}
                          </TableCell>
                          <TableCell className="mitel-table-body">
                            {row.CallRecID}
                          </TableCell>
                          <TableCell className="mitel-table-body">
                            {row.DisplayDirection === 'Inbound' ? (
                              <Chip
                                className="mitel__chip"
                                label={row.DisplayDirection}
                                color="primary"
                                sx={{
                                  backgroundColor: '#6471EB',
                                  color: '#fff',
                                  fontWeight: 700,
                                  fontSize: '13px',
                                  textTransform: 'uppercase',
                                }}
                              />
                            ) : (
                              <Chip
                                className="mitel__chip"
                                label={row.DisplayDirection}
                                color="primary"
                                sx={{
                                  backgroundColor: '#FF9900',
                                  color: '#fff',
                                  fontWeight: 700,
                                  fontSize: '13px',
                                  textTransform: 'uppercase',
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell className="mitel-table-body__status">
                            {row.DisplayInProgress}
                          </TableCell>
                          <TableCell className="mitel-table-body__nums">
                            {row.DisplayCallFrom}
                          </TableCell>
                          <TableCell className="mitel-table-body__nums">
                            {row.DisplayCallTo}
                          </TableCell>
                          <TableCell className="mitel-table-body">
                            <span className="mitel-table-body__ext">
                              ({row.DisplayExtensionExt})
                            </span>{' '}
                            {row.DisplayExtensionName}
                          </TableCell>
                          <TableCell className="mitel-table-body">
                            <span className="mitel-table-body__date">
                              {row.DisplayStartTimeDate}
                            </span>{' '}
                            {row.DisplayStartTimeTime}
                          </TableCell>
                          <TableCell className="mitel-table-body">
                            {row.DisplayAnswerLength}
                          </TableCell>
                          <TableCell className="mitel-table-body">
                            {row.DisplayCallLength}
                          </TableCell>
                          <TableCell className="mitel-table-body">
                            {row.DisplayEndEvent === 'Call Transferred' ? (
                              <span className="mitel-table-body__event">
                                {row.DisplayEndEvent}
                              </span>
                            ) : (
                              row.DisplayEndEvent
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={11}
                          sx={{ fontWeight: 'bold', fontSize: '20px' }}
                        >
                          {mitelData.summary.status}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

          {mitelData && (
            <Grid
              style={{
                gridColumn: 1,
                gridRowStart: 3,
                gridRowEnd: 3,
              }}
            >
              <Grid
                container
                className="mitel-summary"
                sx={{
                  height: '54px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '15px',
                }}
              >
                <Grid
                  item
                  style={{
                    marginRight: '1rem',
                  }}
                >
                  Total calls:{' '}
                  <span className="mitel-summary-highlight">
                    {mitelData.summary.count}
                  </span>
                </Grid>
                <Grid
                  item
                  style={{
                    marginRight: '1rem',
                  }}
                >
                  Time to first call:{' '}
                  <span className="mitel-summary-highlight">
                    {mitelData.summary.first ? mitelData.summary.first : 'N/A'}
                  </span>
                </Grid>
                <Grid
                  item
                  style={{
                    marginRight: '1rem',
                  }}
                >
                  Total time:{' '}
                  <span className="mitel-summary-highlight">
                    {mitelData.summary.total ? mitelData.summary.total : 'N/A'}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  )
}

export default MitelDataPanel

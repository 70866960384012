import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  TextField,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Button, useRecordContext } from 'react-admin'
import { getVehicleNotes } from '../../../../Utils/RestUtils'
import './style/index.scss'
import { Cancel, Save } from '@mui/icons-material'
import { sendLog } from '../../../../Class/AuditLog'
import { useGetIdentity } from 'ra-core'
import { useLocation } from 'react-router-dom'

const VehicleNotesPanel = ({
  notes,
  editable,
  saveNewVehicleNote,
  setNotes,
}) => {
  const [newNoteInputFocus, setNewNoteInputFocus] = useState(false)
  const isEditable = editable
  const newVehicleNoteRef = useRef()
  const updateNotesRef = useRef()
  const record = useRecordContext()
  const [showDialog, setShowDialog] = useState(false)
  const { identity, isLoading: identityLoading } = useGetIdentity()
  const location = useLocation()

  useEffect(() => {
    const result = getVehicleNotes(record.id)
    result.then((r) => {
      setNotes({
        salvageNotes: r.data.salvage,
        quoteNotes: r.data.quoteNotes,
      })
    })
  }, [location])

  const saveNewVehicleNoteTest = async () => {
    if (newVehicleNoteRef.current.value === notes.quoteNotes) {
      return
    }

    const newVehicleNote =
      notes.quoteNotes + ' | ' + newVehicleNoteRef.current.value

    saveNewVehicleNote(newVehicleNote, record.originId)
    document.getElementById('new-vehicle-note').value = ''
  }

  const handleUpdateVehicleNotes = async () => {
    if (updateNotesRef.current.value === notes.quoteNotes) {
      return
    }

    const newVehicleNote = updateNotesRef.current.value

    sendLog(
      record.originId,
      identity.fullName +
        ' changed vehicle notes from: ' +
        notes.quoteNotes +
        ' -> ' +
        newVehicleNote
    )

    saveNewVehicleNote(newVehicleNote, record.originId)
    setShowDialog(false)
  }

  const handleButtonClick = () => {
    //change new note input focus
    setNewNoteInputFocus(!newNoteInputFocus)
    saveNewVehicleNoteTest()
  }

  const NotesList = () => {
    return (
      <div className="vehicle-notes__list">
        <div dangerouslySetInnerHTML={{ __html: notes.salvageNotes }} />
        <hr />
        <Typography color="primary" fontSize="16px">
          {notes.quoteNotes}
        </Typography>
      </div>
    )
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleEditClick = () => {
    setShowDialog(true)
  }

  return (
    <React.Fragment>
      <Grid id="vehicle-notes" className="vehicle-notes__paper">
        <List className="vehicle-notes__list">
          <NotesList />
          <Button onClick={handleEditClick}>Edit</Button>
          <Dialog
            fullWidth
            open={showDialog}
            onClose={handleCloseClick}
            aria-label="Edit Vehicle Notes"
          >
            <React.Fragment>
              <DialogTitle>Edit vehicle notes</DialogTitle>
              <DialogContent>
                <TextField
                  id="updated-note-content"
                  inputRef={updateNotesRef}
                  multiline
                  defaultValue={notes.quoteNotes}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.save"
                  onClick={(e) => handleUpdateVehicleNotes()}
                >
                  <Save />
                </Button>
                <Button label="ra.action.cancel" onClick={handleCloseClick}>
                  <Cancel />
                </Button>
              </DialogActions>
            </React.Fragment>
          </Dialog>
        </List>
      </Grid>
      {isEditable === true && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <TextField
            name="newVehicleNote"
            inputRef={newVehicleNoteRef}
            InputProps={{
              disableUnderline: true,
              className: 'vehicle-notes__new-note',
              id: 'new-vehicle-note',
            }}
            sx={{
              marginRight: '15px',
            }}
            multiline
          />
          <Button
            color="primary"
            size="large"
            onClick={handleButtonClick}
            label="Save"
          />
        </div>
      )}
    </React.Fragment>
  )
}

VehicleNotesPanel.propTypes = {
  notes: PropTypes.object,
  editable: PropTypes.bool,
  saveNewVehicleNote: PropTypes.func,
  setNotes: PropTypes.func,
}
export default VehicleNotesPanel

import { Grid, List } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useRecordContext } from 'react-admin'
import { getVehicleNotes } from '../../../../Utils/RestUtils'
import './style/index.scss'

const VehicleNotesPanel = ({
  notes,
  editable,
  saveNewVehicleNote,
  setNotes,
}) => {
  const record = useRecordContext()

  useEffect(() => {
    if (notes.salvageNotes === null && record) {
      const result = getVehicleNotes(record.id)
      result.then((r) => {
        setNotes({
          salvageNotes: r.data.salvage,
          quoteNotes: r.data.quoteNotes,
        })
      })
    }
  }, [notes])

  const NotesList = () => {
    return (
      <div className="vehicle-notes__list">
        {notes.salvageNotes}
        <hr />
        {notes.quoteNotes}
      </div>
    )
  }

  return (
    <React.Fragment>
      <Grid id="vehicle-notes" className="vehicle-notes__paper">
        <List className="vehicle-notes__list">
          <NotesList />
        </List>
      </Grid>
    </React.Fragment>
  )
}

VehicleNotesPanel.propTypes = {
  notes: PropTypes.object,
  editable: PropTypes.bool,
  saveNewVehicleNote: PropTypes.func,
  setNotes: PropTypes.func,
}
export default VehicleNotesPanel

import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import './style/index.scss'
import { Button } from 'react-admin'

const ListReminders = (props) => {
  const { data, setReminderScreen } = { ...props }

  return (
    <React.Fragment>
      <TableContainer>
        <Table stickyHeader>
          <TableHead className="table-header">
            <TableRow>
              <TableCell className="table-header__individual">
                Date / Time
              </TableCell>
              <TableCell className="table-header__individual">Admin</TableCell>
              <TableCell className="table-header__individual">
                Reminder
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell className="table-body">
                    <div>{row.date}</div>
                    <div className="table-body table-body__hour">
                      {row.hour}
                    </div>
                  </TableCell>
                  <TableCell className="table-body table-body__admin">
                    {row.admin && row.admin.displayName}
                  </TableCell>
                  <TableCell className="table-body table-body__reminder">
                    {row.payload}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        onClick={() => setReminderScreen('add')}
        sx={{ marginTop: 2 }}
        label="Add a Reminder"
      />
    </React.Fragment>
  )
}

export default ListReminders

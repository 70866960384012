import { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useNotify, useRefresh } from 'react-admin'
import FeeTypeSelect from './FeeTypeSelect'
import FeeAmountInput from './FeeAmountInput'
import AddFeeButton from './AddFeeButton'
import { addFeeSalvageBookingsLead } from '../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const AddFee = ({ sb }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [type, setType] = useState('')
  const [amount, setAmount] = useState('')
  const [amountValidationError, setAmountValidationError] = useState(false)
  const [typeValidationError, setTypeValidationError] = useState(false)
  const [loading, setLoading] = useState(false)

  if (
    sb == null ||
    sb.assigned_to_copart_at == null ||
    sb.payments.balance ||
    sb.cancelled != null
  ) {
    return null
  }

  const handleTypeChange = (event, child) => {
    setType(event.target.value)
    setTypeValidationError(false)

    // Set default fee amount for certain types
    setAmount(child.props.defaultamount ?? '')
  }

  const handleAmountChange = (event) => {
    setAmountValidationError(false)
    setAmount(event.target.value)
  }

  const handleButtonClick = async () => {
    if (type === '') {
      setTypeValidationError(true)
      notify('Fee type must be set', { type: 'error' })
      return
    }

    if (amount === '') {
      setAmountValidationError(true)
      notify('Fee amount must be set', { type: 'error' })
      return
    }

    if (amount < 1) {
      setAmountValidationError(true)
      notify('Amount must be more than 1', { type: 'error' })
      return
    }

    if (amount > sb.payments.outstandingBalance) {
      setAmountValidationError(true)
      notify(
        'Amount must not be more than ' +
          new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
          }).format(sb.payments.outstandingBalance),
        { type: 'error' }
      )
      return
    }

    if (!loading) {
      setLoading(true)
    }

    // Post new fee to salvage bookings
    const responseStatus = await addFeeSalvageBookingsLead(
      sb.quote_request_id,
      type,
      amount
    )

    if (responseStatus) {
      // Reset inputs
      setAmount('')
      setType('')

      refresh()
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: '700',
            letterSpacing: '0.46px',
            marginRight: '5px',
            textTransform: 'uppercase',
          }}
        >
          Fees:
        </Typography>
      </Grid>
      <Grid container sx={{ paddingLeft: 3, paddingTop: 3, paddingRight: 3 }}>
        <Grid item xs={6}>
          <FeeTypeSelect
            sb={sb}
            type={type}
            handleChange={handleTypeChange}
            typeValidationError={typeValidationError}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ paddingLeft: 3, paddingTop: 3, paddingRight: 3 }}>
        <Grid item xs={6}>
          <FeeAmountInput
            amount={amount}
            handleChange={handleAmountChange}
            amountValidationError={amountValidationError}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ paddingLeft: 3, paddingTop: 3, paddingRight: 3 }}>
        <Grid item xs={6}>
          <AddFeeButton
            handleButtonClick={handleButtonClick}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

AddFee.propTypes = {
  sb: PropTypes.object,
}

export default AddFee

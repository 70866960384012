import { Card, CardContent } from '@mui/material'

import {
  Datagrid,
  EditButton,
  List,
  TextField,
  useRecordContext,
} from 'react-admin'

const RulesSetPanel = (props) => {
  const record = useRecordContext()
  const fakeProps = {
    basePath: '/rules',
    resource: 'rules',
    hasEdit: true,
  }

  return (
    <div style={{ marginLeft: 30, marginRight: 30 }}>
      <Card>
        <CardContent>
          <h3>Rule Sets</h3>
          {record && (
            <List
              {...fakeProps}
              filter={{ 'collectionAgent.id': record.id }}
              perPage={999}
            >
              <Datagrid>
                <TextField source="name" />
                <TextField source="matchType" />
                <TextField source="type" />
                <EditButton target="_blank" />
              </Datagrid>
            </List>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default RulesSetPanel

import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useRecordContext } from 'react-admin'

const LeadSourceButton = () => {
  const record = useRecordContext()
  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState([])

  const handleClickOpen = () => {
    let newRows = []

    if (record.leadContact.urlPath) {
      record.leadContact.urlPath.split('|').map((row) => {
        newRows.push({ path: row })
      })
    }

    setRows(newRows)
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Lead Source</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TableContainer>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Path</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.path}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Button fullWidth onClick={handleClickOpen} color="primary">
        Lead Source
      </Button>
    </React.Fragment>
  )
}

export default LeadSourceButton

import React, { useState } from 'react'
import { Grid } from '@mui/material'
import {
  FormDataConsumer,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { validateAutoSaveSingleInput } from '../../../../Utils/AutoSave'
import { getManualCap } from '../../../../Utils/RestUtils'
import { blockInvalidCharFromNumberInput } from '../../../../Utils/ConversionUtils'

const RetailAndManualInputs = () => {
  let vehicleCap
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [isLoadingManualCap, setIsLoadingManualCap] = useState(false)
  const vehicleCapLength =
    record.leadContact.vehicle.vehicleCapHistory.length - 1

  vehicleCap = record.leadContact.vehicle.vehicleCapHistory[vehicleCapLength]

  const handleRetailChange = async (e) => {
    if (!isLoadingManualCap) {
      setIsLoadingManualCap(true)
      //update DB value
      await validateAutoSaveSingleInput(record, e.target, null, null)

      //calculate manual CAP
      await getManualCap(record.leadContact.vehicle['@id'], true)

      refresh()
      setIsLoadingManualCap(false)
    }
  }

  const handleManualChange = async (e) => {
    if (!isLoadingManualCap) {
      setIsLoadingManualCap(true)
      //update DB value
      await validateAutoSaveSingleInput(record, e.target, null, null)
      refresh()
      setIsLoadingManualCap(false)
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={2}>
        <TextInput
          label="Retail Value"
          type="number"
          disabled={isLoadingManualCap}
          inputProps={{
            step: 0.01,
            min: 0,
          }}
          onKeyDown={blockInvalidCharFromNumberInput}
          source="leadContact.vehicle.vehicleSalvage.retailValue"
          onBlur={handleRetailChange}
        />
      </Grid>
      <Grid item xs={2}>
        <FormDataConsumer>
          {({ formData, record }) => (
            <TextInput
              label="Manual Cap"
              type="number"
              class={
                formData.leadContact.vehicle.vehicleSalvage &&
                vehicleCap &&
                formData.leadContact.vehicle.vehicleSalvage.manualCap >
                  vehicleCap.value
                  ? 'highlight-red'
                  : 'highlight-green'
              }
              disabled={isLoadingManualCap}
              inputProps={{
                step: 0.01,
                min: 0,
              }}
              onKeyDown={blockInvalidCharFromNumberInput}
              source="leadContact.vehicle.vehicleSalvage.manualCap"
              onBlur={handleManualChange}
            />
          )}
        </FormDataConsumer>
      </Grid>
    </React.Fragment>
  )
}

export default RetailAndManualInputs

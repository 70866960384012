import { List, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecordContext } from 'react-admin'
import { getPricingNotes } from '../../../../Utils/RestUtils'
import { useLocation } from 'react-router-dom'

const NotesListingPanel = () => {
  const record = useRecordContext()
  const location = useLocation()
  const [pricingNotes, setPricingNotes] = useState({
    matches: [],
  })

  let fetchingPricingNotesActive = false
  useEffect(() => {
    if (pricingNotes.matches.length === 0 && !fetchingPricingNotesActive) {
      const getNotes = async () => {
        fetchingPricingNotesActive = true
        const payload = await getPricingNotes(record.id)

        setPricingNotes(() => payload)

        fetchingPricingNotesActive = false
      }
      getNotes()
    }
  }, [])

  useEffect(() => {
    const getNotes = async () => {
      fetchingPricingNotesActive = true
      const payload = await getPricingNotes(record.id)

      setPricingNotes(() => payload)

      fetchingPricingNotesActive = false
    }

    getNotes()
  }, [location])

  const NotesList = () => {
    if (pricingNotes.matches && pricingNotes.matches.length > 0) {
      return pricingNotes.matches.map((note) => {
        return (
          <div key={note.id}>
            {note.rule_set_name}
            <br />
            <p
              dangerouslySetInnerHTML={{
                __html: `${note.rule_set_note
                  .replace(/Â/g, '')
                  .replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
              }}
            ></p>
          </div>
        )
      })
    }
  }

  return (
    <React.Fragment>
      <Paper
        id="pricing-list"
        elevation={0}
        sx={{
          border: '1.5px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
        }}
        className="pricing-notes__paper"
      >
        <List className="pricing-notes__list">
          <NotesList />
        </List>
      </Paper>
    </React.Fragment>
  )
}

export default NotesListingPanel

import { Edit, SimpleForm, TextInput } from 'react-admin'
import { Box } from '@mui/material'

const EditSetting = (props) => {
  return (
    <Box sx={{ mt: 1 }}>
      <Edit {...props} actions={null}>
        <SimpleForm>
          <TextInput source="name" name="name" />
          <TextInput source="identifier" name="identifier" />
          <TextInput source="value" name="value" />
        </SimpleForm>
      </Edit>
    </Box>
  )
}

export default EditSetting

import { Grid, TextField } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
  BooleanInput,
  Form,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import {
  validateAutoSaveMultiInput,
  validateAutoSaveSingleInput,
} from '../../../../Utils/AutoSave'
import {
  blockInvalidCharFromNumberInput,
  numberInputOnWheelPreventChange,
} from '../../../../Utils/ConversionUtils'
import './style/index.scss'

const PaymentDetailsPanel = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [bankNumber, setBankNumber] = useState(undefined)
  const [sortCode, setSortCode] = useState(undefined)
  const bankAccRef = useRef()
  const sortCodeRef = useRef()
  const vehicleDetails = record.leadContact.vehicle
  const vehicleDocumentation = vehicleDetails.vehicleDocumentation
  const quotePaymentDetails = record.quotePaymentDetails

  const handleChequePaymentChange = async (e) => {
    const chequePaymentValue = e.target.checked

    const quotePaymentDetailsInputsToSave = [
      {
        name: 'quotePaymentDetails.acc',
        value: '00000000',
      },
      {
        name: 'quotePaymentDetails.sort',
        value: '000000',
      },
    ]

    const quoteRequestInputsToSave = [
      {
        name: 'deposit',
        value: chequePaymentValue ? false : true,
      },
      {
        name: 'depositAmount',
        value: `${chequePaymentValue ? '0' : '20'}`,
      },
    ]

    await Promise.all([
      validateAutoSaveSingleInput(record, e.target, notify, refresh),
      validateAutoSaveMultiInput(
        record,
        quotePaymentDetailsInputsToSave,
        notify,
        refresh,
        '',
        true
      ),
      validateAutoSaveMultiInput(
        record,
        quoteRequestInputsToSave,
        notify,
        refresh
      ),
    ]).then(() => {
      setTimeout(() => {
        refresh()
      }, 100)
    })
  }

  const onSortCodeBlur = async () => {
    const inputToSave = [
      {
        name: 'quotePaymentDetails.sort',
        value: sortCode,
      },
    ]

    await validateAutoSaveMultiInput(record, inputToSave, notify, refresh)
  }

  const onBankNumberBlur = async () => {
    const inputToSave = [
      {
        name: 'quotePaymentDetails.acc',
        value: bankNumber,
      },
    ]

    await validateAutoSaveMultiInput(record, inputToSave, notify, refresh)
  }

  useEffect(() => {
    setSortCode(quotePaymentDetails ? quotePaymentDetails.sort : '')
    setBankNumber(quotePaymentDetails ? quotePaymentDetails.acc : '')
  }, [refresh, record])

  useLayoutEffect(() => {
    sortCodeRef.current.addEventListener('blur', onSortCodeBlur)
    bankAccRef.current.addEventListener('blur', onBankNumberBlur)

    return () => {
      sortCodeRef.current.removeEventListener('blur', onSortCodeBlur)
      bankAccRef.current.removeEventListener('blur', onBankNumberBlur)
    }
  })

  return (
    <Form>
      <Grid
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 4fr)',
          gridColumn: 1,
          gridRowStart: 1,
          gridRowEnd: 1,
        }}
      >
        <Grid
          style={{
            gridColumn: 1,
            gridRowStart: 1,
            gridRowEnd: 1,
          }}
        >
          <Typography className="collection-panel__title">
            Cheque payment
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>No</Typography>
            <BooleanInput
              source="leadContact.vehicle.vehicleSalvage.chequePayment"
              label="Yes"
              style={{ alignItems: 'space-between' }}
              onChange={handleChequePaymentChange}
            />
          </Stack>
        </Grid>

        <Grid
          style={{
            gridColumn: '2 / span 12',
            gridRowStart: 1,
            gridRowEnd: 1,
          }}
        >
          <Grid
            style={{
              display: 'grid',
              gridColumn: 1,
              gridRowStart: 1,
              gridRowEnd: 1,
              marginBottom: '1rem',
            }}
          >
            Customer bank account details
          </Grid>
          <Grid
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 2fr)',
              gridColumn: '2 / span 7',
              gridRowStart: 2,
              gridRowEnd: 2,
              gap: 10,
            }}
          >
            <TextField
              name="quotePaymentDetails.acc"
              label="Bank Account Number"
              value={bankNumber}
              sx={{
                marginBottom: '-0.7rem !important',
                marginRight: '1.4rem !important',
              }}
              type={'number'}
              inputRef={bankAccRef}
              onWheel={numberInputOnWheelPreventChange}
              onKeyDown={blockInvalidCharFromNumberInput}
              onChange={(e) => setBankNumber(e.target.value)}
            />
            <TextField
              name="quotePaymentDetails.sort"
              label="Sort Code"
              value={sortCode}
              sx={{
                marginBottom: '-0.7rem !important',
              }}
              type={'number'}
              inputRef={sortCodeRef}
              onWheel={numberInputOnWheelPreventChange}
              onKeyDown={blockInvalidCharFromNumberInput}
              onChange={(e) => setSortCode(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          style={{
            display: 'grid',
            gridColumn: '2 / span 7',
            gridRowStart: 3,
            gridRowEnd: 3,
            marginBottom: '1rem',
            marginTop: '1rem',
          }}
        >
          Finance company details
        </Grid>
        <Grid
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 3fr)',
            gridColumn: '2 / span 7',
            gridRowStart: 4,
            gridRowEnd: 4,
            marginBottom: '1rem',
            gap: 10,
          }}
        >
          <TextField
            name="leadContact.vehicle.vehicleDocumentation.financeAccountNumber"
            label="Bank Account Number"
            defaultValue={
              vehicleDocumentation && vehicleDocumentation.financeAccountNumber
            }
            sx={{ marginBottom: '-0.7rem !important' }}
            type={'number'}
            onWheel={numberInputOnWheelPreventChange}
            onKeyDown={blockInvalidCharFromNumberInput}
            onBlur={async (e) =>
              await validateAutoSaveSingleInput(
                record,
                e.target,
                notify,
                refresh,
                'Account Number'
              )
            }
          />
          <TextField
            name="leadContact.vehicle.vehicleDocumentation.financeSortCode"
            label="Sort Code"
            defaultValue={
              vehicleDocumentation && vehicleDocumentation.financeSortCode
            }
            sx={{ marginBottom: '-0.7rem !important' }}
            type={'number'}
            onWheel={numberInputOnWheelPreventChange}
            onKeyDown={blockInvalidCharFromNumberInput}
            onBlur={async (e) =>
              await validateAutoSaveSingleInput(
                record,
                e.target,
                notify,
                refresh,
                'Sort Code'
              )
            }
          />
          <TextField
            name="leadContact.vehicle.vehicleDocumentation.financeCompanyName"
            label="Name"
            defaultValue={
              vehicleDocumentation && vehicleDocumentation.financeCompanyName
            }
            sx={{ marginBottom: '-0.7rem !important' }}
            onBlur={async (e) =>
              await validateAutoSaveSingleInput(
                record,
                e.target,
                notify,
                refresh,
                'Name'
              )
            }
          />
        </Grid>
      </Grid>
    </Form>
  )
}

export default PaymentDetailsPanel

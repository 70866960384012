import { useSearchParams } from 'react-router-dom'
import ListLeads from './List'
import NoPhoneNumber from './NoPhoneNumber'

const InboundCall = () => {
  const [params] = useSearchParams()
  const receivedNumber = params.get('number')
  const dialledNumber = params.get('dialled')
  const receivedReg = params.get('registration')

  if (!receivedNumber && !receivedReg) {
    return <NoPhoneNumber />
  }

  return (
    <ListLeads
      receivedNumber={receivedNumber}
      receivedReg={receivedReg}
      dialledNumber={dialledNumber}
    />
  )
}

export default InboundCall

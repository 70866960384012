import React, { useRef, useState } from 'react'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Button } from 'react-admin'
import {
  DateFormatting,
  PhoneContact,
  VehicleStatus,
} from '../../Components/QuoteRequest/List/Utils'
import { getQuoteList } from '../../Components/QuoteRequest/List/hooks/getQuotes'

const ListLeads = (props) => {
  const { receivedNumber, receivedReg, dialledNumber } = { ...props }
  const [registrationSearch, setRegistrationSearch] = useState(false)
  const [checkForDataLoad, setCheckForDataLoad] = useState(false)
  const [previousIsLoadingState, setPreviousIsLoadingState] = useState(false)
  const [searchRegistration, setSearchRegistration] = useState(receivedReg)

  let registration = receivedReg
  let phoneNumber = ''

  const tableRef = useRef()

  const { data, setQuoteListFilters, totalRecords, isLoading } = getQuoteList(
    tableRef,
    receivedNumber,
    receivedReg
  )

  const submit = (event) => {
    event.preventDefault()

    registration = event.target.registration.value
    phoneNumber = event.target.number.value

    setQuoteListFilters((prev) => {
      return {
        ...prev,
        page: 1,
        phoneNumber: phoneNumber,
        registration: registration,
      }
    })

    setSearchRegistration(registration)
    setRegistrationSearch(true)
    setCheckForDataLoad(true)
  }

  if (
    registrationSearch &&
    checkForDataLoad &&
    isLoading === false &&
    previousIsLoadingState &&
    totalRecords === 0
  ) {
    location.hash =
      '#/quote-requests/create?registration=' +
      searchRegistration +
      '&dialled=' +
      dialledNumber +
      '&number=' +
      receivedNumber
  }

  if (isLoading !== previousIsLoadingState) {
    setPreviousIsLoadingState(isLoading)
  }

  const getNotFoundCell = () => {
    const text =
      'No leads match your search criteria - please search again below'

    return (
      <React.Fragment>
        <p
          style={{
            backgroundColor: '#ffe6e6',
            color: 'red',
            borderColor: 'red',
            borderStyle: 'solid',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}
        >
          {text}
        </p>
        <br />
        <br />
        <form className="login-form" onSubmit={submit} method="get">
          <label>Registration: </label>
          <input name="registration" type="text" />
          <br />
          <label>Telephone number: </label>
          <input
            name="number"
            type="text"
            pattern=".{0}|.{11,}"
            title="Enter a valid phone number"
          />
          <br />
          <Button
            className="login-form__submit"
            variant="contained"
            type="submit"
            color="primary"
            label="Search"
            sx={{ marginLeft: '10px' }}
          />
        </form>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Grid container className="quote-list__table">
        <Grid item xs={12}>
          <Typography variant="h2">{totalRecords} Lead(s) Found</Typography>
          <TableContainer ref={tableRef} style={{ maxHeight: '900px' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Lead
                    <br />
                    ID
                  </TableCell>
                  <TableCell>
                    Date / Time
                    <br />
                    Received
                  </TableCell>
                  <TableCell>
                    Customer
                    <br />
                    Name
                  </TableCell>
                  <TableCell>
                    Customer
                    <br />
                    Telephone
                  </TableCell>
                  <TableCell>
                    Cust.
                    <br />
                    Postcode
                  </TableCell>
                  <TableCell>
                    Car
                    <br />
                    Reg
                  </TableCell>
                  <TableCell>
                    Car
                    <br />
                    Year
                  </TableCell>
                  <TableCell>
                    Car
                    <br />
                    Make
                  </TableCell>
                  <TableCell>
                    Car
                    <br />
                    Model
                  </TableCell>
                  <TableCell>
                    Inquiry
                    <br />
                    Status
                  </TableCell>
                  <TableCell>
                    Date / Time
                    <br />
                    Entered
                  </TableCell>
                  <TableCell>
                    Lead
                    <br />
                    Source
                  </TableCell>
                  <TableCell>
                    Assigned
                    <br />
                    To
                  </TableCell>
                  <TableCell>Commission</TableCell>
                  <TableCell>
                    Agent
                    <br />
                    Status
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="quote-list__table-body">
                {data &&
                  data.map((row) => {
                    return (
                      <TableRow key={row.qrId}>
                        <TableCell className="yellow">
                          {!row.inUseBy && (
                            <React.Fragment>#{row.qrId}</React.Fragment>
                          )}
                          {row.inUseBy && row.inUseBy !== '' && (
                            <React.Fragment>
                              #{row.qrId} <br /> [In use by: {row.inUseBy}]
                            </React.Fragment>
                          )}
                        </TableCell>
                        <TableCell>
                          <DateFormatting
                            dateToFormat={row.dateCreated['date']}
                            isBold={false}
                          />
                        </TableCell>
                        <TableCell className="yellow">{row.fullName}</TableCell>
                        <TableCell>
                          {!row.inUseBy && (
                            <PhoneContact
                              telNumber={row.telNumber}
                              altTelNumber={row.altTelNumber}
                              originId={row.qrId}
                            />
                          )}
                        </TableCell>
                        <TableCell className="yellow uppercase">
                          {row.postcode}
                        </TableCell>
                        <TableCell className="bold uppercase">
                          {row.registration}
                        </TableCell>
                        <TableCell className="yellow bold"></TableCell>
                        <TableCell className="yellow bold">
                          {row.make}
                        </TableCell>
                        <TableCell className="yellow bold">
                          {row.model}
                        </TableCell>
                        <TableCell className="green bold">
                          {row.quoteStatus}
                        </TableCell>
                        <TableCell>
                          <DateFormatting
                            dateToFormat={row.lastAccessed['date']}
                            isBold={false}
                          />
                        </TableCell>
                        <TableCell className="red bold">
                          {row.leadSourceTitle}
                        </TableCell>
                        <TableCell className="bold">
                          {row.collectionAgentCompanyName}
                        </TableCell>
                        <TableCell className="orange bold">
                          {row.commission ? `£${row.commission}` : ''}
                        </TableCell>
                        <TableCell className="bold">
                          <VehicleStatus {...row} />
                        </TableCell>
                        <TableCell>
                          {!row.inUseBy && (
                            <Button
                              href={
                                '#/quote-requests/' +
                                encodeURIComponent(
                                  '/api/quote-requests/' + row.qrId
                                ) +
                                '?number=' +
                                receivedNumber +
                                '&dialled=' +
                                dialledNumber
                              }
                              label="edit"
                              className="quote-list__table-body_edit-btn"
                            ></Button>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}

                {data && data.length === 0 && (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      align="center"
                      colSpan={16}
                      className="yellow bold"
                    >
                      {getNotFoundCell()}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ListLeads

import { canBeAssigned } from '../../../../Utils/QuoteRequestUtils'

const AgentAssignValidator = async (record, agentId) => {
  const quoteId = record.originId
  const leadContact = record.leadContact
  const vehicle = leadContact.vehicle

  //Finance remaining validation
  if (
    vehicle.vehicleDocumentation &&
    vehicle.vehicleDocumentation.outstandingFinance === 2
  ) {
    if (
      agentId &&
      confirm(
        'This vehicle has outstanding finance, are you sure you want to assign it a collection agent?'
      ) == false
    ) {
      return false
    }
  }

  const validateData = await canBeAssigned(quoteId, agentId)

  return validateData.errors
}

export { AgentAssignValidator }

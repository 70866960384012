import { useEffect, useState } from 'react'
import { Grid, List } from '@mui/material'
import { getCustomerNotes } from '../../../../Utils/RestUtils'
import { useRecordContext } from 'react-admin'

const NotesList = () => {
  const record = useRecordContext()
  const [customerNotes, setCustomerNotes] = useState(null)

  let fetchingCustomerNotesActive = false
  useEffect(() => {
    if (customerNotes === null && !fetchingCustomerNotesActive) {
      const getNotes = async () => {
        fetchingCustomerNotesActive = true
        const payload = await getCustomerNotes(record['@id'])

        setCustomerNotes(payload)

        fetchingCustomerNotesActive = false
      }

      getNotes()
    }
  }, [])

  return (
    <Grid
      id="customer-list"
      className="customer-notes__paper"
      sx={{
        border: '1.5px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        padding: '8px 14px',
      }}
    >
      <List className="customer-notes__list">
        {customerNotes && <div>{customerNotes.data}</div>}
      </List>
    </Grid>
  )
}

export default NotesList

import React from 'react'
import SetNewStatusButton from './SetNewStatusButton'
import PropTypes from 'prop-types'

const SetNoContactButtons = ({ sb, currentLeadStatus }) => {
  return (
    <React.Fragment>
      {(currentLeadStatus === null ||
        currentLeadStatus.slug !== 'action_required') && (
        <SetNewStatusButton
          sb={sb}
          label="Set No Contact"
          name="No Contact"
          slug="action_required"
          resetButton="false"
        />
      )}

      {currentLeadStatus !== null &&
        currentLeadStatus.slug === 'action_required' && (
          <SetNewStatusButton
            sb={sb}
            label="Reset No Contact"
            name="No Contact"
            slug="action_required"
            resetButton="true"
          />
        )}
    </React.Fragment>
  )
}

SetNoContactButtons.propTypes = {
  sb: PropTypes.object,
  currentLeadStatus: PropTypes.any,
}

export default SetNoContactButtons

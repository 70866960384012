import { useState } from 'react'
import {
  BooleanInput,
  Edit,
  FormTab,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin'
import SMSPreview from './partial/SMSPreview'

const EditSMSContent = (props) => {
  let [message, setMessage] = useState()

  let setMessageState = (e) => {
    setMessage(e.target.value)
  }

  return (
    <Edit
      {...props}
      aside={<SMSPreview message={message} />}
      hasShow={false}
      actions={false}
    >
      <TabbedForm>
        <FormTab label="Edit Message">
          <TextInput
            source="smsBody"
            fullWidth
            name="smsBody"
            onChange={setMessageState}
            onFocus={setMessageState}
            multiline
          />
        </FormTab>
        <FormTab label="Message Meta">
          <TextInput
            source="smsAvailablePlaceholders"
            fullWidth
            multiline
            name="smsAvailablePlaceholders"
          />
          <TextInput source="smsPurpose" fullWidth name="smsPurpose" />
          <TextInput source="smsTargetLabel" fullWidth name="smsTargetLabel" />
          <BooleanInput
            name="enabledInContactLog"
            source="enabledInContactLog"
          />
          <SelectInput
            source="receiver"
            fullWidth
            name="receiver"
            choices={[
              { id: 'customer', name: 'Customer' },
              { id: 'agent', name: 'Agent' },
            ]}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default EditSMSContent

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useCreatePath, useRecordContext, useRefresh } from 'react-admin'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { sendLog } from '../../../../Class/AuditLog'

const DuplicateLeadDialog = ({
  open,
  duplicateQuotes,
  duplicates,
  setOpen,
  setFetchedDuplicates,
}) => {
  let subtitle = null
  const refresh = useRefresh()
  const record = useRecordContext()
  const createPath = useCreatePath()
  const [isLoading, setIsLoading] = useState(false)
  const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT

  const handleClose = () => setOpen(false)

  const openLink = (url) => {
    window.open(url, '_blank')
  }

  const changePrimaryLead = (IRI) => {
    sendLog(record.originId, 'Marked lead as primary')
    const request = new Request(`${entrypoint}/services/quote/set-primary/`, {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'PUT',
      body: JSON.stringify({
        IRI: IRI,
      }),
    })

    setIsLoading(true)
    fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        setIsLoading(false)
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }
        refresh()
        setFetchedDuplicates(false)
      })
  }

  if (record && record.primaryLead === true) {
    subtitle =
      'There are duplicates of this lead, check the Duplicate Leads table to view them.'
  } else if (duplicates && duplicates.length > 1) {
    subtitle =
      'A vehicle with this Registration number has been already processed as Lead'
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle variant="h4" align="center">
        Duplicate lead details
      </DialogTitle>
      <DialogTitle variant="subtitle2" textAlign="center" sx={{ mt: -2 }}>
        {subtitle}
      </DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Quote ID</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Lead Source</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {duplicateQuotes &&
            duplicateQuotes.map((row, index) => {
              let date = new Date(row.createdDate.date)
              let status = row.archived ? 'Archived' : row.status

              return (
                <TableRow key={index} hover={true}>
                  <TableCell>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() =>
                        openLink(
                          createPath({
                            resource: '/#/quote-requests',
                            type: 'edit',
                            id: row.IRI,
                          })
                        )
                      }
                      style={{ margin: 0 }}
                    >
                      #{row.ID}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {date.toLocaleDateString()} {date.toLocaleTimeString()}
                  </TableCell>
                  <TableCell>{status}</TableCell>
                  <TableCell>{row.source}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {row.isPrimary ? (
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          disabled={isLoading}
                          style={{ margin: 0 }}
                        >
                          Primary
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="warning"
                          disabled={isLoading}
                          size="small"
                          onClick={() => changePrimaryLead(row.IRI)}
                          style={{ margin: 0 }}
                        >
                          Make Primary
                        </Button>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              )
            })}
        </Table>
      </DialogContent>
    </Dialog>
  )
}

DuplicateLeadDialog.propTypes = {
  open: PropTypes.bool,
  duplicateQuotes: PropTypes.array,
  duplicates: PropTypes.array,
  setOpen: PropTypes.func,
  setFetchedDuplicates: PropTypes.func,
}

export default DuplicateLeadDialog

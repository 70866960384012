import { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { useRecordContext, useUpdate } from 'react-admin'
import { sendLog } from '../../../Class/AuditLog'

const ArchiveLeadButton = () => {
  const record = useRecordContext()
  const [update, { isLoading, error }] = useUpdate()
  const [isArchived, setIsArchived] = useState(false)
  const [buttonText, setButtonText] = useState('Archive')

  useEffect(() => {
    if (record) {
      setIsArchived(record.archived)
      setButtonText(record.archived ? 'UnArchive' : 'Archive')
    }
  })

  const toggleArchived = () => {
    if (record) {
      update('quote-requests', {
        id: record.id,
        data: { archived: !isArchived },
        previousData: record,
      })
      let archivedText = isArchived ? 'Unarchived' : 'Archived'
      sendLog(record.originId, 'Lead ' + archivedText)
    }
  }

  if (error) {
    return (
      <Button fullWidth disabled={true} color="error">
        ERROR
      </Button>
    )
  }

  return (
    <Button
      fullWidth
      onClick={() => toggleArchived()}
      disabled={isLoading}
      variant={isArchived ? 'contained' : 'outlined'}
      color={isArchived ? 'warning' : 'primary'}
    >
      {buttonText}
    </Button>
  )
}

export default ArchiveLeadButton

import PropTypes from 'prop-types'

const ShoreTerms = (fee) => {
  /* eslint-disable */
  return (
    <>
      <h1>Shore Cars - Terms & Conditions - Read word for word</h1>
      <p>
        So [customer name], what I need to do now is read through the following statements to make this
        official for you and our affiliated buyer. This part of the call is scripted and also recorded.
        Please answer yes or no to the following questions, as this forms your purchase agreement and is
        legally binding.
      </p>
      <ul>
        <li>
          (Customer full name) you confirm that you have legal permission to sell the vehicle, registration number (XXX XXX) which is a “(XXX XXX)”
          [wait for confirmation from customer]
        </li>
        <li>You are confirming there is no finance outstanding on the vehicle. [wait for confirmation from customer]</li>
        <li>
          You agree that the description of the vehicle is as follows: (Read back description notes in full as written on SQR).
          [wait for confirmation from customer]
        </li>
        <li>
          You are agreeing to sell this vehicle to a company called “Shore Cars” for (£XXX).
          This price is based on the vehicle being in the condition described. [wait for confirmation from customer]
        </li>
        <li>
          As soon as this call is completed, if for any reason you decide to cancel this agreement there will be an
          administration fee of  <span style={{color: "red"}}>£{ fee }</span>. This is to cover the costs incurred by
          Shore Cars to handle your request. Please confirm that you agree to these terms and would like to go ahead.
          [wait for confirmation from customer]
        </li>
      </ul>
      <p>
        Great, your booking is now officially confirmed this will now be sent to
        you via email for your records
      </p>
      <p>
        What will happen next is Shore Cars will be in touch with you to discuss
        collection dates and times and will arrange something suitable for both
        parties. They will aim to be in touch within the next 24 hours. Please
        ensure that the vehicle is free from any personal property and all waste.
        Also make sure you have any relevant paperwork ready for the collection.
      </p>
      <p>
        On the day of collection, when the driver arrives he will contact their
        office, to let them know the collection is taking place. If for any reason
        you cannot be present for the collection, a friend or family member can be
        there on your behalf. If no one can be present to meet the driver, Shore
        Cars can still collect the vehicle as long as the keys and paperwork are
        accessible. In this instance Shore cars will call you when the driver is
        present.
      </p>
      <p>
        They will confirm with you all the information you have provided about the
        vehicle is correct and pay the remaining balance into your account.
      </p>
      <p>Your booking is now complete. Do you have any questions for me?</p>
    </>
  )
  /* eslint-enable */
}

ShoreTerms.propTypes = {
  fee: PropTypes.string,
}

export { ShoreTerms }

import { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import {
  EditBase,
  Form,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { validateAutoSaveSingleInput } from '../../../../Utils/AutoSave'
import {
  calculateCommission,
  calculatePrices,
  getAdminUsers,
} from '../../../../Utils/RestUtils'
import RetailAndManualInputs from './RetailAndManualFields'
import CapFields from './CapFields'
import AutotraderFields from './AutotraderFields'
import './style/index.scss'
import { blockInvalidCharFromNumberInput } from '../../../../Utils/ConversionUtils'
import { calculateAdjustmentPrice } from '../../../../Utils/PricingUtils'

const CapPanel = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [adminUsers, setAdminUsers] = useState()
  const [isLoadingCalculate, setIsLoadingCalculate] = useState(false)
  const vehicleData = record.leadContact.vehicle
  const [mileageEstimate, setMileageEstimate] = useState(null)
  const [approvedBy, setApprovedBy] = useState()
  const [recalculate, setRecalculate] = useState(
    window.localStorage.getItem('recalculate')
  )
  const roles = localStorage.getItem('permissions').split(',')

  if (vehicleData) {
    if (
      !approvedBy &&
      vehicleData.vehicleSalvage &&
      vehicleData.vehicleSalvage.approvedBy
    ) {
      setApprovedBy(vehicleData.vehicleSalvage.approvedBy['@id'])
    }
    if (mileageEstimate === null) {
      setMileageEstimate(vehicleData.mileageEstimate)
    }
  }

  window.addEventListener('storage', function () {
    setRecalculate(window.localStorage.getItem('recalculate'))
  })

  useEffect(() => {
    if (!adminUsers) {
      const getSelectOptions = async () => {
        const admins = await getAdminUsers()
        setAdminUsers(admins)
      }

      getSelectOptions()
    }
  }, [])

  const handleCalculate = async () => {
    if (!isLoadingCalculate) {
      setIsLoadingCalculate(true)

      await calculatePrices({
        quoteId: record['@id'],
      })

      if (record.leadContact.vehicle.vehicleSalvage.chosenValue) {
        await calculateCommission({
          vehicle: record.leadContact.vehicle['@id'],
          recalculate: true,
        })
      }

      if (record.leadContact.vehicle.vehicleSalvage.highAdjustmentPercentage) {
        await calculateAdjustmentPrice(
          record['@id'],
          'cost',
          record.leadContact.vehicle.vehicleSalvage.highAdjustmentCost
        )
      }

      refresh()

      setIsLoadingCalculate(false)
      window.localStorage.setItem('recalculate', 'false')
      window.dispatchEvent(new Event('storage'))
    }
  }

  const handleEstimateChange = async (e) => {
    setMileageEstimate(e.target.value)

    await validateAutoSaveSingleInput(
      record,
      e.target,
      notify,
      refresh,
      'Mileage Estimated'
    )
  }

  const handleApprovedByChange = async (e) => {
    setApprovedBy(e.target.value)

    await validateAutoSaveSingleInput(
      record,
      e.target,
      notify,
      refresh,
      'Approved By'
    )
  }

  const saveChosenValue = async (
    props,
    inputEvent,
    notify,
    refresh,
    inputPopupText = ''
  ) => {
    await validateAutoSaveSingleInput(
      record,
      inputEvent,
      notify,
      refresh,
      inputPopupText
    )

    await calculateCommission({
      vehicle: record.leadContact.vehicle['@id'],
      recalculate: true,
    })

    refresh()
  }

  return (
    <EditBase>
      <Form>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          columns={16}
          rowSpacing={1}
          columnSpacing={2}
          className="cap-panel"
        >
          <CapFields />

          <Grid item xs={2}>
            <TextInput
              label="Mileage"
              type="number"
              class={!mileageEstimate ? 'mileage-confirm' : 'estimated-mileage'}
              readonly={true}
              disabled={true}
              inputProps={{
                step: 1,
                min: 0,
              }}
              onKeyDown={blockInvalidCharFromNumberInput}
              source="leadContact.vehicle.mileage"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl sx={{ width: '100%' }} size="small">
              <InputLabel id="est-milage-label">Est. Mileage?</InputLabel>
              <Select
                labelId="est-milage-label"
                label="Est. Mileage?"
                name="leadContact.vehicle.mileageEstimate"
                value={mileageEstimate}
                onChange={handleEstimateChange}
                readonly={true}
                disabled={true}
              >
                <MenuItem disabled value="">
                  Est. Mileage?
                </MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <TextInput
              label="Customer wants"
              type="number"
              readonly={true}
              disabled={true}
              inputProps={{
                step: 0.01,
                min: 0,
              }}
              onKeyDown={blockInvalidCharFromNumberInput}
              source="leadContact.vehicle.vehicleSalvage.customerWants"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh,
                  'Customer Wants'
                )
              }
            />
          </Grid>

          <RetailAndManualInputs />

          <Grid item xs={2}>
            <FormControl sx={{ width: '100%' }} size="small">
              <InputLabel id="approved-by-label">Approved by</InputLabel>
              <Select
                labelId="approved-by-label"
                name="leadContact.vehicle.vehicleSalvage.approvedBy"
                defaultValue="default"
                label="Approved by"
                readonly={true}
                disabled={true}
                value={
                  vehicleData.vehicleSalvage && approvedBy !== null
                    ? approvedBy
                    : 'default'
                }
                onChange={handleApprovedByChange}
              >
                <MenuItem disabled value="default">
                  Approved By?
                </MenuItem>
                {adminUsers &&
                  adminUsers.map((user) => {
                    return (
                      <MenuItem key={user.id} value={user.id}>
                        {user.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Grid>

          {/* 2nd row */}
          <Grid
            item
            xs={2}
            sx={{
              '&.MuiGrid-item': {
                paddingTop: '0',
              },
            }}
          >
            <Button
              fullWidth
              disabled={true}
              color={recalculate === 'false' ? 'primary' : 'error'}
              onClick={handleCalculate}
            >
              Calculate
            </Button>
          </Grid>
          <Grid item xs={2}>
            {record.leadContact.vehicle.vehicleSalvage &&
              record.leadContact.vehicle.vehicleSalvage.low40Adjustment && (
                <TextInput
                  label="Guide price"
                  disabled
                  source="leadContact.vehicle.vehicleSalvage.low40Adjustment"
                />
              )}
            {record.leadContact.vehicle.vehicleSalvage &&
              !record.leadContact.vehicle.vehicleSalvage.low40Adjustment && (
                <TextInput
                  label="Guide price"
                  disabled
                  source="leadContact.vehicle.vehicleSalvage.low40Value"
                />
              )}
          </Grid>
          <Grid item xs={2}>
            {record.leadContact.vehicle.vehicleSalvage &&
              record.leadContact.vehicle.vehicleSalvage.highAdjustment && (
                <TextInput
                  label="Max price"
                  disabled
                  source="leadContact.vehicle.vehicleSalvage.highAdjustment"
                />
              )}
            {record.leadContact.vehicle.vehicleSalvage &&
              !record.leadContact.vehicle.vehicleSalvage.highAdjustment && (
                <TextInput
                  label="Max price"
                  disabled
                  source="leadContact.vehicle.vehicleSalvage.highValue"
                />
              )}
          </Grid>
          <Grid item xs={2}>
            <TextInput
              label="Chosen value £"
              type={'number'}
              inputProps={{
                step: 0.01,
                min: 0,
              }}
              readonly={true}
              disabled={true}
              onKeyDown={blockInvalidCharFromNumberInput}
              source="leadContact.vehicle.vehicleSalvage.chosenValue"
              onBlur={async (e) =>
                await saveChosenValue(
                  record,
                  e.target,
                  notify,
                  refresh,
                  'Chosen Value'
                )
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextInput
              label="Commission"
              type={'number'}
              inputProps={{
                step: 0.01,
                min: 0,
              }}
              readonly={true}
              disabled={true}
              onKeyDown={blockInvalidCharFromNumberInput}
              source="leadContact.vehicle.vehicleSalvage.calcCommission"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh,
                  'Commission'
                )
              }
            />
          </Grid>

          <AutotraderFields />
        </Grid>
      </Form>
    </EditBase>
  )
}

export default CapPanel

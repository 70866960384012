import { Edit, TextInput, SimpleForm } from 'react-admin'

const EditTag = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput label="Tag Name" source="tagName" fullWidth required />
      </SimpleForm>
    </Edit>
  )
}

export default EditTag

import { Button, ButtonGroup, FormControl } from '@mui/material'
import PropTypes from 'prop-types'

const AdjustmentSelect = ({
  adjustmentSettings,
  selectedIndex,
  confirmed,
  onChange,
  adjustName,
}) => {
  const getOptionName = (option, index) => {
    return typeof option.label === 'undefined'
      ? `Option #${index + 1}`
      : option.label
  }

  return (
    <FormControl fullWidth>
      <span>{adjustName}</span>
      <ButtonGroup variant="contained" style={{ boxShadow: 'none' }}>
        {adjustmentSettings.adjustmentValues.map((option, index) => {
          if (getOptionName(option, index) !== 'Unknown') {
            return (
              <Button
                onClick={onChange}
                color={`${
                  confirmed && selectedIndex === index
                    ? 'success'
                    : !confirmed && selectedIndex === index
                    ? 'secondary'
                    : 'error'
                }`}
                data-confrimed={confirmed}
                data-selected={selectedIndex === index}
                value={index}
                key={index}
              >
                {getOptionName(option, index)}
              </Button>
            )
          }
        })}
      </ButtonGroup>
    </FormControl>
  )
}

AdjustmentSelect.propTypes = {
  adjustmentSettings: PropTypes.any,
  selectedIndex: PropTypes.any,
  confirmed: PropTypes.any,
  onChange: PropTypes.any,
  adjustName: PropTypes.string,
}

export default AdjustmentSelect

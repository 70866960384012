import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const LotDetails = ({ sb }) => {
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        padding: '0 !important',
      }}
    >
      <Grid item xs={3}>
        <h4
          style={{
            color: '#ED9C23',
            display: 'inline-flex',
            marginRight: '1em',
            fontWeight: '700',
          }}
        >
          Lot Number:
        </h4>
        {sb && sb.lot_number ? (
          <a
            href={'http://seller.copart.co.uk/lotdisplay/' + sb.lot_number}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="contained"
              sx={{
                color: '#FFFFFF !important',
                textDecoration: 'none !important',
                background: '#1065CA',
                border: 'none !important',
                borderRadius: '16px',
                height: '34px',
                width: '125px',
                '&:hover': {
                  background: '#1065CA',
                },
                '& > a': {
                  textDecoration: 'none !important',
                  background: '#1065CA !important',
                },
              }}
            >
              {sb.lot_number}
            </Button>
          </a>
        ) : (
          <React.Fragment>TBD</React.Fragment>
        )}
      </Grid>
      <Grid
        item
        container
        xs={8}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {sb && sb.yard ? (
          <React.Fragment>
            <Grid
              item
              xs={4}
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Typography
                sx={{
                  background: 'transparent',
                  lineHeight: '26px',
                  letterSpacing: '0.46 px',
                  color: '#ED9C23',
                  fontWeight: '700',
                }}
              >
                Yard Details:{' '}
              </Typography>
              <Typography
                sx={{
                  background: 'transparent',
                  lineHeight: '26px',
                  letterSpacing: '0.46 px',
                  marginRight: '5px',
                  fontWeight: '700',
                  marginLeft: '5px',
                }}
              >
                ID:
              </Typography>
              <Typography
                sx={{
                  background: 'transparent',
                  fontWeight: '400',
                  lineHeight: '26px',
                  letterSpacing: '0.46 px',
                }}
              >
                {sb.yard.id}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Typography
                sx={{
                  background: 'transparent',
                  lineHeight: '26px',
                  letterSpacing: '0.46 px',
                  marginRight: '5px',
                  fontWeight: '700',
                }}
              >
                Name:
              </Typography>
              <Typography
                sx={{
                  background: 'transparent',
                  fontWeight: '400',
                  lineHeight: '26px',
                  letterSpacing: '0.46 px',
                }}
              >
                {sb.yard.name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Typography
                sx={{
                  background: 'transparent',
                  lineHeight: '26px',
                  letterSpacing: '0.46 px',
                  marginRight: '5px',
                  fontWeight: '700',
                }}
              >
                Tel No:{' '}
              </Typography>
              <a
                href={'tel: ' + sb.yard.telephone_number}
                style={{
                  background: '#F7FC03',
                  border: '1px solid #FFF',
                  borderRadius: '2.59px',
                  color: '#2A2A2A',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: '143%',
                  padding: '5px 10px',
                  textDecoration: 'none',
                }}
              >
                {sb.yard.telephone_number}
              </a>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid
              item
              xs={3}
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              TBD
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  )
}

LotDetails.propTypes = {
  sb: PropTypes.object,
}

export default LotDetails

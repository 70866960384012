import React, { useState } from 'react'
import { useNotify, useRecordContext } from 'react-admin'
import { Box, Button, Typography, CircularProgress } from '@mui/material'
import {
  setReviewStatusSalvageBookingsLead,
  payBalanceSalvageBookingsLead,
  redirectToSB,
} from '../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const CustomerHappyPopup = ({ setPopupPanelName, chequePayeeName }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [loading, setLoading] = useState(false)

  const handleButtonClick = async function (status) {
    if (!loading) {
      setLoading(true)
    }

    // Update send review status in salvage bookings
    const setReviewStatusResponseStatus =
      await setReviewStatusSalvageBookingsLead(
        parseInt(record.originId),
        status
      )

    if (setReviewStatusResponseStatus) {
      // Post new payment to salvage bookings
      const createBalancePaymentResponseStatus =
        await payBalanceSalvageBookingsLead(
          parseInt(record.originId),
          0,
          'balance',
          chequePayeeName ?? ''
        )
      if (createBalancePaymentResponseStatus) {
        redirectToSB(record.originId)
        setLoading(false)
        setPopupPanelName('')
      } else {
        setLoading(false)
        notify('Something went wrong, please try again', { type: 'warning' })
      }
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  return (
    <React.Fragment>
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: 700,
            fontSize: '25px',
            marginBottom: 10,
            textTransform: 'uppercase',
          }}
        >
          Was the customer happy?
        </Typography>
        <Box sx={{ textAlign: 'center', position: 'relative' }}>
          <Button
            variant="contained"
            name="setSendReviewStatusNo"
            disabled={loading}
            onClick={() => handleButtonClick('false')}
            sx={{
              fontWeight: 700,
              fontSize: '23.2px',
              lineHeight: '16.38px',
              padding: 4,
              backgroundColor: '#D70000',
              color: '#FFFFFF',
              border: '4px solid #FFFFFF !important',
              minWidth: '145px',
              marginRight: 4,
              '&:hover': {
                backgroundColor: '#D70000',
              },
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            name="setSendReviewStatusYes"
            disabled={loading}
            onClick={() => handleButtonClick('true')}
            sx={{
              fontWeight: 700,
              fontSize: '23.2px',
              lineHeight: '16.38px',
              padding: 4,
              backgroundColor: '#00D715',
              color: '#FFFFFF',
              border: '4px solid #FFFFFF !important',
              minWidth: '145px',
              '&:hover': {
                backgroundColor: '#00D715',
              },
            }}
          >
            Yes
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </Box>
    </React.Fragment>
  )
}

CustomerHappyPopup.propTypes = {
  setPopupPanelName: PropTypes.func,
  chequePayeeName: PropTypes.string,
}

export default CustomerHappyPopup

import { Button, Grid, Typography } from '@mui/material'
import React from 'react'

const NoPhoneNumber = (props) => {
  const submit = (event) => {
    event.preventDefault()
    let number = event.target.phoneNumber.value

    location.hash = '#/inbound-call?number=' + number
  }

  return (
    <React.Fragment>
      <Grid container columnSpacing={1} className="quote-list__filter">
        <Grid item xs={6} className="quote-list__filter-grid">
          <form className="login-form" onSubmit={submit} method="get">
            <Typography variant={'h4'}>
              Please search for a phone number
            </Typography>
            <br />
            <input name="phoneNumber" type="text" />
            <Button
              className="login-form__submit"
              variant="contained"
              type="submit"
              color="primary"
              label="Search"
              sx={{ marginLeft: '10px' }}
            >
              Search
            </Button>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default NoPhoneNumber

import React from 'react'
import DialButton from './Modals/DialButton'
import PropTypes from 'prop-types'

export const CallButton = (props) => {
  const { record } = props
  if (!record) return null
  let id = parseInt(record.originId)
  return (
    <React.Fragment>
      {record && record.telNumber ? (
        <DialButton
          number={record.telNumber}
          goToEditPage={true}
          originId={id}
        />
      ) : null}
    </React.Fragment>
  )
}

CallButton.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
}

import { Layout, Sidebar } from 'react-admin'
import AppBar from './AppBar'
import CustomMenu from './Menu'

const AppSidebar = (props) => {
  return <Sidebar transitionDuration={100} {...props} />
}

const MyLayout = (props) => (
  <Layout {...props} appBar={AppBar} menu={CustomMenu} sidebar={AppSidebar} />
)

export default MyLayout

import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useRecordContext } from 'react-admin'
import PartialNotRequired from './PartialNotRequired'
import PartialPaid from './PartialPaid'
import PartialPending from './PartialPending'
import PartialNotPaid from './PartialNotPaid'
import PropTypes from 'prop-types'

function PartialPayment({ sb }) {
  const record = useRecordContext()

  if (sb) {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: '#ED9C23',
                fontWeight: '700',
                letterSpacing: '0.46px',
                marginRight: '5px',
                textTransform: 'uppercase',
              }}
            >
              Partial Payment:
            </Typography>
          </Grid>

          {record.leadContact.vehicle.vehicleSalvage.chequePayment ? (
            <PartialNotRequired />
          ) : sb.payments.partial ? (
            sb.payments.partial.payment_paid ? (
              <PartialPaid payment={sb.payments.partial} />
            ) : (
              <PartialPending sb={sb} />
            )
          ) : (
            !sb.cancelled &&
            (!record.deposit ||
              (sb.payments.deposit && sb.payments.deposit.payment_paid)) &&
            (!record.leadContact.vehicle.vehicleCommercial ||
              !record.leadContact.vehicle.vehicleCommercial
                .commercialPurchase ||
              record.quoteRequestInvoices[0]) && <PartialNotPaid sb={sb} />
          )}
        </Grid>
      </React.Fragment>
    )
  }
}

PartialPayment.propTypes = {
  sb: PropTypes.object,
}

export default PartialPayment

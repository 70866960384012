import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

const AGENTS_PER_SCROLL = 5

const getCollectionAgents = (agents, tableRef) => {
  const [agentsList, setAgentsList] = useState([])
  const [distanceBottom, setDistanceBottom] = useState(0)

  const getAgentsToDisplay = () => {
    if (typeof agents === 'undefined') {
      return []
    }

    const currentListLength = agentsList.length
    const futureListLength = currentListLength + AGENTS_PER_SCROLL
    const newAgentsArray = agents.slice(agentsList.length, futureListLength)

    if (futureListLength >= agents.length) {
      tableRef.current.removeEventListener('scroll', scrollListener)
    }

    return newAgentsArray
  }

  const scrollListener = useCallback(() => {
    let bottom = tableRef.current.scrollHeight - tableRef.current.clientHeight

    if (!distanceBottom) {
      setDistanceBottom(Math.round((bottom / 100) * 20))
    }

    if (tableRef.current.scrollTop > bottom - distanceBottom) {
      const newData = getAgentsToDisplay()
      setAgentsList((prev) => [...prev, ...newData])
    }
  }, [distanceBottom])

  useLayoutEffect(() => {
    tableRef.current.addEventListener('scroll', scrollListener)

    return () => {
      tableRef.current.removeEventListener('scroll', scrollListener)
    }
  }, [scrollListener])

  useEffect(() => {
    setAgentsList(getAgentsToDisplay())
  }, [])

  return { agentsList }
}

export { getCollectionAgents }

import React from 'react'
import QuoteHistoryList from './List'
import './style/index.scss'

const QuoteHistoryPanel = (props) => {
  return (
    <React.Fragment>
      <QuoteHistoryList />
    </React.Fragment>
  )
}

export default QuoteHistoryPanel

import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { dateConverter } from '../../../../Utils/ConversionUtils'
import { useRecordContext } from 'react-admin'
import { capitalize } from '@mui/material'

const formatDate = (dateFromApi) => {
  const { date, hour } = dateConverter(dateFromApi, false)

  return (
    <React.Fragment>
      <div>{date}</div>
      <div className="table-body__hour">{hour}</div>
    </React.Fragment>
  )
}

const QuoteHistoryList = () => {
  const record = useRecordContext()

  return (
    <TableContainer>
      <Table stickyHeader className="table">
        <TableHead className="table-header">
          <TableRow>
            <TableCell className="table-header__individual">
              Date / Time
            </TableCell>
            <TableCell className="table-header__individual">
              Coll. Price
            </TableCell>
            <TableCell className="table-header__individual">
              Cust. Price
            </TableCell>
            <TableCell className="table-header__individual">
              Commission
            </TableCell>
            <TableCell className="table-header__individual">
              Coll. Agent
            </TableCell>
            <TableCell className="table-header__individual">Method</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.offerHistory.toReversed().map((row, key) => {
            let type = row.source + ' (' + capitalize(row.quoteType) + ')'
            if (type.indexOf('API offer (') === -1) {
              return (
                <TableRow key={key}>
                  <TableCell className="table-body">
                    {formatDate(row.timeAdded)}
                  </TableCell>
                  <TableCell className="table-body table-body__collector-price">
                    £{row.collectorPrice.toFixed(2)}
                  </TableCell>
                  <TableCell className="table-body table-body__customer-price">
                    £{row.customerPrice.toFixed(2)}
                  </TableCell>
                  <TableCell className="table-body table-body__commission">
                    £{row.commission.toFixed(2)}
                  </TableCell>
                  <TableCell className="table-body">
                    {row.collector && row.collector.collectionAgentCompanyName}
                  </TableCell>
                  <TableCell className="table-body table-body__source">
                    {row.source} ({capitalize(row.quoteType)})
                  </TableCell>
                </TableRow>
              )
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default QuoteHistoryList

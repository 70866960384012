import React from 'react'

import {
  BooleanInput,
  Create,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  FormDataConsumer,
} from 'react-admin'
import { Typography } from '@mui/material'

const CreateCollectionAgentZone = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          label="Collection zone name"
          source="collectionAgentZoneName"
          validate={required()}
        />
        <Typography>Collect on:</Typography>
        <BooleanInput source="collectionAgentDay1" label="Monday" fullWidth />
        <BooleanInput source="collectionAgentDay2" label="Tuesday" fullWidth />
        <BooleanInput
          source="collectionAgentDay3"
          label="Wednesday"
          fullWidth
        />
        <BooleanInput source="collectionAgentDay4" label="Thursday" fullWidth />
        <BooleanInput source="collectionAgentDay5" label="Friday" fullWidth />
        <BooleanInput source="collectionAgentDay6" label="Saturday" fullWidth />
        <BooleanInput source="collectionAgentDay7" label="Sunday" fullWidth />
        <SelectInput
          label="Estimated collection time"
          source="collectionAgentEstimated"
          emptyText="Please select"
          choices={[
            { id: '0', name: 'Please select' },
            { id: '1', name: '1 day' },
            { id: '2', name: '2 days' },
            { id: '3', name: '3 days' },
            { id: '4', name: '4 days' },
            { id: '5', name: '5 days' },
            { id: '6', name: '6 days' },
            { id: '7', name: '7 days' },
            { id: '8', name: '8 days' },
            { id: '9', name: '9 days' },
            { id: '10', name: '10 days' },
          ]}
        />
        <SelectInput
          label="Guaranteed collection time"
          source="collectionAgentGuaranteed"
          emptyText="Please select"
          choices={[
            { id: '1', name: '1 day' },
            { id: '2', name: '2 days' },
            { id: '3', name: '3 days' },
            { id: '4', name: '4 days' },
            { id: '5', name: '5 days' },
            { id: '6', name: '6 days' },
            { id: '7', name: '7 days' },
            { id: '8', name: '8 days' },
            { id: '9', name: '9 days' },
            { id: '10', name: '10 days' },
          ]}
        />
        <RadioButtonGroupInput
          source="collectionAgentOohCollection"
          choices={[
            { id: 2, name: 'Yes' },
            { id: 1, name: 'Sometimes' },
            { id: 0, name: 'Never' },
          ]}
        />
        <RadioButtonGroupInput
          source="pricingMethod"
          choices={[
            { id: 1, name: 'Price per ton' },
            { id: 0, name: 'Weight Brackets' },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.pricingMethod === '1' && (
              <TextInput source="perTon" label="Price per ton" />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.pricingMethod === '0' && (
              <React.Fragment>
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  }}
                >
                  Weight Pricing
                </Typography>
                <Typography
                  style={{
                    marginBottom: '2rem',
                  }}
                >
                  Please put into each box your maximum price paid at this time
                  for each weight bracket. This is the maximum you will pay for
                  each vehicle including commission (WE DO NOT ADD COMMISSION TO
                  THIS PRICE).
                </Typography>
                <TextInput source="collectionAgent0800" label="0 - 800kg" />
                <TextInput
                  source="collectionAgent8011100"
                  label="801kg - 1100kg"
                />
                <TextInput
                  source="collectionAgent11011250"
                  label="1101kg - 1250kg"
                />
                <TextInput
                  source="collectionAgent12511400"
                  label="1251kg - 1400kg"
                />
                <TextInput
                  source="collectionAgent1400"
                  label="1401kg - 1600kg"
                />
              </React.Fragment>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export default CreateCollectionAgentZone

import { useState } from 'react'
import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from '@mui/material'
import { setReviewStatusSalvageBookingsLead } from '../../../../Utils/SalvageBookingsUtils'
import PropTypes from 'prop-types'

const SendReviewStatus = ({ sb }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [status, setStatus] = useState(
    !sb || sb.send_review_status === null ? '' : !!sb.send_review_status
  )
  const [validationError, setValidationError] = useState(false)
  const [loading, setLoading] = useState(false)
  const record = useRecordContext()

  const handleChange = (event) => {
    setStatus(event.target.value)
    setValidationError(false)
  }

  const handleButtonClick = async function () {
    if (status === null || status === '') {
      notify('Send review status must be set', {
        type: 'error',
      })
      setValidationError(true)
      return
    }

    if (!loading) {
      setLoading(true)
    }

    // Update send review status in salvage bookings
    const responseStatus = await setReviewStatusSalvageBookingsLead(
      record.originId,
      status ? 'true' : 'false'
    )

    if (responseStatus) {
      refresh()
      setLoading(false)
    } else {
      setLoading(false)
      notify('Something went wrong, please try again', { type: 'warning' })
    }
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={3}>
          <h4
            style={{
              color: '#ED9C23',
              textTransform: 'uppercase',
            }}
          >
            Send Review:
          </h4>
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth error={!!validationError}>
            <Select
              name="review-status"
              select
              displayEmpty
              value={status}
              onChange={handleChange}
              sx={{
                marginTop: 2,
                lineHeight: '1rem',
                borderRadius: '4px',
                height: '30px !important',
                minHeight: '30px !important',
                '& > .MuiOutlinedInput-root': {
                  minHeight: '30px !important',
                  fontWeight: '700',
                },
              }}
            >
              <MenuItem key="Select" value="">
                Select
              </MenuItem>
              <MenuItem key="Yes" value={true}>
                Yes
              </MenuItem>
              <MenuItem key="No" value={false}>
                No
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              label="Update review status"
              fullWidth
              disabled={loading}
              onClick={handleButtonClick}
              sx={{
                background: '#ED9C23',
                border: '2px solid #FFF !important',
                color: '#FFF',
                height: '40px',
                '&:hover': {
                  background: '#ED9C23',
                },
              }}
            >
              Update
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

SendReviewStatus.propTypes = {
  sb: PropTypes.object,
}

export default SendReviewStatus

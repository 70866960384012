import React, { useEffect, useState } from 'react'
import { Button, Form, useNotify, useRecordContext } from 'react-admin'
import { Grid, MenuItem, TextField, Typography } from '@mui/material'
import {
  getTemplateMapping,
  getTemplates,
} from '../../../../Utils/QuoteRequestUtils'
import { sendSmsTemplated } from '../../../../Utils/CommunicationsUtils'
import ModalOptions from './ModalOptions'
import './style/index.scss'
import PropTypes from 'prop-types'

const SendText = ({ setContactLogScreen }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [textTemplatesList, setTextTemplatesList] = useState([])
  const [textForm, setTextForm] = useState({
    templateId: '',
    templateName: '',
    smsContent: '',
    leadId: record.id,
  })
  const [smsTemplatePurpose, setSmsTemplatePurpose] = useState('')

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setTextForm({
      ...textForm,
      [name]: value,
    })
  }

  const handleTemplateChange = async (e) => {
    const { value } = e.target
    const templateMapped = await getTemplateMapping('sms', value, record.id)

    setTextForm({
      ...textForm,
      templateId: value,
      templateName: templateMapped.templateName,
      smsContent: templateMapped.content,
    })

    setSmsTemplatePurpose(templateMapped.purpose)
  }

  const handleSendText = async () => {
    let content = ''
    if (smsTemplatePurpose === 'custom_text') {
      content = textForm.content
    }
    const result = await sendSmsTemplated({
      leadId: textForm.leadId,
      purpose: smsTemplatePurpose,
      content: content,
    })

    if (result.status === 'success') {
      notify('Text sent successfully', { type: 'success' })
      setContactLogScreen('list')
    } else {
      notify(result.message, { type: 'error' })
    }
  }

  const getDataFromApi = async () => {
    const result = await getTemplates('text')
    setTextTemplatesList(result)
  }

  useEffect(() => {
    getDataFromApi()
  }, [])

  return (
    <React.Fragment>
      <Typography
        variant="h5"
        component="h2"
        className="contact-log__header-title"
      >
        Send Text
      </Typography>
      <Form onSubmit={handleSendText} className="reminder-form">
        <Grid container spacing={1} xs={12}>
          <Grid container spacing={1} xs={6} sx={{ paddingRight: 2 }}>
            <Grid item flex={1} xs={12}>
              <TextField
                name="template"
                select
                label="Template"
                onChange={handleTemplateChange}
                value={textForm.templateId}
                required
              >
                {textTemplatesList.map((option, key) => (
                  <MenuItem key={key} value={option.id}>
                    {option.smsTargetLabel}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={0} xs={6}>
            <Grid item flex={1} xs={12}>
              <TextField
                name="content"
                label="Content"
                multiline
                rows={7}
                onChange={handleInputChange}
                defaultValue={textForm.smsContent}
              />
            </Grid>
            <Grid item flex={1} xs={12}>
              <Button
                type="submit"
                sx={{ float: 'right' }}
                label="Send Text"
                color="primary"
                size="large"
              />
              <Button
                onClick={() => setContactLogScreen('list')}
                type="button"
                sx={{
                  float: 'right',
                  marginRight: '10px',
                }}
                label="Cancel"
                color="primary"
                size="large"
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
      <ModalOptions setContactLogScreen={setContactLogScreen} />
    </React.Fragment>
  )
}

SendText.propTypes = {
  setContactLogScreen: PropTypes.func,
}

export default SendText

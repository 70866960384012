import React, { useEffect, useState } from 'react'
import { Button, Form, useNotify, useRecordContext } from 'react-admin'
import {
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import {
  getTemplateMapping,
  getTemplates,
} from '../../../../Utils/QuoteRequestUtils'
import { sendEmailTemplated } from '../../../../Utils/CommunicationsUtils'
import ModalOptions from './ModalOptions'
import './style/index.scss'
import PropTypes from 'prop-types'

const SendEmail = ({ setContactLogScreen }) => {
  const notify = useNotify()
  const record = useRecordContext()
  const [emailTemplatesList, setEmailTemplatesList] = useState([])
  const [emailForm, setEmailForm] = useState({
    templateId: '',
    templateName: '',
    title: '',
    content: '',
    cc: '',
    bcc: '',
    leadId: record.id,
  })
  const [displayPreview, setDisplayPreview] = useState('none')
  const [emailTemplatePurpose, setEmailTemplatePurpose] = useState('')

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setEmailForm({
      ...emailForm,
      [name]: value,
    })
  }

  const handleTemplateChange = async (e) => {
    const { value } = e.target
    const templateMapped = await getTemplateMapping('email', value, record.id)

    if (templateMapped.result === 'error') {
      notify('Unable to generate email preview', { type: 'error' })

      setDisplayPreview('none')
    } else {
      setDisplayPreview('block')
    }

    setEmailForm({
      ...emailForm,
      templateId: value,
      templateName: templateMapped.templateName,
      title: templateMapped.subject,
      content: templateMapped.content,
    })

    setEmailTemplatePurpose(templateMapped.purpose)
  }

  const handleSendEmail = async () => {
    const result = await sendEmailTemplated({
      leadId: emailForm.leadId,
      cc: emailForm.cc,
      bcc: emailForm.bcc,
      purpose: emailTemplatePurpose,
    })

    if (result.status === 'success') {
      notify('Email sent successfully', { type: 'success' })
      setContactLogScreen('list')
    } else {
      notify(result.error, { type: 'error' })
    }
  }

  const getDataFromApi = async () => {
    const result = await getTemplates('email')
    setEmailTemplatesList(result)
  }

  useEffect(() => {
    getDataFromApi()
  }, [])

  return (
    <React.Fragment>
      <Typography
        variant="h5"
        component="h2"
        className="contact-log__header-title"
      >
        Send Email
      </Typography>
      <Form onSubmit={handleSendEmail} className="reminder-form">
        <Grid container spacing={1} xs={12}>
          <Grid container spacing={1} xs={6} style={{ paddingRight: 2 }}>
            <Grid item flex={1} xs={12}>
              <TextField
                name="template"
                select
                label="Template"
                onChange={handleTemplateChange}
                value={emailForm.templateId}
                required
              >
                {emailTemplatesList.map((option, key) => (
                  <MenuItem key={key} value={option.id}>
                    {option.targetLabel}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item flex={1} xs={12}>
              <TextField
                name="title"
                label="Title / Subject"
                onChange={handleInputChange}
                value={emailForm.title}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item flex={1} xs={12}>
              <TextField name="cc" label="CC" onChange={handleInputChange} />
            </Grid>
            <Grid item flex={1} xs={12}>
              <TextField
                name="quote"
                label="BCC"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} xs={12}>
            <Grid item flex={1} xs={12}>
              <InputLabel
                variant="filled"
                className="preview-email__label"
                sx={{ display: displayPreview }}
              >
                Preview
              </InputLabel>
              <Paper
                sx={{
                  background: 'white',
                  overflow: 'auto',
                  display: displayPreview,
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: emailForm.content }} />
              </Paper>
            </Grid>
            <Grid item flex={1} xs={12}>
              <Button
                type="submit"
                sx={{ float: 'right' }}
                label="Send Email"
                color="primary"
                size="large"
              />
              <Button
                onClick={() => setContactLogScreen('list')}
                type="button"
                sx={{ float: 'right', marginRight: '10px' }}
                label="Cancel"
                color="primary"
                size="large"
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
      <ModalOptions setContactLogScreen={setContactLogScreen} />
    </React.Fragment>
  )
}

SendEmail.propTypes = {
  setContactLogScreen: PropTypes.func,
}

export default SendEmail

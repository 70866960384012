import { useState } from 'react'
import $ from 'jquery'
import {
  EditBase,
  Form,
  SelectInput,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { Close, Search } from '@mui/icons-material'
import { getAddressLookup } from '../../Utils/RestUtils'
import { IconButton, Stack } from '@mui/material'
import {
  validateAutoSaveMultiInput,
  validateAutoSaveSingleInput,
} from '../../Utils/AutoSave'
import PropTypes from 'prop-types'
import { blockBlankCharFromInput } from '../../Utils/ConversionUtils'

const PostcodeLookup = (props) => {
  const [addressList, setAddressList] = useState()
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()

  const getAddressList = async () => {
    try {
      let postcode = $('[name="leadContact.postcode"]').val()
      const data = await getAddressLookup(postcode)
      if (data.status === 'error') {
        notify(data.error, { type: 'error' })
      } else {
        setAddressList(data.address)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const clearAddress = () => {
    setAddressList([])
  }

  const populateAddress = (e) => {
    const addressParts = e.target.value.split(',')
    let inputsToSave = [
      {
        name: 'leadContact.postcode',
        value: $('[name="leadContact.postcode"]').val(),
      },
    ]

    if (typeof addressParts[0] !== 'undefined') {
      populateInput('leadContact.addressLine1', addressParts[0])

      inputsToSave = [
        ...inputsToSave,
        {
          name: 'leadContact.addressLine1',
          value: addressParts[0],
        },
      ]
    }

    if (typeof addressParts[1] !== 'undefined') {
      populateInput('leadContact.addressLine2', addressParts[1])

      inputsToSave = [
        ...inputsToSave,
        {
          name: 'leadContact.addressLine2',
          value: addressParts[1],
        },
      ]
    }

    if (typeof addressParts[2] !== 'undefined') {
      populateInput('leadContact.town', addressParts[2])

      inputsToSave = [
        ...inputsToSave,
        {
          name: 'leadContact.town',
          value: addressParts[2],
        },
      ]
    }

    if (typeof addressParts[3] !== 'undefined') {
      populateInput('leadContact.county', addressParts[3])

      inputsToSave = [
        ...inputsToSave,
        {
          name: 'leadContact.county',
          value: addressParts[3],
        },
      ]
    }

    validateAutoSaveMultiInput(
      record,
      inputsToSave,
      notify,
      refresh,
      'Postcode & Address'
    )

    props.setPostCodeChanged(true)
    refresh()
    clearAddress()
  }

  const populateInput = (input, value) => {
    let field = document.querySelector('[name="' + input + '"]')

    const lastValue = field.value
    field.value = value
    const event = new Event('input', { bubbles: true })
    const tracker = field._valueTracker

    if (tracker) {
      tracker.setValue(lastValue)
    }

    field.dispatchEvent(event)
  }

  const handleOnBlur = async (e) => {
    await validateAutoSaveSingleInput(record, e.target, notify, refresh)
    props.setPostCodeChanged(true)
  }

  return (
    <EditBase>
      <Form>
        <TextInput
          label="Postcode"
          source="leadContact.postcode"
          autoComplete="off"
          data-lpignore="true"
          data-form-type="other"
          onKeyDown={blockBlankCharFromInput}
          onBlur={handleOnBlur}
          InputProps={{
            endAdornment: (
              <IconButton edge="end" onClick={getAddressList}>
                <Search color="primary" />
              </IconButton>
            ),
          }}
        />
        {addressList && addressList.length > 0 && (
          <Stack direction="row">
            <SelectInput
              source="name"
              label="Select Address list"
              choices={addressList}
              translateChoice={false}
              onChange={(e) => populateAddress(e)}
              optionValue="name"
            />
            <IconButton
              style={{
                alignSelf: 'baseline',
                marginTop: '8px',
              }}
              onClick={clearAddress}
            >
              <Close />
            </IconButton>
          </Stack>
        )}
      </Form>
    </EditBase>
  )
}

PostcodeLookup.propTypes = {
  setPostCodeChanged: PropTypes.func,
}

export default PostcodeLookup

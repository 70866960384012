import { useGetIdentity } from 'ra-core'
import { useGetOne } from 'react-admin'

const getCurrentUserRoles = () => {
  const { data: currentUserData, isLoading: currentUserLoading } = useGetOne(
    'users',
    {
      id: 'api/users/' + localStorage.getItem('user_id'),
    }
  )

  if (currentUserData) {
    return { roles: currentUserData.roles, isLoading: currentUserLoading }
  }

  return {
    roles: undefined,
    isLoading: true,
  }
}

const getCurrentUser = () => {
  const { data: currentUser } = useGetIdentity()

  const { data: currentUserData, isLoading: currentUserLoading } = useGetOne(
    'users',
    {
      id: 'api/users/' + currentUser.id,
    }
  )

  if (currentUserData) {
    return { user: currentUserData, isLoading: currentUserLoading }
  }

  return {
    user: undefined,
    isLoading: true,
  }
}

const currentUserHasRole = (role) => {
  const { data: currentUserData } = useGetOne('users', {
    id: 'api/users/' + localStorage.getItem('user_id'),
  })

  return !!(currentUserData && currentUserData.roles.includes(role))
}

export { getCurrentUserRoles, currentUserHasRole, getCurrentUser }

const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT

const getLatestContactTime = async (quoteID, type) => {
  const request = new Request(
    `${entrypoint}/services/quote/latest-contact?quoteID=${quoteID}&type=${type}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const addContactLog = async (formData) => {
  const request = new Request(`${entrypoint}/services/quote/contact-logs`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const addTags = async (formData) => {
  try {
    const request = new Request(`${entrypoint}/services/quote/tags`, {
      body: JSON.stringify(formData),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    })

    return await fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }
        return responseJSON
      })
  } catch (e) {
    return Promise.reject(
      'There was an error adding a tag this might be because the API has changed'
    )
  }
}

const removeTag = async (formData) => {
  const request = new Request(`${entrypoint}/services/quote/tags`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'DELETE',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const sendSMS = async (formData) => {
  const request = new Request(`${entrypoint}/services/quote/send-sms`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const sendPurchaseAgreementEmail = async (leadId, purpose) => {
  const request = new Request(
    `${entrypoint}/services/quote/contract-acceptance?leadId=${leadId}&purpose=${purpose}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const sendEmail = async (formData) => {
  const request = new Request(`${entrypoint}/services/quote/send-email`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const addQuoteHistory = async (formData) => {
  const request = new Request(`${entrypoint}/services/quote/quote-history`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getTemplates = async (type) => {
  const request = new Request(
    `${entrypoint}/services/quote/get-templates?type=${type}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getTemplateMapping = async (requestType, templateId, leadId) => {
  const request = new Request(
    `${entrypoint}/services/quote/get-template-mapping?requestType=${requestType}&templateId=${templateId}&leadIri=${leadId}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getAgentAssignedToLeadByOfferHistory = (offerHistory) => {
  return offerHistory.find((offer) => offer.accepted === true)
}

const scrollToPanelById = (id) => {
  let retries = 0
  const scroll = () => {
    retries += 0
    if (retries > 50) return
    const element = document.getElementById(id)
    if (element) {
      setTimeout(() => element.scrollIntoView(), 0)
    } else {
      setTimeout(scroll, 100)
    }
  }
  scroll()
}

const getNextLead = async (currentLead) => {
  let permissions = localStorage.getItem('permissions')
  const request = new Request(
    `${entrypoint}/services/remote-workers/get-next?currentLead=${currentLead}&role=${permissions}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getCurrentLeadState = async (leadId) => {
  const request = new Request(
    `${entrypoint}/services/quote/get-lead-state/${leadId}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const getDuplicatesByReg = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/quote/check-duplicates-by-reg`,
    {
      body: JSON.stringify(formData),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}
const reassignBookingAgent = async (formData) => {
  const request = new Request(
    `${entrypoint}/services/quote/reassign-booking-agent`,
    {
      body: JSON.stringify(formData),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'POST',
    }
  )

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const canBeAssigned = async (quoteId, agentId) => {
  let formData = {
    quoteId: quoteId,
    agentId: agentId,
  }
  const request = new Request(`${entrypoint}/services/validate-can-assign`, {
    body: JSON.stringify(formData),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    method: 'POST',
  })

  return await fetch(request)
    .then((response) => response.json())
    .then((responseJSON) => {
      if (responseJSON.Error !== undefined) {
        throw Error(responseJSON.Error)
      }

      return responseJSON
    })
}

const removeLeadReplyTagFromInboxNotificationList = async (inboxDetails) => {
  const replyTagDetails = inboxDetails.inboxQuote.tags.filter((tag) => {
    return tag.tagName.toUpperCase() === 'REPLY'
  })

  if (replyTagDetails.length > 0) {
    //Remove tag reply
    await removeTag({
      quoteID: inboxDetails.inboxQuote['@id'],
      tag: replyTagDetails[0]['@id'],
    })
  }
}

export {
  addQuoteHistory,
  getLatestContactTime,
  addContactLog,
  addTags,
  removeTag,
  sendSMS,
  sendEmail,
  getTemplates,
  getTemplateMapping,
  sendPurchaseAgreementEmail,
  getAgentAssignedToLeadByOfferHistory,
  scrollToPanelById,
  getNextLead,
  getCurrentLeadState,
  getDuplicatesByReg,
  canBeAssigned,
  removeLeadReplyTagFromInboxNotificationList,
  reassignBookingAgent,
}

import React, { useEffect, useState } from 'react'
import { useRecordContext } from 'react-admin'
import { getLeadNotifications } from '../../../../Utils/RestUtils'
import { dateConverter } from '../../../../Utils/ConversionUtils'
import ListReminders from './List'
import './style/index.scss'

const RemindersPanel = (props) => {
  const { data, setData } = { ...props }
  const record = useRecordContext()
  const [screen, setScreen] = useState('list')

  const getDataFromApi = async (record) => {
    const result = await getLeadNotifications(
      record.originId,
      1,
      'targetTimestamp',
      'asc'
    )

    const newDataArray = result.map((row) => {
      const { date, hour } = dateConverter(row.targetTimestamp)

      return {
        ...row,
        date: date,
        hour: hour,
      }
    })

    setData(newDataArray)
  }

  useEffect(() => {
    getDataFromApi(record)
  }, [])

  return (
    <React.Fragment>
      {screen === 'list' && (
        <ListReminders data={data} setReminderScreen={setScreen} />
      )}
    </React.Fragment>
  )
}

export default RemindersPanel

import React, { useState } from 'react'
import { Button, Grid } from '@mui/material'
import { TextInput, useNotify, useRecordContext, useRefresh } from 'react-admin'
import { validateAutoSaveSingleInput } from '../../../../Utils/AutoSave'
import { getAutotraderLink } from '../../../../Utils/RestUtils'

const AutotraderFields = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [isLoadingAutotrader, setIsLoadingAutotrader] = useState(false)

  const handleAutotrader = async () => {
    if (!isLoadingAutotrader) {
      setIsLoadingAutotrader(true)

      const result = await getAutotraderLink({
        type: 'basic',
        data: record,
      })

      window.open(result.link, '_blank')

      refresh()
      setIsLoadingAutotrader(false)
    }
  }

  return (
    <React.Fragment>
      <Grid
        item
        xs={2}
        sx={{
          '&.MuiGrid-item': {
            paddingTop: '0px',
          },
        }}
      >
        <Button
          disabled={isLoadingAutotrader}
          className="cap-panel__button cap-panel__button-green"
          onClick={handleAutotrader}
          fullWidth
        >
          Autotrader
        </Button>
      </Grid>
      <Grid item xs={2}>
        <TextInput
          label="Alternative URL 1"
          source="leadContact.vehicle.foundUrl"
          onBlur={async (e) =>
            await validateAutoSaveSingleInput(
              record,
              e.target,
              notify,
              refresh,
              'Alternative URL 1'
            )
          }
        />
      </Grid>
      <Grid item xs={2}>
        <TextInput
          label="Alternative URL 2"
          source="leadContact.vehicle.foundUrl2"
          onBlur={async (e) =>
            await validateAutoSaveSingleInput(
              record,
              e.target,
              notify,
              refresh,
              'Alternative URL 2'
            )
          }
        />
      </Grid>
    </React.Fragment>
  )
}

export default AutotraderFields

import { TextInput, SimpleForm, Create } from 'react-admin'

const CreateTag = (props) => {
  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <TextInput label="Tag Name" source="tagName" fullWidth required />
      </SimpleForm>
    </Create>
  )
}

export default CreateTag

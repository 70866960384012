import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useRecordContext } from 'react-admin'

const MTButton = () => {
  const record = useRecordContext()
  const [open, setOpen] = useState(false)
  const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT
  const params = new URLSearchParams({ quoteID: record.id })
  const [content, setContent] = useState()

  const request = new Request(
    `${entrypoint}/services/quote/motor-trading?${params}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
      method: 'GET',
    }
  )

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    fetch(request)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (responseJSON.Error !== undefined) {
          throw Error(responseJSON.Error)
        }
        if (responseJSON.result === 'error') {
          setContent(responseJSON.message)
        }
        if (responseJSON.payload && content !== responseJSON.payload) {
          setContent(responseJSON.payload)
        }
      })
  })

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Motor Trading</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Button fullWidth onClick={handleClickOpen} color="primary">
        MT
      </Button>
    </React.Fragment>
  )
}

export default MTButton

import Resizer from 'react-image-file-resizer'

const imageFileResizer = (file, maxWidth = 300, maxHeight = 300) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })

export { imageFileResizer }

import { DoNotDisturb, Refresh } from '@mui/icons-material'
import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import {
  BooleanInput,
  Form,
  FormDataConsumer,
  RadioButtonGroupInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import {
  validateAutoSaveMultiInput,
  validateAutoSaveSingleInput,
} from '../../../../Utils/AutoSave'
import {
  getHpiLookupFiles,
  getTemporaryQuoteRequestInvoiceUrls,
  hpiCheck,
  uploadVATInvoices,
  removeInvoice,
} from '../../../../Utils/RestUtils'
import {
  blockInvalidCharFromNumberInput,
  numberInputOnWheelPreventChange,
} from '../../../../Utils/ConversionUtils'

const PriceTypePanel = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const vehicleDetails = record.leadContact.vehicle
  const [loadingHpi, setLoadingHpi] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [quoteRequestInvoiceUrls, setQuoteRequestInvoiceUrls] = useState(null)
  const [hpiLookUps, setHpiLookUps] = useState()
  const [altHpiLookUps, setAltHpiLookUps] = useState()
  const [depositAmount, setDepositAmount] = useState(undefined)
  const depositAmountRef = useRef()
  const isUserASuperAdmin = localStorage
    .getItem('permissions')
    .includes('ROLE_ADMIN')
  const isUserASalvageUser = localStorage
    .getItem('permissions')
    .includes('ROLE_SALVAGE')

  const isAdmin = isUserASuperAdmin || isUserASalvageUser

  const handleHpiClick = (additional) => {
    if (!loadingHpi) {
      setLoadingHpi(true)
    }
    const getPDF = async (quote) => {
      const response = await hpiCheck(quote, additional)
      setLoadingHpi(false)

      if (response.result === 'error') {
        notify(response.message, { type: 'error' })
      } else {
        window.open(response.url, '_blank')
        getHpiLookups()
        refresh()
      }
    }

    getPDF(record.id)
  }

  const handleViewHpi = (url) => {
    window.open(url, '_blank')
  }

  const handleCapture = async (e) => {
    setIsUploading(true)

    const files = e.target.files
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData()
      formData.append('quoteRequestId', record['@id'])
      formData.append('file', files[i], files[i].name)

      await uploadVATInvoices(formData)
    }

    getQuoteRequestInvoiceUrls()

    setIsUploading(false)
  }

  const handleDeletenvoice = async (invoiceId) => {
    await removeInvoice(invoiceId)

    getQuoteRequestInvoiceUrls()
  }

  let fetchingQuoteRequestInvoiceUrls = false
  let fetchingHpiLookups = false

  const getQuoteRequestInvoiceUrls = async () => {
    fetchingQuoteRequestInvoiceUrls = true

    const response = await getTemporaryQuoteRequestInvoiceUrls(record.originId)

    if (response.result === 'success') {
      setQuoteRequestInvoiceUrls(response.temporary_urls)
    } else {
      setQuoteRequestInvoiceUrls([])
      notify('Unable to get quote request invoices', { type: 'error' })
    }

    fetchingQuoteRequestInvoiceUrls = false
  }

  const getHpiLookups = async () => {
    fetchingHpiLookups = true

    const response = await getHpiLookupFiles(record.originId)

    if (response.result === 'success') {
      let lookups = response.temporary_urls
      if (lookups.main) {
        setHpiLookUps(lookups.main)
      }
      if (lookups.alt) {
        setAltHpiLookUps(lookups.alt)
      }
    } else {
      setHpiLookUps([])
      notify('Unable to get Previous HPI lookups', { type: 'error' })
    }

    fetchingHpiLookups = false
  }

  const onDepositAmountBlur = async () => {
    const inputToSave = [
      {
        name: 'depositAmount',
        value: depositAmount === '' ? 0 : depositAmount,
      },
    ]

    await validateAutoSaveMultiInput(record, inputToSave, notify, refresh)
  }

  const handleDepositChange = async (e) => {
    let depositValue = e.target.checked

    let inputToSave = [
      {
        name: 'deposit',
        value: depositValue,
      },
      {
        name: 'depositAmount',
        value: `${depositValue ? '20' : '0'}`,
      },
    ]

    await validateAutoSaveMultiInput(record, inputToSave, notify, refresh)

    //As we are updating 2 fields, we need to refresh to make sure all fields are updated
    refresh()
  }

  const handleDepositAmountChanged = (e) => {
    if (e.target.value > 20) {
      setDepositAmount(20)
      notify('Value must be £20 or lower', { type: 'error' })
    } else {
      setDepositAmount(e.target.value)
    }
  }

  useEffect(() => {
    if (quoteRequestInvoiceUrls === null && !fetchingQuoteRequestInvoiceUrls) {
      getQuoteRequestInvoiceUrls()
    }
  }, [])

  useEffect(() => {
    if (!hpiLookUps && !fetchingHpiLookups) {
      getHpiLookups()
    }
  }, [])

  useEffect(() => {
    setDepositAmount(record.depositAmount)
  }, [record, refresh])

  useLayoutEffect(() => {
    depositAmountRef.current.addEventListener('blur', onDepositAmountBlur)

    return () => {
      depositAmountRef.current.removeEventListener('blur', onDepositAmountBlur)
    }
  })

  return (
    <React.Fragment>
      <Form>
        <Grid
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 2fr)',
            gridColumn: '1 / span 6',
            gridRowStart: 1,
            gridRowEnd: 1,
          }}
        >
          <Grid
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 2fr)',
              gridRowStart: 1,
              gridRowEnd: 1,
              marginRight: '1rem',
              marginBottom: '1rem',
            }}
          >
            <Grid
              style={{
                display: 'grid',
                gridColumn: '1 / span 12',
                gridRowStart: 1,
                gridRowEnd: 1,
                marginRight: '1rem',
                marginBottom: '1rem',
              }}
            >
              <Typography className="collection-panel__title">
                Price type
              </Typography>
              <RadioButtonGroupInput
                source="leadContact.vehicle.vehicleSalvage.chosenValueOpt"
                label="Price Type"
                row
                onChange={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
                choices={[
                  { id: 1, name: 'Scrap' },
                  { id: 2, name: 'Rule' },
                  { id: 3, name: 'Calc' },
                ]}
              />
            </Grid>
            <Grid
              style={{
                display: 'grid',
                gridColumn: 1,
                gridRowStart: 2,
                gridRowEnd: 2,
                marginRight: '1.4rem',
              }}
            >
              <Typography
                className="collection-panel__title"
                sx={{ height: '20px' }}
              >
                Deposit
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ marginTop: `${record.deposit ? '0' : '-154px'}` }}
              >
                <Typography>No</Typography>
                <BooleanInput
                  source="deposit"
                  label="Yes"
                  style={{ alignItems: 'space-between' }}
                  onChange={handleDepositChange}
                />
              </Stack>

              <TextField
                name="depositAmount"
                label="Amount"
                type={'number'}
                disabled={!isAdmin}
                inputRef={depositAmountRef}
                onKeyDown={blockInvalidCharFromNumberInput}
                onWheel={numberInputOnWheelPreventChange}
                value={depositAmount}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 20,
                }}
                sx={{
                  marginTop: '1.4rem !important',
                  width: '100%',
                  display: `${record.deposit ? 'relative' : 'none'}`,
                }}
                onChange={(e) => handleDepositAmountChanged(e)}
              />
            </Grid>

            <Grid
              style={{
                display: 'grid',
                gridColumn: 2,
                gridRowStart: 2,
                gridRowEnd: 2,
              }}
            >
              <Typography
                className="collection-panel__title"
                sx={{ height: '20px' }}
              >
                Rtn. Prvt. Plates?
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>No</Typography>
                <BooleanInput
                  source="leadContact.vehicle.vehicleInfo.retainPrivatePlate"
                  label="Yes"
                  style={{ alignItems: 'space-between' }}
                  onChange={async (e) =>
                    await validateAutoSaveSingleInput(
                      record,
                      e.target,
                      notify,
                      refresh
                    )
                  }
                />
              </Stack>
              <TextField
                name="leadContact.vehicle.vehicleInfo.additionalRegistration"
                label="Additional Reg"
                sx={{ marginTop: '1.4rem !important' }}
                defaultValue={
                  vehicleDetails.vehicleInfo &&
                  vehicleDetails.vehicleInfo.additionalRegistration
                }
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh,
                    'Additional Registration'
                  )
                }
              />
              {!altHpiLookUps &&
                vehicleDetails.vehicleInfo &&
                vehicleDetails.vehicleInfo.retainPrivatePlate &&
                vehicleDetails.vehicleInfo.additionalRegistration &&
                vehicleDetails.vehicleInfo.additionalRegistration !== '' && (
                  <Button
                    variant="contained"
                    onClick={() => handleHpiClick('true')}
                    disabled={loadingHpi}
                    color="action"
                    endIcon={loadingHpi && <CircularProgress size={20} />}
                    style={{
                      height: '2rem',
                      marginRight: '1.4rem',
                      backgroundColor: '#00ECA5',
                      color: '#000',
                    }}
                  >
                    Additional HPI Lookup
                  </Button>
                )}

              {altHpiLookUps && (
                <Button
                  variant="contained"
                  onClick={() => handleViewHpi(altHpiLookUps.url)}
                  color="action"
                  style={{
                    height: '2rem',
                    marginRight: '1.4rem',
                    backgroundColor: '#00ECA5',
                    color: '#000',
                  }}
                >
                  View Additional HPI
                </Button>
              )}
              {altHpiLookUps && isUserASuperAdmin && (
                <Button
                  variant="contained"
                  onClick={() => handleHpiClick('true')}
                  disabled={loadingHpi}
                  color="action"
                  endIcon={loadingHpi && <CircularProgress size={20} />}
                  style={{
                    height: '2rem',
                    marginRight: '1.4rem',
                    marginTop: '5px',
                    backgroundColor: '#00ECA5',
                    color: '#000',
                  }}
                >
                  Re-check HPI <Refresh />
                </Button>
              )}
            </Grid>

            <Grid
              style={{
                display: 'grid',
                gridColumn: '1 / span 12',
                gridRowStart: 3,
                gridRowEnd: 3,
              }}
              spacing={1}
            >
              {hpiLookUps && (
                <React.Fragment>
                  <Typography className="collection-panel__title">
                    Finance?
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>No</Typography>
                    <BooleanInput
                      source="leadContact.vehicle.vehicleDocumentation.outstandingFinance"
                      label="Yes"
                      style={{ alignItems: 'space-between' }}
                      onChange={async (e) =>
                        await validateAutoSaveSingleInput(
                          record,
                          e.target,
                          notify,
                          refresh
                        )
                      }
                    />
                  </Stack>
                  <TextField
                    name="leadContact.vehicle.vehicleDocumentation.outstandingFinanceNotes"
                    label="Finance Ref"
                    sx={{ marginTop: '1.4rem !important' }}
                    disabled={
                      vehicleDetails.vehicleDocumentation &&
                      vehicleDetails.vehicleDocumentation
                        .outstandingFinanceNotes
                    }
                    aria-readonly={
                      vehicleDetails.vehicleDocumentation &&
                      vehicleDetails.vehicleDocumentation
                        .outstandingFinanceNotes
                    }
                    value={
                      vehicleDetails.vehicleDocumentation &&
                      vehicleDetails.vehicleDocumentation
                        .outstandingFinanceNotes
                    }
                    onBlur={async (e) =>
                      await validateAutoSaveSingleInput(
                        record,
                        e.target,
                        notify,
                        refresh,
                        'Finance Reference'
                      )
                    }
                  />
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          <Grid
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 2fr)',
              gridColumn: '2 / span 6',
              gridRowStart: 1,
              gridRowEnd: 1,
            }}
            spacing={1}
          >
            <Grid
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 2fr)',
                gridColumn: '1 / span 12',
                gridRowStart: 1,
                gridRowEnd: 1,
              }}
            >
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: 1,
                  gridRowStart: 1,
                  gridRowEnd: 1,
                  marginBottom: '1rem',
                }}
              >
                <Button
                  disableElevation={true}
                  href={'https://www.check-mot.service.gov.uk/'}
                  target={'_blank'}
                  variant="contained"
                  color="primary"
                  style={{
                    height: '2rem',
                    marginRight: '1.4rem',
                    marginTop: '28px',
                  }}
                >
                  Check Mileage
                </Button>
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: 2,
                  gridRowStart: 1,
                  gridRowEnd: 1,
                  marginBottom: '1rem',
                }}
                spacing={1}
              >
                <Typography className="collection-panel__title">
                  Mileage Clear?
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <BooleanInput
                    source="leadContact.vehicle.mileageClear"
                    label="Yes"
                    style={{ alignItems: 'space-between' }}
                    onChange={async (e) =>
                      await validateAutoSaveSingleInput(
                        record,
                        e.target,
                        notify,
                        refresh
                      )
                    }
                  />
                </Stack>
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: 1,
                  gridRowStart: 2,
                  gridRowEnd: 2,
                  marginBottom: '1rem',
                }}
                spacing={1}
              >
                {!hpiLookUps && (
                  <Button
                    variant="contained"
                    onClick={() => handleHpiClick('false')}
                    disabled={loadingHpi}
                    color="secondary"
                    endIcon={loadingHpi && <CircularProgress size={20} />}
                    style={{
                      height: '2rem',
                      marginRight: '1.4rem',
                      marginTop: '28px',
                    }}
                  >
                    HPI Lookup
                  </Button>
                )}
                {hpiLookUps && (
                  <Button
                    variant="contained"
                    onClick={() => handleViewHpi(hpiLookUps.url)}
                    color="action"
                    style={{
                      height: '2rem',
                      marginRight: '1.4rem',
                      backgroundColor: '#00ECA5',
                      color: '#000',
                    }}
                  >
                    View HPI
                  </Button>
                )}
                {hpiLookUps && isUserASuperAdmin && (
                  <Button
                    variant="contained"
                    onClick={() => handleHpiClick('false')}
                    disabled={loadingHpi}
                    color="action"
                    endIcon={loadingHpi && <CircularProgress size={20} />}
                    title="Get new HPI"
                    style={{
                      height: '2rem',
                      marginRight: '1.4rem',
                      marginTop: '5px',
                      backgroundColor: '#00ECA5',
                      color: '#000',
                    }}
                  >
                    Re-Check HPI <Refresh />
                  </Button>
                )}
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: 2,
                  gridRowStart: 2,
                  gridRowEnd: 2,
                  marginBottom: '1rem',
                }}
                spacing={1}
              >
                <Typography className="collection-panel__title">
                  HPI Clear?
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <BooleanInput
                    source="leadContact.vehicle.vehicleDocumentation.hpiClear"
                    label="Yes"
                    style={{ alignItems: 'space-between' }}
                    onChange={async (e) =>
                      await validateAutoSaveSingleInput(
                        record,
                        e.target,
                        notify,
                        refresh
                      )
                    }
                  />
                </Stack>
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 12',
                  gridRowStart: 3,
                  gridRowEnd: 3,
                }}
                spacing={1}
              >
                <TextField
                  name="leadContact.vehicle.vehicleDocumentation.hpiNotes"
                  label="HPI Notes"
                  value={
                    vehicleDetails.vehicleDocumentation &&
                    vehicleDetails.vehicleDocumentation.hpiNotes
                  }
                  sx={{ marginTop: '1.4rem !important' }}
                  onBlur={async (e) =>
                    await validateAutoSaveSingleInput(
                      record,
                      e.target,
                      notify,
                      refresh,
                      'HPI Notes'
                    )
                  }
                />
              </Grid>
              <Grid
                style={{
                  display: 'grid',
                  gridColumn: '1 / span 12',
                  gridRowStart: 4,
                  gridRowEnd: 4,
                }}
                spacing={1}
              >
                <Typography className="collection-panel__title">
                  VAT Registered?
                </Typography>
                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    formData.status === 'Collected' &&
                    !isAdmin && (
                      <Typography color="error">
                        To change the VAT details of a vehicle once booked in,
                        please speak to your manager
                      </Typography>
                    )
                  }
                </FormDataConsumer>
                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    (formData.status !== 'Collected' || isAdmin) && (
                      <React.Fragment>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>No</Typography>
                          <BooleanInput
                            source="leadContact.vehicle.vehicleCommercial.commercialPurchase"
                            label="Yes"
                            style={{ alignItems: 'space-between' }}
                            onChange={async (e) =>
                              await validateAutoSaveSingleInput(
                                record,
                                e.target,
                                notify,
                                refresh
                              )
                            }
                          />
                        </Stack>

                        <Button
                          component="label"
                          size="large"
                          className="customer-notes__upload-btn"
                          disabled={isUploading}
                        >
                          Upload docs
                          <input
                            hidden
                            accept="application/*"
                            multiple
                            type="file"
                            onChange={handleCapture}
                          />
                        </Button>
                        {quoteRequestInvoiceUrls &&
                          quoteRequestInvoiceUrls
                            .slice(0)
                            .reverse()
                            .map((invoice, index) => {
                              return (
                                <Grid
                                  key={index}
                                  item
                                  xs={3}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '5px',
                                  }}
                                >
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={invoice.url}
                                    style={{
                                      marginRight: '15px',
                                    }}
                                  >
                                    View Invoice
                                  </a>
                                  <DoNotDisturb
                                    onClick={() =>
                                      handleDeletenvoice(invoice.id)
                                    }
                                  />
                                </Grid>
                              )
                            })}
                      </React.Fragment>
                    )
                  }
                </FormDataConsumer>
              </Grid>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  (formData.status !== 'Collected' || isAdmin) && (
                    <Grid
                      style={{
                        display: 'grid',
                        gridColumn: '1 / span 12',
                        gridRowStart: 5,
                        gridRowEnd: 5,
                      }}
                      spacing={1}
                    >
                      <TextField
                        name="leadContact.vehicle.vehicleCommercial.vatNumber"
                        label="VAT Number"
                        defaultValue={
                          vehicleDetails.vehicleCommercial &&
                          vehicleDetails.vehicleCommercial.vatNumber
                        }
                        sx={{ marginTop: '1.4rem !important' }}
                        onBlur={async (e) =>
                          await validateAutoSaveSingleInput(
                            record,
                            e.target,
                            notify,
                            refresh,
                            'VAT Number'
                          )
                        }
                      />
                    </Grid>
                  )
                }
              </FormDataConsumer>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </React.Fragment>
  )
}

export default PriceTypePanel

import { Card, CardContent } from '@mui/material'
import {
  BooleanField,
  Datagrid,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  List,
  TextField,
  WithRecord,
} from 'react-admin'
import AddUserModal from '../../Components/Users/AddUserModal'
import EditUserModal from '../../Components/Users/EditUserModal'

const HighValueFilter = () => (
  <FilterList label="High Value Status">
    <FilterListItem
      label="Yes"
      value={{
        highValueUser: 1,
      }}
    />
    <FilterListItem
      label="No"
      value={{
        highValueUser: 0,
      }}
    />
  </FilterList>
)

const FilterSidebar = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch label="Search User Role" source="roles" />
      <FilterLiveSearch label="Search User Name" source="displayName" />
      <FilterLiveSearch label="Search User Email" source="email" />
      <HighValueFilter />
    </CardContent>
  </Card>
)

const ListUsers = (props) => {
  return (
    <CardContent>
      <List
        {...props}
        aside={<FilterSidebar />}
        actions={<AddUserModal />}
        title="Users"
      >
        <Datagrid>
          <TextField label="Display name" source="displayName" />
          <TextField label="Roles" source="roles" />
          <BooleanField label="High Value User" source="highValueUser" />
          <WithRecord
            render={(record) => {
              return <EditUserModal currentUser={record} />
            }}
          />
        </Datagrid>
      </List>
    </CardContent>
  )
}

export default ListUsers

import React, { useState } from 'react'
import { Button, Grid, TextField, Tooltip } from '@mui/material'
import { useRecordContext, useRefresh } from 'react-admin'
import { getCAPQuote } from '../../../../Utils/RestUtils'

const CapFields = () => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const [isLoadingCap, setIsLoadingCap] = useState(false)
  let vehicleCap
  let tooltipData
  if (
    record.leadContact.vehicle.vehicleCapHistory !== null &&
    record.leadContact.vehicle.vehicleCapHistory.length > 0
  ) {
    const vehicleCapLength =
      record.leadContact.vehicle.vehicleCapHistory.length - 1

    vehicleCap = record.leadContact.vehicle.vehicleCapHistory[vehicleCapLength]

    tooltipData = [
      {
        name: 'Average',
        value: vehicleCap.average,
      },
      {
        name: 'Clean',
        value: vehicleCap.clean,
      },
      {
        name: 'Retail',
        value: vehicleCap.retail,
      },
    ]
  }

  const handleGetCap = async () => {
    if (!isLoadingCap) {
      setIsLoadingCap(true)

      await getCAPQuote(record.leadContact.vehicle['@id'])
      refresh()
      setIsLoadingCap(false)
    }
  }

  return (
    <React.Fragment>
      <Grid
        item
        xs={2}
        sx={{
          '&.MuiGrid-item': {
            paddingTop: '0',
          },
        }}
      >
        <Button
          className="cap-panel__button cap-panel__button-green"
          disabled={isLoadingCap}
          onClick={handleGetCap}
          fullWidth
        >
          Get Cap
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Tooltip
          title={
            tooltipData &&
            tooltipData.map((tip) => (
              <div key={tip.name} style={{ fontSize: '12px', padding: '2px' }}>
                <div style={{ width: '60px', display: 'inline-block' }}>
                  {tip.name}:
                </div>
                <div style={{ display: 'inline-block' }}>{tip.value}</div>
              </div>
            ))
          }
        >
          <TextField
            label="Cap Value"
            disabled
            value={vehicleCap && vehicleCap.value}
          />
        </Tooltip>
      </Grid>
    </React.Fragment>
  )
}

export default CapFields

import React, { useEffect, useState } from 'react'
import { ListGuesser } from '@api-platform/admin'

import { Card, CardContent, Typography } from '@mui/material'

import {
  ArrayField,
  BooleanField,
  Datagrid,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  FunctionField,
  TextField,
} from 'react-admin'

import { getOptions } from '../../Utils/RestUtils'

const StatusFilter = () => (
  <FilterList label="Rule Type">
    <FilterListItem
      label="Salvage Exclusion"
      value={{
        type: 'salvage_exclusion',
      }}
    />
    <FilterListItem
      label="CAP Lookup"
      value={{
        type: 'cap_lookup',
      }}
    />
    <FilterListItem
      label="Salvage"
      value={{
        type: 'salvage',
      }}
    />
    <FilterListItem
      label="Price Adjustment"
      value={{
        type: 'price_adjustment',
      }}
    />
    <FilterListItem
      label="Price Notes"
      value={{
        type: 'price_notes',
      }}
    />
  </FilterList>
)

const FilterSidebar = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch label="Search Rule set Name" source="name" />
      <FilterLiveSearch label="Match Value" source="ruleSetRules.matchValue" />
      <StatusFilter />
    </CardContent>
  </Card>
)

const ListRules = (props) => {
  const [matchCriteria, setMatchCriteria] = useState([])
  const [matchType, setMatchType] = useState([])

  async function loadData() {
    const tempMatchCriteria = await getOptions(
      'RuleSetsRules',
      'getCriteriaOptions'
    )
    const tempMatchType = await getOptions('RuleSetsRules', 'getMatchOptions')

    setMatchCriteria(tempMatchCriteria)
    setMatchType(tempMatchType)
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <CardContent>
      <Typography typography="h4" component="h1" mb={2}>
        Rules
      </Typography>

      <ListGuesser
        {...props}
        exporter={false}
        aside={<FilterSidebar />}
        filters={<React.Fragment></React.Fragment>}
        filterDefaultValues={{ type: 'salvage_exclusion' }}
      >
        <TextField source="name" label="Rule Name" />
        <TextField source="type" label="Rule Type" />
        <ArrayField source="ruleSetRules" label="Rules">
          <Datagrid bulkActionButtons={false}>
            {matchType.length > 0 ? (
              <FunctionField
                render={(record) => `${matchType[record.matchType].name}`}
                source="matchType"
              />
            ) : (
              <p>Loading...</p>
            )}
            {matchCriteria.length > 0 ? (
              <FunctionField
                render={(record) =>
                  `${matchCriteria[record.matchCriteria].name}`
                }
                source="matchCriteria"
              />
            ) : (
              <p>Loading...</p>
            )}
            <TextField source="matchValue" />
          </Datagrid>
        </ArrayField>
        <TextField
          source="collectionAgent.collectionAgentCompanyName"
          label="Collection Agent"
        />
        <BooleanField source="active" label="Active" />
      </ListGuesser>
    </CardContent>
  )
}

export default ListRules

import { Grid, TextField } from '@mui/material'
import {
  EditBase,
  Form,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { validateAutoSaveSingleInput } from '../../../../Utils/AutoSave'
import './style/index.scss'

const PriceAdjustPanel = () => {
  const refresh = useRefresh()
  const notify = useNotify()
  const record = useRecordContext()

  return (
    <EditBase>
      <Form>
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item flex={1} xs={3}>
            <TextInput
              name="leadContact.vehicle.vehicleSalvage.highAdjustmentPercentage"
              label="Price Adjust %"
              type={'number'}
              inputProps={{
                step: 0.01,
              }}
              readonly={true}
              disabled={true}
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh,
                  'Price Adjust %'
                )
              }
            />
          </Grid>
          <Grid item flex={1} xs={3}>
            <TextInput
              name="leadContact.vehicle.vehicleSalvage.highAdjustmentCost"
              label="Price Adjust £"
              type={'number'}
              inputProps={{
                step: 0.01,
              }}
              readonly={true}
              disabled={true}
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh,
                  'Price Adjust £'
                )
              }
            />
          </Grid>
          <Grid item flex={1} xs={6}>
            <TextField
              name="leadContact.vehicle.vehicleSalvage.highAdjustmentNote"
              label="Price Adjust Reason"
              defaultValue={
                record.leadContact.vehicle.vehicleSalvage &&
                record.leadContact.vehicle.vehicleSalvage.highAdjustmentNote
              }
              readonly={true}
              disabled={true}
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh,
                  'Price Adjust Reason'
                )
              }
            />
          </Grid>
        </Grid>
      </Form>
    </EditBase>
  )
}

export default PriceAdjustPanel
